export const scoreWords = ['Too short', 'Weak', 'Good', 'Strong'];
export const mobileEmailError = 'Please enter a valid Email or Mobile number';
export const statusConstant = { reject: 'Rejected', approve: 'Accepted', pending: 'Pending' };
export const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const errorMessage = {
    validateField: 'Please enter a valid',
    validateName: 'Please enter name with minimum 3 character and maximum 64',
    cardNumber:"Please enter 16 digits",
    cvv:"Please enter correct CVV",
    nameOnCard:"Please enter name with minimum 3 character and maximum 64",
    postalCode:"Please enter max 10 numeric digits",
    couponCode:"Please enter maximum 16 numeric digits",
    expiration:"Please type correct expiration date",
    validUrl: "Please enter an valid url"
 
};
export const dataNotFound = "Data not found";
export const dateFormat = '2022-05-03';
export const DiscardChanges = "Discard changes";
export const SaveChanges = "Save changes";
export const String = {
    deviceStatus: [
        { label: 'Accepted' },
        { label: 'Rejected' },

    ],
    assignFilter:[
        {label: 'All'},
        {label: 'Unassigned'},
        {label:"Assigned"}
    ]
};

export const audioVideoText = {
    title:'Live Audio & Video Calling SDK',
    subTitle:'Portego provides video SDKs come with powerful features and functionalities, such as real-time messaging, screen sharing, and recording capabilities. These features can be used to enhance the user experience and create engaging, interactive applications.',
    buttonLeft:'Start Now',
    buttonRight:'Talk to our Experts',
    featureHead:'Features that Build NexGen Audio & Video Calling Apps',
    firstFeatureTitle:'Live audio streaming',
    firstFeatureContent:'It is a powerful tool for businesses and developers looking to add real-time audio and video communication to their websites or applications. By integrating an audio and video SDK, you can take advantage of a global infrastructure that can handle the scale and latency of live audio and video calls, without needing to build your own infrastructure from scratch.',
    secondFeatureTitle:'Customize audio & video experience',
    secondFeatureContent:'To help increase the level of engagement of participants during the event, we provide Gamification where Host can send Quiz, Tests, quick actions to the Participants. Attendees can send their response once the window pops up.Build cross-channel live audio and video calling experience. Easily build a complete customized experience embedded in your app. Portego works on every platform such as Web, React Native, Flutter, Android, and iOS.',
    thirdFeatureTitle:'Record live audio and video',
    thirdFeatureContent:'Providing recording and access control features in virtual event software can help to increase the value of the product and provide a more flexible and customisable experience for users. By incorporating these features, you can help businesses and organisations to reach a broader audience and ensure that their virtual events remain secure and accessible only to authorised individuals.Portego recording is a service that lets you capture & record live audio and video calls to meet your business needs. Choose your layout, then record seperate audio and video calling live. You can also export the recorded audio or video calls, add watermarks, or use snapshots from them in real-time for content moderation.',
    fourthFeatureTitle:'Active speaker indication',
    fourthFeatureContent:'Offer some intelligent features to improve the overall meeting experience for medium and large groups. The active speaker technology you mentioned, which can identify or observe active speakers based on consistent speaking patterns in addition to high volume, can be a powerful tool for ensuring that the right people are in focus during a meeting.Building smooth backstage, on-stage, or breakout rooms API is now easy. We provide simple video calling APIs to connect multiple rooms with each other and on top of that, you can switch participants, send chat messages, fetch other room information, etc.',
    fifthFeatureTitle:'Moderate camera and mic permissions',
    fifthFeatureContent:'Provides the ability for hosts to set permissions for who can access the recording can help to ensure that the content remains secure and only accessible to authorized individuals. For example, hosts may want to restrict access to the recording to registered attendees or specific individuals who have been granted permission to view the content.Now its easy to build applications with multiple roles such as host moderator, and co-host. Our moderation video calling APIs help you to mute, unmute, enable the camera and disable the camera of other participants as a host.',
    sixthFeatureTitle:'Low latency globally',
    sixthFeatureContent:"Allows users to record virtual events and providing the ability to set permissions for who can access the recording can be a valuable feature for businesses or organisations that want to extend the reach of their virtual events beyond the live broadcast. By allowing attendees to record the event, you can ensure that the content remains available for future reference or for those who couldn't attend the live event.With a global intelligent network designed to reduce the latency and quality in any corner of the world. Our Video calling SDK edge network locations are the best in the market to deliver high-quality live video and audio.",
    
}



