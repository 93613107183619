import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { HomePlanCardAPI } from 'src/slices/Home';
import { RootState } from 'src/store';
import { ButtonPrimary } from 'src/components/CustomButtons';
import { CustomInfoCard, IntroHeading, IntroSubHeading, CustomBox, CustomGrid, IconContainer, UseCount, PlansContainer, HeadingTitle, HeadingSubTitle } from './homePageStyled';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Util } from 'src/utils/Auth0GetToken';

const HomePlans = () => {

    const { homePlanRecords } = useSelector((state: RootState) => state.Home);
    const dispatch = useDispatch();
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();
    
    useEffect(() => {
        async function getToken() {
            Auth0Util.setAccessTokenSilently(getAccessTokenSilently);
        }
        if (isAuthenticated) {
            getToken();
        }
    }, [getAccessTokenSilently, isAuthenticated]);

    useEffect(() => {
        dispatch(HomePlanCardAPI());
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return (
        <PlansContainer>
            <HeadingTitle>Plans designed for every business</HeadingTitle>
            <HeadingSubTitle>
                Get all great features of Portego tailored for your monthly active users
            </HeadingSubTitle>
            {/* Top info cards start */}
            <CustomGrid container sx={{ width: { xs: '90%', sm: '100%', md: '75%', lg: '80%' }, margin: '30px 0', }} columnGap={3} rowGap={2} >
                {homePlanRecords.map((data, index) =>
                    <Grid item xs={12} sm={3.5} md={3.5} lg={2.7} xl={2.7} key={index}>
                        <CustomInfoCard>
                            <IconContainer>{data.icon && <data.icon />}</IconContainer>
                            <IntroHeading gutterBottom >{data.subscription_type}</IntroHeading>
                            <IntroSubHeading gutterBottom>{data.status}</IntroSubHeading>
                            <UseCount gutterBottom>{data.active_users}</UseCount>
                            <CustomBox>
                                <ButtonPrimary
                                    id="estimate-your-usage"
                                    responsive={false}
                                    margin="1.3rem 0 0 0"
                                    fixedHeight="40px"
                                    fixedWidth='100%'
                                // onClick={() => navigate("/contact-us")}
                                >
                                    {data.subscription_plan}
                                </ButtonPrimary>
                            </CustomBox>
                        </CustomInfoCard>
                    </Grid>
                )}
            </CustomGrid>
            {/* Top info cards end */}
            <HeadingSubTitle>
                *These prices do not include taxes and fees.
            </HeadingSubTitle>
        </PlansContainer>
    )
}

export default HomePlans;