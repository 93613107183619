import { useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { SidebarContext } from "src/contexts/SidebarContext";
import { Box, Button, Drawer, Hidden } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuContainer from "src/container/MenuContainer";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
//import useAuth from "src/hooks/useAuth";
import Logo from "../Header/Logo";
import { useAuth0 } from "@auth0/auth0-react";
const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 10;
        height: 100%;
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 2)};
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { logout } = useAuth0();
  const closeSidebar = () => toggleSidebar();
  const handleLogout = async (): Promise<void> => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <Box
              sx={{ display: "flex", justifyContent: "center", height: "60px" }}
            >
              <Logo />
            </Box>
            <MenuContainer />
            <Button
              sx={{
                width: "220px",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                color: "#373737",
                left: 36,
                bottom: 10,
                position: "fixed",
                borderRadius: "0px",

                "&.active": {
                  color: "#ffffff",
                  borderRadius: "0px",
                  fontWeight: 500,
                  backgroundColor: "#3087EC",
                },
              }}
              onClick={handleLogout}
              id="signout-button"
              startIcon={<LockOpenTwoToneIcon sx={{ mr: 1 }} />}
            >
              {"Sign out"}
            </Button>
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection></TopSection>
              <MenuContainer />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
