import React from 'react';
function DownloadInvoiceIcon({ props }) {
  return (
    <>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16.2141" cy="16" r="16" fill="#F1F5FD" />
        <g clipPath="url(#clip0_935_4567)">
          <path
            d="M23.464 19.525C23.214 19.525 23.014 19.725 23.014 19.975V22.825H9.38901V19.95C9.38901 19.7 9.18901 19.5 8.93901 19.5C8.68901 19.5 8.48901 19.7 8.48901 19.95V23.05C8.48901 23.4 8.83901 23.7 9.26401 23.7H23.089C23.514 23.7 23.864 23.425 23.864 23.05V19.95C23.914 19.725 23.714 19.525 23.464 19.525Z"
            fill="#0062FD"
          />
          <path
            d="M15.4389 18.8C15.6639 19 15.9389 19.125 16.2139 19.125C16.4889 19.125 16.7639 19.025 16.9639 18.825L18.8889 16.975C19.0639 16.8 19.0639 16.525 18.8889 16.35C18.7139 16.175 18.4389 16.175 18.2639 16.35L16.6389 17.925V8.75005C16.6389 8.50005 16.4389 8.30005 16.1889 8.30005C15.9389 8.30005 15.7389 8.50005 15.7389 8.75005V17.9L14.1139 16.3C13.9389 16.125 13.6639 16.125 13.4889 16.3C13.3139 16.475 13.3139 16.75 13.4889 16.925L15.4389 18.8Z"
            fill="#0062FD"
          />
        </g>
        <defs>
          <clipPath id="clip0_935_4567">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(8.21411 8)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default DownloadInvoiceIcon;
