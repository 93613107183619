import React from 'react';
function CopyFileIcon({ props }) {
  return (
    <>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 2V11C4 11.3978 4.15804 11.7793 4.43934 12.0607C4.72064 12.342 5.10218 12.5 5.5 12.5H11.5C11.8978 12.5 12.2794 12.342 12.5607 12.0607C12.842 11.7793 13 11.3978 13 11V4.4315C13 4.23167 12.96 4.03386 12.8825 3.84968C12.805 3.66551 12.6914 3.49868 12.5485 3.359L10.0623 0.9275C9.78202 0.653493 9.40568 0.500049 9.01375 0.5H5.5C5.10218 0.5 4.72064 0.658035 4.43934 0.93934C4.15804 1.22064 4 1.60217 4 2V2Z"
          stroke="#686868"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 12.5V14C10 14.3978 9.84196 14.7794 9.56066 15.0607C9.27936 15.342 8.89782 15.5 8.5 15.5H2.5C2.10218 15.5 1.72064 15.342 1.43934 15.0607C1.15804 14.7794 1 14.3978 1 14V5.75C1 5.35218 1.15804 4.97064 1.43934 4.68934C1.72064 4.40804 2.10218 4.25 2.5 4.25H4"
          stroke="#686868"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default CopyFileIcon;