import React from "react";
function wallet({ props }) {
  return (
    <>
      <svg
        fill="#ffffff"
        width={props.width}
        height={props.height}
        version="1.1"
        id="lni_lni-wallet"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        xmlSpace="preserve"
      >
        <path
          d="M54.4,21.5h-1v-5.6c0-2.4-1.1-4.5-3-5.9c-1.9-1.4-4.3-1.7-6.5-0.9L8.1,21.5c-3.8,0.2-6.8,3.3-6.8,7.2v19.4
	c0,4,3.2,7.2,7.2,7.2h45.9c4,0,7.2-3.2,7.2-7.2V28.7C61.6,24.7,58.3,21.5,54.4,21.5z M45,12.4c1.1-0.4,2.4-0.2,3.4,0.5
	c1,0.7,1.6,1.8,1.6,3v5.6H18.8L45,12.4z M54.4,51.8H8.5c-2,0-3.7-1.7-3.7-3.7V28.7c0-2,1.7-3.7,3.7-3.7h45.9c2,0,3.7,1.7,3.7,3.7
	v2.7H46.4c-3.9,0-7.1,3.2-7.1,7.1s3.2,7.1,7.1,7.1h11.7v2.4C58.1,50.1,56.4,51.8,54.4,51.8z M58.1,34.9v7.3H46.4
	c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6H58.1z"
        />
      </svg>
    </>
  );
}

export default wallet;
