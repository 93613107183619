import React from "react";
function MenuIcon({ props }) {
  return (
    <>
     <svg 
     width={ props.width }
     height={ props.height } 
     viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
     <rect width="17.6732" height="2" fill="#0062FD"/>
     <rect x="5.6748" y="7" width="12" height="2" fill="#0062FD"/>
     <rect y="14" width="17.6732" height="2" fill="#0062FD"/>
     </svg>
     


    </>
  );
}

export default MenuIcon;