import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "src/store";
// import UserService from "./services/UserService";

// const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );

// UserService.initKeycloak(renderApp);
