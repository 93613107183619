import React from 'react';

export const VerifyEmailCodeIcon = () => {
    return (
        <>
            <svg width="223" height="150" viewBox="0 0 223 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.83">
                    <path d="M127.716 19.4272C126.851 21.2066 126.274 23.1008 125.813 25.0525C124.602 30.3908 116.759 55.0158 75.812 59.9523C29.8481 65.5202 5.56847 91.1211 16.18 119.362C28.637 152.425 116.932 163.332 169.297 128.834C221.663 94.2782 200.324 17.3033 174.199 6.85634C146.402 -4.27947 131.522 11.4484 127.716 19.4272Z" fill="#F1F5FD" />
                </g>
                <path d="M214.511 97.1749H181.628C179.775 97.1749 178.221 95.6277 178.221 93.783V76.4074C178.221 74.5628 179.775 73.0156 181.628 73.0156H214.511C216.364 73.0156 217.918 74.5628 217.918 76.4074V93.783C217.918 95.6872 216.424 97.1749 214.511 97.1749ZM181.688 73.6702C180.134 73.6702 178.878 74.9198 178.878 76.4669V93.8425C178.878 95.3897 180.134 96.6393 181.688 96.6393H214.57C216.125 96.6393 217.38 95.3897 217.38 93.8425V76.4669C217.38 74.9198 216.125 73.6702 214.57 73.6702H181.688Z" fill="#FF9900" />
                <path d="M217.619 80.3942H178.52C178.34 80.3942 178.221 80.2752 178.221 80.0967C178.221 79.9182 178.34 79.7992 178.52 79.7992H217.619C217.799 79.7992 217.918 79.9182 217.918 80.0967C217.918 80.2752 217.799 80.3942 217.619 80.3942Z" fill="#FF9900" />
                <path d="M212.776 78.7878C211.7 78.7878 210.863 77.9548 210.863 76.8837C210.863 75.8126 211.7 74.9795 212.776 74.9795C213.853 74.9795 214.69 75.8126 214.69 76.8837C214.69 77.9548 213.853 78.7878 212.776 78.7878ZM212.776 75.634C212.059 75.634 211.461 76.2291 211.461 76.9432C211.461 77.6572 212.059 78.2523 212.776 78.2523C213.494 78.2523 214.092 77.6572 214.092 76.9432C214.092 76.1696 213.494 75.634 212.776 75.634Z" fill="#FF9900" />
                <path d="M84.2979 3.03706V32.6113C84.2979 33.5634 85.1946 34.3369 86.2708 34.3369H148.627C149.703 34.3369 150.6 33.5634 150.6 32.6113V3.03706C150.6 2.08497 149.703 1.3114 148.627 1.3114H86.2708C85.1349 1.3114 84.2979 2.08497 84.2979 3.03706Z" fill="#3087EC" />
                <path d="M84.2979 2.2637V4.70343H150.6V2.2637C150.6 1.01409 149.703 0.00249623 148.627 0.00249623H86.2708C85.1349 -0.0570092 84.2979 0.954583 84.2979 2.2637Z" fill="#F1F5FD" />
                <path d="M138.583 2.32311C138.583 2.91816 139.061 3.45371 139.719 3.45371C140.317 3.45371 140.855 2.97767 140.855 2.32311C140.855 1.72806 140.377 1.1925 139.719 1.1925C139.061 1.1925 138.583 1.66855 138.583 2.32311Z" fill="#FF9900" />
                <path d="M142.17 2.32311C142.17 2.91816 142.648 3.45371 143.306 3.45371C143.904 3.45371 144.442 2.97767 144.442 2.32311C144.442 1.72806 143.963 1.1925 143.306 1.1925C142.708 1.1925 142.17 1.66855 142.17 2.32311Z" fill="#FF5A5A" />
                <path d="M145.758 2.32311C145.758 2.91816 146.236 3.45371 146.894 3.45371C147.492 3.45371 148.03 2.97767 148.03 2.32311C148.03 1.72806 147.551 1.1925 146.894 1.1925C146.296 1.1925 145.758 1.66855 145.758 2.32311Z" fill="#3087EC" />
                <path d="M108.753 13.218L144.445 13.218V11.0758L108.753 11.0758V13.218Z" fill="#E8E8E8" />
                <path d="M108.753 18.8235L144.445 18.8235V16.6813L108.753 16.6813V18.8235Z" fill="#E8E8E8" />
                <path d="M108.753 24.4886L144.445 24.4886V22.3465L108.753 22.3465V24.4886Z" fill="#E8E8E8" />
                <path d="M89.7878 24.4507L104.794 24.4507V11.181L89.7878 11.181V24.4507Z" fill="#FF5A5A" />
                <path d="M0 29.8743V103.84C0 105.089 1.01635 106.16 2.33164 106.16H107.494C108.75 106.16 109.826 105.149 109.826 103.84V29.8743C109.826 28.6247 108.81 27.5536 107.494 27.5536H2.33164C1.01635 27.6131 0 28.6247 0 29.8743Z" fill="#CDE0FF" />
                <path d="M96.6137 85.155H32.7627V87.5352H96.6137V85.155Z" fill="#BABABA" />
                <path d="M0 29.8743V33.5636H109.766V29.8743C109.766 28.6247 108.75 27.5536 107.435 27.5536H2.33164C1.01635 27.6131 0 28.6247 0 29.8743Z" fill="#F1F5FD" />
                <path d="M27.9799 46.7145H12.1367V62.4834H27.9799V46.7145Z" fill="white" />
                <path d="M27.9799 65.9344H12.1367V81.7034H27.9799V65.9344Z" fill="white" />
                <path d="M27.7406 85.155H11.8975V100.924H27.7406V85.155Z" fill="white" />
                <path d="M96.6137 46.7145H32.7627V49.0947H96.6137V46.7145Z" fill="white" />
                <path d="M96.6137 51.5935H32.7627V53.9737H96.6137V51.5935Z" fill="#3087EC" />
                <path d="M96.6137 65.9344H32.7627V68.3147H96.6137V65.9344Z" fill="#BABABA" />
                <path d="M96.6137 70.8141H32.7627V73.1943H96.6137V70.8141Z" fill="#3087EC" />
                <path d="M96.6137 56.8898H32.7627V59.27H96.6137V56.8898Z" fill="white" />
                <path d="M19.4306 60.0437L13.2129 55.6403L14.6477 53.6171L19.0719 56.7709L24.8711 50.1658L26.7244 51.7724L19.4306 60.0437Z" fill="#FF5A5A" />
                <path d="M19.8486 79.2043L13.6309 74.7414L15.0059 72.7777L19.4301 75.9315L25.289 69.3264L27.0826 70.9331L19.8486 79.2043Z" fill="#FF5A5A" />
                <path d="M12.7344 30.5882C12.7344 31.4212 13.4518 32.1353 14.2888 32.1353C15.1258 32.1353 15.8432 31.4212 15.8432 30.5882C15.8432 29.7551 15.1258 29.041 14.2888 29.041C13.4518 29.041 12.7344 29.6956 12.7344 30.5882Z" fill="#FF5A5A" />
                <path d="M8.19043 30.5882C8.19043 31.4212 8.90786 32.1353 9.74485 32.1353C10.5819 32.1353 11.2993 31.4212 11.2993 30.5882C11.2993 29.7551 10.5819 29.041 9.74485 29.041C8.90786 29.041 8.19043 29.6956 8.19043 30.5882Z" fill="#3087EC" />
                <path d="M5.26146 32.1353C6.11994 32.1353 6.81588 31.4426 6.81588 30.5882C6.81588 29.7337 6.11994 29.041 5.26146 29.041C4.40297 29.041 3.70703 29.7337 3.70703 30.5882C3.70703 31.4426 4.40297 32.1353 5.26146 32.1353Z" fill="#FF9900" />
                <path d="M203.218 35.26C204.514 29.7566 201.082 24.25 195.552 22.9607C190.023 21.6713 184.491 25.0874 183.195 30.5908C181.9 36.0942 185.332 41.6008 190.861 42.8901C196.391 44.1795 201.923 40.7633 203.218 35.26Z" fill="white" />
                <path d="M195.498 35.8247H188.205C187.846 35.8247 187.547 35.5271 187.547 35.1701V29.9336C187.547 29.5766 187.846 29.2791 188.205 29.2791H195.498C195.857 29.2791 196.156 29.5766 196.156 29.9336V35.1701C196.156 35.5271 195.857 35.8247 195.498 35.8247Z" fill="#3087EC" />
                <path d="M194.063 32.5522L196.634 31.0646L198.368 30.053C198.727 29.815 199.205 30.1125 199.205 30.5291V32.5522V34.5754C199.205 34.992 198.727 35.2895 198.368 35.0515L196.634 34.0399L194.063 32.5522Z" fill="#3087EC" />
                <path d="M222.343 138.293H3.70723C3.34852 138.293 3.10938 138.055 3.10938 137.698C3.10938 137.341 3.34852 137.103 3.70723 137.103H222.403C222.761 137.103 223.001 137.341 223.001 137.698C222.941 138.055 222.702 138.293 222.343 138.293Z" fill="#BABABA" />
                <path d="M128.002 108.719C128.002 108.719 124.056 111.992 122.801 111.456C121.545 110.921 110.545 101.519 110.545 101.519L101.158 110.087C101.158 110.087 112.159 132.581 119.931 135.556C127.703 138.531 135.894 137.341 140.497 132.283C145.16 127.285 141.872 113.539 141.872 113.539L128.002 108.719Z" fill="#FFAC99" />
                <path d="M199.086 120.382C199.086 120.382 192.57 90.9268 177.564 85.3333C162.557 79.7398 152.274 82.239 146.595 83.8457C136.909 86.5829 123.816 107.053 123.816 107.053L140.018 120.382L139.361 138.293H177.026L177.444 120.799L199.086 120.382Z" fill="#3087EC" />
                <path d="M161.302 63.257C161.302 63.257 153.53 63.614 153.111 56.2353C152.633 48.3211 159.986 47.607 163.095 50.7608C166.204 53.9741 165.606 57.1874 165.606 57.1874C165.606 57.1874 167.759 55.2237 170.15 57.6039C171.884 59.3891 170.987 62.2454 170.987 62.2454C170.987 62.2454 174.993 62.0669 174.634 65.5777C174.275 69.029 171.764 70.8142 171.764 70.8142L161.302 63.257Z" fill="#171717" />
                <path d="M159.381 70.594C159.682 67.982 158.489 65.7008 156.718 65.4988C154.946 65.2969 153.267 67.2506 152.966 69.8627C152.666 72.4747 153.858 74.7559 155.629 74.9578C157.401 75.1598 159.08 73.206 159.381 70.594Z" fill="#FF5A5A" />
                <path d="M168.356 76.943L167.519 85.1547C167.519 85.1547 165.786 87.3564 160.943 87.2969C157.296 87.2374 156.16 86.2853 156.16 86.2853L157.057 82.1795L157.954 78.1926L166.862 77.1215L168.356 76.943Z" fill="#FFAC99" />
                <path d="M166.861 77.1215C166.802 77.3595 166.682 77.5975 166.563 77.895C164.291 81.8224 159.149 82.2389 157.057 82.1794L157.953 78.1926L166.861 77.1215Z" fill="#171717" />
                <path d="M157.953 59.2697C157.953 59.2697 152.633 69.1476 153.768 75.0386C154.904 80.9297 160.285 80.4536 163.513 79.6205C166.742 78.7875 168.356 76.9428 168.356 76.9428C168.356 76.9428 171.226 78.1924 173.438 75.6337C175.65 73.0749 174.693 69.8616 173.079 68.9691C171.465 68.136 169.372 69.1476 169.372 69.1476L171.166 66.2913C171.166 66.2913 165.785 50.6414 157.953 59.2697Z" fill="#FFAC99" />
                <path d="M169.253 73.6703C169.133 73.6703 169.014 73.6108 168.894 73.4918C168.775 73.3133 168.834 73.0752 169.014 72.9562L173.019 70.6355C173.199 70.5165 173.438 70.576 173.558 70.7545C173.677 70.9331 173.617 71.1711 173.438 71.2901L169.432 73.6108C169.373 73.6108 169.313 73.6703 169.253 73.6703Z" fill="#171717" />
                <path d="M172.602 73.9081C172.542 73.9081 172.542 73.9081 172.482 73.9081L170.091 73.0156C169.911 72.956 169.792 72.718 169.851 72.5395C169.911 72.361 170.15 72.242 170.33 72.3015L172.721 73.1941C172.901 73.2536 173.02 73.4916 172.96 73.6701C172.901 73.8486 172.781 73.9081 172.602 73.9081Z" fill="#171717" />
                <path d="M164.471 64.2092C164.411 64.2092 164.351 64.2092 164.292 64.1497C162.976 63.2571 161.721 63.1976 161.721 63.1976C161.541 63.1976 161.422 63.0191 161.422 62.9001C161.422 62.7215 161.541 62.6025 161.721 62.6025C161.781 62.6025 163.215 62.7216 164.65 63.6736C164.77 63.7927 164.83 63.9712 164.71 64.0902C164.71 64.2092 164.591 64.2092 164.471 64.2092Z" fill="#171717" />
                <path d="M156.519 63.0781C156.399 63.0781 156.279 63.0186 156.22 62.8996C156.16 62.721 156.22 62.602 156.399 62.483C157.356 62.126 158.252 62.483 158.312 62.483C158.492 62.5425 158.551 62.721 158.492 62.84C158.432 63.0186 158.252 63.0781 158.073 63.0186C158.073 63.0186 157.356 62.721 156.578 63.0186C156.578 63.0781 156.578 63.0781 156.519 63.0781Z" fill="#171717" />
                <path d="M157.655 72.4205C157.536 72.4205 157.476 72.361 157.416 72.3015C155.862 69.6832 158.612 66.708 158.731 66.5294C158.851 66.4104 159.03 66.4104 159.15 66.5294C159.27 66.6485 159.27 66.827 159.15 66.946C159.15 66.946 156.579 69.7427 157.894 72.0039C157.954 72.123 157.954 72.3015 157.775 72.4205C157.715 72.4205 157.715 72.4205 157.655 72.4205Z" fill="#171717" />
                <path d="M156.16 67.0057C156.1 67.0057 156.1 67.0057 156.041 67.0057C155.861 66.9462 155.802 66.7677 155.861 66.6487L156.579 64.804C156.638 64.6255 156.818 64.566 156.937 64.6255C157.117 64.685 157.177 64.8635 157.117 64.9825L156.399 66.8272C156.399 66.9462 156.28 67.0057 156.16 67.0057Z" fill="#171717" />
                <path d="M161.361 67.6008C161.302 67.6008 161.302 67.6008 161.242 67.6008C161.062 67.5413 161.003 67.3628 161.062 67.2437L161.78 65.3991C161.84 65.2206 162.019 65.1611 162.139 65.2206C162.318 65.2801 162.378 65.4586 162.318 65.5776L161.601 67.4223C161.601 67.5413 161.541 67.6008 161.361 67.6008Z" fill="#171717" />
                <path d="M156.877 61.4715C156.877 61.4715 157.954 59.9244 159.986 60.1029C162.019 60.2814 163.095 61.8285 163.095 61.8285C163.095 61.8285 161.84 65.1609 164.47 67.303C167.101 69.3857 169.313 69.0882 169.313 69.0882L171.405 68.5527L173.199 65.7559L167.938 58.9128L160.704 54.1523L156.459 57.2466L156.877 61.4715Z" fill="#171717" />
                <path d="M159.568 75.5747C158.551 75.5747 157.894 74.3846 157.834 74.3251C157.774 74.2656 157.774 74.1465 157.834 74.0275C157.894 73.968 157.953 73.849 158.013 73.849L161.062 73.1945C161.182 73.1945 161.301 73.1945 161.361 73.3135C161.421 73.373 161.481 73.492 161.421 73.611C161.301 73.849 160.584 75.5747 159.568 75.5747ZM158.551 74.3846C158.79 74.6821 159.149 74.9796 159.508 74.9796C159.807 74.9796 160.225 74.4441 160.524 73.968L158.551 74.3846Z" fill="#171717" />
                <path d="M195.798 120.441C195.798 120.441 200.222 133.116 191.673 137.222C183.064 141.268 154.546 138.828 154.546 138.828L156.16 128.296L175.471 120.501H195.798V120.441Z" fill="#FFAC99" />
                <path d="M110.544 101.816C110.544 101.816 109.408 96.5202 107.495 94.735C105.522 92.9499 99.9623 89.32 98.1089 90.1531C96.2556 90.9862 100.201 94.616 100.201 94.616C100.201 94.616 85.7931 93.7829 84.418 95.0326C83.043 96.2822 85.255 97.5318 85.255 97.5318C85.255 97.5318 81.6081 97.9483 82.4451 99.3169C83.2821 100.686 85.9127 101.281 85.9127 101.281C85.9127 101.281 82.1462 101.4 84.2387 103.363C86.3312 105.327 94.5218 108.005 99.0655 108.124C103.609 108.183 106.598 108.124 106.598 108.124L110.544 101.816Z" fill="#FFAC99" />
                <path d="M104.685 103.066C104.625 103.066 104.625 103.066 104.565 103.066C99.9618 101.578 100.859 95.7467 100.859 95.6872C100.859 95.5087 101.038 95.3897 101.217 95.4492C101.397 95.4492 101.516 95.6277 101.456 95.8062C101.397 96.0443 100.679 101.221 104.745 102.53C104.924 102.59 104.984 102.768 104.924 102.887C104.924 102.947 104.804 103.066 104.685 103.066Z" fill="#171717" />
                <path d="M92.7282 102.352C92.6685 102.352 92.6685 102.352 92.7282 102.352L85.9725 101.519C85.7931 101.519 85.6735 101.34 85.7333 101.162C85.7333 100.983 85.9127 100.864 86.092 100.924L92.6685 101.697L96.3154 95.5086C96.3752 95.3896 96.5545 95.3301 96.7339 95.3896C96.8534 95.4491 96.9132 95.6276 96.8534 95.8061L93.0869 102.173C92.9076 102.292 92.788 102.352 92.7282 102.352Z" fill="#171717" />
                <path d="M94.9402 98.5433L85.255 97.7697C85.0756 97.7697 84.9561 97.5912 84.9561 97.4722C84.9561 97.2937 85.1354 97.1747 85.255 97.1747L94.9402 97.9482C95.1196 97.9482 95.2392 98.1268 95.2392 98.2458C95.2392 98.4243 95.1196 98.5433 94.9402 98.5433Z" fill="#171717" />
                <path d="M154.247 122.941C154.247 122.941 150.959 120.799 149.883 120.204C148.747 119.549 146.535 116.276 144.981 116.633C143.426 116.99 147.013 122.286 147.013 122.286C147.013 122.286 134.518 119.906 133.562 121.215C132.605 122.524 139.361 125.381 139.361 125.381C139.361 125.381 130.214 123.834 129.975 125.559C129.795 127.225 138.165 129.368 138.165 129.368C138.165 129.368 131.828 129.07 131.888 130.677C131.947 132.283 144.443 135.913 144.443 135.913C144.443 135.913 154.666 141.864 158.492 136.925C162.438 131.926 154.247 122.941 154.247 122.941Z" fill="#FFAC99" />
                <path d="M149.524 124.547C149.465 124.547 149.405 124.547 149.345 124.488L146.834 122.524C146.714 122.405 146.655 122.226 146.774 122.107C146.894 121.988 147.073 121.929 147.193 122.048L149.704 124.012C149.823 124.131 149.883 124.309 149.763 124.428C149.704 124.547 149.584 124.547 149.524 124.547Z" fill="#171717" />
                <path d="M139.959 129.963C139.959 129.963 139.899 129.963 139.959 129.963L138.105 129.606C137.926 129.546 137.866 129.427 137.866 129.249C137.926 129.07 138.046 129.01 138.225 129.01L140.018 129.368C140.198 129.427 140.258 129.546 140.258 129.725C140.198 129.844 140.078 129.963 139.959 129.963Z" fill="#171717" />
                <path d="M141.154 125.976C141.094 125.976 141.094 125.976 141.154 125.976L139.301 125.678C139.121 125.678 139.002 125.5 139.061 125.321C139.061 125.142 139.241 125.023 139.42 125.083L141.214 125.381C141.393 125.381 141.513 125.559 141.453 125.738C141.393 125.857 141.274 125.976 141.154 125.976Z" fill="#171717" />
                <path d="M70.9658 138.293H139.958V135.497C139.958 134.068 138.822 132.938 137.388 132.938H70.9658V138.293Z" fill="#373737" />
                <path d="M70.9658 138.293H122.142V105.505C122.142 103.958 120.887 102.709 119.332 102.709H73.7757C72.2213 102.709 70.9658 103.958 70.9658 105.505V138.293V138.293Z" fill="#373737" />
                <path d="M163.753 91.3433C161.9 91.3433 160.585 89.4391 160.106 88.606C159.15 89.3796 156.34 91.4623 155.084 90.1531C153.59 88.606 155.921 86.1068 155.981 85.9878C156.101 85.8688 156.22 85.8688 156.34 85.9283C162.139 89.082 167.34 84.8572 167.4 84.7977C167.52 84.7382 167.639 84.6786 167.759 84.7381C167.878 84.7977 167.938 84.9167 167.938 85.0357C167.938 85.2737 167.4 90.2722 164.65 91.1052C164.291 91.3433 163.992 91.3433 163.753 91.3433ZM160.166 87.8919C160.226 87.8919 160.226 87.8919 160.166 87.8919C160.345 87.8919 160.405 87.9514 160.465 88.0705C160.465 88.13 162.139 91.4028 164.411 90.6887C166.264 90.1531 166.922 87.1779 167.161 85.8092C165.607 86.8208 161.123 89.2011 156.22 86.7018C155.742 87.2374 154.546 88.9035 155.443 89.8556C156.34 90.8077 159.09 88.7845 159.927 88.0109C160.046 87.8919 160.106 87.8919 160.166 87.8919Z" fill="white" />
                <path d="M174.634 120.739C174.455 120.739 174.335 120.62 174.335 120.441V114.312C174.335 114.134 174.455 114.015 174.634 114.015C174.813 114.015 174.933 114.134 174.933 114.312V120.441C174.933 120.62 174.813 120.739 174.634 120.739Z" fill="white" />
                <path d="M140.018 120.68C139.838 120.68 139.719 120.561 139.719 120.382L139.958 108.124C139.958 107.946 140.137 107.827 140.257 107.827C140.436 107.827 140.556 107.946 140.556 108.124L140.317 120.382C140.317 120.561 140.197 120.68 140.018 120.68Z" fill="white" />
                <path d="M166.025 72.1823C165.726 74.8006 166.922 77.0618 168.716 77.2403C170.509 77.4188 172.183 75.5146 172.482 72.8964C172.781 70.2781 171.585 68.0169 169.792 67.8384C167.998 67.6599 166.324 69.6236 166.025 72.1823Z" fill="#FF5A5A" />
                <path d="M173.322 73.0399C173.623 70.4279 172.431 68.1467 170.659 67.9448C168.888 67.7428 167.208 69.6966 166.908 72.3086C166.607 74.9206 167.799 77.2018 169.571 77.4038C171.342 77.6057 173.022 75.652 173.322 73.0399Z" fill="#FF5A5A" />
                <path d="M94.9394 122.643C97.4819 122.643 99.5429 120.592 99.5429 118.061C99.5429 115.531 97.4819 113.479 94.9394 113.479C92.397 113.479 90.3359 115.531 90.3359 118.061C90.3359 120.592 92.397 122.643 94.9394 122.643Z" fill="#E8E8E8" />
                <path d="M91.2334 106.458H75.2706C75.0913 106.458 74.9717 106.339 74.9717 106.16C74.9717 105.982 75.0913 105.863 75.2706 105.863H91.2334C91.4127 105.863 91.5323 105.982 91.5323 106.16C91.5323 106.279 91.3529 106.458 91.2334 106.458Z" fill="#3087EC" />
                <path d="M168.835 56.6519C168.835 56.6519 169.851 58.6155 169.612 62.7809C169.373 66.9463 169.074 69.148 169.074 69.148L171.884 69.4455C171.824 69.4455 174.275 59.4486 168.835 56.6519Z" fill="#FF5A5A" />
                <path d="M140.137 115.979C140.078 115.979 140.018 115.979 139.958 115.919L125.669 104.256C125.55 104.137 125.55 103.958 125.61 103.839C125.729 103.72 125.909 103.72 126.028 103.78L140.257 115.443C140.377 115.562 140.377 115.741 140.317 115.86C140.257 115.919 140.197 115.979 140.137 115.979Z" fill="white" />
                <path d="M198.13 116.931H174.754C174.575 116.931 174.455 116.812 174.455 116.633C174.455 116.455 174.575 116.336 174.754 116.336H198.13C198.31 116.336 198.429 116.455 198.429 116.633C198.429 116.812 198.25 116.931 198.13 116.931Z" fill="white" />
                <path d="M204.126 136.794H217.458V119.418H204.126V136.794Z" fill="#FF5A5A" />
                <path d="M204.048 119.43C204.048 119.846 207.037 120.203 210.744 120.203C214.451 120.203 217.44 119.846 217.44 119.43C217.44 119.013 214.451 118.656 210.744 118.656C207.037 118.656 204.048 119.013 204.048 119.43Z" fill="#FFE6E6" />
                <path d="M199.086 129.01C199.086 125.5 201.298 122.584 204.048 122.584C206.798 122.584 209.01 125.44 209.01 129.01C209.01 132.521 206.798 135.437 204.048 135.437C201.298 135.437 199.086 132.581 199.086 129.01ZM200.282 129.01C200.282 131.926 202.015 134.247 204.108 134.247C206.2 134.247 207.934 131.867 207.934 129.01C207.934 126.154 206.2 123.774 204.108 123.774C201.956 123.774 200.282 126.154 200.282 129.01Z" fill="#FF5A5A" />
                <path d="M17.9959 136.806H52.4324C54.226 136.806 55.6608 135.378 55.6608 133.593V133.116C55.6608 131.331 54.226 129.903 52.4324 129.903H17.9959C17.3383 129.903 16.86 130.439 16.86 131.034V135.675C16.8002 136.27 17.3383 136.806 17.9959 136.806Z" fill="#FF5A5A" />
                <path d="M12.7346 136.865H47.171C48.9646 136.865 50.3994 135.437 50.3994 133.652V133.176C50.3994 131.391 48.9646 129.962 47.171 129.962H12.7346C12.0769 129.962 11.5986 130.498 11.5986 131.093V135.734C11.5986 136.329 12.0769 136.865 12.7346 136.865Z" fill="#FF5A5A" />
                <path d="M11.5986 135.497H46.633C47.8287 135.497 48.845 134.544 48.845 133.295C48.845 132.105 47.8885 131.093 46.633 131.093H11.5986V135.497Z" fill="white" />
                <path d="M57.275 130.022H18.534C16.6208 130.022 15.0664 128.475 15.0664 126.57C15.0664 124.666 16.6208 123.119 18.534 123.119H57.275C57.3348 123.119 57.3946 123.179 57.3946 123.238V129.962C57.3946 129.962 57.3348 130.022 57.275 130.022Z" fill="#3087EC" />
                <path d="M58.1719 130.022H28.2791C26.3659 130.022 24.8115 128.475 24.8115 126.57C24.8115 124.666 26.3659 123.119 28.2791 123.119H58.2316C58.8295 123.119 59.3078 123.595 59.3078 124.19V128.951C59.248 129.486 58.7697 130.022 58.1719 130.022Z" fill="#3087EC" />
                <path d="M59.2479 128.653H28.3986C27.2029 128.653 26.1865 127.701 26.1865 126.452C26.1865 125.261 27.1431 124.25 28.3986 124.25H59.3077V128.653H59.2479Z" fill="white" />
                <path d="M54.1061 123.119H22.9578C21.0447 123.119 19.4902 121.572 19.4902 119.668C19.4902 117.764 21.0447 116.217 22.9578 116.217H54.1061C54.3452 116.217 54.5246 116.395 54.5246 116.633V122.762C54.5246 123 54.3452 123.119 54.1061 123.119Z" fill="#3087EC" />
                <path d="M55.0629 123.119H31.0291C29.1159 123.119 27.5615 121.572 27.5615 119.668C27.5615 117.764 29.1159 116.217 31.0291 116.217H55.1227C55.6607 116.217 56.139 116.633 56.139 117.228V122.167C56.0792 122.703 55.6607 123.119 55.0629 123.119Z" fill="#BABABA" />
                <path d="M56.079 121.81H30.9093C29.7136 121.81 28.6973 120.858 28.6973 119.609C28.6973 118.419 29.6538 117.407 30.9093 117.407H56.079V121.81V121.81Z" fill="white" />
            </svg>


        </>
    );
};
