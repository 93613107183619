import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "src/store";
import { ReactNode } from "react";

const menuItems: MenuItems[] = [
  {
    heading: "",
    items: [
      {
        name: "Overview",
        icon: "BarChartOutlinedIcon",
        link: "/dashboard",
      },
      {
        name: "API Keys",
        icon: "AddLocationIcon",
        link: "/api-keys",
      },
      {
        name: "Webhooks",
        icon: "WebhookIcon",
        link: "/webhooks",
      },
      {
        name: "Recording",
        icon: "RecordVoiceOverIcon",
        link: "/recording",
      },
      {
        name: "Activity",
        icon: "LocalActivityIcon",
        link: "/activity/conference-report",
      },
      {
        name: "Billing",
        icon: "AccountBalanceTwoToneIcon",
        link: "/billing",
      },
    ],
  },
  // {
  //   heading: "People",
  //   items: [
  //     {
  //       name: "Team",
  //       icon: "GroupsIcon",
  //       link: "/Team",
  //     },

  //     {
  //       name: "Plans",
  //       icon: "SendIcon",
  //       link: "/plans",
  //     },
  //   ],
  // },
  {
    heading: "Documentation",
    items: [
      {
        name: "Star Guide",
        icon: "TravelExploreIcon",
        link: "/docs",
      },
      {
        name: "How Meeting Works",
        icon: "MeetingRoomIcon",
        link: "/docs",
      },
      {
        name: "FAQ",
        icon: "LiveHelpIcon",
        link: "/docs",
      },
      {
        name: "Contact Support",
        icon: "ConnectWithoutContactIcon",
        link: "/docs",
      },
    ],
  },
];

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

interface MenuState {
  MenuItems: MenuItems[];
}
const initialState: MenuState = {
  MenuItems: [],
};
const slice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    getmenu(
      state: MenuState,
      action: PayloadAction<{ menuItems: MenuItems[] }>
    ) {
      const { menuItems } = action.payload;
      state.MenuItems = menuItems;
    },
  },
});

export const reducer = slice.reducer;

export const getMenu = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.getmenu({ menuItems }));
};

export default slice;
