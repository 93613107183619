import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import { FailureSnackbar, SuccessSnackbar } from 'src/slices/Snackbar';

interface IPasswordReset {
  resetPassword: {
    oldPassword: string
    newPassword: string
    confirmNewPassword: string
  }
  validData: {
    validOldPassword: boolean
    validNewPassword: boolean
    validConfirmPassword: boolean
  }
  resetPasswordStatus: {
    setSuccess: boolean
    setError: boolean
  }

  passwordResetLoading: boolean
}

const initialState: IPasswordReset = {
  resetPassword: {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  },
  validData: {
    validOldPassword: true,
    validNewPassword: true,
    validConfirmPassword: true,
  },
  resetPasswordStatus: {
    setSuccess: false,
    setError: false,
  },

  passwordResetLoading: false,
};

const slice = createSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    setFormData(state, action) {
      state.resetPassword[action.payload.key] = action.payload.value;
    },
    resetPasswordFields(state) {
      state.resetPassword.oldPassword = '';
      state.resetPassword.newPassword = '';
      state.resetPassword.confirmNewPassword = '';
    },
    setValidNewPassword(state, action) {
      state.validData.validNewPassword = action.payload;
    },
    setValidOldPassword(state, action) {
      state.validData.validOldPassword = action.payload;
    },
    setValidConfirmPassword(state, action) {
      state.validData.validConfirmPassword = action.payload;
    },
    setSuccessHandler(state, action) {
      state.resetPasswordStatus.setSuccess = action.payload;
    },
    setErrorHandler(state, action) {
      state.resetPasswordStatus.setError = action.payload;
    },
    setResetLoading(state, action) {
      state.passwordResetLoading = action.payload;
    },
  },
});

export const PasswordResetActions = slice.actions;

export const changePassword = (data: any) => async (dispatch) => {
  dispatch(slice.actions.setResetLoading(true));
  await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/v1/user/change-password`, data)
    .then((res: any) => {
      dispatch(slice.actions.setResetLoading(false));
      if (res.data.message === "Password updated successfully") {
        dispatch(slice.actions.setSuccessHandler(true));
        dispatch(slice.actions.resetPasswordFields());
        dispatch(
          SuccessSnackbar({
            message: res.data.message || 'Password reset successful',
            open: true,
          }),
        );
      } else if (res.data.message === 'Current email or password incorrect') {
        dispatch(slice.actions.setErrorHandler(true));
        dispatch(
          FailureSnackbar({
            message: 'current password is incorrect',
            open: true,
          }),
        );
      } else {
        dispatch(slice.actions.setErrorHandler(true));
        dispatch(slice.actions.setResetLoading(false));
        dispatch(
          FailureSnackbar({
            message: res.data.message || 'Failed to reset',
            open: true,
          }),
        );
      }
    })
    .catch((err) => {
      throw new Error(err);
      // dispatch(slice.actions.setResetLoading(false));
      // dispatch(
      //   FailureSnackbar({
      //     message: 'Failed to reset',
      //     open: true,
      //   }),
      // );
    });
};

export const reducer = slice.reducer;

export default slice;
