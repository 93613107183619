import { Suspense, Fragment, lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout'
import BaseLayout from 'src/layouts/BaseLayout'
import Landing from 'src/content/landing'
import SuspenseLoader from 'src/components/SuspenseLoader'
import Dashboard from './content/dashboard'
import ApiKeys from './content/apiKeyDailog'
import WebHooks from './content/WebHooks/WebHookRegister'
import Team from './content/Team'
import Billing from './content/billing'
import Plans from './content/plans'
import Recording from './content/recording/index'
import ManageSubscription from './content/plans/managesubscription'
import Activity from './content/activity/index'
import Profile from './content/Profile/index'
import Authenticated from './components/Authenticated'

type RoutesType = {
  exact?: boolean
  path?: string | string[]
  guard?: any
  layout?: any
  component?: any
  routes?: RoutesType
}[]

export const renderRoutes = (routes: RoutesType = []): JSX.Element => (
  <Suspense fallback={<SuspenseLoader />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path as string}
            element={
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component />}
                </Layout>
              </Guard>
            }
          />
        )
      })}
    </Routes>
  </Suspense>
)

const routes: RoutesType = [
  {
    exact: true,
    layout: BaseLayout,
    // guard: Authenticated,
    path: '/',
    component: Landing,
  },
  {
    exact: true,
    layout: BaseLayout,
    // guard: Authenticated,
    path: '/pricing',
    component: Landing,
  },
  {
    exact: true,
    layout: BaseLayout,
    // guard: Authenticated,
    path: '/audio-video-calling-api-sdk',
    component: Landing,
  },
  {
    exact: true,
    layout: BaseLayout,
    // guard: Authenticated,
    path: '/live-streaming-api-sdk',
    component: Landing,
  },
  {
    exact: true,
    // guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/ChooseEventType',
    // component:Schedulemeeting
  },
  {
    exact: true,
    // guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/CreateEvent',
    // component:Stepper
  },
  {
    exact: true,
    guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/dashboard',
    component: Dashboard,
  },
  {
    exact: true,
    guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/api-keys',
    component: ApiKeys,
  },
  {
    exact: true,
    guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/webhooks',
    component: WebHooks,
  },
  {
    exact: true,
    guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/recording',
    component: Recording,
  },
  {
    exact: true,
    guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/activity/conference-report',
    component: Activity,
  },
  {
    exact: true,
    guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/activity/webhook-hits',
    component: Activity,
  },
  {
    exact: true,
    guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/activity/notifications',
    component: Activity,
  },
  {
    exact: true,
    // guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/team',
    component: Team,
  },
  {
    exact: true,
    guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/billing',
    component: Billing,
  },
  {
    exact: true,
    layout: AccentHeaderLayout,
    guard: Authenticated,
    path: '/profile',
    component: Profile,
  },
  {
    exact: true,
    // guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/plans',
    component: Plans,
  },
  {
    exact: true,
    // guard: Authenticated,
    layout: AccentHeaderLayout,
    path: '/manage-subscription',
    component: ManageSubscription,
  },
  {
    exact: true,
    // guard: Authenticated,
    path: '/event/:id',
    // component: Overview
  },
  {
    layout: BaseLayout,
    // guard: Authenticated,
    path: '/pages',
    component: Landing,
  },
  {
    exact: true,
    layout: BaseLayout,
    // guard: Authenticated,
    path: "/pages/terms-and-conditions",
    component: Landing,
  },
  {
    exact: true,
    layout: BaseLayout,
    // guard: Authenticated,
    path: "/pages/privacy-policy",
    component: Landing,
  },
  {
    exact: true,
    layout: BaseLayout,
    path: "*",
    component: lazy(() => import("src/content/notFoundPages/index")),
  },
  {
    component: () => <Navigate to="/" />,
  },
  {
    path: '*',
    layout: BaseLayout,
    routes: [
      {
        component: () => <Navigate to="/" />,
      },
    ],
  },
]

export default routes
