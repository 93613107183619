import Link from "@mui/material/Link";
import "./Footer.css";
// import Divider from '@mui/material/Divider'
import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Divider,
  experimentalStyled,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
// import { MessageIcon } from 'src/assets/Icons/SummeryIcons'
import { FbIcon, LinkedinIcon, TweetIcon, PortegoIcon } from "src/assets/icons/footerIcon";

const CopyrightWrapper = experimentalStyled(Box)(
  () => `
  border-radius:0px;
  width: 100%;
  height:auto;
  padding-left:35px;
  padding-bottom:30px;
`
);

const CopyrightWrapperMobile = experimentalStyled(Box)(
  () => `
  border-radius:0px;
  width: 100%;
  height:auto;
  padding-bottom:30px;
`
);

function Footer() {
  return (
    <Box
      sx={{
        background: "#24263A",
        paddingLeft: {
          xs: "10px",
          sm: "20px",
          md: "90px",
          lg: "100px",
          xl: "100px",
        },
        paddingRight: {
          xs: "10px",
          sm: "10px",
          md: "20px",
          lg: "40px",
          xl: "20px",
        },
        margin: 'auto', maxWidth: '2000px', display: 'block'
      }}
      
    >
      {/* Footer For Other Devices Start */}
      <Hidden only={"xs"}>
        <footer className="footer">
          <Grid container rowGap={1}>
            {/* <Grid item xs={12} sm={5} md={5} lg={3} xl={3}> */}
            <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
              <li className="nav__item">
                <Box
                  sx={{
                    height: "180px",
                    width: "180px",
                    // border: '1px solid white',
                    marginTop: "15px",
                    // marginLeft: '15px',
                  }}
                >
                  {/* <img src={ELLogo} alt="logo" height="100%" width="100%" /> */}
                  <PortegoIcon props={{width:'200px', height:'150px'}}/>
                </Box>
              </li>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={4} lg={2} xl={2}> */}
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <li className="nav__item">
                <h2 className="nav__title">Product</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                      component={NavLink}
                      to="/pricing"
                    >
                      Product Pricing
                    </Link>
                  </li>
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/support-plans"
                    >
                      Support Plans
                    </Link>
                  </li>
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/solutions"
                    >
                      Solutions
                    </Link>
                  </li>
                  {/* <li className="sub-list">
                    <Link color="inherit" underline="none">
                      Platform
                    </Link>
                  </li> */}
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/get-demo"
                    >
                      Get A Demo
                    </Link>
                  </li>
                </ul>
              </li>
            </Grid>
            {/* <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
              <li className="nav__item">
                <h2 className="nav__title">Events</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list">
                    <Link color="inherit" underline="none">
                      Ongoing Events
                    </Link>
                  </li>
                  <li className="sub-list">
                    <Link color="inherit" underline="none">
                      Upcoming Events
                    </Link>
                  </li>
                  <li className="sub-list">
                    <Link color="inherit" underline="none">
                      Trainings
                    </Link>
                  </li>
                  <li className="sub-list">
                    <Link color="inherit" underline="none">
                      Webinars
                    </Link>
                  </li>
                </ul>
              </li>
            </Grid> */}
            {/* <Grid item xs={12} sm={5} md={5} lg={2} xl={2}> */}
            <Grid item xs={12} sm={2} md={2} lg={2.5} xl={2.5}>
              <li className="nav__item">
                <h2 className="nav__title">About</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/about-us"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/careers"
                    >
                      Careers
                    </Link>
                  </li>
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/our-partners"
                    >
                      Our Partners
                    </Link>
                  </li>
                  {/* <li className="sub-list">
                    <Link color="inherit" underline="none">
                  </li> */}
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/newsroom"
                    >
                      Newsroom
                    </Link>
                  </li>
                </ul>
              </li>
            </Grid>
            {/* <Grid item xs={12} sm={7} md={5} lg={3} xl={3}> */}
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <li className="nav__item">
                <h2 className="nav__title">Help & Resources </h2>
                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/contact-us"
                    >
                      Contact Us
                    </Link>
                  </li>
                  {/* <li className="sub-list">
                    <Link color="inherit" underline="none">
                      Blog
                    </Link>
                  </li> */}
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/support"
                    >
                      Get Support
                    </Link>
                  </li>
                  {/* <li className="sub-list">
                    <Link color="inherit" underline="none">
                      FAQ's
                    </Link>
                  </li> */}
                </ul>
              </li>
            </Grid>
          </Grid>
          <Box height={"30px"}></Box>
        </footer>
        <Hidden only={["xs"]}>
          <div style={{ paddingLeft: "20px" }}>
            <Divider
              style={{
                background: "#47454E",
                marginBottom: "10px",
                width: "90%",
              }}
              variant="middle"
            />
          </div>
        </Hidden>

        <CopyrightWrapper>
          <Box
            display={{
              xs: "block",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            }}
            alignItems="center"
            textAlign={{ xs: "left", lg: "left" }}
            justifyContent={{
              sm: "space-between",
              md: "space-between",
              lg: "space-between",
              xl: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="subtitle1"
                color={"white"}
                fontSize={"12px"}
                fontWeight={400}
              >
                Copyright &copy; 2023 Portego. All rights reserved.
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                className="footer-privacy-condition"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color={"white"}
                  fontSize={"12px"}
                  fontWeight={400}
                >
                  <a
                    href="/pages/privacy-policy"
                    style={{ textDecoration: "none"}}
                  >
                    Privacy Policy
                  </a>
                  &emsp;|&emsp;
                  <a
                    href="/pages/terms-and-conditions"
                    style={{ textDecoration: "none" }}
                  >
                    Terms and Conditions
                  </a>
                  &emsp;|&emsp;
                  <IconButton
                    style={{
                      cursor: "pointer",
                      padding: "0px",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/logichivesolutions",
                        "_blank"
                      );
                    }}
                  >
                    <FbIcon />
                  </IconButton>
                  <IconButton
                    style={{
                      cursor: "pointer",
                      padding: "0px",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      window.open("https://logichive.in/", "_blank");
                    }}
                  >
                    <TweetIcon />
                  </IconButton>
                  <IconButton
                    style={{ cursor: "pointer", padding: "0px" }}
                    onClick={() => {
                      window.open(
                        "https://www.linkedin.com/company/logichive-solutions-pvt-ltd/mycompany/",
                        "_blank"
                      );
                    }}
                  >
                    <LinkedinIcon />
                  </IconButton>
                </Typography>
              </Box>

              <Box
                sx={{
                  marginLeft: {
                    xs: "0px",
                    sm: "10px",
                    md: "75px",
                    lg: "115px",
                    xl: "150px",
                  },
                }}
              >
                {/* <MessageIcon props={{ width: '50px', height: '50px' }} /> */}
              </Box>
            </Box>
          </Box>
        </CopyrightWrapper>
      </Hidden>
      {/* Footer For Other Devices End */}

      {/* Footer For Mobile Start */}
      <Hidden smUp>
        <footer className="footer-mobile">
          <Grid container rowGap={1}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "10px",
              }}
            >
              {/* <li className="nav__item"> */}
              <Box
                sx={{
                  // height: "180px",
                  // width: "180px",
                  marginTop: "15px",
                  textAlign:'center',
                }}
              >
                <PortegoIcon props={{width:'200px', height:'150px'}}/>
              </Box>
              {/* </li> */}
            </Grid>
            <Divider
              style={{
                background: "#47454E",
                marginTop: "10px",
                // marginBottom: '10px',
                width: "90%",
              }}
              variant="middle"
            />
            <Grid item className="footer-item-left-box" xs={6}>
              <li className="nav__item">
                <h2 className="nav__title_mobile">Product</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                      component={NavLink}
                      to="/pricing"
                    >
                      Product Pricing
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/support-plans"
                    >
                      Support Plans
                    </Link>
                  </li>

                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/solutions"
                      >
                      Solutions
                    </Link>
                  </li>
                  {/* <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      Platform
                    </Link>
                  </li> */}
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/get-demo"
                    >
                      Get A Demo
                    </Link>
                  </li>
                </ul>
              </li>
            </Grid>
            {/* <Grid item className="footer-item-right-box" xs={6}>
              <li className="nav__item">
                <h2 className="nav__title_mobile">Events</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      Ongoing Events
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      Upcoming Events
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      Trainings
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      Webinars
                    </Link>
                  </li>
                </ul>
              </li>
            </Grid> */}
            <Grid item className="footer-item-left-box" xs={6}>
              <li className="nav__item">
                <h2 className="nav__title_mobile">About</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/about-us"
                    >
                      About
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/careers"
                    >
                      Careers
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/our-partners"
                    >
                      Our Partners
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/pages/newsroom"
                    >
                      Newsroom
                    </Link>
                  </li>
                </ul>
              </li>
            </Grid>
            {/* <Grid item className="footer-item-right-box" xs={6}> */}
            <Grid item className="footer-item-left-box" xs={12}>
              <li className="nav__item">
                <h2 className="nav__title_mobile">Help & Resources</h2>
                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/contact-us"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      Blog
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                      // component={NavLink}
                      // to="/support"
                    >
                      Get Support
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      FAQ&#39;s
                    </Link>
                  </li>
                </ul>
              </li>
            </Grid>
          </Grid>

          <Box height={"30px"}></Box>
        </footer>

        <Divider
          style={{
            background: "#47454E",
            marginTop: "10px",
            marginBottom: "15px",
            width: "90%",
          }}
          variant="middle"
        />

        <CopyrightWrapperMobile>
          <Box
            display={{
              xs: "block",
            }}
            alignItems="center"
            textAlign={{ xs: "center" }}
          >
            <Box>
              <Typography
                variant="subtitle1"
                color={"white"}
                fontSize={"12px"}
                fontWeight={400}
                mb={"5px"}
              >
                Copyright &copy; 2023 Portego. All rights reserved.
              </Typography>
            </Box>

            <Box
              sx={{
                display: "block",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                className="footer-privacy-condition"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color={"white"}
                  fontSize={"12px"}
                  fontWeight={400}
                  mb={"5px"}
                >
                  <a
                    href="/pages/privacy-policy"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Privacy Policy
                  </a>
                  &emsp;|&emsp;
                  <a
                    href="/pages/terms-and-conditions"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Terms and Conditions
                  </a>
                </Typography>
              </Box>

              <Box
                sx={{
                  marginLeft: {
                    xs: "0px",
                    sm: "10px",
                    md: "75px",
                    lg: "115px",
                    xl: "150px",
                  },
                }}
              >
                <IconButton
                  style={{
                    cursor: "pointer",
                    padding: "0px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    window.open(process.env.REACT_FACEBOOK_URL, "_blank");
                  }}
                >
                  <FbIcon />
                </IconButton>
                <IconButton
                  style={{
                    cursor: "pointer",
                    padding: "0px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    window.open(process.env.REACT_TWITTER_URL, "_blank");
                  }}
                >
                  <TweetIcon />
                </IconButton>
                <IconButton
                  style={{ cursor: "pointer", padding: "0px" }}
                  onClick={() => {
                    window.open(process.env.REACT_LINKEDIN_URL, "_blank");
                  }}
                >
                  <LinkedinIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </CopyrightWrapperMobile>
      </Hidden>
      {/* Footer For Mobile End*/}
    </Box>
  );
}
export default Footer;
