import { combineReducers } from '@reduxjs/toolkit'
import { reducer as sideBarReducer } from 'src/slices/SideBar'
import { reducer as apiKeysReducer } from 'src/slices/apiKeys'
import { reducer as WebHookReducer } from '../slices/WebHook'
import { reducer as TeamReducer } from 'src/slices/Team'
import { reducer as Billing } from 'src/slices/billing'
import { reducer as Home } from 'src/slices/Home'
import { reducer as Snackbar } from 'src/slices/Snackbar'
import { reducer as profilePageForm } from 'src/slices/ProfilePage/ProfilePageFormSlice'
import { reducer as passwordReset } from 'src/slices/ProfilePage/passwordReset'
import { reducer as conference } from 'src/slices/Conference/ConferenceTable'
import { reducer as webHookHits } from 'src/slices/WebhookHits/WebHookHits'
import { reducer as notifications } from 'src/slices/Notifications/Notification'
import { reducer as recordings } from 'src/slices/Recording';
import { reducer as pricingButtons } from 'src/slices/pricing';
import { reducer as ContactUs } from 'src/slices/contactUs';
import { reducer as profile } from 'src/slices/profile';
import { reducer as passwordUpdate } from 'src/slices/passwordUpdate';
import { reducer as overview } from 'src/slices/dashboard';

const rootReducer = combineReducers({
  sideBar: sideBarReducer,
  apiKeys: apiKeysReducer,
  WebHook: WebHookReducer,
  Team: TeamReducer,
  Billing: Billing,
  Home: Home,
  Snackbar: Snackbar,
  profilePage: profilePageForm,
  passwordReset: passwordReset,
  conference: conference,
  webHookHits: webHookHits,
  notifications: notifications,
  recordings: recordings,
  pricing: pricingButtons,
  ContactUs: ContactUs,
  profile: profile,
  passwordUpdate: passwordUpdate,
  overview: overview,
})

export default rootReducer
