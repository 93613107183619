import { FC, ReactNode, useState, useContext } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { SidebarContext } from "src/contexts/SidebarContext";
import { Icon as IconObj } from "../../../../constants/icons";
import PropTypes from "prop-types";
import { Button, Badge, Collapse, ListItem, Tooltip } from "@mui/material";
import ExpandLessTwoToneIcon from "@mui/icons-material/ExpandLessTwoTone";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  name: string;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  name,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const { toggleSidebar } = useContext(SidebarContext);
  Icon = IconObj[Icon];
  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ "Mui-active": menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
          disabled
        >
          {name}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem key={name} {...rest}>
      <Tooltip title={name==="Recording" ? "For Enterprise Users Only" : ""} arrow>
      <Button
        component={name !=="Recording" && RouterLink}
        onClick={toggleSidebar}
        to={link}
        startIcon={Icon && <Icon />}
        disabled ={(  
          name === "Star Guide" || 
          name === "How Meeting Works" ||
          name === "FAQ" ||
          name === "Contact Support"

          
          ) ? true : false}
      >
        {name}
        {badge && <Badge badgeContent={badge} />}
      </Button>
      </Tooltip>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

SidebarMenuItem.defaultProps = {
  open: false,
};

export default SidebarMenuItem;
