import { useNavigate } from "react-router-dom";
import { styled as experimentalStyled } from "@mui/material/styles";
//import portegoLogo from "src/assets/Portego-logo.png";
import PortegoIcon from "src/assets/icons/PortegoIcon";
import { Box, Hidden } from "@mui/material";

const LogoWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        // padding: ${theme.spacing(0, 1, 0, 0)};
        height: 40px;
        display: flex;
        cursor:pointer;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  const navigate = useNavigate();
  const handleLandingPage = () => {
    navigate('/');
    setTimeout(() => {
      const contactUsSection = document.getElementById('landing-page-section');
      if (contactUsSection) {
        const componentHeight = 200;
        const scrollOffset = contactUsSection.offsetTop - componentHeight;
        window.scrollTo({ top: scrollOffset, behavior: 'auto' });
      }
    }, 0);
  };

  return (
    <LogoWrapper onClick={handleLandingPage}>
      {/* <img src={portegoLogo} alt="Portego Logo" /> */}
      <Hidden smDown>
        <PortegoIcon props={{ height: 'auto', width: '100px' }} />
      </Hidden>
      
      <Hidden smUp>
        <PortegoIcon props={{ height: 'auto', width: '80px' }} />
      </Hidden>
    </LogoWrapper>
  );
}

export default Logo;
