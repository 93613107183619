import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Divider,
  Grid,
  Hidden,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddFilterStack,
  StatusCompleted,
  StatusLive,
  TableBox,
  TableCells,
  TableContainerStack,
  TablePaginationBox,
  TableRowBox,
} from "./indexStyled";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import Calender from "./calender";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
// import { Box } from "@mui/system";
import moment from "moment";
import "./table.css";
import { useDispatch, useSelector } from "react-redux";
import { editTableData } from "src/slices/apiKeys";
import {
  fetchWebhookHits,
  WebhookTableActions,
} from "src/slices/WebhookHits/WebHookHits";
import { RootState } from "src/store";
import { ButtonPrimary } from "src/components/CustomButtons";
import Loader from "src/components/Loader/Loader";
import {
  ContentWrapperCenterMob,
  ContentWrapperMob,
  CustomPagination,
  DeviderBox,
  MenuWrapperMob,
  TableHeaderName,
  TableHeaderValue,
  TableHeaderWrapperNotification,
} from "src/components/TableMobileCss";
import Scrollbars from "react-custom-scrollbars-2";
import NoData from "src/assets/icons/NoData";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
// import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const hooknamesList = [
  {
    id: "00",
    name: "all",
    value: "ALL",
  },
  {
    id: "01",
    name: "ROOM_CREATED",
    value: "ROOM_CREATED",
  },
  {
    id: "02",
    name: "PARTICIPANT_JOINED",
    value: "PARTICIPANT_JOINED",
  },
  {
    id: "03",
    name: "PARTICIPANT_LEFT",
    value: "PARTICIPANT_LEFT",
  },
  {
    id: "04",
    name: "ROOM_DESTROYED",
    value: "ROOM_DESTROYED",
  },
  {
    id: "05",
    name: "TOKEN_CREATED",
    value: "TOKEN_CREATED",
  },
  {
    id: "06",
    name: "RECORDING_STARTED",
    value: "RECORDING_STARTED",
  },
  {
    id: "07",
    name: "RECORDING_STOPPED",
    value: "RECORDING_STOPPED",
  },
  {
    id: "08",
    name: "RECORDING_READY",
    value: "RECORDING_READY",
  },
];

const WebhookHits = () => {
  const { meta, webHookHitsData, hookName, appIdData, loading } = useSelector(
    (state: RootState) => state.webHookHits
  );
  const { tableData } = useSelector((state: RootState) => state.apiKeys);
  const [validAppIdSelected, setValidAppIdSelected] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [calenderDialog, setCalenderDialog] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  /*eslint-disable*/
  useEffect(() => {
    const data = {
      appId: appIdData?.appId,
      hooksName: hookName?.name,
      page: meta.page,
      per_page: meta.per_page,
    };
    const dateRange = {
      from_date: selectedRange?.startDate
        ? moment(selectedRange?.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",

      to_date: selectedRange?.endDate
        ? moment(selectedRange?.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",
    };
    dispatch(editTableData());
    dispatch(fetchWebhookHits(data, dateRange));
  }, [dispatch]);

  const tableData1 = [...tableData];

  const searchHandleClick = () => {
    const data = {
      appId: appIdData?.appId,
      hooksName: hookName?.name ? hookName?.name : "all",
      page: meta.page,
      per_page: meta.per_page,
    };
    const dateRange = {
      from_date: selectedRange?.startDate
        ? moment(selectedRange?.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",

      to_date: selectedRange?.endDate
        ? moment(selectedRange?.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",
    };

    if (appIdData?.appId !== "" || appIdData?.appId === "") {
      dispatch(fetchWebhookHits(data, dateRange));
    } else {
      setValidAppIdSelected(true);
      setTimeout(() => {
        setValidAppIdSelected(false);
      }, 3000);
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    const data = {
      appId: appIdData?.appId,
      hooksName: hookName?.name,
      page: newPage + 1,
      per_page: meta.per_page,
    };
    const dateRange = {
      from_date: moment(selectedRange?.startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),

      to_date: moment(selectedRange?.endDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };
    dispatch(fetchWebhookHits(data, dateRange));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = {
      appId: appIdData?.appId,
      hooksName: hookName?.name,
      page: 1,
      per_page: event.target.value,
    };
    const dateRange = {
      from_date: moment(selectedRange?.startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),

      to_date: moment(selectedRange?.endDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };
    dispatch(fetchWebhookHits(data, dateRange));
  };

  const handlePicker = () => {
    setCalenderDialog(true);
  };
  const handleSelect = (ranges) => {
    setSelectedRange(ranges);
  };

  const handleSave = (ranges) => {
    const dateRange = {
      from_date: ranges?.startDate
        ? moment(ranges?.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",

      to_date: ranges?.endDate
        ? moment(ranges?.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",
    };
    const data = {
      appId: appIdData?.appId,
      hooksName: hookName?.name,
      page: meta.page,
      per_page: meta.per_page,
    };

    dispatch(fetchWebhookHits(data, dateRange));
    // dispatch(
    //   fetchConferenceTableData(
    //     {
    //       page: 1,
    //       per_page: 5,
    //       page_count: 0,
    //       total_count: 0,
    //     },
    //     // dataAppid?.appId,
    //     appIdData.appId,
    //     dateRange
    //   )
    // );
    setCalenderDialog(false);
  };

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <TableContainerStack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 1,
              alignItems: "center",
            }}
          >
            <Box sx={{}}>
              <AddFilterStack>
                <Typography variant="h3" sx={{ color: "#3087EC" }}>
                  Webhook Hits
                </Typography>
              </AddFilterStack>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Autocomplete
                sx={{
                  width: "30ch",
                  mr: 1,
                  ".MuiIconButton-root.MuiAutocomplete-clearIndicator": {
                    display: appIdData.name ? "" : "none",
                    background: "#f6f8fb",
                    color: "black",
                    marginRight: "-2px",
                    "&:hover": {
                      display: appIdData.name ? "" : "none",
                      background: "#f6f8fb",
                      color: "black",
                      marginRight: "-2px",
                    },
                  },
                }}
                defaultValue=""
                value={appIdData}
                onChange={(e: any, newvalue: string) => {
                  if (newvalue === null) {
                    dispatch(WebhookTableActions.setAppId({ appId: "" }));
                    const data = {
                      appId: "",
                      hooksName: hookName?.name ? hookName?.name : "all",
                      page: meta.page,
                      per_page: meta.per_page,
                    };
                    const dateRange = {
                      from_date: selectedRange?.startDate
                        ? moment(selectedRange?.startDate)
                          .startOf("day")
                          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                        : "",

                      to_date: selectedRange?.endDate
                        ? moment(selectedRange?.endDate)
                          .endOf("day")
                          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                        : "",
                    };

                    dispatch(fetchWebhookHits(data, dateRange));

                    return;
                  }
                  dispatch(WebhookTableActions.setAppId(newvalue));
                }}
                id="combo-box-demo"
                size="small"
                options={tableData1}
                // getOptionLabel={(option: any) => option.appId || ""}
                getOptionLabel={(option: any) =>
                  option?.name ? `${option.name} (${option.appId})` : ""
                }
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name} ({option.appId})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="Select an application name"
                    helperText=""
                    label="App name"
                  />
                )}
              />

              <Autocomplete
                sx={{
                  width: "30ch",
                  mr: 1,

                  ".MuiIconButton-root.MuiAutocomplete-clearIndicator": {
                    display: hookName.name ? "" : "none",
                    background: "#f6f8fb",
                    color: "black",
                    marginRight: "-2px",
                    "&:hover": {
                      display: hookName.name ? "" : "none",
                      background: "#f6f8fb",
                      color: "black",
                      marginRight: "-2px",
                    },
                  },
                }}
                defaultValue=""
                value={hookName}
                onChange={(e: any, newvalue: string) => {
                  if (newvalue === null) {
                    dispatch(WebhookTableActions.setHookName({ value: "" }));
                    const data = {
                      appId: appIdData.appId,
                      hooksName: "all",
                      page: meta.page,
                      per_page: meta.per_page,
                    };
                    const dateRange = {
                      from_date: selectedRange?.startDate
                        ? moment(selectedRange?.startDate)
                          .startOf("day")
                          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                        : "",

                      to_date: selectedRange?.endDate
                        ? moment(selectedRange?.endDate)
                          .endOf("day")
                          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                        : "",
                    };
                    dispatch(fetchWebhookHits(data, dateRange));
                    return;
                  }
                  dispatch(WebhookTableActions.setHookName(newvalue));
                }}
                size="small"
                disableClearable
                options={hooknamesList}
                getOptionLabel={(option: any) =>
                  option?.value?.replace(/_/g, " ") || ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Hook Name"
                    placeholder="Select hook name here.."
                    sx={{ textTransform: "uppercase" }}
                  />
                )}
              />

              <TextField
                sx={{ width: "32ch", mr: 1 }}
                size="small"
                variant="outlined"
                onClick={handlePicker}
                InputProps={{
                  readOnly: true,
                }}
                value={`${selectedRange?.startDate
                  ? moment(selectedRange?.startDate)
                    ?.format("DD-MM-YYYY")
                    .trim()
                  : ""
                  } - ${selectedRange?.endDate
                    ? moment(selectedRange?.endDate)
                      ?.format("DD-MM-YYYY")
                      .trim()
                    : ""
                  }
                    `}
                placeholder="Select a Filter"
                helperText=""
                label="Filter"
              />

              <ButtonPrimary
                id="searchWebhookHits"
                responsive={false}
                onClick={searchHandleClick}
              >
                Search
              </ButtonPrimary>
            </Box>
          </Box>
          <Divider />
          <TableBox sx={{ height: "55.2vh" }}>
            <TableContainer sx={{ height: "100%" }}>
              {loading ? (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                </Box>
              ) : (
                <>
                  {webHookHitsData.length === 0 ? (
                    <div
                      style={{
                        alignSelf: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <NoData />
                    </div>
                  ) : (
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCells sx={{ zIndex: "1", color: "#3087EC", width:'37%' }}>
                            <Box className="wenhook_table_appId webhook_header_wrapper">
                              Hook ID
                            </Box>
                          </TableCells>
                          <TableCells sx={{ zIndex: "1", color: "#3087EC", width:'21%'  }}>
                            <Box className="wenhook_table_hookName webhook_header_wrapper">
                              Hook Name
                            </Box>
                          </TableCells>
                          <TableCells sx={{ zIndex: "1", color: "#3087EC", width:'20%'  }}>
                            <Box className="wenhook_table_datetime webhook_header_wrapper">
                              Date 
                            </Box>
                          </TableCells>
                          <TableCells sx={{ zIndex: "1", color: "#3087EC", width:'22%' }}>
                            <Box className="wenhook_table_status webhook_header_wrapper_status">
                              STATUS
                            </Box>
                          </TableCells>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {webHookHitsData.map((row, index) => {
                          return (
                            <TableRowBox key={index}>
                              <TableCells>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <WebhookOutlinedIcon
                                    sx={{ fontSize: "25px" }}
                                  />
                                  <Typography
                                    style={{ textTransform: "lowercase" }}
                                  >
                                    {row.webhookId}
                                  </Typography>
                                </Box>
                              </TableCells>

                              <TableCells
                                style={{ textTransform: "uppercase" }}
                              >
                                {row?.hookName.replace(/_/g, " ")}
                              </TableCells>
                              <TableCell style={{ textTransform: "uppercase" }}>
                                {moment(row?.createdDate).format("DD MMM YYYY")}
                                &nbsp;&nbsp;
                                {moment(row?.createdDate).format("hh:mm a")}
                              </TableCell>

                              <TableCell align="center">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {row.status ? (
                                    <StatusCompleted>Success</StatusCompleted>
                                  ) : (
                                    <StatusLive>Failure</StatusLive>
                                  )}
                                </Box>
                              </TableCell>
                            </TableRowBox>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </TableContainer>
          </TableBox>
          <TablePaginationBox>
            {webHookHitsData.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={meta.total_count}
                rowsPerPage={meta.per_page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                }}
                page={meta.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={{
                  style: {
                    background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                    color: (meta.page - 1) ? "#0062FD" : "#aaa",
                    borderRadius: "50%",
                    marginRight: "2px",
                  },
                }}
                nextIconButtonProps={{
                  style: {
                    background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                    color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                    borderRadius: "50%",
                    marginLeft: "2px",
                  },
                }}
              />
            )}
          </TablePaginationBox>
        </TableContainerStack>
      </Hidden>
      <Hidden only={["xl", "lg", "md"]}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between", p: "10px" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "50%" }}>
              <Typography variant="h4" sx={{ color: "#3087EC" }}>
                Webhook Hits
              </Typography>
            </Box>
            {/* {webHookHitsData.length > 0 && ( */}
            {/* <Box
              sx={{
                width: "40%",
                display: "flex",
                justifyContent: "flex-end",
                ml: "3px",
              }}
            >
              <ButtonPrimary
                id="searchWebhookHits"
                responsive={false}
                onClick={searchHandleClick}
              >
                Search
              </ButtonPrimary>
            </Box> */}
            {/* // )} */}
          </Box>
          {/* {webHookHitsData.length > 0 && ( */}
          <Grid container item xs={12} spacing={1} sx={{ pl: 1.5, pr: 1.5 }}>
            <Grid item xs={6} sm={3} sx={{ mt: { sm: 1.5, xs: 0 } }}>
              <Autocomplete
                sx={{
                  ".MuiIconButton-root.MuiAutocomplete-clearIndicator": {
                    display: appIdData.name ? "" : "none",
                    background: "#f6f8fb",
                    color: "black",
                    marginRight: "-2px",
                    "&:hover": {
                      display: appIdData.name ? "" : "none",
                      background: "#f6f8fb",
                      color: "black",
                      marginRight: "-2px",
                    },
                  },
                }}
                defaultValue=""
                value={appIdData}
                onChange={(e: any, newvalue: string) => {
                  if (newvalue === null) {
                    dispatch(WebhookTableActions.setAppId({ appId: "" }));
                    const data = {
                      appId: "",
                      hooksName: hookName?.name ? hookName?.name : "all",
                      page: meta.page,
                      per_page: meta.per_page,
                    };
                    const dateRange = {
                      from_date: selectedRange?.startDate
                        ? moment(selectedRange?.startDate)
                          .startOf("day")
                          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                        : "",

                      to_date: selectedRange?.endDate
                        ? moment(selectedRange?.endDate)
                          .endOf("day")
                          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                        : "",
                    };

                    dispatch(fetchWebhookHits(data, dateRange));

                    return;
                  }
                  dispatch(WebhookTableActions.setAppId(newvalue));
                }}
                id="combo-box-demo"
                size="small"
                options={tableData1}
                getOptionLabel={(option: any) =>
                  option?.name ? `${option.name} (${option.appId})` : ""
                }
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name} ({option.appId})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="App Id"
                    fullWidth
                    placeholder="Select an application name"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={3} sx={{ mt: { sm: 1.5, xs: 0 } }}>
              <Autocomplete
                sx={{
                  textTransform: "uppercase",
                  width: "100%",
                  ".MuiIconButton-root.MuiAutocomplete-clearIndicator": {
                    display: hookName.name ? "" : "none",
                    background: "#f6f8fb",
                    color: "black",
                    marginRight: "-2px",
                    "&:hover": {
                      display: hookName.name ? "" : "none",
                      background: "#f6f8fb",
                      color: "black",
                      marginRight: "-2px",
                    },
                  },
                }}
                defaultValue=""
                value={hookName}
                onChange={(e: any, newvalue: string) => {
                  if (newvalue === null) {
                    dispatch(WebhookTableActions.setHookName({ value: "" }));
                    const data = {
                      appId: appIdData.appId,
                      hooksName: "all",
                      page: meta.page,
                      per_page: meta.per_page,
                    };
                    const dateRange = {
                      from_date: selectedRange?.startDate
                        ? moment(selectedRange?.startDate)
                          .startOf("day")
                          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                        : "",

                      to_date: selectedRange?.endDate
                        ? moment(selectedRange?.endDate)
                          .endOf("day")
                          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                        : "",
                    };
                    dispatch(fetchWebhookHits(data, dateRange));
                    return;
                  }
                  dispatch(WebhookTableActions.setHookName(newvalue));
                }}
                id="combo-box-demo"
                size="small"
                disableClearable
                options={hooknamesList}
                getOptionLabel={(option: any) =>
                  option?.value?.replace(/_/g, " ") || ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Hook Name"
                    placeholder="Select hook name here.."
                    sx={{ textTransform: "uppercase" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8.4} sm={3} sx={{ mt: { xs: 1, sm: 1.5 } }}>
              <TextField
                // sx={{ width: "30ch", mr: 1 }}
                fullWidth
                size="small"
                variant="outlined"
                onClick={handlePicker}
                InputProps={{
                  readOnly: true,
                }}
                value={`${selectedRange?.startDate
                  ? moment(selectedRange?.startDate)
                    ?.format("DD-MM-YYYY")
                    .trim()
                  : ""
                  } - ${selectedRange?.endDate
                    ? moment(selectedRange?.endDate)
                      ?.format("DD-MM-YYYY")
                      .trim()
                    : ""
                  }
                    `}
                placeholder="Select a Filter"
                helperText=""
                label="Filter"
              />
            </Grid>
            <Grid item xs={3.6} sm={3} sx={{ mt: { xs: 1, sm: 1.5 } }}>
              <ButtonPrimary
                id="searchWebhookHits"
                responsive={false}
                onClick={searchHandleClick}
              >
                Search
              </ButtonPrimary>
            </Grid>
          </Grid>
          {validAppIdSelected && webHookHitsData.length > 0 && (
            <Typography sx={{ color: "red", ml: "15px", fontSize: "10px" }}>
              Please select an app id
            </Typography>
          )}
        </Box>
        <Box sx={{ width: "100%", pt: "7px" }}>
          <Divider sx={{ height: "1px" }} />
        </Box>
        {loading ? (
          <Box
            sx={{
              height: "58vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box>
            {webHookHitsData.length === 0 ? (
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NoData />
              </div>
            ) : (
              <Box>
                <Scrollbars style={{ height: "52vh" }}>
                  {webHookHitsData.map((row, index) => (
                    <>
                      <Box key={index}>
                        <TableHeaderWrapperNotification>
                          <TableHeaderName>Hook ID</TableHeaderName>
                          <MenuWrapperMob>
                            <Typography>{row.webhookId}</Typography>
                          </MenuWrapperMob>
                        </TableHeaderWrapperNotification>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Hook Name</TableHeaderName>
                            <TableHeaderValue>{row?.hookName}</TableHeaderValue>
                          </ContentWrapperCenterMob>
                          <DeviderBox>
                            <Divider sx={{ height: "1.5px" }} />
                          </DeviderBox>
                        </ContentWrapperMob>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Date & Time</TableHeaderName>
                            <TableHeaderValue>
                              {moment(row?.createdDate).format("DD MMM YYYY")}
                              &nbsp;&nbsp;
                              {moment(row?.createdDate).format("hh:mm a")}
                            </TableHeaderValue>
                          </ContentWrapperCenterMob>
                          <DeviderBox>
                            <Divider sx={{ height: "1.5px" }} />
                          </DeviderBox>
                        </ContentWrapperMob>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Status</TableHeaderName>
                            <TableHeaderValue sx={{ textAlign: "center" }}>
                              {row.status ? (
                                <StatusCompleted>Success</StatusCompleted>
                              ) : (
                                <StatusLive>Failure</StatusLive>
                              )}
                            </TableHeaderValue>
                          </ContentWrapperCenterMob>
                        </ContentWrapperMob>
                      </Box>
                    </>
                  ))}
                </Scrollbars>
                <Box
                  sx={{
                    position: "fixed",
                    bottom: "0px",
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomPagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    count={meta.total_count}
                    rowsPerPage={meta.per_page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                    }}
                    page={meta.page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    backIconButtonProps={{
                      style: {
                        background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                        color: (meta.page - 1) ? "#0062FD" : "#aaa",
                        borderRadius: "50%",
                        marginRight: "2px",
                        height: "25px",
                        width: "25px",
                      },
                    }}
                    nextIconButtonProps={{
                      style: {
                        background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                        color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                        borderRadius: "50%",
                        marginLeft: "2px",
                        height: "25px",
                        width: "25px",
                      },
                    }}
                    labelRowsPerPage={
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          wordWrap: "break-word",
                        }}
                      >
                        Rows per page:
                      </div>
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Hidden>

      {calenderDialog && (
        <Calender
          open={calenderDialog}
          handleSelect={handleSelect}
          setCalenderDialog={setCalenderDialog}
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          handleSave={handleSave}
        />
      )}
    </>
  );
};
export default WebhookHits;
