import React, { useEffect, useState } from "react";
import {
  // Button,
  Divider,
  Hidden,
  TablePagination,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";

import {
  AddFilterStack,
  TableBox,
  TableCells,
  TableContainerStack,
  TablePaginationBox,
} from "./indexStyled";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styled from "@emotion/styled";

import "./table.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Box } from "@mui/system";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsData } from "src/slices/Notifications/Notification";
import { RootState } from "src/store";
import Loader from "src/components/Loader/Loader";
import {
  ContentWrapperCenterMob,
  ContentWrapperMob,
  CustomPagination,
  DeviderBox,
  MenuWrapperMob,
  TableHeaderName,
  TableHeaderValue,
  TableHeaderWrapperNotification,
} from "src/components/TableMobileCss";
import Scrollbars from "react-custom-scrollbars-2";
import Calender from "../WebhookHits/calender";
import NoData from "src/assets/icons/NoData";

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

const NotificationsTable = () => {
  const { notificationsData, loading, meta } = useSelector(
    (state: RootState) => state.notifications
  );
  const dispatch = useDispatch();
  const [calenderDialog, setCalenderDialog] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  /*eslint-disable*/
  useEffect(() => {
    const dateRange = {
      startDate: selectedRange?.startDate
        ? moment(selectedRange?.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",

      endDate: selectedRange?.endDate
        ? moment(selectedRange?.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",
    };
    dispatch(getNotificationsData({ page: 1, per_page: 5 }, dateRange));
  }, [selectedRange]);

  const handleChangePage = (event: unknown, newPage: number) => {
    const data = {
      page: newPage + 1,
      per_page: meta.per_page,
    };
    const dateRange = {
      startDate: moment(selectedRange?.startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),

      endDate: moment(selectedRange?.endDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };
    dispatch(getNotificationsData(data, dateRange));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = {
      page: 1,
      per_page: event.target.value,
    };
    const dateRange = {
      startDate: moment(selectedRange?.startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),

      endDate: moment(selectedRange?.endDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };
    dispatch(getNotificationsData(data, dateRange));
  };

  const handleSelect = (ranges) => {
    setSelectedRange(ranges);
  };

  const handlePicker = () => {
    setCalenderDialog(true);
  };

  const handleSave = (ranges) => {
    const dateRange = {
      startDate: ranges?.startDate
        ? moment(ranges?.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",

      endDate: ranges?.endDate
        ? moment(ranges?.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",
    };
    dispatch(getNotificationsData({ page: 1, per_page: 5 }, dateRange));
    setCalenderDialog(false);
  };

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <TableContainerStack>
          {/* add filter button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 1,
              alignItems: "center",
            }}
          >
            <AddFilterStack>
              <Typography variant="h3" sx={{ color: "#3087EC" }}>
                Notifications
              </Typography>
            </AddFilterStack>

            <TextField
              sx={{ width: "48ch", mr: 1 }}
              size="small"
              variant="outlined"
              onClick={handlePicker}
              InputProps={{
                readOnly: true,
              }}
              value={`${selectedRange?.startDate
                ? moment(selectedRange?.startDate)
                  ?.format("DD-MM-YYYY")
                  .trim()
                : ""
                } - ${selectedRange?.endDate
                  ? moment(selectedRange?.endDate)?.format("DD-MM-YYYY").trim()
                  : ""
                }
                    `}
              placeholder="Select a Filter"
              helperText=""
              label="Filter"
            />
          </Box>

          {/* add filter button */}
          <Divider />
          <TableBox sx={{ height: "57.5vh" }}>
            <TableContainer sx={{ height: "100%" }}>
              {loading ? (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                </Box>
              ) : (
                <>
                  {notificationsData.length === 0 ? (
                    <div
                      style={{
                        alignSelf: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <NoData />
                    </div>
                  ) : (
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCells
                            style={{
                              width: "30%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            APP ID
                          </TableCells>
                          <TableCells
                            style={{
                              width: "30%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            Description
                          </TableCells>
                          <TableCells
                            style={{
                              width: "20%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            TITLE
                          </TableCells>
                          <TableCells
                            style={{
                              width: "20%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            DATE & TIME
                          </TableCells>
                          {/* <TableCells style={{ width: '10%', zIndex: "1", color: '#3087EC' }}>STATUS</TableCells> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {notificationsData.map((row, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCells>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <NotificationsIcon
                                    sx={{ fontSize: "22px" }}
                                  />
                                  <Typography>
                                    {row?.appId ? (
                                      <NoMaxWidthTooltip
                                        title={
                                          <Typography
                                            style={{
                                              display: "flex",
                                              gap: "0.5rem",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {row.appId}
                                          </Typography>
                                        }
                                      >
                                        <div>{row?.appId?.slice(0, 32)}...</div>
                                      </NoMaxWidthTooltip>
                                    ) : (
                                      "Not Applicable"
                                    )}
                                  </Typography>
                                </Box>
                              </TableCells>
                              <Tooltip title={row?.desc}>
                                {row?.desc.length >= 40 ? (
                                  <TableCell>
                                    {row.desc.slice(0, 40)}...
                                  </TableCell>
                                ) : (
                                  <TableCell>
                                    {row?.desc === ""
                                      ? "Not available"
                                      : row?.desc}
                                  </TableCell>
                                )}
                              </Tooltip>

                              <TableCell>{row.title}</TableCell>
                              <TableCells>
                                {moment(row.createdDate).format("DD MMM YYYY")}{" "}
                                &nbsp;&nbsp;
                                {moment(row.createdDate).format("hh:mm a")}
                              </TableCells>
                              {/* <TableCell>
                                {row.read ?
                                  <Typography sx={{ color: '#11BD60', background: '#DDFFDE', textAlign: 'center', width: '70px', borderRadius: '16px', padding:'5px 10px' }}>Read</Typography> :
                                  <Tooltip title={"Mark as read"} arrow>
                                    <Button disableRipple onClick={markUnreadHandler} sx={{padding:'0px'}}>
                                      <Typography sx={{ color: '#FF5A5A', background: '#FFE5E5', textAlign: 'center', width: '70px', borderRadius: '16px', padding:'5px 10px' }}>Unread</Typography>
                                    </Button>
                                  </Tooltip>
                                }
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </TableContainer>
          </TableBox>
          <TablePaginationBox>
            {notificationsData.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={meta.total_count}
                rowsPerPage={meta.per_page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                }}
                page={meta.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={{
                  style: {
                    background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                    color: (meta.page - 1) ? "#0062FD" : "#aaa",
                    borderRadius: "50%",
                    marginRight: "2px",
                  },
                }}
                nextIconButtonProps={{
                  style: {
                    background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                    color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                    borderRadius: "50%",
                    marginLeft: "2px",
                  },
                }}
              />
            )}
          </TablePaginationBox>
        </TableContainerStack>
      </Hidden>
      <Hidden only={["xl", "lg", "md"]}>
        <Box sx={{}}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", p: "10px" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "50%" }}>
              <Typography variant="h4" sx={{ color: "#3087EC" }}>
                Notifications
              </Typography>
            </Box>
            <TextField
              // sx={{ width: "32ch", mr: 1 }}
              size="small"
              variant="outlined"
              onClick={handlePicker}
              value={`${selectedRange?.startDate
                ? moment(selectedRange?.startDate)
                  ?.format("DD-MM-YYYY")
                  .trim()
                : ""
                } - ${selectedRange?.endDate
                  ? moment(selectedRange?.endDate)?.format("DD-MM-YYYY").trim()
                  : ""
                }
                  `}
              InputProps={{
                readOnly: true,
              }}
              placeholder="Select a Filter"
              helperText=""
              label="Filter"
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%", pt: "7px" }}>
          <Divider sx={{ height: "1px" }} />
        </Box>
        {loading ? (
          <Box
            sx={{
              height: "59vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box>
            {notificationsData.length === 0 ? (
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NoData />
              </div>
            ) : (
              <Box>
                <Scrollbars style={{ height: "60vh" }}>
                  {notificationsData.map((row, index) => (
                    <>
                      <Box key={index}>
                        <TableHeaderWrapperNotification>
                          <TableHeaderName>App ID</TableHeaderName>
                          <MenuWrapperMob>
                            <Typography>
                              {row?.appId ? row.appId : "Not Applicable"}
                            </Typography>
                          </MenuWrapperMob>
                        </TableHeaderWrapperNotification>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Session ID</TableHeaderName>
                            <TableHeaderValue>
                              <Tooltip title={row?.desc}>
                                {row?.desc.length >= 40 ? (
                                  <>{row.desc.slice(0, 40)}...</>
                                ) : (
                                  <>
                                    {row?.desc === ""
                                      ? "Not available"
                                      : row?.desc}
                                  </>
                                )}
                              </Tooltip>
                            </TableHeaderValue>
                          </ContentWrapperCenterMob>
                          <DeviderBox>
                            <Divider sx={{ height: "1.5px" }} />
                          </DeviderBox>
                        </ContentWrapperMob>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Title</TableHeaderName>
                            <TableHeaderValue>{row.title}</TableHeaderValue>
                          </ContentWrapperCenterMob>
                          <DeviderBox>
                            <Divider sx={{ height: "1.5px" }} />
                          </DeviderBox>
                        </ContentWrapperMob>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Date & Time</TableHeaderName>
                            <TableHeaderValue>
                              {moment(row.createdDate).format("DD MMM YYYY")}
                              &nbsp;&nbsp;
                              {moment(row.createdDate).format("hh:mm a")}
                            </TableHeaderValue>
                          </ContentWrapperCenterMob>
                          <DeviderBox>
                            <Divider sx={{ height: "1.5px" }} />
                          </DeviderBox>
                        </ContentWrapperMob>
                        {/* <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Status</TableHeaderName>
                            <TableHeaderValue>
                              {row.read ?
                                <Typography sx={{ color: '#11BD60', background: '#DDFFDE', textAlign: 'center', width: '70px', borderRadius: '16px', padding:'5px 10px' }}>Read</Typography> :
                                <Tooltip title={"Mark as read"} arrow>
                                  <Button disableRipple onClick={markUnreadHandler} sx={{padding:'0px'}}>
                                    <Typography sx={{ color: '#FF5A5A', background: '#FFE5E5', textAlign: 'center', width: '70px', borderRadius: '16px', padding:'5px 10px' }}>Unread</Typography>
                                  </Button>
                                </Tooltip>
                              }
                            </TableHeaderValue>
                          </ContentWrapperCenterMob>
                        </ContentWrapperMob> */}
                      </Box>
                    </>
                  ))}
                </Scrollbars>
                <Box
                  sx={{
                    position: "fixed",
                    bottom: "0px",
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomPagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    count={meta.total_count}
                    rowsPerPage={meta.per_page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                    }}
                    page={meta.page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    backIconButtonProps={{
                      style: {
                        background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                        color: (meta.page - 1) ? "#0062FD" : "#aaa",
                        borderRadius: "50%",
                        marginRight: "2px",
                        height: "25px",
                        width: "25px",
                      },
                    }}
                    nextIconButtonProps={{
                      style: {
                        background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                        color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                        borderRadius: "50%",
                        marginLeft: "2px",
                        height: "25px",
                        width: "25px",
                      },
                    }}
                    labelRowsPerPage={
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          wordWrap: "break-word",
                        }}
                      >
                        Rows per page:
                      </div>
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Hidden>

      {calenderDialog && (
        <Calender
          open={calenderDialog}
          handleSelect={handleSelect}
          setCalenderDialog={setCalenderDialog}
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          handleSave={handleSave}
        />
      )}
    </>
  );
};
export default NotificationsTable;
