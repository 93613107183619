import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
// import URLs from 'src/URls.json'
import { FailureSnackbar, SuccessSnackbar } from "src/slices/Snackbar";
import { CountryName, CountryCode } from "src/constants/countryDetails";

interface IProfile {
  tabState: string;
  profilePageLoading: boolean;
  profilePicture: string;
  profilePictureLoading: boolean;
  profileData: {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: boolean;
    countryCode: string;
    contactNo: string;
    organizationName: string;
    streetAddress1: string;
    streetAddress2: string;
    pincode: string;
    city: string;
    state: string;
    country: string;
    gst: string;
  };
  oldProfileData: {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: boolean;
    countryCode: string;
    contactNo: string;
    organizationName: string;
    streetAddress1: string;
    streetAddress2: string;
    pincode: string;
    city: string;
    state: string;
    country: string;
    gst: string;
  };
  updateDataLoading: boolean;
  fieldsTouched: boolean;
  countryNameList: string[];
  countryCodeList: string[];
  isHostOnboarded: boolean;
  verifyEmailDialog: boolean;
  emailOTP: string;
  verifyOTPLoader: boolean;
  enableResend: boolean;
}

const initialState: IProfile = {
  tabState: "/account/profile",
  profilePicture: "",
  profilePageLoading: false,
  profilePictureLoading: false,
  profileData: {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    emailVerified: false,
    countryCode: "",
    contactNo: "",
    organizationName: "",
    streetAddress1: "",
    streetAddress2: "",
    pincode: "",
    city: "",
    state: "",
    country: "India",
    gst: "",
  },
  oldProfileData: {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    emailVerified: false,
    countryCode: "",
    contactNo: "",
    organizationName: "",
    streetAddress1: "",
    streetAddress2: "",
    pincode: "",
    city: "",
    state: "",
    country: "India",
    gst: "",
  },
  updateDataLoading: false,
  fieldsTouched: false,
  countryNameList: CountryName,
  countryCodeList: CountryCode,
  isHostOnboarded: false,
  verifyEmailDialog: false,
  emailOTP: "",
  verifyOTPLoader: false,
  enableResend: false,
};

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setTabState(state, action) {
      state.tabState = action.payload;
    },
    setProfile(state, action) {
      state.profilePicture = action.payload;
    },
    setProfileLoading(state, action) {
      state.profilePictureLoading = action.payload;
    },
    setProfilePageLoading(state, action) {
      state.profilePageLoading = action.payload;
    },
    setProfileData(state, action) {
      state.profileData[action.payload.key] = action.payload.value;
    },
    getUserData(state, action) {
      const data = action.payload;
      state.profilePicture = data.avatar ? data.avatar : "";
      state.profileData.username = data.userName ? data.userName : "";
      state.profileData.firstName = data.firstName ? data.firstName : "";
      state.profileData.lastName = data.lastName ? data.lastName : "";
      state.profileData.email = data.email ? data.email : "";
      state.profileData.emailVerified = data.emailVerified
        ? data.emailVerified
        : false;
      state.profileData.countryCode = data.countryCode
        ? data.countryCode
        : "+91";
      state.profileData.contactNo = data.phoneNumber ? data.phoneNumber : "";
      state.profileData.organizationName = data?.address?.companyName
        ? data.address.companyName
        : "";

      state.profileData.streetAddress1 = data?.address?.streetAddress1
        ? data.address.streetAddress1
        : "";
      state.profileData.streetAddress2 = data?.address?.streetAddress2
        ? data.address.streetAddress2
        : "";
      state.profileData.pincode = data?.address?.pincode
        ? data.address.pincode
        : "";
      state.profileData.city = data?.address?.city ? data.address.city : "";
      state.profileData.state = data?.address?.state ? data.address.state : "";
      state.profileData.country = data?.address?.country
        ? data.address.country
        : "India";
      state.profileData.gst = data.gstNumber ? data.gstNumber : "";

      // store profile data to discard changes
      state.oldProfileData.username = data.userName ? data.userName : "";
      state.oldProfileData.firstName = data.firstName ? data.firstName : "";
      state.oldProfileData.lastName = data.lastName ? data.lastName : "";
      state.oldProfileData.email = data.email ? data.email : "";
      state.oldProfileData.emailVerified = data.emailVerified
        ? data.emailVerified
        : false;
      state.oldProfileData.countryCode = data.countryCode
        ? data.countryCode
        : "+91";
      state.oldProfileData.contactNo = data.phoneNumber ? data.phoneNumber : "";
      state.oldProfileData.organizationName = data?.address?.companyName
        ? data?.address?.companyName
        : "";
      state.oldProfileData.streetAddress1 = data?.address?.streetAddress1
        ? data.address.streetAddress1
        : "";
      state.oldProfileData.streetAddress2 = data?.address?.streetAddress2
        ? data.address.streetAddress2
        : "";
      state.oldProfileData.pincode = data?.address?.pincode
        ? data.address.pincode
        : "";
      state.oldProfileData.city = data?.address?.city ? data.address.city : "";
      state.oldProfileData.state = data?.address?.state
        ? data.address.state
        : "";
      state.oldProfileData.country = data?.address?.country
        ? data.address.country
        : "India";
      state.oldProfileData.gst = data.gstNumber ? data.gstNumber : "";
    },
    replaceProfileData(state, action) {
      state.profileData = action.payload;
    },
    setCountry(state, action) {
      state.profileData.country = action.payload;
    },
    setTouchedFields(state, action) {
      state.fieldsTouched = action.payload;
    },
    setUpdateLoading(state, action) {
      state.updateDataLoading = action.payload;
    },
    setIsHostOnboarded(state, action) {
      state.isHostOnboarded = action.payload;
    },
    setVerifyEmailDialog(state, action) {
      state.verifyEmailDialog = action.payload;
    },
    setEmailOTP(state, action) {
      state.emailOTP = action.payload;
    },
    setverifyOTPLoader(state, action) {
      state.verifyOTPLoader = action.payload;
    },
    setEnableResend(state, action) {
      state.enableResend = action.payload;
    },
  },
});

export const ProfileAction = slice.actions;

export const profilePicUpdate = (data: any) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("image", data);
    dispatch(slice.actions.setProfileLoading(true));
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/api/v1/user/avatar`,
      formData
    );
    dispatch(slice.actions.setProfileLoading(false));
    if (
      response.data.message === "User profile picture updated Successfully."
    ) {
      dispatch(slice.actions.setProfile(response.data.data.avatar));
      dispatch(
        SuccessSnackbar({
          message: "Profile picture updated successfully",
          open: true,
        })
      );
    } else {
      dispatch(
        FailureSnackbar({
          message: "Failed to update!",
          open: true,
        })
      );
    }
  } catch (err) {
    dispatch(
      FailureSnackbar({
        message: "Failed to update!",
        open: true,
      })
    );
    throw new Error(err);
  }
};

// delete profile picture
export const profilePicDelete = () => async (dispatch) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/v1/user/avatar`)
    .then((response) => {
      if (
        response.data.message === "User profile picture deleted Successfully."
      ) {
        dispatch(slice.actions.setProfile(""));
        dispatch(
          SuccessSnackbar({
            message: "Profile picture deleted successfully",
            open: true,
          })
        );
      } else {
        dispatch(
          FailureSnackbar({
            message: "Failed to delete!",
            open: true,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(
        FailureSnackbar({
          message: "Failed to delete!",
          open: true,
        })
      );
      throw new Error(err);
    });
};

// get user data
export const getUserDetails = () => async (dispatch) => {
  dispatch(slice.actions.setProfilePageLoading(true));
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/v1/user/`)
    .then((response) => {
      dispatch(slice.actions.setProfilePageLoading(false));
      const data = response.data.data;
      dispatch(slice.actions.getUserData(data));
    })
    .catch((err) => {
      dispatch(slice.actions.setProfilePageLoading(false));
      throw new Error(err);
    });
};
export const getData = () => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/v1/user/`)
    .then((response) => {
      const data = response?.data?.data;

      dispatch(slice.actions.getUserData(data));
      dispatch(
        slice.actions.setIsHostOnboarded(data?.preferences?.hostOnboarded)
      );
    })
    .catch((err) => {
      throw new Error(err);
    });
};

// update user data
export const updateUserDetails = (data) => async (dispatch) => {
  dispatch(slice.actions.setUpdateLoading(true));

  await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/v1/user/`, data)
    .then((response) => {
      dispatch(slice.actions.setUpdateLoading(false));
      if (response.data.message === "User details updated successfully") {
        dispatch(slice.actions.setTouchedFields(false));
        dispatch(
          SuccessSnackbar({
            message: "User details updated successfully",
            open: true,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(slice.actions.setUpdateLoading(false));
      dispatch(
        FailureSnackbar({
          message: "Profile update Unsuccessful, Try Again!",
          open: true,
        })
      );
      throw new Error(err);
    });
};

// verifyEmail
export const verifyEmail = (email: string) => async (dispatch) => {
  await axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/v1/user/email-verify?email=${email}`
    )
    .then((response) => {
      if (response.data.status) {
        dispatch(slice.actions.setEnableResend(false));
        dispatch(
          SuccessSnackbar({
            message: "Verification code sent successfully!",
            open: true,
          })
        );
        setTimeout(() => {
          dispatch(slice.actions.setEnableResend(true));
        }, 5 * 60 * 1000);
        dispatch(ProfileAction.setVerifyEmailDialog(true));
      }
      if (!response.data.status) {
        dispatch(slice.actions.setEnableResend(false));
        dispatch(
          FailureSnackbar({
            message: response.data.message || "Try after sometime!",
            open: true,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(slice.actions.setEnableResend(false));
      throw new Error(err);
    });
};

// verify through OTP
export const verifyEmailOTPAPI =
  (email: string, code: number) => async (dispatch) => {
    dispatch(slice.actions.setverifyOTPLoader(true));
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/email-verify?email=${email}&code=${code}`
      )
      .then((response) => {
        dispatch(slice.actions.setverifyOTPLoader(false));
        if (response.data.status) {
          if (response?.data?.message.includes("Email Verified Successfully")) {
            dispatch(
              SuccessSnackbar({
                message: "Email verified successfully!",
                open: true,
              })
            );
            dispatch(ProfileAction.setVerifyEmailDialog(false));
            dispatch(ProfileAction.setEmailOTP(""));
            dispatch(getUserDetails());
          } else {
            dispatch(
              FailureSnackbar({
                message: response.data.message || "Try after sometime!",
                open: true,
              })
            );
          }
        }
        if (!response.data.status) {
          dispatch(
            FailureSnackbar({
              message: response.data.message || "Try after sometime!",
              open: true,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(slice.actions.setverifyOTPLoader(true));
        throw new Error(err);
      });
  };

// discard changes
export const discardChanges = () => async (dispatch) => {
  dispatch(
    SuccessSnackbar({
      message: " Data discarded successfully!",
      open: true,
    })
  );
};

export const reducer = slice.reducer;

export default slice;
