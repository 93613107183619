import { Box, Typography } from "@mui/material";
import {
  CustomBox,
  Heading,
  MiniBoxMarginFifteen,
  Paragraph,
  SubHeading,
} from "./pageStyled";

const TermsConditions = () => {
  return (
    <>

      <Box
        sx={{
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingBottom: "5%",
          alignItems: "center",
        }}
      >
        <Box>
          <Heading>Terms of use</Heading>
          <Typography style={{ fontSize: "16px" }}>
            Updated on June 14, 2021
          </Typography>
        </Box>
        {/* <CustomBox>
          <Typography style={{ fontSize: '16px' }}>
            Updated on June 14, 2021
          </Typography>
        </CustomBox> */}
        <CustomBox>
          <SubHeading>1. AGREEMENT TO TERMS </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of{" "}
            <span style={{ fontWeight: 600 }}>an entity</span> (“you”) and{" "}
            <span style={{ fontWeight: 600 }}>Einston Labs P. Ltd.</span>{" "}
            (&#34;Company&#34;, &#34;we&#34;, &#34;us&#34;, or &#34;our&#34;),
            concerning your access to and use of the website as well as any
            other media form, media channel, mobile website or mobile
            application related, linked, or otherwise connected thereto
            (collectively, the “Site”). You agree that by accessing the Site,
            you have read, understood, and agreed to be bound by all of these
            Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE,
            THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            Supplemental terms and conditions or documents that may be posted on
            the Site from time to time are hereby expressly incorporated herein
            by reference. We reserve the right, in our sole discretion, to make
            changes or modifications to these Terms of Use at any time and for
            any reason. We will alert you about any changes by updating the
            “Last updated” date of these Terms of Use, and you waive any right
            to receive specific notice of each such change. It is your
            responsibility to periodically review these Terms of Use to stay
            informed of updates. You will be subject to, and will be deemed to
            have been made aware of and to have accepted, the changes in any
            revised Terms of Use by your continued use of the Site after the
            date such revised Terms of Use are posted.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            The information provided on the Site is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Site from other locations do so on
            their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            The Site is not tailored to comply with industry-specific
            regulations (Health Insurance Portability and Accountability Act
            (HIPAA), Federal Information Security Management Act (FISMA), etc.),
            so if your interactions would be subjected to such laws, you may not
            use this Site. You may not use the Site in a way that would violate
            the Gramm-Leach-Bliley Act (GLBA).{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            The Site is intended for users who are at least 18 years old.
            Persons under the age of 18 are not permitted to use or register for
            the Site.
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>2. INTELLECTUAL PROPERTY RIGHTS </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            Unless otherwise indicated, the Site is our proprietary property and
            all source code, databases, functionality, software, website
            designs, audio, video, text, photographs, and graphics on the Site
            (collectively, the “Content”) and the trademarks, service marks, and
            logos contained therein (the “Marks”) are owned or controlled by us
            or licensed to us, and are protected by copyright and trademark laws
            and various other intellectual property rights and unfair
            competition laws of the Republic of India, international copyright
            laws, and international conventions. The Content and the Marks are
            provided on the Site “AS IS” for your information and personal use
            only. Except as expressly provided in these Terms of Use, no part of
            the Site and no Content or Marks may be copied, reproduced,
            aggregated, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted, distributed, sold, licensed, or
            otherwise exploited for any commercial purpose whatsoever, without
            our express prior written permission.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            Provided that you are eligible to use the Site, you are granted a
            limited license to access and use the Site and to download or print
            a copy of any portion of the Content to which you have properly
            gained access solely for your personal, non-commercial use. We
            reserve all rights not expressly granted to you in and to the Site,
            the Content and the Marks.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>3. USER REPRESENTATIONS </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            By using the Site, you represent and warrant that: (1) all
            registration information you submit will be true, accurate, current,
            and complete; (2) you will maintain the accuracy of such information
            and promptly update such registration information as necessary; (3)
            you have the legal capacity and you agree to comply with these Terms
            of Use; (4) you are not a minor in the jurisdiction in which you
            reside; (5) you will not access the Site through automated or
            non-human means, whether through a bot, script, or otherwise; (6)
            you will not use the Site for any illegal or unauthorized purpose;
            and (7) your use of the Site will not violate any applicable law or
            regulation.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Site (or any portion thereof).{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>4. USER REGISTRATION </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            You may be required to register with the Site. You agree to keep
            your password confidential and will be responsible for all use of
            your account and password. We reserve the right to remove, reclaim,
            or change a username you select if we determine, in our sole
            discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>5. PROHIBITED ACTIVITIES </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            You may not access or use the Site for any purpose other than that
            for which we make the Site available. The Site may not be used in
            connection with any commercial endeavours except those that are
            specifically endorsed or approved by us.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>As a user of the Site, you agree not to: </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <ol type="i">
            <li>
              <Paragraph>
                Systematically retrieve data or other content from the Site to
                create or compile, directly or indirectly, a collection,
                compilation, database, or directory without written permission
                from us.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Trick, defraud, or mislead us and other users, especially in any
                attempt to learn sensitive account information such as user
                passwords.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Circumvent, disable, or otherwise interfere with
                security-related features of the Site, including features that
                prevent or restrict the use or copying of any Content or enforce
                limitations on the use of the Site and/or the Content contained
                therein.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Disparage, tarnish, or otherwise harm, in our opinion, us and/or
                the Site.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Use any information obtained from the Site in order to harass,
                abuse, or harm another person.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Make improper use of our support services or submit false
                reports of abuse or misconduct.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Use the Site in a manner inconsistent with any applicable laws
                or regulations.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Use the Site to advertise or offer to sell goods and services.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Engage in unauthorized framing of or linking to the Site.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material, including excessive
                use of capital letters and spamming (continuous posting of
                repetitive text), that interferes with any party’s uninterrupted
                use and enjoyment of the Site or modifies, impairs, disrupts,
                alters, or interferes with the use, features, functions,
                operation, or maintenance of the Site.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Delete the copyright or other proprietary rights notice from any
                Content.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Attempt to impersonate another user or person or use the
                username of another user.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>Sell or otherwise transfer your profile. </Paragraph>
            </li>
            <li>
              <Paragraph>
                Upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
                cookies, or other similar devices (sometimes referred to as
                “spyware” or “passive collection mechanisms” or “pcms”).{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Interfere with, disrupt, or create an undue burden on the Site
                or the networks or services connected to the Site.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Harass, annoy, intimidate, or threaten any of our employees or
                agents engaged in providing any portion of the Site to you.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Attempt to bypass any measures of the Site designed to prevent
                or restrict access to the Site, or any portion of the Site.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Copy or adapt the Site’s software, including but not limited to
                Flash, PHP, HTML, JavaScript, or other code.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Decipher, decompile, disassemble, or reverse engineer any of the
                software comprising or in any way making up a part of the Site.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Except as may be the result of standard search engine or
                internet browser usage, use, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                the Site, or using or launching any unauthorized script or other
                software.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Use a buying agent or purchasing agent to make purchases on the
                Site.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Make any unauthorized use of the Site, including collecting
                usernames and/or email addresses of users by electronic or other
                means for the purpose of sending unsolicited email, or creating
                user accounts by automated means or under false pretences.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Use the Site as part of any effort to compete with us or
                otherwise use the Site and/or the Content for any
                revenue-generating endeavour or commercial enterprise.{" "}
              </Paragraph>
            </li>
          </ol>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>6. USER GENERATED CONTRIBUTIONS </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            The Site does not offer users to submit or post content. We may
            provide you with the opportunity to create, submit, post, display,
            transmit, perform, publish, distribute, or broadcast content and
            materials to us or on the Site, including but not limited to text,
            writings, video, audio, photographs, graphics, comments,
            suggestions, or personal information or other material
            (collectively, &#34;Contributions&#34;). Contributions may be
            viewable by other users of the Site and through third-party
            websites. As such, any Contributions you transmit may be treated in
            accordance with the Site Privacy Policy. When you create or make
            available any Contributions, you thereby represent and warrant that:
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <ol type="1">
            <li>
              <Paragraph>
                The creation, distribution, transmission, public display, or
                performance, and the accessing, downloading, or copying of your
                Contributions do not and will not infringe the proprietary
                rights, including but not limited to the copyright, patent,
                trademark, trade secret, or moral rights of any third party.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                You are the creator and owner of or have the necessary licenses,
                rights, consents, releases, and permissions to use and to
                authorize us, the Site, and other users of the Site to use your
                Contributions in any manner contemplated by the Site and these
                Terms of Use.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                {" "}
                You have the written consent, release, and/or permission of each
                and every identifiable individual person in your Contributions
                to use the name or likeness of each and every such identifiable
                individual person to enable inclusion and use of your
                Contributions in any manner contemplated by the Site and these
                Terms of Use.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Your Contributions are not false, inaccurate, or misleading.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Your Contributions are not unsolicited or unauthorized
                advertising, promotional materials, pyramid schemes, chain
                letters, spam, mass mailings, or other forms of solicitation.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Your Contributions are not obscene, lewd, lascivious, filthy,
                violent, harassing, libellous, slanderous, or otherwise
                objectionable (as determined by us).
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Your Contributions do not ridicule, mock, disparage, intimidate,
                or abuse anyone.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Your Contributions are not used to harass or threaten (in the
                legal sense of those terms) any other person and to promote
                violence against a specific person or class of people.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Your Contributions do not violate any applicable law,
                regulation, or rule.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Your Contributions do not violate the privacy or publicity
                rights of any third party.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                {" "}
                Your Contributions do not contain any material that solicits
                personal information from anyone under the age of 18 or exploits
                people under the age of 18 in a sexual or violent manner.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Your Contributions do not violate any applicable law concerning
                child pornography, or otherwise intended to protect the health
                or well-being of minors.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Your Contributions do not include any offensive comments that
                are connected to race, religion, caste, national origin, gender,
                sexual preference, or physical handicap.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Your Contributions do not otherwise violate, or link to material
                that violates, any provision of these Terms of Use, or any
                applicable law or regulation. Any use of the Site in violation
                of the foregoing violates these Terms of Use and may result in,
                among other things, termination or suspension of your rights to
                use the Site.
              </Paragraph>
            </li>
          </ol>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>7. CONTRIBUTION LICENSE </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            You and the Site agree that we may access, store, process, and use
            any information and personal data that you provide following the
            terms of the Privacy Policy and your choices (including settings).
            By submitting suggestions or other feedback regarding the Site, you
            agree that we can use and share such feedback for any purpose
            without compensation to you. We do not assert any ownership over
            your Contributions. You retain full ownership of all of your
            Contributions and any intellectual property rights or other
            proprietary rights associated with your Contributions. We are not
            liable for any statements or representations in your Contributions
            provided by you in any area on the Site. You are solely responsible
            for your Contributions to the Site and you expressly agree to
            exonerate us from any and all responsibility and to refrain from any
            legal action against us regarding your Contributions.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>8. SOCIAL MEDIA </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            As part of the functionality of the Site, you may link your account
            with online accounts you have with third-party service providers
            (each such account, a “Third-Party Account”) by either: (1)
            providing your Third-Party Account login information through the
            Site; or (2) allowing us to access your Third-Party Account, as is
            permitted under the applicable terms and conditions that govern your
            use of each Third-Party Account. You represent and warrant that you
            are entitled to disclose your Third-Party Account login information
            to us and/or grant us access to your Third-Party Account, without
            breach by you of any of the terms and conditions that govern your
            use of the applicable Third-Party Account, and without obligating us
            to pay any fees or making us subject to any usage limitations
            imposed by the third-party service provider of the Third-Party
            Account. By granting us access to any Third-Party Accounts, you
            understand that (1) we may access, make available, and store (if
            applicable) any content that you have provided to and stored in your
            Third-Party Account (the “Social Network Content”) so that it is
            available on and through the Site via your account, including
            without limitation any friend lists and (2) we may submit to and
            receive from your Third-Party Account additional information to the
            extent you are notified when you link your account with the
            Third-Party Account. Depending on the Third-Party Accounts you
            choose and subject to the privacy settings that you have set in such
            Third-Party Accounts, personally identifiable information that you
            post to your Third-Party Accounts may be available on and through
            your account on the Site. Please note that if a Third-Party Account
            or associated service becomes unavailable or our access to such
            Third-Party Account is terminated by the third-party service
            provider, then Social Network Content may no longer be available on
            and through the Site. You will have the ability to disable the
            connection between your account on the Site and your Third-Party
            Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE
            THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY
            ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
            THIRD-PARTY SERVICE PROVIDERS. We make no effort to review any
            Social Network Content for any purpose, including but not limited
            to, for accuracy, legality, or non-infringement, and we are not
            responsible for any Social Network Content. You acknowledge and
            agree that we may access your email address book associated with a
            Third-Party Account and your contacts list stored on your mobile
            device or tablet computer solely for purposes of identifying and
            informing you of those contacts who have also registered to use the
            Site. You can deactivate the connection between the Site and your
            Third-Party Account by contacting us using the contact information
            below or through your account settings (if applicable). We will
            attempt to delete any information stored on our servers that was
            obtained through such a Third-Party Account, except the username and
            profile picture that become associated with your account.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>9. SUBMISSIONS </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information regarding the Site
            (&#34;Submissions&#34;) provided by you to us are non-confidential
            and shall become our sole property. We shall own exclusive rights,
            including all intellectual property rights, and shall be entitled to
            the unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are
            original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any
            alleged or actual infringement or misappropriation of any
            proprietary right in your Submissions.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>10. THIRD-PARTY WEBSITE AND CONTENT </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            The Site may contain (or you may be sent via the Site) links to
            other websites (&#34;Third-Party Websites&#34;) as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties (&#34;Third-Party
            Content&#34;). Such Third-Party Websites and Third-Party Content are
            not investigated, monitored, or checked for accuracy,
            appropriateness, or completeness by us, and we are not responsible
            for any Third-Party Websites accessed through the Site or any
            Third-Party Content posted on, available through, or installed from
            the Site, including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in
            the Third-Party Websites or the Third-Party Content. Inclusion of,
            linking to, or permitting the use or installation of any Third-Party
            Websites or any Third-Party Content does not imply approval or
            endorsement thereof by us. If you decide to leave the Site and
            access the Third-Party Websites or to use or install any Third-Party
            Content, you do so at your own risk, and you should be aware these
            Terms of Use no longer govern. You should review the applicable
            terms and policies, including privacy and data gathering practices,
            of any website to which you navigate from the Site or relating to
            any applications you use or install from the Site. Any purchases you
            make through Third-Party Websites will be through other websites and
            from other companies, and we take no responsibility whatsoever in
            relation to such purchases which are exclusively between you and the
            applicable third party. You agree and acknowledge that we do not
            endorse the products or services offered on Third-Party Websites and
            you shall hold us harmless from any harm caused by your purchase of
            such products or services. Additionally, you shall hold us harmless
            from any losses sustained by you or harm caused to you relating to
            or resulting in any way from any Third-Party Content or any contact
            with Third-Party Websites.
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>11. SITE MANAGEMENT </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We reserve the right, but not the obligation, to: (1) monitor the
            Site for violations of these Terms of Use; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Terms of Use, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Site or otherwise disable all files and content that
            are excessive in size or are in any way burdensome to our systems;
            and (5) otherwise manage the Site in a manner designed to protect
            our rights and property and to facilitate the proper functioning of
            the Site.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>12. PRIVACY POLICY </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We care about data privacy and security. By using the Site, you
            agree to be bound by our Privacy Policy posted on the Site, which is
            incorporated into these Terms of Use. Please be advised the Site is
            hosted in the Republic of India. If you access the Site from any
            other region of the world with laws or other requirements governing
            personal data collection, use, or disclosure that differ from
            applicable laws in the Republic of India, then through your
            continued use of the Site, you are transferring your data to the
            Republic of India, and you agree to have your data transferred to
            and processed in the Republic of India.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>13. CHARGES AND CANCELLATION </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            You agree that the company may charge to your credit card or other
            payment mechanism selected by you and approved by the company
            (&#34;Your Account&#34;) all amounts due and owing for the Services,
            including taxes and service fees, set up fees, subscription fees, or
            any other fee or charge associated with your account. Company may
            change prices at any time, including changing from a free service to
            a paid service and charging for services that were previously
            offered free of charge; provided, however, that the company will
            provide you with prior notice and an opportunity to terminate your
            account if the company changes the price of a service to which you
            are subscribed and will not charge you for a previously free service
            unless you have been notified of the applicable fees and agreed to
            pay such fees. You agree that in the event company is unable to
            collect the fees owed to the company for the Services through your
            account, the company may take any other steps it deems necessary to
            collect such fees from you and that you will be responsible for all
            costs and expenses incurred by the company in connection with such
            collection activity, including collection fees, court costs and
            attorneys&#39; fees. You further agree that the company may collect
            interest at the lesser of 1.5% per month or the highest amount
            permitted by law on any amounts not paid when due. You may cancel
            your subscription at any time. If you cancel, you will not be billed
            for any additional terms of service, and service will continue until
            the end of the current subscription term. If you cancel, you will
            not receive a refund for any service already paid for.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>14. FEES COLLECTION MODEL </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            You agree that you will be liable for the payment of the services
            used by you and the same must be paid as per the fees collection
            policy mentioned hereby. You will be paying the company as per
            pay-as-you-go model, that is you will be informed by the company
            during the monthly billing period about your usage and the same will
            be added in your monthly invoice. Invoice will be generated on the
            end of the last day of each month, that is the 31st day of January,
            March, May, July, August, October and December months; 30th day of
            April, June, September and November months; 28th/29th day of
            February month. You will be informed about your generated bill on
            the 1st day of each month and you will be liable to pay the same by
            the 7th day of the same month, failing which will cause deactivation
            of the services by the company. You can also be facilitated with an
            auto debit facility as per availability with reference to the
            privacy policy as mentioned above. Apart from the pay-as-you-go fees
            model as mentioned hereby, the company may provide additional
            discounts on upfront payment models which can be conveyed to you at
            regular intervals and may change as per market situation at regular
            intervals of time. As per upfront payment model, you will be making
            the payment in-advance which will be credited in your account with
            additional discount, commonly abbreviated as Credit Balance. You
            will be billed from the credit balance you own in your account.
            Although, your monthly invoice will still be generated on the end of
            the last day of each month, that is the 31st day of January, March,
            May, July, August, October and December months; 30th day of April,
            June, September and November months; 28th/29th day of February
            month. However, you may not be asked to make payments until you owe
            credit balance in your account. In such a case, the sole purpose of
            the monthly invoice would be to inform you about your monthly usage
            and credit balance remaining in your account. Though, you might be
            asked to make a payment in case of the services used which are not
            covered under upfront payment model or your credit balance has been
            exhausted.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>15. TERM AND TERMINATION </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            These Terms of Use shall remain in full force and effect while you
            use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
            USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
            NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING
            BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
            NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
            REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF
            USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY
            CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
            IN OUR SOLE DISCRETION.
          </Paragraph>{" "}
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>16. MODIFICATIONS AND INTERRUPTIONS </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We reserve the right to change, modify, or remove the contents of
            the Site at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Site. We also reserve the right to modify or
            discontinue all or part of the Site without notice at any time. We
            will not be liable to you or any third party for any modification,
            price change, suspension, or discontinuance of the Site. We cannot
            guarantee the Site will be available at all times. We may experience
            hardware, software, or other problems or need to perform maintenance
            related to the Site, resulting in interruptions, delays, or errors.
            We reserve the right to change, revise, update, suspend,
            discontinue, or otherwise modify the Site at any time or for any
            reason without notice to you. You agree that we have no liability
            whatsoever for any loss, damage, or inconvenience caused by your
            inability to access or use the Site during any downtime or
            discontinuance of the Site. Nothing in these Terms of Use will be
            construed to obligate us to maintain and support the Site or to
            supply any corrections, updates, or releases in connection
            therewith.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>17. GOVERNING LAW </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            These Terms of Use and your use of the Site are governed by and
            construed in accordance with the laws of the constitution of the
            Republic of India applicable to agreements made and to be entirely
            performed within the constitution of the Republic of India, without
            regard to its conflict of law principles.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>18. DISPUTE RESOLUTION </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            Any legal action of whatever nature brought by either you or us
            (collectively, the “Parties” and individually, a “Party”) shall be
            commenced or prosecuted in the District and Sessions located in
            Surat, Gujarat, and the Parties hereby consent to, and waive all
            defences of lack of personal jurisdiction and forum non convenience
            with respect to venue and jurisdiction in such District and Sessions
            courts. Application of the United Nations Convention on Contracts
            for the International Sale of Goods and the Uniform Computer
            Information Transaction Act (UCITA) are excluded from these Terms of
            Use.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>19. CORRECTIONS </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior
            notice.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>20. DISCLAIMER</SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
            THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
            RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR
            USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SITE&#39;S CONTENT OR THE CONTENT OF
            ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
            WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
            THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>21. LIMITATIONS OF LIABILITY </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>22. INDEMNIFICATION </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of: (1)
            use of the Site; (2) breach of these Terms of Use; (3) any breach of
            your representations and warranties set forth in these Terms of Use;
            (4) your violation of the rights of a third party, including but not
            limited to intellectual property rights; or (5) any overt harmful
            act toward any other user of the Site with whom you connected via
            the Site. Notwithstanding the foregoing, we reserve the right, at
            your expense, to assume the exclusive defence and control of any
            matter for which you are required to indemnify us, and you agree to
            cooperate, at your expense, with our defence of such claims. We will
            use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming
            aware of it.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>23. USER DATA</SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We will maintain certain data that you transmit to the Site for the
            purpose of managing the performance of the Site, as well as data
            relating to your use of the Site. Although we perform regular
            routine backups of data, you are solely responsible for all data
            that you transmit or that relates to any activity you have
            undertaken using the Site. You agree that we shall have no liability
            to you for any loss or corruption of any such data, and you hereby
            waive any right of action against us arising from any such loss or
            corruption of such data.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>
            24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SITE. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>25. MISCELLANEOUS</SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            These Terms of Use and any policies or operating rules posted by us
            on the Site or in respect to the Site constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Terms of Use
            shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. We
            may assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Terms of
            Use is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Terms of Use and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Terms of Use or use of the Site. You agree that
            these Terms of Use will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defences you may
            have based on the electronic form of these Terms of Use and the lack
            of signing by the parties hereto to execute these Terms of Use.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>26. CONTACT US </SubHeading>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            In order to resolve a complaint regarding the Site or to receive
            further information regarding use of the Site, please contact us at:
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>Einston Labs </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>9th Floor, Infinity Tower, </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>Lal Darwaja Station Rd, </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>Beside Ayurvedic College, </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>Puttur, Karnataka 574202 </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>Phone: +91 8347844939 </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>support@einstonlabs.in </Paragraph>
        </MiniBoxMarginFifteen>
      </Box>
    </>
  );
};

export default TermsConditions;
