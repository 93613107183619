import React from 'react';
function Pin({ props }) {
  return (
    <>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 20 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99995 0.487488C4.78745 0.487488 0.512451 4.61249 0.512451 9.63749C0.512451 13.65 6.24995 20.2875 8.72495 22.9875C9.06245 23.3625 9.51245 23.55 9.99995 23.55C10.4875 23.55 10.9375 23.3625 11.275 22.9875C13.75 20.325 19.4875 13.65 19.4875 9.63749C19.4875 4.57499 15.2125 0.487488 9.99995 0.487488ZM10.3 22.0875C10.1125 22.275 9.84995 22.275 9.69995 22.0875C7.86245 20.1 1.82495 13.275 1.82495 9.63749C1.82495 5.28749 5.49995 1.79999 9.99995 1.79999C14.5 1.79999 18.175 5.32499 18.175 9.63749C18.175 13.275 12.1375 20.0625 10.3 22.0875Z"
          fill="#212121"
        />
        <path
          d="M9.99995 5.88748C7.78745 5.88748 5.94995 7.68748 5.94995 9.93748C5.94995 12.15 7.74995 13.9875 9.99995 13.9875C12.25 13.9875 14.05 12.1875 14.05 9.93748C14.05 7.68748 12.2125 5.88748 9.99995 5.88748ZM9.99995 12.6375C8.49995 12.6375 7.26245 11.4 7.26245 9.89998C7.26245 8.39998 8.49995 7.16248 9.99995 7.16248C11.5 7.16248 12.7375 8.39998 12.7375 9.89998C12.7375 11.4 11.5 12.6375 9.99995 12.6375Z"
          fill=" #212121"
        />
      </svg>
    </>
  );
}

export default Pin;
