import React, { useRef } from "react";
import { Grid, Box, Divider } from "@mui/material";
import { ButtonPrimary, ButtonSecondary } from "src/components/CustomButtons";
import RewardIcon from "src/assets/icons/reward";
import {
  BannerContainer,
  BannerHeading,
  BannerSubHeading,
  CustomBillingInfoCard,
  CustomBox,
  CustomInfoCard,
  Heading,
  IconContainer,
  IntroHeading,
  IntroSubHeading,
  SmallText,
  SubHeading,
  TermsText,
} from "./pricingStyle";
import AVConfIcon from "src/assets/icons/avConfIcon";
import LiveConfIcon from "src/assets/icons/liveConfIcon";
import PricingCalculator from "./pricingCalculator";
import VolumnDiscountIcon from "src/assets/icons/volDiscountIcon";
import PayAsUseIcon from "src/assets/icons/payAsUseIcon";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import AddServiceIcon from "src/assets/icons/addServiceIcon";
// import { useNavigate } from "react-router-dom";
// import useAuth from "src/hooks/useAuth";

const TopCard = () => {
  // const { isAuthenticated } = useAuth();
  // const { loginWithPopup } = useAuth() as any;
  // const navigate = useNavigate();
  const {
    videoRateHD,
    audioRate,
    liveSpRate,
    liveViRate,
    encodeRate,
    recordingRate,
    storageRate,
    reStreamingRate,
  } = useSelector((state: RootState) => state.pricing);
  const scrollToRef = useRef(null);
  const handleButtonClick = () => {
    const scrollOffset = -74;
    const elementPosition =
      scrollToRef.current.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: elementPosition + scrollOffset,
      behavior: "smooth",
    });
  };

  const topCardData = [
    {
      id: 0,
      icon: <PayAsUseIcon props={{ width: "45px", height: "45px" }} />,
      head: "Pay as you go",
      content:
        "No upfront, first use then pay Select plans based on your needs.",
      btnText: "Start for free",
    },
    {
      id: 1,
      icon: <RewardIcon props={{ width: "38px", height: "38px" }} />,
      head: "Free 10,000 minutes",
      content:
        "Every month free conferencing mins. Select plans based on your needs. Use 10,000mins and then pay.",
      btnText: "Talk to us",
    },
    {
      id: 2,
      icon: <VolumnDiscountIcon props={{ width: "35px", height: "45px" }} />,
      head: "Volume discounts",
      content:
        "Never Worry about Large invoices. Discounts grow as your usage grows. Contact us for custom pricing and discounts",
      btnText: "Talk to us",
    },
  ];

  const middleCardData = [
    {
      id: 0,
      icon: <AVConfIcon props={{ width: "50px", height: "50px" }} />,
      head: "Audio & Video Conferencing",
      subheadname1: "Video call",
      subheadrate1: videoRateHD,
      person1: "per participant min",
      rule1: <Divider />,
      subheadname2: "Audio call",
      subheadrate2: audioRate,
      person2: "per participant min",
      rule2: "",
      subheadname3: "",
      subheadrate3: "",
      person3: "",
    },
    {
      id: 1,
      icon: <LiveConfIcon props={{ width: "60px", height: "40px" }} />,
      head: "Live Streaming",
      subheadname1: "Livestream (Speaker)",
      subheadrate1: liveSpRate,
      rule1: <Divider />,
      person1: "per speaker min",
      subheadname2: "Livestream (Viewer)",
      subheadrate2: liveViRate,
      person2: "per viewer min",
      rule2: <Divider />,
      subheadname3: "Encoding",
      subheadrate3: encodeRate,
      person3: "per encoded min",
    },
    {
      id: 2,
      icon: <AddServiceIcon props={{ width: "50px", height: "50px" }} />,
      head: "Additional Services Offered",
      subheadname1: "Cloud Recording",
      subheadrate1: recordingRate,
      rule1: <Divider />,
      person1: "per recorded min",
      subheadname2: "Storage",
      subheadrate2: storageRate,
      person2: "per min of video stored in a month",
      rule2: <Divider />,
      subheadname3: "Re-Streaming",
      subheadrate3: reStreamingRate,
      person3: "per streaming min",
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "40px" }}>
      {/* Top info cards start */}
      <Grid
        container
        sx={{
          width: { xs: "90%", sm: "100%", md: "90%", lg: "70%" },
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
        columnGap={2}
        rowGap={2}
      >
        {topCardData.map((data, index) => (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} xl={3.5} key={index}>
            <CustomInfoCard>
              <IconContainer>{data.icon}</IconContainer>
              <IntroHeading gutterBottom>{data.head}</IntroHeading>
              <IntroSubHeading gutterBottom>{data.content}</IntroSubHeading>
              <CustomBox>
                <ButtonPrimary
                  id="estimate-your-usage"
                  responsive={false}
                  margin="1.3rem 1.3rem 0 0"
                  fixedHeight="40px"
                  fixedWidth="100%"
                  // onClick={() => navigate("/contact-us")}
                >
                  {data.btnText}
                </ButtonPrimary>
              </CustomBox>
            </CustomInfoCard>
          </Grid>
        ))}
      </Grid>
      {/* Top info cards end */}
      {/* banner 1 */}
      <BannerContainer>
        <BannerHeading>
          Features that make Audio Video Conferencing experience smoother
        </BannerHeading>
        <BannerSubHeading>
          Get all great features of Protego tailored for your monthly active
          users
        </BannerSubHeading>
        <ButtonSecondary
          id="know-more"
          responsive={false}
          //   onClick={handleBeginToday}
        >
          Know more
        </ButtonSecondary>
      </BannerContainer>
      {/* banner 1 */}
      {/* Pricing rate chart cards start */}
      <Grid
        container
        sx={{
          width: { xs: "95%", sm: "100%", md: "80%" },
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
        columnGap={4}
        rowGap={4}
      >
        {middleCardData.map((data, index) => (
          <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5} key={index}>
            <CustomBillingInfoCard>
              <div style={{ height: "400px" }}>
                <SubHeading gutterBottom>{data.icon}</SubHeading>
                <Heading gutterBottom sx={{ marginBottom: "50px" }}>
                  {data.head}
                </Heading>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SubHeading gutterBottom>{data.subheadname1}</SubHeading>
                  <SubHeading gutterBottom>${data.subheadrate1}</SubHeading>
                </Box>
                <SmallText gutterBottom>{data.person1}</SmallText>
                <Heading gutterBottom sx={{ marginBottom: "10px" }}>
                  {data.rule1}
                </Heading>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SubHeading gutterBottom>{data.subheadname2}</SubHeading>
                  <SubHeading gutterBottom>${data.subheadrate2}</SubHeading>
                </Box>
                <SmallText gutterBottom>{data.person2}</SmallText>
                <Heading gutterBottom sx={{ marginBottom: "10px" }}>
                  {data.rule2}
                </Heading>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SubHeading gutterBottom>{data.subheadname3}</SubHeading>
                  <SubHeading gutterBottom>
                    {data.subheadrate3 !== "" && "$"}
                    {data.subheadrate3}
                  </SubHeading>
                </Box>
                <SmallText gutterBottom sx={{ marginBottom: "10px" }}>
                  {data.person3}
                </SmallText>
              </div>

              <CustomBox>
                {data.id !== 2 ? (
                  <ButtonPrimary
                    id="estimate-your-usage"
                    responsive={false}
                    margin="1.3rem 0 0 0"
                    fixedHeight="40px"
                    onClick={handleButtonClick}
                  >
                    Estimate your usage
                  </ButtonPrimary>
                ) : (
                  <TermsText>
                    *Data will be retained for 7 days.&nbsp;
                    <span
                      id="contact-us"
                      className="contact_link"
                      // onClick={() => {navigate("/pages/terms-and-conditions");}}
                    >
                      Contact Us
                    </span>
                    &nbsp;in order to increase the data retention period.
                  </TermsText>
                )}
              </CustomBox>
            </CustomBillingInfoCard>
          </Grid>
        ))}
      </Grid>
      {/* Pricing rate chart cards end */}

      {/* banner 2 */}
      <BannerContainer>
        <BannerHeading>
          Get 10,000mins of streaming free every month
        </BannerHeading>
        <BannerSubHeading>
          Start with us today for free, no credit cards required
        </BannerSubHeading>
        <ButtonSecondary
          id="know-more"
          responsive={false}
          //   onClick={handleBeginToday}
        >
          Start today
        </ButtonSecondary>
      </BannerContainer>
      {/* banner 2 */}

      {/* Pricing calculator start */}
      <div id="element-to-scroll-to" ref={scrollToRef}>
        <PricingCalculator />
      </div>
      {/* Pricing calculator end */}
    </Box>
  );
};

export default TopCard;
