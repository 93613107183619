import React from "react";
import { Typography, Card, Button, Grid } from "@mui/material";

const ManageSubscriptionCard = () => {
  const subscriptionRecords = [
    {
      subscription_type: "Dev",
      active_users: 25,
      subscription_plan: "Free",
      plan: "Current Plan",
      add_payment: "Add payment method to unlock add - ons",
    },
    {
      subscription_type: "Basic",
      active_users: 300,
      subscription_plan: "$99.00/month",
      plan: "Upgrade",
      add_payment: "Add - ons available(paid per usage)",
    },
    {
      subscription_type: "Standard",
      active_users: 1500,
      subscription_plan: "$499.00/month",
      plan: "Upgrade",
      add_payment: "Add - ons available(paid per usage)",
    },
    {
      subscription_type: "Enterprise",
      active_users: 3000,
      subscription_plan: "$999.00/month",
      plan: "Upgrade",
      add_payment: "Add - ons available(paid per usage)",
    },
  ];

  const upgradePlan = () => {
    // console.log("Please upgrade your plan");
  };

  return (
    <>
      <Grid container spacing={2}>
        {subscriptionRecords.map((record, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Card className="manage_subscription_card">
              <Typography
                variant="h2"
                component="div"
                gutterBottom
                className="subscription_type justify_center"
              >
                {record.subscription_type}
              </Typography>
              <br />
              <Typography
                variant="subtitle1"
                component="div"
                gutterBottom
                className="active_users justify_center"
              >
                Monthly Active Users
              </Typography>
              <Typography
                variant="h1"
                component="div"
                gutterBottom
                className="users_count justify_center"
              >
                {record.active_users}
              </Typography>
              <div className="plan_amount_container">
                <Typography
                  variant="h1"
                  component="div"
                  gutterBottom
                  className="plan_amount"
                >
                  {record.subscription_plan}
                </Typography>
              </div>
              <br />
              {record.plan === "Current Plan" && (
                <Typography
                  variant="subtitle1"
                  component="div"
                  gutterBottom
                  className="current_plan justify_center"
                >
                  {record.plan}
                </Typography>
              )}
              {record.plan !== "Current Plan" && (
                <div className="justify_center">
                  <Button className="upgrade_plan_button" onClick={upgradePlan}>
                    {record.plan}
                  </Button>
                </div>
              )}
              <br />
              <Typography
                variant="subtitle1"
                component="div"
                gutterBottom
                className="subscription_add_ons"
              >
                {record.add_payment}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
      <br />
      <Typography
        className="sub_title"
        variant="subtitle2"
        gutterBottom
        component="div"
        style={{ textAlign: "center" }}
      >
        These prices do not include taxes and fees.
      </Typography>
    </>
  );
};

export default ManageSubscriptionCard;
