import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddFilterStack,
  StatusCompleted,
  StatusLive,
  TableBox,
  TableCells,
  TableContainerStack,
  TablePaginationBox,
  TableRowBox,
} from "./indexStyled";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import "./table.css";
import AssessmentIcon from "@mui/icons-material/Assessment";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  ConferenceAction,
  fetchCompleteData,
  fetchConferenceTableData,
} from "src/slices/Conference/ConferenceTable";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import Loader from "src/components/Loader/Loader";
import { RootState } from "src/store";
import {
  ContentWrapperCenterMob,
  ContentWrapperMob,
  CustomPagination,
  DeviderBox,
  MenuWrapperMob,
  TableHeaderName,
  TableHeaderValue,
  TableHeaderWrapper,
} from "src/components/TableMobileCss";
import Scrollbars from "react-custom-scrollbars-2";
import { editTableData } from "src/slices/apiKeys";
import Calender from "../WebhookHits/calender";
import NoData from "src/assets/icons/NoData";
import { InputLabelText } from "src/content/billing/BillingStyled";

const status = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Live",
    value: "live",
  },
];

const ConferenceTable = () => {
  const { conferenceData, meta, appIdData, loading } = useSelector(
    (state: RootState) => state.conference
  );
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [calenderDialog, setCalenderDialog] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const { tableData } = useSelector((state: RootState) => state.apiKeys);
  const dispatch = useDispatch();
  const idList = [...tableData];

  const exportToExcelHandler = () => {
    dispatch(
      fetchCompleteData(
        meta.total_count ? meta.total_count : 10000,
        appIdData.appId
      )
    );
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    const data = {
      page: newPage + 1,
      per_page: meta.per_page,
      page_count: meta.page_count,
      total_count: meta.total_count,
    };
    const dateRange = {
      startDate: moment(selectedRange?.startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),

      endDate: moment(selectedRange?.endDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };
    dispatch(
      fetchConferenceTableData(data, appIdData.appId, dateRange, statusFilter)
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = {
      page: 1,
      per_page: event.target.value,
      page_count: meta.page_count,
      total_count: meta.total_count,
    };
    const dateRange = {
      startDate: moment(selectedRange?.startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),

      endDate: moment(selectedRange?.endDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };
    dispatch(
      fetchConferenceTableData(data, appIdData.appId, dateRange, statusFilter)
    );
  };
  /*eslint-disable*/
  useEffect(() => {
    const data = {
      page: 1,
      per_page: meta.per_page,
      page_count: meta.page_count,
      total_count: meta.total_count,
    };
    const dateRange = {
      startDate: moment(selectedRange?.startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),

      endDate: moment(selectedRange?.endDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };
    dispatch(editTableData());
    dispatch(
      fetchConferenceTableData(data, appIdData.appId, dateRange, statusFilter)
    );
  }, [dispatch]);

  const handlePicker = () => {
    setCalenderDialog(true);
  };

  const handleSelect = (ranges) => {
    setSelectedRange(ranges);
  };

  const handleSave = (ranges) => {
    const dateRange = {
      startDate: ranges?.startDate
        ? moment(ranges?.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",

      endDate: ranges?.endDate
        ? moment(ranges?.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",
    };
    dispatch(
      fetchConferenceTableData(
        {
          page: 1,
          per_page: 5,
          page_count: 0,
          total_count: 0,
        },
        // dataAppid?.appId,
        appIdData.appId ? appIdData.appId : "",
        dateRange,
        statusFilter ? statusFilter : ""
      )
    );
    setCalenderDialog(false);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    const dateRange = {
      startDate: selectedRange?.startDate
        ? moment(selectedRange?.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",

      endDate: selectedRange?.endDate
        ? moment(selectedRange?.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",
    };
    dispatch(
      fetchConferenceTableData(
        {
          page: 1,
          per_page: 5,
          page_count: 0,
          total_count: 0,
        },
        appIdData?.appId,
        dateRange,
        e.target.value
      )
    );
    dispatch(ConferenceAction.setPageNumber(1));
  };

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <TableContainerStack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 1,
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AddFilterStack>
                <Typography variant="h3" sx={{ color: "#3087EC" }}>
                  Conference
                </Typography>
              </AddFilterStack>
              {appIdData.name ? (
                <IconButton
                  sx={{ color: "#3087EC" }}
                  onClick={exportToExcelHandler}
                >
                  <SimCardDownloadOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton disabled>
                  <SimCardDownloadOutlinedIcon />
                </IconButton>
              )}
            </Box>

            <Box sx={{ display: "flex" }}>
              <Autocomplete
                sx={{
                  width: "30ch",
                  ".MuiIconButton-root.MuiAutocomplete-clearIndicator": {
                    display: appIdData.name ? "" : "none",
                    background: "#f6f8fb",
                    color: "black",
                    marginRight: "-2px",
                    "&:hover": {
                      display: appIdData.name ? "" : "none",
                      background: "#f6f8fb",
                      color: "black",
                      marginRight: "-2px",
                    },
                  },
                }}
                value={appIdData}
                onChange={(e: any, newvalue) => {
                  const dateRange = {
                    startDate: moment(selectedRange?.startDate)
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),

                    endDate: moment(selectedRange?.endDate)
                      .endOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  };
                  if (newvalue === null) {
                    dispatch(ConferenceAction.setAppId(""));
                    dispatch(
                      fetchConferenceTableData(
                        {
                          page: 1,
                          per_page: 5,
                          page_count: 0,
                          total_count: 0,
                        },
                        "",
                        dateRange,
                        statusFilter
                      )
                    );
                    dispatch(ConferenceAction.setPageNumber(1));

                    return;
                  }
                  dispatch(ConferenceAction.setAppId(newvalue));
                  dispatch(
                    fetchConferenceTableData(
                      {
                        page: 1,
                        per_page: 5,
                        page_count: 0,
                        total_count: 0,
                      },
                      newvalue?.appId,
                      dateRange,
                      statusFilter
                    )
                  );
                  dispatch(ConferenceAction.setPageNumber(1));
                }}
                id="combo-box-demo"
                size="small"
                options={idList}
                getOptionLabel={(option: any) =>
                  option?.name ? `${option.name} (${option.appId})` : ""
                }
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name} ({option.appId})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    sx={{ pr: "0px" }}
                    {...params}
                    label="App name"
                    placeholder="Select an application name"
                  />
                )}
              />

              {/* <Grid item xs={12} sm={3.73} md={3.73} lg={3.8}> */}
              <FormControl
                variant="outlined"
                sx={{ width: "26ch", ml: 1 }}
                fullWidth
              >
                <InputLabelText>Status</InputLabelText>
                <Select
                  size="small"
                  // value={currency}
                  value={statusFilter}
                  onChange={handleStatusFilter}
                  label="Status"
                  placeholder="Status"
                  MenuProps={{ MenuListProps: { sx: { p: 0, m: 0 } } }}
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                  id="status"
                >
                  {status?.length > 0 &&
                    status.map((item, index) => (
                      <MenuItem
                        value={item.value}
                        key={index}
                        sx={{ fontFamily: "Poppins" }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/* </Grid>  */}

              <TextField
                placeholder="Select a Filter"
                label="Filter"
                sx={{ width: "35ch", ml: 1 }}
                size="small"
                variant="outlined"
                onClick={handlePicker}
                InputProps={{
                  readOnly: true,
                }}
                value={`${selectedRange?.startDate
                  ? moment(selectedRange?.startDate)
                    ?.format("DD-MM-YYYY")
                    .trim()
                  : ""
                  } - ${selectedRange?.endDate
                    ? moment(selectedRange?.endDate)
                      ?.format("DD-MM-YYYY")
                      .trim()
                    : ""
                  }
                    `}
              />
            </Box>
          </Box>
          <Divider />
          <TableBox sx={{ height: "56vh" }}>
            <TableContainer sx={{ height: "100%" }}>
              {loading ? (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </Box>
              ) : (
                <>
                  {conferenceData.length === 0 ? (
                    <div
                      style={{
                        alignSelf: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <NoData />
                    </div>
                  ) : (
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCells
                            style={{
                              width: "20%",
                              paddingLeft: "15px",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            ROOM
                          </TableCells>
                          <TableCells
                            style={{
                              width: "15%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            SESSION ID
                          </TableCells>
                          {/* <TableCells
                            style={{
                              width: "10%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            NAME
                          </TableCells> */}
                          <TableCells
                            style={{
                              width: "17%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            START & END TIME
                          </TableCells>
                          <TableCells
                            style={{
                              width: "12%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            DURATION (MINS)
                          </TableCells>
                          <TableCells
                            style={{
                              width: "13%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            CAUSE CODE
                          </TableCells>
                          <TableCells
                            style={{
                              width: "10%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            COST
                          </TableCells>
                          <TableCells
                            align="center"
                            style={{
                              width: "15%",
                              zIndex: "1",
                              color: "#3087EC",
                            }}
                          >
                            STATUS
                          </TableCells>
                        </TableRow>
                      </TableHead>
                      {!loading && meta.total_count >= 1 && (
                        <TableBody sx={{ overflow: "scroll" }}>
                          {conferenceData.map((row, index) => {
                            return (
                              <TableRowBox key={Math.random()}>
                                <TableCells>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "0.5rem",
                                    }}
                                  >
                                    <AssessmentIcon sx={{ fontSize: "22px" }} />
                                    <Typography sx={{ textTransform: 'none' }}>
                                      {row.roomName}
                                      {/* <NoMaxWidthTooltip
                                        title={
                                          <Typography
                                            style={{
                                              display: "flex",
                                              gap: "0.5rem",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {row.appId}
                                            <CopyToClipboard text={row.appId}>
                                              <IconBase>
                                                <ContentCopy />
                                              </IconBase>
                                            </CopyToClipboard>
                                          </Typography>
                                        }
                                      >
                                        <div style={{textTransform:'none'}}>{row?.appId.slice(0, 18)}...</div>
                                      </NoMaxWidthTooltip> */}
                                    </Typography>
                                  </Box>
                                </TableCells>
                                <TableCells><Typography sx={{ textTransform: 'none' }}>{row.sessionId}</Typography></TableCells>
                                {/* <TableCells>{row.participantName}</TableCells> */}
                                <TableCell>
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    <div>
                                      {moment(new Date(row.startTime)).format(
                                        "DD MMM YYYY"
                                      )}
                                      &nbsp;&nbsp;
                                      {moment(new Date(row.startTime)).format(
                                        "hh:mm a"
                                      )}
                                    </div>

                                    <div>
                                      {row.endTime === null ? (
                                        "-"
                                      ) : (
                                        <>
                                          {moment(new Date(row.endTime)).format(
                                            "DD MMM YYYY"
                                          )}
                                          &nbsp;&nbsp;
                                          {moment(new Date(row.endTime)).format(
                                            "hh:mm a"
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  className="tablecell_minutes"
                                  sx={{ textAlign: "center" }}
                                >
                                  {row.minutes}
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    {row.causeCode === ""
                                      ? "Not available"
                                      : row.causeCode}
                                  </Typography>
                                </TableCell>
                                <TableCells>$&nbsp;{row.cost}</TableCells>
                                <TableCell align="center">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {row.status === "completed" ? (
                                      <StatusCompleted>
                                        {row.status}
                                      </StatusCompleted>
                                    ) : (
                                      <StatusLive>{row.status}</StatusLive>
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRowBox>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  )}
                </>
              )}
            </TableContainer>
          </TableBox>
          <TablePaginationBox>
            {conferenceData.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={meta.total_count}
                rowsPerPage={meta.per_page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                }}
                page={meta.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={{
                  style: {
                    background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                    color: (meta.page - 1) ? "#0062FD" : "#aaa",
                    borderRadius: "50%",
                    marginRight: "2px",
                  },
                }}
                nextIconButtonProps={{
                  style: {
                    background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                    color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                    borderRadius: "50%",
                    marginLeft: "2px",
                  },
                }}
              />
            )}
          </TablePaginationBox>
        </TableContainerStack>
      </Hidden>
      {/* mobile  */}
      <Hidden only={["xl", "lg", "md"]}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "", sm: "center" },
            alignItems: { xs: "", sm: "center" },
            alignSelf: { xs: "", sm: "center" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pl: "10px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h4"
                sx={{ color: "#3087EC", mb: { xs: 2, sm: 0 } }}
              >
                Conference
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={1} sx={{ pl: 1.5, pr: 1.5 }}>
            <Grid item xs={12} sm={4}>
              {/* {conferenceData.length > 0 && ( */}
              <Autocomplete
                sx={{
                  ".MuiIconButton-root.MuiAutocomplete-clearIndicator": {
                    display: appIdData.name ? "" : "none",
                    background: "#f6f8fb",
                    color: "black",
                    marginRight: "-2px",
                    "&:hover": {
                      display: appIdData.name ? "" : "none",
                      background: "#f6f8fb",
                      color: "black",
                      marginRight: "-2px",
                    },
                  },
                }}
                defaultValue=""
                value={appIdData}
                onChange={(e: any, newvalue) => {
                  const dateRange = {
                    startDate: moment(selectedRange?.startDate)
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),

                    endDate: moment(selectedRange?.endDate)
                      .endOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  };
                  if (newvalue === null) {
                    dispatch(ConferenceAction.setAppId(""));
                    dispatch(
                      fetchConferenceTableData(
                        {
                          page: 1,
                          per_page: 5,
                          page_count: 0,
                          total_count: 0,
                        },
                        "",
                        dateRange,
                        statusFilter
                      )
                    );
                    dispatch(ConferenceAction.setPageNumber(1));

                    return;
                  }
                  dispatch(ConferenceAction.setAppId(newvalue));
                  dispatch(
                    fetchConferenceTableData(
                      {
                        page: 1,
                        per_page: 5,
                        page_count: 0,
                        total_count: 0,
                      },
                      newvalue?.appId,
                      dateRange,
                      statusFilter
                    )
                  );
                  dispatch(ConferenceAction.setPageNumber(1));
                }}
                id="combo-box-demo"
                size="small"
                options={idList}
                getOptionLabel={(option: any) =>
                  option?.name ? `${option.name} (${option.appId})` : ""
                }
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name} ({option.appId})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    sx={{ pr: "0px" }}
                    {...params}
                    label="App name"
                    placeholder="Select an application name"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} sm={4} sx={{ mt: { xs: 1, sm: 0 } }}>
              {/* <TextField
                // sx={{ width: "19ch" }}
                sx={{ width: "100%" }}
                size="small"
                variant="outlined"
                select
                placeholder="Select a status"
                label="Status"
                value={statusFilter}
                onChange={handleStatusFilter}
              >
                {status?.length > 0 &&
                  status.map((item, id) => {
                    return (
                      <MenuItem
                        key={id}
                        value={item.value}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </TextField> */}
              <FormControl
                variant="outlined"
                // sx={{ width: "26ch", ml: 1 }}
                fullWidth
              >
                <InputLabelText>Status</InputLabelText>
                <Select
                  size="small"
                  // value={currency}
                  value={statusFilter}
                  onChange={handleStatusFilter}
                  label="Status"
                  placeholder="Status"
                  // onChange={handleCurrenyChange}
                  MenuProps={{ MenuListProps: { sx: { p: 0, m: 0 } } }}
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                    // width: "26ch",
                    // ml: 1,
                  }}
                  id="status"
                >
                  {status?.length > 0 &&
                    status.map((item, index) => (
                      <MenuItem
                        value={item.value}
                        key={index}
                        // id={`${data.toLowerCase()}`}
                        sx={{ fontFamily: "Poppins" }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} sx={{ mt: { xs: 1, sm: 0 } }}>
              <TextField
                placeholder="Select a Filter"
                label="Filter"
                fullWidth
                size="small"
                variant="outlined"
                onClick={handlePicker}
                InputProps={{
                  readOnly: true,
                }}
                value={`${selectedRange?.startDate
                  ? moment(selectedRange?.startDate)
                    ?.format("DD-MM-YYYY")
                    .trim()
                  : ""
                  } - ${selectedRange?.endDate
                    ? moment(selectedRange?.endDate)
                      ?.format("DD-MM-YYYY")
                      .trim()
                    : ""
                  }
                    `}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", pt: "7px" }}>
          <Divider sx={{ height: "1px" }} />
        </Box>
        {loading ? (
          <Box
            sx={{
              height: "58vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box>
            {conferenceData.length === 0 ? (
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NoData />
              </div>
            ) : (
              <>
                <Scrollbars style={{ height: "58vh" }}>
                  {conferenceData.map((row, index) => (
                    <>
                      <Box key={index}>
                        <TableHeaderWrapper>
                          <TableHeaderName>Room</TableHeaderName>
                          <MenuWrapperMob>
                            <Typography sx={{ textTransform: 'none' }}>{row.roomName}</Typography>
                          </MenuWrapperMob>
                        </TableHeaderWrapper>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Session ID</TableHeaderName>
                            <TableHeaderValue><Typography sx={{ textTransform: 'none' }}>{row.sessionId}</Typography></TableHeaderValue>
                          </ContentWrapperCenterMob>
                          <DeviderBox>
                            <Divider sx={{ height: "1.5px" }} />
                          </DeviderBox>
                        </ContentWrapperMob>
                        {/* <ContentWrapperMob>
                        <ContentWrapperCenterMob>
                          <TableHeaderName>Name</TableHeaderName>
                          <TableHeaderValue>
                            {row.participantName}
                          </TableHeaderValue>
                        </ContentWrapperCenterMob>
                        <DeviderBox>
                          <Divider sx={{ height: "1.5px" }} />
                        </DeviderBox>
                      </ContentWrapperMob> */}
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Start & End Time</TableHeaderName>
                            <TableHeaderValue>
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  textTransform: "uppercase",
                                }}
                              >
                                <div>
                                  {moment(new Date(row.startTime)).format(
                                    "DD MMM YYYY"
                                  )}
                                  &nbsp;&nbsp;
                                  {moment(new Date(row.startTime)).format(
                                    "hh:mm a"
                                  )}
                                </div>

                                <div>
                                  {moment(new Date(row.endTime)).format(
                                    "DD MMM YYYY"
                                  )}
                                  &nbsp;&nbsp;
                                  {moment(new Date(row.endTime)).format(
                                    "hh:mm a"
                                  )}
                                </div>
                              </Box>
                            </TableHeaderValue>
                          </ContentWrapperCenterMob>
                          <DeviderBox>
                            <Divider sx={{ height: "1.5px" }} />
                          </DeviderBox>
                        </ContentWrapperMob>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Duration (MINS)</TableHeaderName>
                            <TableHeaderValue>{row.minutes}</TableHeaderValue>
                          </ContentWrapperCenterMob>
                          <DeviderBox>
                            <Divider sx={{ height: "1.5px" }} />
                          </DeviderBox>
                        </ContentWrapperMob>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Cause Code</TableHeaderName>
                            <TableHeaderValue>
                              {row.causeCode === ""
                                ? "Not available"
                                : row.causeCode}
                            </TableHeaderValue>
                          </ContentWrapperCenterMob>
                          <DeviderBox>
                            <Divider sx={{ height: "1.5px" }} />
                          </DeviderBox>
                        </ContentWrapperMob>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Cost</TableHeaderName>
                            <TableHeaderValue>${row.cost}</TableHeaderValue>
                          </ContentWrapperCenterMob>
                          <DeviderBox>
                            <Divider sx={{ height: "1.5px" }} />
                          </DeviderBox>
                        </ContentWrapperMob>
                        <ContentWrapperMob>
                          <ContentWrapperCenterMob>
                            <TableHeaderName>Status</TableHeaderName>
                            <TableHeaderValue>
                              {row.status === "completed" ? (
                                <StatusCompleted>{row.status}</StatusCompleted>
                              ) : (
                                <StatusLive>{row.status}</StatusLive>
                              )}
                            </TableHeaderValue>
                          </ContentWrapperCenterMob>
                        </ContentWrapperMob>
                      </Box>
                    </>
                  ))}
                </Scrollbars>
                <Box
                  sx={{
                    position: "fixed",
                    bottom: "0px",
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomPagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    count={meta.total_count}
                    rowsPerPage={meta.per_page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                    }}
                    page={meta.page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    backIconButtonProps={{
                      style: {
                        background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                        color: (meta.page - 1) ? "#0062FD" : "#aaa",
                        borderRadius: "50%",
                        marginRight: "2px",
                        height: "25px",
                        width: "25px",
                      },
                    }}
                    nextIconButtonProps={{
                      style: {
                        background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                        color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                        borderRadius: "50%",
                        marginLeft: "2px",
                        height: "25px",
                        width: "25px",
                      },
                    }}
                    labelRowsPerPage={
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          wordWrap: "break-word",
                        }}
                      >
                        Rows per page:
                      </div>
                    }
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </Hidden>

      {calenderDialog && (
        <Calender
          open={calenderDialog}
          handleSelect={handleSelect}
          setCalenderDialog={setCalenderDialog}
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          handleSave={handleSave}
        />
      )}
    </>
  );
};
export default ConferenceTable;
