import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
// import AddLocationIcon from "@mui/icons-material/AddLocation";
import APIKeySBIcon from 'src/assets/icons/apiKeySBIcon';
import WebhookIcon from "@mui/icons-material/Webhook";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import SendIcon from '@mui/icons-material/Send';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

export const Icon = {
  'BarChartOutlinedIcon' : BarChartOutlinedIcon,
  'AddLocationIcon': APIKeySBIcon,
  'WebhookIcon': WebhookIcon,
  'LocalActivityIcon': LocalActivityIcon,
  'GroupsIcon': GroupsIcon,
  'AccountBalanceTwoToneIcon' : AccountBalanceTwoToneIcon,
  'SendIcon' : SendIcon,
  'MeetingRoomIcon' : MeetingRoomIcon,
  'TravelExploreIcon' : TravelExploreIcon,
  'LiveHelpIcon' : LiveHelpIcon,
  'ConnectWithoutContactIcon' : ConnectWithoutContactIcon,
  'RecordVoiceOverIcon' : RecordVoiceOverIcon
};
