import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, PasswordResetActions } from 'src/slices/passwordUpdate';
import { RootState } from 'src/store';
import { AiFillEyeInvisible } from 'react-icons/ai';
import { AiFillEye } from 'react-icons/ai';
import {
  UpdatePasswordButton,
  UpdatePasswordButtonLoading,
} from './indexStyled';
import { useState } from 'react';

const PasswordUpdate = () => {
  const dispatch = useDispatch();
  const { resetPassword, validData, passwordResetLoading } = useSelector(
    (state: RootState) => state.passwordUpdate,
  );
  // const { profileData } = useSelector((state: RootState) => state.profile);
  // const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // regex validation
  const passwordRegex = /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*)(?=.{8,30}).*$/;
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9@#$%^&+!=*]+$/;

  // password reset change
  const resetPasswordChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    if (value !== '' && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      return;
    }
    dispatch(
      PasswordResetActions.setFormData({
        key: e.target.name,
        value: e.target.value,
      }),
    );
  };
  // password reset change
  // password reset form handler
  const resetFormHandler = (e: React.FormEvent) => {
    e.preventDefault();
    // if (resetPassword.oldPassword && resetPassword.oldPassword.length >= 8) {
    //   dispatch(PasswordResetActions.setValidOldPassword(true));
    // } else {
    //   dispatch(PasswordResetActions.setValidOldPassword(false));
    // }
    if (
      resetPassword.newPassword &&
      resetPassword.newPassword.match(passwordRegex)
    ) {
      dispatch(PasswordResetActions.setValidNewPassword(true));
    } else {
      dispatch(PasswordResetActions.setValidNewPassword(false));
    }
    if (
      resetPassword.confirmNewPassword &&
      resetPassword.confirmNewPassword === resetPassword.newPassword
    ) {
      dispatch(PasswordResetActions.setValidConfirmPassword(true));
    } else {
      dispatch(PasswordResetActions.setValidConfirmPassword(false));
    }
    if (
      // resetPassword.oldPassword.length >= 8 &&
      resetPassword.newPassword.match(passwordRegex) &&
      resetPassword.confirmNewPassword === resetPassword.newPassword
    ) {
      const resetPayload = {
        // email: profileData.email,
        // currentPassword: resetPassword.oldPassword,
        newPassword: resetPassword.newPassword,
      };
      dispatch(changePassword(resetPayload));
    }
  };
  // password reset form handler
  // const handleClickShowPassword = () => {
  //   setShowPassword(true);
  // };

  // const handleMouseDownPassword = () => {
  //   setShowPassword(false);
  // };

  return (
    <>
      {/* password reset */}
      <Grid container component="form" onSubmit={resetFormHandler}>
        {/* current password */}
        {/* <Grid item xs={12} sm={4} md={4} lg={4} style={{ padding: '10px' }}>
          <Box>
            <TextField
              autoComplete="off"
              label="Current Password"
              variant="outlined"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              name="oldPassword"
              value={resetPassword.oldPassword}
              placeholder="Enter current password here.."
              onChange={resetPasswordChangeHandler}
              InputLabelProps={{
                style: {
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                },
              }}
              inputProps={{ maxLength: 30 }}
              InputProps={{
                style: {
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <AiFillEyeInvisible
                          style={{ color: '#5569FF', fontSize: '20px' }}
                        />
                      ) : (
                        <AiFillEye
                          style={{ color: '#5569FF', fontSize: '20px' }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!validData.validOldPassword && (
              <Typography
                style={{
                  color: 'red',
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: '500',
                }}
              >
                Please enter valid old password
              </Typography>
            )}
          </Box>
        </Grid> */}
        {/* current password */}
        {/* new password */}
        <Grid item xs={12} sm={4} md={4} lg={4} style={{ padding: '10px' }}>
          <Box>
            <TextField
              autoComplete="off"
              label="New Password"
              variant="outlined"
              fullWidth
              type={showNewPassword ? 'text' : 'password'}
              name="newPassword"
              value={resetPassword.newPassword}
              placeholder="****************"
              onChange={resetPasswordChangeHandler}
              sx={{ marginBottom: '5px' }}
              InputLabelProps={{
                style: {
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                },
              }}
              inputProps={{ maxLength: 30 }}
              InputProps={{
                style: {
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => setShowNewPassword(true)}
                      onMouseDown={() => setShowNewPassword(false)}
                    >
                      {showNewPassword ? (
                        <AiFillEyeInvisible
                          style={{ color: '#5569FF', fontSize: '20px' }}
                        />
                      ) : (
                        <AiFillEye
                          style={{ color: '#5569FF', fontSize: '20px' }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {validData.validNewPassword ? (
              <Typography
                style={{
                  color: '#999999',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontSize: '10px',
                }}
              >
                Minimum 8 characters with 1 lowercase, 1 uppercase, and 1 number
              </Typography>
            ) : (
              <Typography
                style={{
                  color: 'red',
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '10px',
                }}
              >
                Minimum 8 characters with 1 lowercase, 1 uppercase, and 1 number
              </Typography>
            )}
          </Box>
        </Grid>
        {/* new password */}
        {/* re-enter new password */}
        <Grid item xs={12} sm={4} md={4} lg={4} style={{ padding: '10px' }}>
          <Box>
            <TextField
              autoComplete="off"
              label="Confirm Password"
              variant="outlined"
              fullWidth
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirmNewPassword"
              value={resetPassword.confirmNewPassword}
              placeholder="****************"
              onChange={resetPasswordChangeHandler}
              InputLabelProps={{
                style: {
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                },
              }}
              inputProps={{ maxLength: 30 }}
              InputProps={{
                style: {
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => setShowConfirmPassword(true)}
                      onMouseDown={() => setShowConfirmPassword(false)}
                    >
                      {showConfirmPassword ? (
                        <AiFillEyeInvisible
                          style={{ color: '#5569FF', fontSize: '20px' }}
                        />
                      ) : (
                        <AiFillEye
                          style={{ color: '#5569FF', fontSize: '20px' }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!validData.validConfirmPassword && (
              <Typography
                style={{
                  color: 'red',
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: '500',
                }}
              >
                New Password and Confirm Password Mismatch
              </Typography>
            )}
          </Box>
        </Grid>
        {/* re-enter new password */}
        <Grid item xs={12} sm={4} md={4} lg={4} style={{ padding: '20px' }}>
          {/* {resetPassword.oldPassword && resetPassword.newPassword ? ( */}
          {resetPassword.newPassword ? (
            <Box>
              {
                // resetPassword.oldPassword &&
                resetPassword.newPassword &&
                  resetPassword.confirmNewPassword === resetPassword.newPassword ? (
                  <Box >
                    {passwordResetLoading ? (
                      <UpdatePasswordButtonLoading variant="contained" loading>
                        Update Password
                      </UpdatePasswordButtonLoading>
                    ) : (
                      <UpdatePasswordButton variant="contained" type="submit">
                        Update Password
                      </UpdatePasswordButton>
                    )}
                  </Box>
                ) : (
                  <Button
                    // variant="contained"
                    disabled
                    sx={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: '500',
                    }}
                  >
                    {/* Update Password */}
                  </Button>
                )}
            </Box>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default PasswordUpdate;
