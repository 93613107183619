import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { SideBarDrawer, DrawerContainer } from "./indexStyled";
import { Box } from "@mui/system";
//import useAuth from "src/hooks/useAuth";
import { NavLink, useNavigate } from "react-router-dom";
//import useRefMounted from "src/hooks/useRefMounted";
import { useState } from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SignOutIcon from "src/assets/icons/signOutIcon";
import { useAuth0 } from "@auth0/auth0-react";


const HeaderMobile = (props) => {
  //const { logout } = useAuth();
  // const { loginWithPopup } = useAuth() as any;
  // const { isAuthenticated } = useAuth();
  // const isMountedRef = useRefMounted();
  // const [, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const [openProduct, setProduct] = useState(false);

  const handleLogin = async (): Promise<void> => {
    // try {
    //   props.close();
    //   await loginWithPopup();
    // } catch (err) {
    //   if (isMountedRef.current) {
    //     setError(err.message);
    //   }
    // }
    loginWithRedirect();
  };

  const handleLogout = async (): Promise<void> => {
    // try {
    //   props.close();
    //   await logout()
    // } catch (err) {
    //   console.error(err)
    // }
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const handleProduct = () => {
    setProduct(!openProduct);
  };

  const closeSideBar = () => {
    props.close();
  };

  const handleContactUs = () => {
    props.close();
    navigate('/');
    setTimeout(() => {
      const contactUsSection = document.getElementById('contact-us-section');
      if (contactUsSection) {
        const componentHeight = 70;
        const scrollOffset = contactUsSection.offsetTop - componentHeight;
        window.scrollTo({ top: scrollOffset, behavior: 'auto' });
      }
    }, 0);
  };

  return (
    <SideBarDrawer
      BackdropProps={{ invisible: false }}
      anchor="right"
      open={props.open}
    >
      <DrawerContainer>
        <List className="sidebar_menu"
          sx={{
            p: 1,
            minWidth: 210,
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            mt: '4vh'
          }}
          component="nav"
        >
          {isAuthenticated &&
            <ListItemButton onClick={() => navigate("/dashboard", { replace: false })}>
              <ListItemText
                id="dashboard"
                primary="Dashboard"
                disableTypography={true}
                style={{ fontFamily: 'poppins', fontWeight:700 }}
              />
            </ListItemButton>}
          <List className="sidebar_menu" sx={{ padding: "0" }} component="nav">
            <ListItemButton onClick={handleProduct}>
              <ListItemText
                id="product"
                primary="Products"
                disableTypography={true}
                style={{ fontFamily: 'poppins', fontWeight:700 }}
              />
              {openProduct ? <ExpandLess style={{ color: '#3087EC' }} /> : <ExpandMore style={{ color: '#3087EC' }} />}
            </ListItemButton>
            <Collapse in={openProduct} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 2 }}
                  onClick={closeSideBar}
                  to="/audio-video-calling-api-sdk"
                  component={NavLink}
                >
                  <ListItemText
                    id="audio-video-sdk"
                    primary="Audio & Video SDK"
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{ pl: 2 }}
                  onClick={closeSideBar}
                  // to="/live-streaming-api-sdk"
                  // component={NavLink}
                >
                  <ListItemText
                    id="live-streaming-sdk"
                    primary="Live Streaming SDK"
                    disableTypography={true}
                    sx={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </List>
          <ListItemButton onClick={closeSideBar}
            to="/pricing"
            component={NavLink}>
            <ListItemText
              id="pricing"
              primary="Pricing"
              disableTypography={true}
              style={{ fontFamily: 'poppins', fontWeight:700 }}
            />
          </ListItemButton>
          {!isAuthenticated ?
            <ListItemButton onClick={handleLogin}>
              <ListItemText
                id="login"
                primary="Login"
                disableTypography={true}
                style={{ fontFamily: 'poppins', fontWeight:700 }}
              />
            </ListItemButton> :
            <ListItemButton onClick={() => navigate("/profile", { replace: false })}>
              <ListItemText
                id="my-profile"
                primary="My profile"
                disableTypography={true}
                style={{ fontFamily: 'poppins', fontWeight:700 }}
              />
            </ListItemButton>
          }
          <ListItemButton onClick={handleContactUs}>
            <ListItemText
              id="contact-us"
              primary="Contact us"
              disableTypography={true}
              style={{ fontFamily: 'poppins', fontWeight:700 }}
            />
          </ListItemButton>
        </List>
        {isAuthenticated &&
          <Box sx={{ ml: 4, mr: 4, mt: 3 }} className="signOut">
            <Button
              fullWidth
              sx={{
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#373737",
              }}
              onClick={handleLogout}
              id="signout-button"
              startIcon={<SignOutIcon props={{width:'24px', height:'24px'}}/>}
            >
              {"Sign out"}
            </Button>
          </Box>}
      </DrawerContainer>
    </SideBarDrawer >
  );
};

export default HeaderMobile;