import React from 'react';
import { CustomGrid, PlansContainer, HeadingTitle, HeadingSubTitle, ContactCard } from './homePageStyled';
import { ButtonDisabled, ButtonPrimary } from 'src/components/CustomButtons';
import { Box, Checkbox, DialogActions, DialogContent, Divider, FormControlLabel, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { ContactUsSliceAction, callRequestFormChange, callRequestSubmit, emptyContactusForm } from 'src/slices/contactUs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { CloseAlertButtons, CloseAlertMainTitle, CloseAlertSubTitle, DialogOpen } from 'src/components/AlertDialog';
import { useNavigate } from 'react-router-dom';
import CloseButton from 'src/assets/icons/CloseButton';


const ContactUs = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { contactUs, successfull } = useSelector(
        (state: RootState) => state.ContactUs
    );
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
    const nameRegex = /^[a-zA-Z_ ]{3,50}$/;
    const websiteRegex = /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/;


    const jobTitles = [
        "Developer",
        "Architect",
        "Designer",
        "Engineer",
        "Lecturer",
        "Student",
        "Other",
    ];

    const onChangeNumber = (e: any) => {
        dispatch(callRequestFormChange({ name: e.target.name, value: e.target.value }));
    };

    const handleChangeName = (e: any) => {
        const value = e?.target?.value?.replace(/ [0-9]+(?= )/g, "");
        const value1 = value?.replace(/[^a-zA-Z ]/g, "");
        dispatch(
            callRequestFormChange({ name: e.target.name, value: value1.trimLeft() })
        );
    };

    const handleChangeField = (e: any) => {
        dispatch(
            callRequestFormChange({ name: e.target.name, value: e.target.value })
        );
    };

    const handleCheckbox = (e: any) => {
        dispatch(
            callRequestFormChange({ name: e.target.name, value: e.target.checked })
        );
    };
    const handleCallback = () => {
        dispatch(callRequestSubmit(contactUs));
    };

    return (
        <PlansContainer id="contact-us-section">
            <HeadingTitle>Contact us</HeadingTitle>
            <HeadingSubTitle>
                To know more about us fill in your details, we will get back to you soon!
            </HeadingSubTitle>
            {/* Top info cards start */}
            <CustomGrid container sx={{ width: { xs: '90%', sm: '100%', md: '90%', lg: '80%' }, marginTop: '50px', }} columnGap={3} rowGap={2} >
                <ContactCard>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography gutterBottom sx={{ mb: 1, mt: 1, }}>
                                Full name <span style={{ color: "#f56c6c" }}>*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                id="full-name"
                                required
                                size="small"
                                placeholder="Full name"
                                value={contactUs?.fullName}
                                onChange={handleChangeName}
                                name="fullName"
                                fullWidth
                                sx={{ mr: 1, p: 0 }}
                                inputProps={{
                                    maxLength: 50,
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                        md: "row",
                                        lg: "row",
                                        xl: "row",
                                    },
                                    width: "100%",
                                    gap: {
                                        xs: 0,
                                        sm: "8px",
                                        md: "8px",
                                        lg: "8px",
                                        xl: "8px",
                                    },
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                            lg: "50%",
                                            xl: "50%",
                                        },
                                    }}
                                >
                                    <Grid item lg={12}>
                                        <Typography
                                            gutterBottom
                                            sx={{ mb: 1, mt: "20px" }}
                                        >
                                            Email address <span style={{ color: "#f56c6c" }}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12}>
                                        <TextField
                                            id="email-address"
                                            fullWidth
                                            size="small"
                                            autoComplete="off"
                                            required
                                            value={contactUs.email}
                                            placeholder="Enter email address"
                                            onChange={handleChangeField}
                                            name="email"
                                            sx={{ p: 0 }}
                                            inputProps={{
                                                maxLength: 320,
                                            }}
                                        />
                                    </Grid>
                                </Box>
                                <Box sx={{
                                    width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                        lg: "50%",
                                        xl: "50%",
                                    },
                                }}
                                >
                                    <Grid item lg={12}>
                                        <Typography
                                            gutterBottom
                                            sx={{ mb: 1, mt: "20px" }}
                                        >
                                            Phone number <span style={{ color: "#f56c6c" }}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12}>
                                        <TextField
                                            id="phone-number"
                                            placeholder="Enter phone number"
                                            fullWidth
                                            size="small"
                                            value={contactUs?.phoneNumber}
                                            name="phoneNumber"
                                            type='number'
                                            onChange={onChangeNumber}
                                            inputProps={{
                                                maxLength: 15,
                                            }}
                                        />
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                        md: "row",
                                        lg: "row",
                                        xl: "row",
                                    },
                                    width: "100%",
                                    gap: {
                                        xs: 0,
                                        sm: "8px",
                                        md: "8px",
                                        lg: "8px",
                                        xl: "8px",
                                    },
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                            lg: "50%",
                                            xl: "50%",
                                        },
                                    }}
                                >
                                    <Grid item lg={12}>
                                        <Typography
                                            gutterBottom
                                            sx={{ mb: 1, mt: "20px", }}
                                        >
                                            Website <span style={{ color: "#f56c6c" }}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12}>
                                        <TextField
                                            id="company-name"
                                            size="small"
                                            value={contactUs?.website}
                                            onChange={handleChangeField}
                                            name="website"
                                            placeholder="Enter website name"
                                            fullWidth
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                        />
                                    </Grid>
                                </Box>
                                <Box sx={{
                                    width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                        lg: "50%",
                                        xl: "50%",
                                    },
                                }}
                                >
                                    <Grid item lg={12}>
                                        <Typography
                                            gutterBottom
                                            sx={{ mb: 1, mt: "20px" }}
                                        >
                                            Job Title <span style={{ color: "#f56c6c" }}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12}>
                                        <TextField
                                            id="job-title"
                                            size="small"
                                            value={contactUs?.jobTitle}
                                            onChange={handleChangeField}
                                            name="jobTitle"
                                            placeholder="Enter job title"
                                            fullWidth
                                            select
                                        >
                                            {jobTitles?.map((code, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={code}
                                                    >
                                                        {code}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}
                        sx={{
                            display: "flex",
                            mt: {
                                xs: "7px",
                                sm: "7px",
                                md: "3px",
                                lg: "3px",
                                xl: "3px",
                            },
                        }}
                    >
                        <FormControlLabel
                            id="accepted-terms-condition"
                            control={
                                <Checkbox
                                    checked={contactUs?.readTermsAndContdions}
                                    onChange={handleCheckbox}
                                    name="readTermsAndContdions"
                                    style={{
                                        color: "#3087EC",
                                    }}
                                />
                            }
                            label={
                                <Typography
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    I have read and agreed to Poetego&#39;s &nbsp;
                                    <Typography
                                        id="terms-of-service"
                                        onClick={() => {
                                            navigate("/pages/terms-and-conditions");
                                        }}
                                        sx={{ color: "#3087EC", textDecoration: 'none', '&:hover': { textDecoration: "underline" } }}
                                    >
                                        Terms of Service
                                    </Typography>
                                    &nbsp; and &nbsp;
                                    <Typography
                                        id="privacy-policy"
                                        onClick={() => {
                                            navigate("/pages/privacy-policy");
                                        }}
                                        sx={{ color: "#3087EC", textDecoration: 'none', '&:hover': { textDecoration: "underline" } }}
                                    >
                                        Privacy Policy
                                    </Typography>
                                </Typography>
                            }
                        />
                    </Grid>
                    <CustomGrid
                        sx={{
                            width: "100%",
                        }}>
                        {
                            (!nameRegex?.test(contactUs?.fullName) ||
                                contactUs?.fullName?.charAt(0) === " " ||
                                contactUs?.fullName?.charAt(
                                    contactUs?.fullName?.length - 1
                                ) === " " ||
                                !emailRegex?.test(contactUs?.email) ||
                                contactUs?.email?.split("@")[0]?.length > 64 ||
                                contactUs?.email?.split("@")[1]?.length > 255 ||
                                contactUs?.jobTitle?.length === 0 ||
                                contactUs?.readTermsAndContdions === false ||
                                !(
                                    contactUs?.phoneNumber.length >= 7 &&
                                    contactUs?.phoneNumber.length <= 15
                                ) || !websiteRegex?.test(contactUs?.website)) ?
                                <ButtonDisabled
                                    id="Request-a-callback"
                                    responsive={false}
                                    margin="30px 0 0 0"
                                    fixedHeight="40px"
                                    fixedWidth="200px"
                                >
                                    Request a callback
                                </ButtonDisabled> :
                                <ButtonPrimary
                                    id="Request-a-callback"
                                    responsive={false}
                                    margin="30px 0 0 0"
                                    fixedHeight="40px"
                                    fixedWidth="200px"
                                    onClick={handleCallback}
                                >
                                    Request a callback
                                </ButtonPrimary>
                        }
                    </CustomGrid>
                </ContactCard>
            </CustomGrid >

            <DialogOpen
                open={successfull}
                data-testid="dialog-reference"
                PaperProps={{
                    sx: {
                        width: "400px",
                        height: "228px",
                    },
                }}
            >
                <IconButton
                    id="close-cross-btn"
                    style={{
                        background: "none",
                        borderRadius: "0px",
                        width: "70px",
                        height: "70px",
                        display: "flex",
                        position: "absolute",
                        top: -35,
                        right: 0,
                        overflowX: "hidden",
                    }}
                    onClick={() => {
                        dispatch(ContactUsSliceAction.setSuccess(false));
                        dispatch(emptyContactusForm());
                    }}
                >
                    <CloseButton
                        props={{
                            width: "70px",
                            height: "70px",
                        }}
                    />
                </IconButton>
                <CloseAlertMainTitle
                    sx={{ m: 1.5, color: "#11BD60" }}
                    id="close-alert"
                    data-testid="mainTitle"
                >
                    Thank You !
                </CloseAlertMainTitle>
                <Divider />
                <DialogContent
                    style={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                    }}
                >
                    <CloseAlertSubTitle data-testid="title">
                        Thank you for contacting us, we will get back to you shortly
                    </CloseAlertSubTitle>
                </DialogContent>
                <Divider />
                <DialogActions
                    sx={{
                        justifyContent: "right",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                    }}
                >
                    <CloseAlertButtons
                        data-testid="dialog-accept-btn"
                        variant="contained"
                        style={{
                            background: "#11BD60",
                            width: "55px",
                            height: "40px",
                        }}
                        onClick={() => {
                            dispatch(ContactUsSliceAction.setSuccess(false));
                            dispatch(emptyContactusForm());
                        }}
                    >
                        Close
                    </CloseAlertButtons>
                </DialogActions>
            </DialogOpen>


        </PlansContainer >
    )
}

export default ContactUs;