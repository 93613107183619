import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles, ThemeProvider, useTheme } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  Hidden,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AddWebHook from "./AddWebHook";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Chip from "@mui/material/Chip";
import {
  webhookInfo,
  fetchWebhookTable,
  webHookEdit,
  deleteWebhook,
  setOpenWebhook,
  webhookMode1,
  setStoreId1,
  setHookNamesObject,
  fetchWebhookTable1,
  updateWebhookStatus,
} from "../../slices/WebHook";
import { useEffect } from "react";
import { Divider } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars-2";
import "./Webhook.css";
import CloseAlert from "src/components/AlertDialog";
import Loader from "src/components/Loader/Loader";
import { ButtonDisabled, ButtonPrimary } from "src/components/CustomButtons";
import WebHookIcon from "src/assets/icons/webHookIcon";
import { TablePaginationBox } from "../billing/BillingStyled";
import {
  ContentWrapperCenterMob,
  ContentWrapperMob,
  CustomPagination,
  DeviderBox,
  MenuWrapperMob,
  TableHeaderName,
  TableHeaderValue,
  TableHeaderWrapper,
} from "src/components/TableMobileCss";
import ContentWrapper from "src/components/ContentWrapper";
import moment from "moment";
import Calender from "src/content/activity/WebhookHits/calender";

const useStyles = makeStyles((theme) => ({
  labelcolor: {
    color: "black",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: ".5rem",
  },
  webhookTitle: {
    fontWeight: "bold",
    fontSize: "22px",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-Width: 600px)": {
      fontSize: "16px",
    },
  },
  webText: {
    fontWeight: "bold",
    marginTop: ".5rem",
  },
  tableColor: {
    backgroundColor: "#DEE2F3",
  },
  textStyle: {
    fontWeight: "bold",
  },
  buttonStyle: {
    textTransform: "none",
  },
  dialog: {
    width: "25rem",
    "@media(max-Width: 600px)": {
      width: "100%",
    },
  },
  col: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1.5rem",
    marginTop: "1rem",
  },
  updatebutton: {
    backgroundColor: "#0096FF",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#0096FF",
      color: "#ffffff",
    },
  },
  nobutton: {
    backgroundColor: "#F76E11",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#F76E11",
      color: "#ffffff",
    },
  },
  dialogCloseButton: {
    paddingRight: "5px",
    paddingLeft: "5px",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "-10px",
    marginTop: "-5px",
    minWidth: "32px",
  },
}));

export default function WebHookRegister() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [currentRow, setCurrentRow]: any = React.useState();
  const [calenderDialog, setCalenderDialog] = React.useState(false);
  const [selectedRange, setSelectedRange] = React.useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [statusFilter, setStatusFilter] = React.useState("");
  const { allData, storeId, hookNamesObj, tableLoader, meta, pageLoader, getAppName } =
    useSelector((state: RootState) => state.WebHook);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, id: any) => {
    dispatch(setStoreId1(id));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (e: any) => {
    handleClose();
    const data1 = {
      page: meta.page,
      per_page: meta.per_page,
      page_count: meta.page_count,
      total_count: meta.total_count,
    };
    dispatch(deleteWebhook(storeId, data1));
  };
  const [deleteOpen, setOpen] = React.useState(false);
  const handleClickOpenConfirmation = (e: any) => {
    setOpen(true);
  };
  const handleCloseDeleteButton = () => {
    setOpen(false);
    handleClose();
  };

  const handleEdit = (e: any, id: any) => {
    dispatch(setOpenWebhook(true));
    dispatch(webhookMode1("edit"));
    handleClose();
    const data = allData.filter((value) => value.id === storeId);
    if (data[0]) {
      dispatch(webHookEdit(data[0].id));
      dispatch(webhookInfo({ key: "button", value: false }));
      const checkboxmark = hookNamesObj.map((item) => {
        let isChecked = false;
        if (
          data[0] &&
          data[0].registeredHookNames &&
          data[0].registeredHookNames.length
        ) {
          isChecked = data[0]?.registeredHookNames.includes(item.name);
        }
        return { ...item, checked: isChecked };
      });
      dispatch(setHookNamesObject(checkboxmark));
    }
  };

  const handleStatus = (e: any) => {
    const currentStatus =
      currentRow?.status === "active" ? "inactive" : "active";
    const data1 = {
      page: meta.page,
      per_page: meta.per_page,
      page_count: meta.page_count,
      total_count: meta.total_count,
    };
    const dateRange = {
      startDate: selectedRange?.startDate
        ? moment(selectedRange?.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",

      endDate: selectedRange?.endDate
        ? moment(selectedRange?.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",
    };
    dispatch(
      updateWebhookStatus(
        currentStatus,
        currentRow?.id,
        data1,
        dateRange,
        statusFilter
      )
    );
    handleClose();
  };
  /*eslint-disable*/
  useEffect(() => {
    const dateRange = {
      startDate: selectedRange?.startDate
        ? moment(selectedRange?.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",

      endDate: selectedRange?.endDate
        ? moment(selectedRange?.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",
    };
    dispatch(
      fetchWebhookTable1({ page: 1, per_page: 5 }, dateRange, statusFilter)
    );
  }, [dispatch, statusFilter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    const data = {
      page: newPage + 1,
      per_page: meta.per_page,
    };
    dispatch(fetchWebhookTable(data));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = {
      page: 1,
      per_page: event.target.value,
    };
    dispatch(fetchWebhookTable(data));
  };

  const handlePicker = () => {
    setCalenderDialog(true);
  };

  const handleSelect = (ranges) => {
    setSelectedRange(ranges);
  };

  const handleSave = (ranges) => {
    const dateRange = {
      startDate: ranges?.startDate
        ? moment(ranges?.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",

      endDate: ranges?.endDate
        ? moment(ranges?.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : "",
    };
    dispatch(
      fetchWebhookTable1({ page: 1, per_page: 5 }, dateRange, statusFilter)
    );

    // dispatch(
    //   fetchConferenceTableData(
    //     {
    //       page: 1,
    //       per_page: 5,
    //       page_count: 0,
    //       total_count: 0,
    //     },
    //     appIdData.appId,
    //     dateRange
    //   )
    // );
    setCalenderDialog(false);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
  };
  return (
    <>
      <ContentWrapper title="Webhooks">
        {pageLoader ? (
          <Box sx={{ height: "100%" }}>
            <Loader />
          </Box>
        ) : (
          <>
            <Box>
              <Hidden only={["sm", "xs"]}>
                <Box
                  // className="apikey_header justify_space_between align_center webhook_table_container"
                  sx={{
                    p: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  rowGap={2}
                >
                  <ThemeProvider theme={theme}>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        className="align_start apikey_heading"
                        sx={{ color: "#3086EC" }}
                      >
                        Web Hooks
                      </Typography>
                      <Typography
                        color="#808080"
                        className="align_start apikey_subheading"
                      >
                        {getAppName?.length > 0 ? 'Create webhooks for your application' : 'Add API keys to create webhooks for your application'}
                      </Typography>
                    </Box>
                  </ThemeProvider>
                  {allData?.length > 0 && (
                    <Box sx={{ display: "flex" }}>
                      {/* <TextField
                        sx={{ width: "26ch", mr: 1, mt: 0.4 }}
                        size="small"
                        variant="outlined"
                        onClick={handlePicker}
                        value={`${
                          selectedRange?.startDate
                            ? moment(selectedRange?.startDate)?.format(
                                "DD-MM-YYYY"
                              )
                            : ""
                        }  ${
                          selectedRange?.endDate
                            ? moment(selectedRange?.endDate)?.format(
                                "DD-MM-YYYY"
                              )
                            : ""
                        }
                    `}
                        placeholder="Select a Filter"
                        helperText=""
                        label="Filter"
                      /> */}
                      {/* <TextField
                        sx={{ width: "26ch", mr: 1 }}
                        size="small"
                        variant="outlined"
                        select
                        placeholder="Select a status"
                        label="Status"
                        value={statusFilter}
                        onChange={handleStatusFilter}
                      >
                        {allData?.length > 0 &&
                          allData.map((item, id) => {
                            return (
                              <MenuItem
                                key={id}
                                value={item.status}
                                sx={{ textTransform: "capitalize" }}
                              >
                                {item.status}
                              </MenuItem>
                            );
                          })}
                      </TextField> */}
                      {!tableLoader ?
                        <ButtonPrimary
                          id="add-web-hooks"
                          responsive={false}
                          onClick={() => dispatch(setOpenWebhook(true))}
                          fixedHeight="40px"
                          fixedWidth="170px"
                        >
                          <AddIcon fontSize="small" />
                          <Typography
                            sx={{ fontSize: { xs: "10px", sm: "14px" } }}
                          >
                            Add Webhooks
                          </Typography>
                        </ButtonPrimary> : <ButtonDisabled
                          id="add-web-hooks"
                          responsive={false}
                          fixedHeight="40px"
                          fixedWidth="170px"
                        >
                          <AddIcon fontSize="small" />
                          <Typography
                            sx={{ fontSize: { xs: "10px", sm: "14px" } }}
                          >
                            Add Webhooks
                          </Typography>
                        </ButtonDisabled>}
                    </Box>
                  )}
                </Box>
              </Hidden>

              {/* mobile  */}
              {/* <Hidden smUp> */}
              <Hidden only={["xl", "lg", "md"]}>
                <Grid
                  container
                  className="apikey_header justify_space_between align_center"
                  sx={{ p: "10px" }}
                >
                  <Grid item xs={8} sm={9} md={8}>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        className="align_start apikey_heading"
                        sx={{ color: "#3086EC" }}
                      >
                        Web Hooks
                      </Typography>
                      <Typography
                        color="#808080"
                        className="align_start apikey_subheading"
                      >
                        Create webhooks for your application
                      </Typography>
                    </Box>
                  </Grid>
                  {allData?.length > 0 && (
                    <Grid
                      item
                      xs={3.3}
                      sm={3}
                      md={4}
                      className="align_center justify_end"
                    >
                      {!tableLoader ?
                        <ButtonPrimary
                          id="add-web-hooks"
                          responsive={false}
                          onClick={() => dispatch(setOpenWebhook(true))}
                          fixedHeight="40px"
                          fixedWidth="170px"
                        >
                          <AddIcon fontSize="small" />
                          <Typography
                            sx={{ fontSize: { xs: "10px", sm: "14px" } }}
                          >
                            Add
                          </Typography>
                        </ButtonPrimary> : <ButtonDisabled
                          id="add-web-hooks"
                          responsive={false}
                          fixedHeight="40px"
                          fixedWidth="170px"
                        >
                          <AddIcon fontSize="small" />
                          <Typography
                            sx={{ fontSize: { xs: "10px", sm: "14px" } }}
                          >
                            Add
                          </Typography>
                        </ButtonDisabled>}
                    </Grid>
                  )}
                </Grid>
                {/* {allData?.length > 0 && (
                  <Grid item xs={12}>
                    <TextField
                      sx={{ mt: 1.5, p: "10px" }}
                      size="small"
                      variant="outlined"
                      onClick={handlePicker}
                    
                      value={`${
                        selectedRange?.startDate
                          ? moment(selectedRange?.startDate)?.format(
                              "DD-MM-YYYY"
                            )
                          : ""
                      }  ${
                        selectedRange?.endDate
                          ? moment(selectedRange?.endDate)?.format("DD-MM-YYYY")
                          : ""
                      }
                    `}
                      placeholder="Select a Filter"
                      helperText=""
                      label="Filter"
                    />
                  </Grid>
                )} */}
              </Hidden>

              {allData === null || allData?.length === 0 ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  {tableLoader ? (
                    <Box>
                      <Loader />
                    </Box>
                  ) : (
                    <>
                      <WebHookIcon props={{ height: "50%", width: "20vh" }} />
                      {getAppName?.length > 0 ?
                        <ButtonPrimary
                          id="add-web-hooks"
                          responsive={false}
                          onClick={() => dispatch(setOpenWebhook(true))}
                          fixedHeight="40px"
                          fixedWidth="170px"
                        >
                          <AddIcon fontSize="small" />
                          <Typography
                            sx={{ fontSize: { xs: "10px", sm: "14px" } }}
                          >
                            Add Webhooks
                          </Typography>
                        </ButtonPrimary> : <ButtonDisabled
                          id="add-web-hooks"
                          responsive={false}
                          fixedHeight="40px"
                          fixedWidth="170px"
                        >
                          <AddIcon fontSize="small" />
                          <Typography
                            sx={{ fontSize: { xs: "10px", sm: "14px" } }}
                          >
                            Add Webhooks
                          </Typography>
                        </ButtonDisabled>}
                    </>
                  )}
                </Box>
              ) : (
                <>
                  <Paper
                    square
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: "6px",
                      ml: "15px",
                      mr: "15px",
                      mt: "10px",
                    }}
                    className="webhook_table_container"
                  >
                    <TableContainer
                      component={Box}
                      sx={{
                        height: {
                          xl: "75vh",
                          lg: "65vh",
                          md: "60vh",
                          sm: "57vh",
                        },
                        width: "100%",
                      }}
                    >
                      <Table
                        stickyHeader
                        aria-label="simple table"
                        sx={{ position: "relative" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              width="15%"
                              align="left"
                              sx={{ color: "#3087EC", zIndex: "1" }}
                            >
                              App Name
                            </TableCell>
                            <TableCell
                              width="20%"
                              align="left"
                              sx={{ color: "#3087EC", zIndex: "1" }}
                            >
                              Hook ID
                            </TableCell>
                            <TableCell
                              width="35%"
                              align="left"
                              sx={{ color: "#3087EC", zIndex: "1" }}
                            >
                              Hook Name
                            </TableCell>
                            <TableCell
                              width="20%"
                              align="left"
                              sx={{ color: "#3087EC", zIndex: "1" }}
                            >
                              Created Date
                            </TableCell>
                            <TableCell
                              width="10%"
                              align="left"
                              sx={{ color: "#3087EC", zIndex: "1" }}
                            >
                              Status
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ color: "#3087EC", zIndex: "1" }}
                            >
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {tableLoader ? (
                          <Box
                            sx={{
                              position: "absolute",
                              width: "100%",
                              height: "57vh",
                            }}
                          >
                            <Loader />
                          </Box>
                        ) : (
                          <TableBody>
                            {allData &&
                              allData.map((row) => (
                                <TableRow
                                  key={row.appName}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="left"
                                    sx={{
                                      maxWidth: "200px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {row?.appName?.length > 20 ? (
                                      <Tooltip
                                        title={row.appName}
                                        placement={"top-start"}
                                        componentsProps={{
                                          tooltip: {
                                            sx: {
                                              padding: "10px",
                                              // '& .MuiTooltip-arrow': {
                                              //   color: 'common.black',
                                              // },
                                            },
                                          },
                                        }}
                                        arrow
                                      >
                                        <span> {row.appName}</span>
                                      </Tooltip>
                                    ) : (
                                      <span> {row.appName}</span>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip
                                      title={row.id}
                                      placement={"top-start"}
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            padding: "10px",
                                            // '& .MuiTooltip-arrow': {
                                            //   color: 'common.black',
                                            // },
                                          },
                                        },
                                      }}
                                      arrow
                                    >
                                      <span> {row.id}</span>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.registeredHookNames &&
                                      row.registeredHookNames.map((value) => (
                                        <Chip
                                          label={value.replace(/_/g, " ")}
                                          sx={{
                                            marginRight: "2px",
                                            marginBottom: "3px",
                                          }}
                                        />
                                      ))}
                                  </TableCell>
                                  <TableCell align="left">
                                    {moment(row?.createdDate).format(
                                      "DD MMM YYYY"
                                    )}
                                    {/* {new Date(row.createdDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-')} */}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography
                                      component="div"
                                      variant="body2"
                                      color="text.primary"
                                      sx={{ textAlign: "center" }}
                                      className={`${row.status === "active"
                                        ? "active_webhook_status"
                                        : "inactive_webhook_status"
                                        }`}
                                    >
                                      {row.status.toUpperCase()}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton
                                      aria-label="more"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={(event) => {
                                        handleClick(event, row.id);
                                        setCurrentRow(row);
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      id="webhook-menu"
                                      MenuListProps={{
                                        "aria-labelledby": "long-button",
                                      }}
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                      PaperProps={{
                                        style: {
                                          padding: 0,
                                          margin: 0,
                                          transform: "translateX(-30px)",
                                          boxShadow:
                                            "rgba(149, 157, 165, 0.2) 0px 2px 2px",
                                        },
                                      }}
                                    >
                                      <MenuItem
                                        onClick={(e) => handleEdit(e, row.id)}
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          handleClickOpenConfirmation(row.id)
                                        }
                                      >
                                        Delete
                                      </MenuItem>
                                      {currentRow?.status === "active" && (
                                        <MenuItem
                                          onClick={(e) => handleStatus(e)}
                                        >
                                          Inactive
                                        </MenuItem>
                                      )}
                                      {currentRow?.status === "inactive" && (
                                        <MenuItem
                                          onClick={(e) => handleStatus(e)}
                                        >
                                          Active
                                        </MenuItem>
                                      )}
                                    </Menu>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        )}
                        <CloseAlert
                          id={() => handleDelete(storeId)}
                          openCloseDialog={deleteOpen}
                          setOpenCloseDialog={handleCloseDeleteButton}
                          accept={`Yes`}
                          cancel={`No`}
                          title={`Are you sure want to delete this record?`}
                          mainTitle={`Delete webhooks`}
                          subTitle={
                            "This action cannot be undone. Please make sure you are performing the correct operation."
                          }
                          textColor={"#FF5A5A"}
                          BackgroundColor={"#FF5A5A"}
                        />
                      </Table>
                    </TableContainer>
                    <TablePaginationBox>
                      <CustomPagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        count={meta.total_count}
                        rowsPerPage={meta.per_page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                        }}
                        page={meta.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        backIconButtonProps={{
                          style: {
                            background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                            color: (meta.page - 1) ? "#0062FD" : "#aaa",
                            borderRadius: "50%",
                            marginRight: "2px",
                          },
                        }}
                        nextIconButtonProps={{
                          style: {
                            background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                            color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                            borderRadius: "50%",
                            marginLeft: "2px",
                          },
                        }}
                      />
                    </TablePaginationBox>
                  </Paper>
                  <Hidden smUp>
                    <div>
                      {tableLoader ? (
                        <Box sx={{ height: "64vh" }}>
                          <Loader />
                        </Box>
                      ) : (
                        <>
                          <Scrollbars style={{ height: "67vh" }}>
                            {allData.map((row, index) => (
                              <>
                                <Box key={index}>
                                  <TableHeaderWrapper>
                                    <TableHeaderName>
                                      Applcation Name
                                    </TableHeaderName>
                                    <MenuWrapperMob>
                                      <Typography> {row.appName}</Typography>
                                      <IconButton
                                        id="fade-button"
                                        onClick={(event) => {
                                          handleClick(event, row.id);
                                          setCurrentRow(row);
                                        }}
                                        aria-controls={
                                          open ? "fade-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id="webhook-menu"
                                        MenuListProps={{
                                          "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                          style: {
                                            padding: 0,
                                            margin: 0,
                                            transform: "translateX(-30px)",
                                            boxShadow:
                                              "rgba(149, 157, 165, 0.2) 0px 2px 2px",
                                          },
                                        }}
                                      >
                                        <MenuItem
                                          onClick={(e) => handleEdit(e, row.id)}
                                        >
                                          Edit
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            handleClickOpenConfirmation(row.id)
                                          }
                                        >
                                          Delete
                                        </MenuItem>
                                        {currentRow?.status === "active" && (
                                          <MenuItem
                                            onClick={(e) => handleStatus(e)}
                                          >
                                            Inactive
                                          </MenuItem>
                                        )}
                                        {currentRow?.status === "inactive" && (
                                          <MenuItem
                                            onClick={(e) => handleStatus(e)}
                                          >
                                            Active
                                          </MenuItem>
                                        )}
                                      </Menu>
                                    </MenuWrapperMob>
                                  </TableHeaderWrapper>
                                  <ContentWrapperMob>
                                    <ContentWrapperCenterMob>
                                      <TableHeaderName>Hook ID</TableHeaderName>
                                      <TableHeaderValue>
                                        {row.id}
                                      </TableHeaderValue>
                                    </ContentWrapperCenterMob>
                                    <DeviderBox>
                                      <Divider sx={{ height: "1.5px" }} />
                                    </DeviderBox>
                                  </ContentWrapperMob>
                                  <ContentWrapperMob>
                                    <ContentWrapperCenterMob>
                                      <TableHeaderName>
                                        Hook Name
                                      </TableHeaderName>
                                      <TableHeaderValue>
                                        {row.registeredHookNames &&
                                          row.registeredHookNames.map(
                                            (value) => (
                                              <Chip
                                                label={value}
                                                sx={{
                                                  mb: "3px",
                                                  fontSize: "10px",
                                                }}
                                              />
                                            )
                                          )}
                                      </TableHeaderValue>
                                    </ContentWrapperCenterMob>
                                    <DeviderBox>
                                      <Divider sx={{ height: "1.5px" }} />
                                    </DeviderBox>
                                  </ContentWrapperMob>
                                  <ContentWrapperMob>
                                    <ContentWrapperCenterMob>
                                      <TableHeaderName>
                                        Created Date
                                      </TableHeaderName>
                                      <TableHeaderValue>
                                        {moment(row?.createdDate).format(
                                          "DD MMM YYYY"
                                        )}
                                        {/* {new Date(row.createdDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-')} */}
                                      </TableHeaderValue>
                                    </ContentWrapperCenterMob>
                                    <DeviderBox>
                                      <Divider sx={{ height: "1.5px" }} />
                                    </DeviderBox>
                                  </ContentWrapperMob>
                                  <ContentWrapperMob>
                                    <ContentWrapperCenterMob>
                                      <TableHeaderName>Status</TableHeaderName>
                                      <TableHeaderValue>
                                        {row.status === "active" ? (
                                          <Chip
                                            label={row.status.toUpperCase()}
                                            sx={{
                                              mb: "3px",
                                              fontSize: "10px",
                                              color: "#11BD60",
                                              background: "#DDFFDE",
                                            }}
                                          />
                                        ) : (
                                          <Chip
                                            label={row.status.toUpperCase()}
                                            sx={{
                                              mb: "3px",
                                              fontSize: "10px",
                                              color: "#FF5A5A",
                                              background: "#FFE5E5",
                                            }}
                                          />
                                        )}
                                      </TableHeaderValue>
                                    </ContentWrapperCenterMob>
                                    <DeviderBox>
                                      <Divider sx={{ height: "1.5px" }} />
                                    </DeviderBox>
                                  </ContentWrapperMob>
                                </Box>
                              </>
                            ))}
                          </Scrollbars>
                          <Box
                            sx={{
                              position: "fixed",
                              bottom: "0px",
                              height: "50px",
                              width: "100%",
                            }}
                          >
                            <TablePagination
                              style={{}}
                              rowsPerPageOptions={[5, 10, 25, 100]}
                              component="div"
                              count={meta.total_count}
                              rowsPerPage={meta.per_page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                              }}
                              page={meta.page - 1}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              backIconButtonProps={{
                                style: {
                                  background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                                  color: (meta.page - 1) ? "#0062FD" : "#aaa",
                                  borderRadius: "50%",
                                  marginRight: "2px",
                                  height: "25px",
                                  width: "25px",
                                },
                              }}
                              nextIconButtonProps={{
                                style: {
                                  background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                                  color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                                  borderRadius: "50%",
                                  marginLeft: "2px",
                                  height: "25px",
                                  width: "25px",
                                },
                              }}
                              labelRowsPerPage={
                                <div
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  }}
                                >
                                  Rows per page:
                                </div>
                              }
                            />
                          </Box>
                        </>
                      )}
                    </div>
                  </Hidden>
                </>
              )}
            </Box>
          </>
        )}
        <Box className={classes.row}>
          <AddWebHook />
        </Box>
      </ContentWrapper>

      {calenderDialog && (
        <Calender
          open={calenderDialog}
          handleSelect={handleSelect}
          setCalenderDialog={setCalenderDialog}
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          handleSave={handleSave}
        />
      )}
    </>
  );
}
