import React from 'react';
function UserCamera({ props }) {
  return (
    <>
      <svg width={props.width}
        height={props.height} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.749987 3.05C1.02499 2.775 1.42499 2.6 1.82499 2.6L3.57499 2.6C3.74999 2.6 3.87499 2.475 3.87499 2.3L3.87499 1.2C3.87499 0.824999 4.04999 0.374999 4.82499 0.374999L11.275 0.375C12 0.375 12.15 0.825 12.15 1.2L12.15 2.3C12.15 2.475 12.275 2.6 12.45 2.6L14.175 2.6C15 2.6 15.675 3.275 15.675 4.1L15.675 10.125C15.675 10.95 15 11.625 14.175 11.625L1.82499 11.625C0.999987 11.625 0.324986 10.95 0.324987 10.125L0.324987 4.1C0.299988 3.7 0.474988 3.325 0.749987 3.05ZM1.19999 10.125C1.19999 10.475 1.47499 10.75 1.82499 10.75L14.175 10.75C14.525 10.75 14.8 10.475 14.8 10.125L14.8 4.1C14.8 3.75 14.525 3.475 14.175 3.475L12.45 3.475C11.8 3.475 11.275 2.95 11.275 2.3L11.275 1.25L4.82499 1.25C4.79999 1.25 4.77499 1.25 4.74999 1.25L4.74999 2.3C4.74999 2.95 4.22499 3.475 3.57499 3.475L1.82499 3.475C1.64999 3.475 1.49999 3.55 1.37499 3.65C1.24999 3.775 1.19999 3.925 1.19999 4.1L1.19999 10.125Z" fill="white"/>
<path d="M8.09999 4.07495C9.37499 4.07495 10.4 5.09995 10.4 6.37495C10.4 7.64995 9.39999 8.64995 8.09999 8.64995C6.82499 8.64995 5.79999 7.62495 5.79999 6.37495C5.82499 5.12495 6.87499 4.07495 8.09999 4.07495ZM8.09999 7.77495C8.89999 7.77495 9.52499 7.17495 9.52499 6.37495C9.52499 5.57495 8.89999 4.94995 8.09999 4.94995C7.32499 4.94995 6.67499 5.59995 6.67499 6.37495C6.69999 7.14995 7.32499 7.77495 8.09999 7.77495Z" fill="white"/>
<path d="M12.875 4.52502C13.275 4.52502 13.625 4.85002 13.625 5.27502C13.625 5.67502 13.3 6.02502 12.875 6.02502C12.45 6.02502 12.125 5.70002 12.125 5.27502C12.125 4.85002 12.45 4.52502 12.875 4.52502Z" fill="white"/>
</svg>

    </>
  );
}

export default UserCamera;
