import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Container, Button, Menu, MenuItem, IconButton, Hidden, } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ContentWrapper from "src/components/ContentWrapper";
import { styled as experimentalStyled } from "@mui/material/styles";
import { IconContext } from 'react-icons';
import { RiArrowDownSLine } from 'react-icons/ri';
// import { CgFileDocument } from 'react-icons/cg';
// import { BiNotepad } from 'react-icons/bi';
// import { ImMobile } from 'react-icons/im';
// import { GiHamburgerMenu } from 'react-icons/gi';
// import { HiOutlineLightBulb } from 'react-icons/hi';
// import { RiQuestionnaireLine } from 'react-icons/ri';
import Logo from "src/components/Logo";
// import Footer from "./LandingFooter";
// import useRefMounted from "src/hooks/useRefMounted";
// import useAuth from "src/hooks/useAuth";
import Pricing from 'src/content/pricing';
import "src/content/pricing/pricing.css";
import Home from "../home";
import "./Landing.css";
import { Duo, HeadsetMic } from "@mui/icons-material";
import AudioVideo from "../product/audioVideo";
import LiveStream from "../product/liveStream";
import { ButtonPrimary } from "src/components/CustomButtons";
import TermsConditions from "../company/termsConditions";
import PrivacyPolicy from "../company/privacyPolicy";
import Footer from "src/components/Footer/Footer";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import HeaderMobile from "src/layouts/AccentHeaderLayout/Header/HeaderMobile";
import NotifyIcon from "src/assets/icons/notifyIcon";
import { useAuth0 } from "@auth0/auth0-react";

const HeaderWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    width: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 6;
    top: 0;
    background: white;
    height: ${theme.spacing(7.5)};
    @media (max-width: ${theme.breakpoints.values.md}px) {
      display: none;
    }
`
);

const MobileWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    width: 100%;
    border-radius: 0;
    display: none;
    position: fixed;
    z-index: 6;
    top: 0;
    background: white;
    height: ${theme.spacing(7.5)};
    @media (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      align-items: center;
      justify-content:space-between;
      padding:5px;
    }
`
);

const OverviewWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    overflow: auto;
    // flex: 1;
    overflow-x: visible;
    margin-top: ${theme.spacing(7.5)};
`
);

function Overview() {
  // const { loginWithPopup } = useAuth() as any;
  // const isMountedRef = useRefMounted();
  // const [, setError] = useState<string | null>(null);
  //const { isAuthenticated} = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleLogin = async (): Promise<void> => {
    // try {
    //   await loginWithPopup();
    // } catch (err) {
    //   if (isMountedRef.current) {
    //     setError(err.message);
    //   }
    // }
    loginWithRedirect()
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 0) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  const [productAnchorEl, setProductAnchorEl] = useState(null);
  // const [developerAnchorEl, setDeveloperAnchorEl] = useState(null);
  const productOpen = Boolean(productAnchorEl);
  // const developerOpen = Boolean(developerAnchorEl);
  const [openSidebar, setOpenDrawer] = useState(false);

  const handleSidebar = () => {
    setOpenDrawer(!openSidebar);
  };

  const closeSidebar = () => {
    setOpenDrawer(false);
  };

  const handleProductClick = (event) => {
    setProductAnchorEl(event.currentTarget);
  };

  // const handleDeveloperClick = (event) => {
  //   setDeveloperAnchorEl(event.currentTarget);
  // };

  const handleProductClose = () => {
    setProductAnchorEl(null);
  };

  const changeAVProductHandler = () => {
    navigate('/audio-video-calling-api-sdk');
  };

  const changeLiveProductHandler = () => {
    // navigate('/live-streaming-api-sdk');
  };
  // const handleDeveloperClose = () => {
  //   setDeveloperAnchorEl(null);
  // };

  const handleContactUs = () => {
    navigate('/');
    setTimeout(() => {
      const contactUsSection = document.getElementById('contact-us-section');
      if (contactUsSection) {
        const componentHeight = 70;
        const scrollOffset = contactUsSection.offsetTop - componentHeight;
        window.scrollTo({ top: scrollOffset, behavior: 'auto' });
      }
    }, 0);
  };

  const getDynamicPage = () => {
    if (location.pathname === '/') {
      return <div style={{ background: "white", color: "black" }}>
        <Home />
      </div>
    } else if (location.pathname === '/pricing') {
      return <div className="features_container">
        <Pricing />
      </div>
    } else if (location.pathname === '/audio-video-calling-api-sdk') {
      return <div className="features_container">
        <AudioVideo />
      </div>
    } else if (location.pathname === '/live-streaming-api-sdk') {
      return <div className="features_container">
        <LiveStream />
      </div>
    }
    else if (location.pathname === '/pages/terms-and-conditions') {
      return <div className="features_container">
        <TermsConditions />
      </div>
    }
    else if (location.pathname === '/pages/privacy-policy') {
      return <div className="features_container">
        <PrivacyPolicy />
      </div>
    } else return;
  }


  return (
    <OverviewWrapper>
      <ContentWrapper title="New age Learning">
        <HeaderWrapper className={`navbar ${hasScrolled ? 'shadow' : ''}`}>
          <Container maxWidth="xl" >
            <Box display="flex" alignItems="center">
              <Logo />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Button
                      aria-controls={productOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={productOpen ? 'true' : undefined}
                      onClick={handleProductClick}
                      className="pricing_button align_center"
                    >
                      Products
                      <IconContext.Provider value={{ color: '#1A75FF', size: '20px' }}>
                        <div className="align_center" style={{ marginTop: "4px" }}>
                          <RiArrowDownSLine />
                        </div>
                      </IconContext.Provider>
                    </Button>
                    <Menu
                      className="navbar_options_container"
                      anchorEl={productAnchorEl}
                      open={productOpen}
                      onClose={handleProductClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleProductClose} className="navbar_options">
                        <div>
                          <div className="flex">
                            <div className="navbar_options_icon">
                              <IconContext.Provider value={{ color: '#1A75FF', size: '20px' }}>
                                <div><HeadsetMic /></div>
                              </IconContext.Provider>
                            </div>
                            <div className="navbar_options_text" onClick={changeAVProductHandler}>
                              Audio & Video SDK
                              <div className="navbar_options_subtext">
                                Add audio & video calling solutions to your platform
                              </div>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                      <MenuItem onClick={handleProductClose} className="navbar_options">
                        <div>
                          <div className="flex">
                            <div className="navbar_options_icon">
                              <IconContext.Provider value={{ color: '#3087EC', size: '20px' }}>
                                <div><Duo /></div>
                              </IconContext.Provider>
                            </div>
                            <div className="navbar_options_text" onClick={changeLiveProductHandler}>
                              Live Streaming SDK
                              <div className="navbar_options_subtext">
                                Add live streaming solutions to your platform
                              </div>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                    </Menu>
                  </div>
                  {/* <div>
                    <Button
                      aria-controls={developerOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={developerOpen ? 'true' : undefined}
                      onClick={handleDeveloperClick}
                      className="pricing_button align_center"
                    >
                      Developer
                      <IconContext.Provider value={{ color: '#1A75FF', size: '20px' }}>
                        <div className="align_center" style={{marginTop: "4px"}}>
                          <RiArrowDownSLine />
                        </div>
                      </IconContext.Provider>
                    </Button>
                    <Menu
                      anchorEl={developerAnchorEl}
                      open={developerOpen}
                      onClose={handleDeveloperClose}
                      className="navbar_options_container"
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleDeveloperClose} className="navbar_options">
                        <div>
                          <div className="flex">
                            <div className="navbar_options_icon">
                              <IconContext.Provider value={{ color: '#1A75FF', size: '20px' }}>
                                <div><CgFileDocument /></div>
                              </IconContext.Provider>
                            </div>
                            <div className="navbar_options_text">
                              Documentation
                              <div className="navbar_options_subtext">
                                Docs, Integration Guide & Code samples
                              </div>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                      <MenuItem onClick={handleDeveloperClose} className="navbar_options">
                        <div>
                          <div className="flex">
                            <div className="navbar_options_icon">
                              <IconContext.Provider value={{ color: '#1A75FF', size: '20px' }}>
                                <div><BiNotepad /></div>
                              </IconContext.Provider>
                            </div>
                            <div className="navbar_options_text">
                              Portego IFrame API
                              <div className="navbar_options_subtext">
                                Integrate Portego into your Web App.
                              </div>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                      <MenuItem onClick={handleDeveloperClose} className="navbar_options">
                        <div>
                          <div className="flex">
                            <div className="navbar_options_icon">
                              <IconContext.Provider value={{ color: '#1A75FF', size: '20px' }}>
                                <div><ImMobile /></div>
                              </IconContext.Provider>
                            </div>
                            <div className="navbar_options_text">
                              Mobile SDKs
                              <div className="navbar_options_subtext">
                                Integrate Portego into your Mobile App
                              </div>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                    </Menu>
                  </div> */}
                  <Button
                    size="small"
                    component={RouterLink}
                    to="/pricing"
                    variant="text"
                    className="pricing_button align_center"
                  > Pricing
                  </Button>
                  <Button
                    size="small"
                    onClick={handleContactUs}
                    variant="text"
                    className="pricing_button align_center"
                  > Contact us
                  </Button>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {isAuthenticated ? (
                    <>
                      <ButtonPrimary
                        id='dashboard'
                        responsive={false}
                        fixedHeight={'40px'}
                        onClick={() => {
                          navigate("/dashboard", { replace: false });
                        }}
                      >
                        {"Dashboard"}
                      </ButtonPrimary>
                    </>
                  ) : (
                    <ButtonPrimary
                      id='login'
                      responsive={false}
                      fixedHeight={'40px'}
                      onClick={handleLogin}
                    >
                      {"Login"}
                    </ButtonPrimary>
                  )}
                </Box>
              </Box>
            </Box>
          </Container>
        </HeaderWrapper>
        <MobileWrapper className={`navbar ${hasScrolled ? 'shadow' : ''}`} sx={{ borderBottom: !openSidebar ? "1px solid #e1e1e1" : "1px solid white" }}>
          <Box display="flex" alignItems="center" sx={{ padding: '10px' }}>
            <Logo />
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              disableFocusRipple
              disableRipple>
              <NotifyIcon props={{ width: '35px', height: '35px' }} />
            </IconButton>
            <IconButton
              id="menu-mobile"
              color="primary"
              // className={Style.categoryMenuMobile}
              onClick={handleSidebar}
              disableFocusRipple
              disableRipple
            >
              {!openSidebar ? (
                <MenuTwoToneIcon
                  style={{
                    color: "#3087EC",
                  }}
                />
              ) : (
                <CloseTwoToneIcon
                  style={{
                    color: "#3087EC",
                  }}
                />
              )}
            </IconButton>
          </Box>
          <Hidden mdUp>
            <HeaderMobile open={openSidebar} close={closeSidebar} />
          </Hidden>
        </MobileWrapper>
      </ContentWrapper>
      <div style={{ margin: 'auto', maxWidth: '2000px', display: 'block' }}>
        {getDynamicPage()}
      </div>
      <Footer />
    </OverviewWrapper>
  );
}

export default Overview;
