import React from 'react';
import HomePageCover from 'src/content/home/homePageCover';
import FeatureCardDetails from 'src/content/home/featureCard';
import HomePlans from 'src/content/home/HomePlans';
import ContactUs from 'src/content/home/contactUs';

const Home = () => {
    return (
        <>
            <HomePageCover />
            <FeatureCardDetails />
            <HomePlans />
            <ContactUs />
        </>
    )
}

export default Home;