import { useRef } from 'react'
// import useAuth from 'src/hooks/useAuth'
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Avatar,
} from '@mui/material'
import { styled } from '@mui/styles'
import OnlintDot from 'src/assets/icons/OnlintDot'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'

const OnlineDotBox = styled(Box)(() => ({
  marginTop: "1.8rem",
  marginLeft: "1.8rem",
  position: "absolute",
}));

function HeaderUserbox() {
  const { user } = useAuth0()
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const { profilePicture } = useSelector((state: RootState) => state.profile);


  return (
    <>
      <Button ref={ref} sx={{ minWidth: '20px', width: '60px' }} onClick={() => {
        navigate("/profile")
      }}>
        {!profilePicture ? <Avatar alt={user?.name} src={user?.avatar} sx={{ background: "#3086EC" }}></Avatar>:
        <Avatar
          alt="Remy Sharp"
          // src={user.avatar}
          src={profilePicture}
          sx={{
            width: '40px',
            height: '40px',
            position: 'relative',
          }}
        />}
        <OnlineDotBox>
          <OnlintDot
            props={{
              height: "10px",
              width: "10px",
            }}
          />
        </OnlineDotBox>
      </Button>
    </>
  )
}

export default HeaderUserbox
