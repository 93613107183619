import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import React from "react";
import { FaqContainer, FaqTitle, FaqContent } from "./pricingStyle";

export default function FAQSection() {
 const [expanded, setExpanded] = React.useState('');

 const handleChange = panel => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : '');
 };

 const data = [
  {
   id: "panel1",
   heading: "How do you calculate minutes?",
   details: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati, doloremque non eius quibusdam veritatis magni itaque quidem ullam vero at eum minus dicta repellat, molestias illo alias veniam, rerum necessitatibus."
  },
  {
   id: "panel2",
   heading: "Can I get 10,000 free minutes every month?",
   details: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, deleniti."
  },
  {
   id: "panel3",
   heading: "Title 3",
   details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates sint fugit iure nobis eaque cum amet vero soluta quasi modi."
  },
  {
   id: "panel4",
   heading: "Title 4",
   details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis alias aperiam dolore accusamus doloremque, illum, cum temporibus nemo, cumque reiciendis sed maxime beatae. Adipisci suscipit eos, inventore quibusdam ad voluptate autem illo nostrum soluta perferendis dolorem rerum quasi fuga, vitae commodi. Sint ullam recusandae quidem itaque velit laboriosam voluptate voluptatem!"
  }
 ];

 return (
  <FaqContainer>
   <Box sx={{ height: { xs: '250px', sm: '350px', display: 'flex', flexDirection: 'column', rowGap: '15px' }, overflow: 'scroll' }}>
    {data.map(accordion => {
     const { id, heading, details } = accordion;
     return (
      <Accordion
       expanded={expanded === id}
       key={id}
       onChange={handleChange(id)}
       sx={{ borderRadius: '8px' }}
      >
       <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
       >
        <FaqTitle>{heading}</FaqTitle>
       </AccordionSummary>
       <AccordionDetails>
        <FaqContent>{details}</FaqContent>
       </AccordionDetails>
      </Accordion>
     );
    })}
   </Box>
  </FaqContainer>
 );
}