import {
    Divider,
    Grid,
    InputLabel,
    Stack,
    TableCell,
    TableRow,
    Typography,
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import { Box } from '@mui/system';
  
  export const BillingContainer = styled(Box)(() => ({
    width: '95%',
  }));
  
  export const BillingCardHeaderText = styled(Typography)(() => ({
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '0px',
    paddingRight: '20px',
    color: '#3087EC',
  }));
  
  export const BillingCardHeader = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
  }));
  
  export const BillingCardSubHeaderText = styled(Typography)(() => ({
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.6)',
  }));
  
  export const BillingCardSubValue = styled(Typography)(() => ({
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 'bold',
  }));
  
  export const CustomDivider = styled(Divider)(() => ({
    background: 'rgba(0, 0, 0, 0.5)',
  }));
  
  export const LastPaymentText = styled(Typography)(() => ({
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: 'bold',
    marginTop: '10px',
    color: 'rgba(0, 0, 0, 0.5)',
  }));
  
  export const BillingCard = styled(Box)(() => ({
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: '8px',
  }));
  
  export const PaymentExtraText = styled(Box)(() => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    margin: '10px',
    color: 'rgba(0, 0, 0, 0.5)',
  }));
  
  export const TableOtherCell = styled(TableCell)(() => ({
    fontStyle: 'normal',
    fontWeight: '500',
    padding: '12px',
    wordBreak: 'break-word',
  }));
  
  export const TableHeadCells = styled(TableCell)(() => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    textTransform: 'none',
    background: '#f1f4fd',
    color: '#0062fd',
    padding: '12px',
  }));
  
  export const TableBox = styled(Stack)(() => ({
    width: '100%',
    height: 'auto',
  }));
  
  export const ParicipantListBox = styled(TableRow)(
    () => `
      &:last-of-type td:first-of-type {
          // border-bottom-left-radius: 8px;
      }
      &:last-of-type td:last-of-type {
          // border-bottom-right-radius: 8px;
      }
      `,
  );
  
  export const WalletInfoGrid = styled(Grid)(
    ({ theme }) => `
      &.MuiGrid-root.MuiGrid-item {
        padding: 16px;
        // border-right: 1px solid rgba(0, 0, 0, 0.5);
        gap:13px;
      }
      @media (max-width: ${theme.breakpoints.values.sm}px) {
        &.MuiGrid-root.MuiGrid-item{
          border-right: none;
          padding: 8px 16px;
        }
      }
      `,
  );
  
  export const LastPaymentInfoGrid = styled(Grid)(
    ({ theme }) => `
      &.MuiGrid-root.MuiGrid-item {
        padding: 8px;
        margin-top:25px;
        border-top: 1px solid #E9EBEE;
        display: flex;
        justify-content: space-between;
      }
      @media (max-width: ${theme.breakpoints.values.sm}px) {
          flex-direction: column;
        }
      `,
  );
  
  export const PayNowButtonBox = styled(Box)(
    ({ theme }) => `
      @media (max-width: ${theme.breakpoints.values.sm}px) {
          display: flex;
          align-items: flex-end;
          
        }
      `,
  );
  
  export const AdditionalGrid = styled(Grid)(
    ({ theme }) => `
      @media (max-width: ${theme.breakpoints.values.sm}px) {
          display: none;
        }
      `,
  );
  
  export const PaymentCardGrid = styled(Grid)(
    () => `
      &.MuiGrid-root.MuiGrid-item {
        padding: 16px;
      }
    `,
  );
  
  export const TablePaginationBox = styled(Stack)`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 5px;
    padding-right: 5px;
  `;
  
  export const InputLabelText = styled(InputLabel)(() => ({
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '400',
  }));
  