import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Box, Typography, TablePagination, Hidden, Divider, } from '@mui/material';
import { RootState } from 'src/store';
import { useSelector, useDispatch } from 'react-redux';
// import helper from 'src/services/helper';
import { editInvoice } from 'src/slices/billing';
import { TablePaginationBox } from './BillingStyled';
import Scrollbars from 'react-custom-scrollbars-2';
import jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import {
  ContentWrapperCenterMob,
  ContentWrapperMob,
  CustomPagination,
  DeviderBox,
  MenuWrapperMob,
  TableHeaderName,
  TableHeaderValue,
  TableHeaderWrapper,
} from "src/components/TableMobileCss";
import DownloadInvoiceIcon from 'src/assets/icons/DownloadInvoiceIcon';
import Loader from 'src/components/Loader/Loader';
import moment from 'moment';
export default function BillingTable() {
  const { invoice, meta, invoiceLoading } = useSelector((state: RootState) => state.Billing);
  const dispatch = useDispatch();
  const InvoiceHeader = ["Invoice Number", "Date", "Deposit", "Payment", "Type", "Status", "Invoice"];
  const handleChangePage = (event: unknown, newPage: number) => {
    const data = {
      page: newPage + 1,
      per_page: meta.per_page,
    }
    dispatch(editInvoice(data))
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = {
      page: 1,
      per_page: event.target.value,
    }
    dispatch(editInvoice(data))
  };

  const printDocument = (data) => {
    const input = document.getElementById(`download-${data}`);
    input.style.display = 'block';
    (html2canvas as any)(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'JPEG', 0, 0, 210, 200);
      pdf.save('download.pdf');
    });
    input.style.display = 'none';
  }

  return (
    <>
      <Hidden only={['sm', 'xs']}>
        <Paper square sx={{ backgroundColor: "#ffffff", borderRadius: '6px', ml: 2, mr: 2, mb: 2 }}>
          <TableContainer component={Box} sx={{ height: "48vh" }}>
            <Table sx={{ minWidth: 650, position: "relative" }} aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow >
                  {InvoiceHeader.map((item, index) => {
                    return <TableCell sx={{ color: '#3087EC', width: '14%' }} key={index} align="left">{item}</TableCell>
                  })}
                </TableRow>
              </TableHead>
              {invoiceLoading ? <Box sx={{ height: '40vh', position: "absolute", width: '100%' }}> <Loader /></Box> :
                <>
                  {invoice.length === 0 ? <Box sx={{ height: '40vh', position: "absolute", width: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <Typography>No data found</Typography>
                  </Box> :
                    <TableBody>
                      {invoice && invoice.map((row, index) => (
                        <>
                          <TableRow
                            key={row.invoiceId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
                          >
                            <TableCell component="th" scope="row">{row?.invoiceNumber ? row?.invoiceNumber : "Invoice pending"}</TableCell>
                            <TableCell align="left">{moment(row?.createdDate).format('DD MMM YYYY')}</TableCell>
                            <TableCell align="left">${row?.credits}</TableCell>
                            <TableCell align="left">${row?.properties?.platformCharges?.totalCost}</TableCell>
                            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{row?.type}</TableCell>
                            <TableCell align="center">
                              {row?.status === "pending" || row?.status === 'failed' ?
                                <Typography style={{ color: "#FF5A5A", width: "85px", borderRadius: "16px", backgroundColor: "#FFE5E5", padding: '5px 10px', textTransform: 'capitalize' }}>{row?.status}</Typography> :
                                <Typography style={{ color: "#11BD60", width: "85px", borderRadius: "16px", backgroundColor: "#DDFFDE", padding: '5px 10px', textTransform: 'capitalize' }}>{row?.status}</Typography>
                              }
                            </TableCell>
                            <TableCell align="left">{row?.status === 'failed' ||
                              row?.status === 'pending' ? "Not available" : (
                              // <Tooltip title="Download Invoice" arrow>
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: 'left',
                                  cursor: 'pointer',
                                }}
                                onClick={() => printDocument(index)}
                                id={`download-history-${row?.id}`}
                              >
                                <DownloadInvoiceIcon
                                  props={{ height: '32px', width: '32px' }}
                                />
                              </span>
                              // </Tooltip>
                            )}</TableCell>
                          </TableRow>
                          <div id={`download-${index}`} style={{ display: "none", width: '700px' }}>
                            <div style={{ padding: "15px" }}>
                              <div
                                style={{
                                  borderBottom: "2px solid black",
                                  display: "flex",
                                  justifyContent: "space-between"
                                }}
                              >
                                <div>
                                  <div>
                                    <div>
                                      <svg
                                        width="140"
                                        height="50"
                                        viewBox="0 0 1020 452"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M705.334 1.60002C690.267 6.93335 681.734 24.2667 686.667 39.3334C696.267 68.1334 738.134 66 744.534 36.4C749.2 14 726.8 -5.86665 705.334 1.60002Z"
                                          fill="#CB2828"
                                        />
                                        <path
                                          d="M620 22.4C610.933 26.8 603.066 34.6667 598.8 43.8667C596.266 49.4667 596 52.5333 595.6 84.2667C595.066 122.8 595.466 125.2 603.866 128C609.6 129.867 616.133 127.067 618 121.867C618.8 120 619.333 104.267 619.333 86.8C619.333 56 619.466 54.9333 622.4 50.5333C627.2 43.4667 629.866 42.6667 651.466 42.6667H670.933L670.8 30.6667L670.666 18.6667H649.333C628.666 18.6667 627.733 18.8 620 22.4Z"
                                          fill="#CB2828"
                                        />
                                        <path
                                          d="M758.934 30.2667L759.2 42L834.267 42.6667C915.6 43.3333 912.667 43.0667 917.2 50.8C918.934 53.8667 919.334 59.3333 919.334 85.7333C919.334 105.067 919.867 118.533 920.8 121.2C922.534 126 928.8 129.867 933.2 128.8C934.8 128.4 948.667 121.467 964.267 113.333C979.734 105.333 993.067 98.6667 993.867 98.6667C994.934 98.6667 995.334 109.867 995.334 137.467V176.267L992.267 175.467C990.667 175.067 977.334 168.4 962.667 160.8C933.6 145.467 928.8 144.133 923.734 149.6C916.667 157.333 919.734 164.8 932.667 171.6C968.667 190.667 1017.73 216 1018.4 216C1018.93 216 1019.33 180.533 1019.33 137.2C1019.33 66.9333 1019.07 58.4 1017.33 59.2C1016.13 59.6 999.867 68 981.067 78C962.134 87.8667 946 96 945.2 96C944 96 943.467 89.7333 943.067 72.2667C942.667 50.8 942.4 48.1333 939.6 42.4C935.734 34.6667 927.2 26.1333 919.334 22.2667C913.334 19.3333 913.067 19.3333 836 18.9333L758.667 18.5333L758.934 30.2667Z"
                                          fill="#CB2828"
                                        />
                                        <path
                                          d="M638 136V184H664.667H691.333V175.333V166.8L675.067 166.4L658.667 166L658.267 126.933L658 88H648H638V136Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M704.667 136V184.133L715.067 183.733L725.334 183.333L725.734 138.667C725.867 114.133 725.734 92.6666 725.334 90.9333C724.667 88.2666 723.867 88 714.667 88H704.667V136Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M736.933 89.6C737.333 90.5333 745.333 112.267 754.8 137.733L771.866 184.133L781.866 183.733L792 183.333L805.6 146.667C813.066 126.533 821.066 105.067 823.333 98.9333L827.333 88H816H804.8L794.8 118.933C789.333 136 784.4 152.533 783.866 155.733C782.666 163.067 781.2 163.867 780.133 157.6C779.733 154.933 776.933 144.8 774 135.333C770.933 125.733 766.4 111.2 763.866 102.933L759.2 88H747.733C739.2 88 736.533 88.4 736.933 89.6Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M838 136V184H866.667H895.467L895.067 175.6L894.667 167.333L876.933 166.667L859.067 166V154.667V143.333L876.933 142.667L894.667 142L895.067 133.6L895.467 125.333H877.333H859.333V116.667C859.333 111.867 859.333 107.6 859.333 106.933C859.333 106.4 867.333 105.733 876.933 105.333L894.667 104.667L895.067 96.2667L895.467 88H866.667H838V136Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M0.666992 302.667V394.667H13.3337H26.0003V356V317.333H57.7337C75.067 317.333 92.667 316.8 96.8003 316.133C113.2 313.467 125.067 304.4 132.667 288.933C136.4 281.333 137.2 278.133 137.734 267.467C138.4 254.667 136.8 244.267 133.067 236.8C130.134 231.067 120.8 221.6 114.4 217.733C103.2 211.2 98.267 210.667 47.7337 210.667H0.666992V302.667ZM94.0003 235.2C102.667 238.4 108.4 244.667 110.934 253.2C114.8 266.4 110.8 281.733 102 288C94.0003 293.733 87.467 294.667 55.7337 294.667H26.0003V264.267C26.0003 247.467 26.4003 233.333 27.067 232.8C27.6003 232.267 41.467 232 58.0003 232.267C82.1337 232.8 89.2003 233.333 94.0003 235.2Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M398 241.333V260H388.667H379.2L379.6 269.6L380 279.333L389.067 279.733L398 280.133V327.867C398 377.067 398.4 380.8 403.6 388.4C407.867 394.4 413.2 396.267 426 396.267C443.334 396.267 443.334 396.267 443.334 385.2V376H434.934C421.334 376 422 378.8 422 325.6V280.133L432.4 279.733L442.667 279.333L443.067 269.6L443.467 260H432.667H422V241.333V222.667H410H398V241.333Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M206.934 257.333C182.934 261.467 163.6 282.667 158.667 310C153.467 339.067 159.2 365.333 174.267 382C188.4 397.6 211.2 403.333 235.467 397.6C248 394.533 255.867 390 263.467 381.6C275.6 368.133 282 348.533 282 325.467C282 317.6 281.067 307.6 280 302.8C272.267 270 242.267 251.467 206.934 257.333ZM234.667 279.6C253.067 286.4 262.134 313.333 256 342.933C250.667 368.133 240 378.933 220.134 379.067C198.267 379.467 185.6 366.533 181.734 340.133C178.134 315.333 187.467 288.133 202.4 280.533C210.8 276.133 224.4 275.733 234.667 279.6Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M788.667 257.867C777.6 260.267 768.8 265.067 760.667 273.2C746.8 287.333 740.667 304.533 740.667 329.733C740.667 354.267 745.734 369.067 758.934 382.8C776.267 400.933 806.934 405.2 832.134 392.667C846.667 385.6 854.534 375.467 861.467 355.333C864.8 345.6 865.067 342.933 865.067 325.333C865.067 303.6 863.067 295.067 854.8 282C841.867 262 814.934 252.133 788.667 257.867ZM819.734 280.4C828.4 284 834.8 291.867 838.134 302.933C840.534 310.667 840.8 314.533 840.4 328.4C839.2 362.4 827.467 378.667 804 378.933C791.734 379.067 784.934 376.667 777.867 369.467C769.067 360.667 765.734 351.067 764.934 333.2C764.267 316.667 766.4 306.267 773.2 294C782 278 800.8 272.4 819.734 280.4Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M355.067 259.333C348.667 261.733 340.134 268.533 335.2 275.2L331.334 280.533V270.267V260H320H308.667V327.333V394.667H320.667H332.667V351.467V308.267L337.067 299.867C339.867 294.267 343.067 290.267 346.667 287.867C352.134 284.133 365.6 280.667 371.467 281.6C374.667 282 374.667 281.867 375.067 269.6L375.467 257.333L367.734 257.467C363.467 257.467 357.734 258.4 355.067 259.333Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M501.733 259.867C481.466 266.4 467.6 281.2 460.666 303.333C456.8 315.733 456.4 342 459.866 354C467.2 378.933 484.4 394.8 508.266 398.933C516.266 400.267 519.733 400.133 529.6 398.4C536 397.333 544.4 394.933 548 393.2C560.8 387.2 574.8 369.733 577.466 356.267L578.4 352H566.933H555.466L552.933 357.6C549.333 365.733 544.533 370.933 537.2 374.667C531.733 377.467 528.8 378 518.666 378C508.4 378 505.866 377.467 501.066 374.8C491.333 369.2 483.6 354.8 482.933 341.067L482.666 335.333L531.733 334.933L580.666 334.667V331.467C580.666 322.267 577.6 300.533 575.333 294.133C569.333 276.4 555.733 264.4 536 259.2C526.666 256.667 510.8 256.933 501.733 259.867ZM531.2 279.467C543.866 283.2 552.8 294.8 554.933 310L555.733 316H519.466H483.333V312.667C483.333 307.867 488.4 295.2 492.266 290.667C497.066 284.933 503.066 281.067 509.866 279.2C518.266 276.933 522.933 276.933 531.2 279.467Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M642 259.2C622.133 264.8 607.2 280.8 601.2 302.8C598.533 313.2 597.467 333.733 599.2 345.467C602 365.467 608.8 377.733 621.867 386.667C631.733 393.467 640.533 396 654.4 396C669.6 396 679.067 392.533 687.733 384L694 377.733V383.467C694 393.333 691.2 409.733 688.667 414.667C682.667 426.4 668.933 432.8 652.533 431.733C639.867 430.8 632.933 426.667 628.667 417.733L625.333 410.667H613.6H601.867L602.8 415.6C604.133 423.333 608.4 432.133 612.8 436.8C622.8 447.067 646.8 453.333 666.133 450.667C691.733 447.2 705.333 436.267 712.933 413.067C715.6 405.067 715.733 400.8 716.267 332.933L716.933 261.333H706.133H695.333V268.667C695.333 276.267 694.4 277.733 691.733 273.6C689.067 269.333 675.867 261.467 667.867 259.333C658.667 256.933 650.267 256.933 642 259.2ZM668.8 280.267C671.333 281.067 675.867 283.6 679.067 285.733C695.333 297.333 699.733 334.267 687.6 358.133C676.4 380 643.467 383.6 630.667 364.267C624.4 354.667 622.933 348.533 622.933 330C622.8 307.6 625.733 297.467 635.333 288C643.733 279.467 657.333 276.267 668.8 280.267Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div style={{ padding: "8px" }}>
                                    Aradhya complex, 1st floor, Darbe, Puttur, 574202
                                  </div>
                                </div>
                                <div style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}>
                                  <div style={{ fontSize: "27px", fontWeight: "bold" }}>
                                    Payment Receipt
                                    <div style={{ fontSize: "16px", fontWeight: "400", marginTop: "10px" }}>
                                      {moment(row?.createdDate).format('DD MMM YYYY')}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                  <div>
                                    <h2>Billing Address:</h2>
                                    <p>Name</p>
                                    <p>India</p>
                                  </div>
                                </div>
                                <div style={{ marginTop: "30px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "0px",
                                      height: "35px"
                                    }}
                                  >
                                    <h3>Invoice Number:</h3>
                                    &nbsp;{row?.invoiceNumber}
                                  </div>
                                  <div style={{ display: "flex", alignItems: "center", height: "35px" }}>
                                    <h3>Invoice Type:</h3>
                                    &nbsp;Manual Payment
                                  </div>
                                  <div style={{ display: "flex", alignItems: "center", height: "35px" }}>
                                    <h3>Payment Method:</h3>
                                    &nbsp;Razor Pay
                                  </div>
                                </div>
                              </div>
                              <div style={{ marginTop: "30px" }}>
                                <table style={{ width: "100%" }}>
                                  <tr
                                    style={{
                                      backgroundColor: "#dbdbdb",
                                      height: "60px"
                                    }}
                                  >
                                    <th>Invoice Number</th>
                                    <th>Description</th>
                                    <th>Deposit</th>
                                    <th>Payment(USD)</th>
                                  </tr>
                                  <tr style={{ height: "60px" }}>
                                    <td style={{ textAlign: "center" }}>{row?.invoiceNumber}</td>
                                    <td style={{ textAlign: "center" }}>Description</td>
                                    <td style={{ textAlign: "center" }}>${row?.credits}</td>
                                    <td style={{ textAlign: "center" }}>${row?.properties?.platformCharges?.totalCost}</td>
                                  </tr>
                                </table>
                              </div>
                              <div style={{ marginTop: "30px", display: "flex", justifyContent: "flex-end" }}>
                                <div style={{ width: "30%", height: "200px" }}>
                                  <div style={{ marginTop: "10px" }}>GST 18%(USD)</div>
                                  <div style={{ marginTop: "10px" }}>Total (USD)</div>
                                  <div style={{
                                    borderTop: "1px solid black",
                                    borderBottom: "1px solid black",
                                    width: "100%", marginTop: "10px"
                                  }}>Paid in total(USD) = ${row?.properties?.platformCharges?.totalCost}</div>
                                </div>
                              </div>
                            </div>
                          </div >
                        </>
                      ))}
                    </TableBody>}
                </>}
            </Table>
          </TableContainer>

          {invoice && <Box display="flex" justifyContent="center" alignContent="center" sx={{ p: 1 }}>
            <TablePaginationBox>
              {invoice.length > 0 &&
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={meta.total_count}
                  rowsPerPage={meta.per_page}
                  SelectProps={{

                    inputProps: {
                      "aria-label": "rows per page"
                    },
                  }}
                  page={meta.page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  backIconButtonProps={{
                    style: {
                      background: (meta.page - 1) ? 'rgb(221,239,255,0.7)' : '#eee',
                      color: (meta.page - 1) ? '#0062FD' : '#aaa',
                      borderRadius: '50%',
                      marginRight: '2px',
                    },
                  }}
                  nextIconButtonProps={{
                    style: {
                      background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                      color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                      borderRadius: '50%',
                      marginLeft: '2px',
                    },
                  }}

                />
              }
            </TablePaginationBox>
          </Box>}

        </Paper>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md']}>
        <Box sx={{ width: '100%', pt: '0px' }}>
          <Divider sx={{ height: '1px' }} />
        </Box>
        <Box>
          <Hidden only={['xl', 'lg', 'md']}>
            {invoiceLoading ? <Box sx={{ height: '45vh', width: '100%' }}> <Loader /></Box> :
              <>
                {invoice.length === 0 ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25vh' }}>
                  <Typography>No data found</Typography>
                </Box> :
                  <Scrollbars style={{ height: '45vh' }}>
                    {invoice.map((row, index) => (
                      <>
                        <Box key={index}>
                          <TableHeaderWrapper>
                            <TableHeaderName>Invoice number </TableHeaderName>
                            <MenuWrapperMob>
                              <Typography>{row?.invoiceNumber ? row?.invoiceNumber : "Invoice pending"}</Typography>
                            </MenuWrapperMob>
                          </TableHeaderWrapper>
                          <ContentWrapperMob>
                            <ContentWrapperCenterMob>
                              <TableHeaderName>Date</TableHeaderName>
                              <TableHeaderValue>{moment(row?.createdDate).format('DD MMM YYYY')}</TableHeaderValue>
                            </ContentWrapperCenterMob>
                            <DeviderBox>
                              <Divider sx={{ height: '1.5px' }} />
                            </DeviderBox>
                          </ContentWrapperMob>
                          <ContentWrapperMob>
                            <ContentWrapperCenterMob>
                              <TableHeaderName>Deposit</TableHeaderName>
                              <TableHeaderValue>
                                ${row?.credits}
                              </TableHeaderValue>
                            </ContentWrapperCenterMob>
                            <DeviderBox>
                              <Divider sx={{ height: '1.5px' }} />
                            </DeviderBox>
                          </ContentWrapperMob>
                          <ContentWrapperMob>
                            <ContentWrapperCenterMob>
                              <TableHeaderName>Payment</TableHeaderName>
                              <TableHeaderValue>
                                ${row?.properties?.platformCharges?.totalCost}
                              </TableHeaderValue>
                            </ContentWrapperCenterMob>
                            <DeviderBox>
                              <Divider sx={{ height: '1.5px' }} />
                            </DeviderBox>
                          </ContentWrapperMob>
                          <ContentWrapperMob>
                            <ContentWrapperCenterMob>
                              <TableHeaderName>Type</TableHeaderName>
                              <TableHeaderValue sx={{ textTransform: 'capitalize' }}>
                                {row?.type}
                              </TableHeaderValue>
                            </ContentWrapperCenterMob>
                            <DeviderBox>
                              <Divider sx={{ height: '1.5px' }} />
                            </DeviderBox>
                          </ContentWrapperMob>
                          <ContentWrapperMob>
                            <ContentWrapperCenterMob>
                              <TableHeaderName>Status</TableHeaderName>
                              <TableHeaderValue sx={{ textAlign: 'center' }}>
                                {row?.status === "pending" || row?.status === 'failed' ?
                                  <Typography style={{ color: "#FF5A5A", width: "85px", borderRadius: "40px", backgroundColor: "#FFE5E5", padding: '5px 10px', textTransform: 'capitalize' }}>{row?.status}</Typography> :
                                  <Typography style={{ color: "#11BD60", width: "85px", borderRadius: "40px", backgroundColor: "#DDFFDE", padding: '5px 10px', textTransform: 'capitalize' }}>{row?.status}</Typography>
                                }
                              </TableHeaderValue>
                            </ContentWrapperCenterMob>
                            <DeviderBox>
                              <Divider sx={{ height: '1.5px' }} />
                            </DeviderBox>
                          </ContentWrapperMob>

                          <ContentWrapperMob>
                            <ContentWrapperCenterMob>
                              <TableHeaderName>Invoice</TableHeaderName>
                              <TableHeaderValue>
                                {row?.status === 'failed' ||
                                  row?.status === 'pending' ? "Not available" : (
                                  // <Tooltip title="Download Invoice" arrow>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'left',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => printDocument(index)}
                                    id={`download-history-${row?.id}`}
                                  >
                                    <DownloadInvoiceIcon
                                      props={{ height: '32px', width: '32px' }}
                                    />
                                  </span>
                                  // </Tooltip>
                                )}
                              </TableHeaderValue>
                            </ContentWrapperCenterMob>
                            <DeviderBox>
                              <Divider sx={{ height: '1.5px' }} />
                            </DeviderBox>
                          </ContentWrapperMob>
                        </Box>
                        <div id={`download-${index}`} style={{ display: "none", width: '700px' }}>
                          <div style={{ padding: "15px" }}>
                            <div
                              style={{
                                borderBottom: "2px solid black",
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <div>
                                <div>
                                  <div>
                                    <svg
                                      width="140"
                                      height="50"
                                      viewBox="0 0 1020 452"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M705.334 1.60002C690.267 6.93335 681.734 24.2667 686.667 39.3334C696.267 68.1334 738.134 66 744.534 36.4C749.2 14 726.8 -5.86665 705.334 1.60002Z"
                                        fill="#CB2828"
                                      />
                                      <path
                                        d="M620 22.4C610.933 26.8 603.066 34.6667 598.8 43.8667C596.266 49.4667 596 52.5333 595.6 84.2667C595.066 122.8 595.466 125.2 603.866 128C609.6 129.867 616.133 127.067 618 121.867C618.8 120 619.333 104.267 619.333 86.8C619.333 56 619.466 54.9333 622.4 50.5333C627.2 43.4667 629.866 42.6667 651.466 42.6667H670.933L670.8 30.6667L670.666 18.6667H649.333C628.666 18.6667 627.733 18.8 620 22.4Z"
                                        fill="#CB2828"
                                      />
                                      <path
                                        d="M758.934 30.2667L759.2 42L834.267 42.6667C915.6 43.3333 912.667 43.0667 917.2 50.8C918.934 53.8667 919.334 59.3333 919.334 85.7333C919.334 105.067 919.867 118.533 920.8 121.2C922.534 126 928.8 129.867 933.2 128.8C934.8 128.4 948.667 121.467 964.267 113.333C979.734 105.333 993.067 98.6667 993.867 98.6667C994.934 98.6667 995.334 109.867 995.334 137.467V176.267L992.267 175.467C990.667 175.067 977.334 168.4 962.667 160.8C933.6 145.467 928.8 144.133 923.734 149.6C916.667 157.333 919.734 164.8 932.667 171.6C968.667 190.667 1017.73 216 1018.4 216C1018.93 216 1019.33 180.533 1019.33 137.2C1019.33 66.9333 1019.07 58.4 1017.33 59.2C1016.13 59.6 999.867 68 981.067 78C962.134 87.8667 946 96 945.2 96C944 96 943.467 89.7333 943.067 72.2667C942.667 50.8 942.4 48.1333 939.6 42.4C935.734 34.6667 927.2 26.1333 919.334 22.2667C913.334 19.3333 913.067 19.3333 836 18.9333L758.667 18.5333L758.934 30.2667Z"
                                        fill="#CB2828"
                                      />
                                      <path
                                        d="M638 136V184H664.667H691.333V175.333V166.8L675.067 166.4L658.667 166L658.267 126.933L658 88H648H638V136Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M704.667 136V184.133L715.067 183.733L725.334 183.333L725.734 138.667C725.867 114.133 725.734 92.6666 725.334 90.9333C724.667 88.2666 723.867 88 714.667 88H704.667V136Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M736.933 89.6C737.333 90.5333 745.333 112.267 754.8 137.733L771.866 184.133L781.866 183.733L792 183.333L805.6 146.667C813.066 126.533 821.066 105.067 823.333 98.9333L827.333 88H816H804.8L794.8 118.933C789.333 136 784.4 152.533 783.866 155.733C782.666 163.067 781.2 163.867 780.133 157.6C779.733 154.933 776.933 144.8 774 135.333C770.933 125.733 766.4 111.2 763.866 102.933L759.2 88H747.733C739.2 88 736.533 88.4 736.933 89.6Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M838 136V184H866.667H895.467L895.067 175.6L894.667 167.333L876.933 166.667L859.067 166V154.667V143.333L876.933 142.667L894.667 142L895.067 133.6L895.467 125.333H877.333H859.333V116.667C859.333 111.867 859.333 107.6 859.333 106.933C859.333 106.4 867.333 105.733 876.933 105.333L894.667 104.667L895.067 96.2667L895.467 88H866.667H838V136Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M0.666992 302.667V394.667H13.3337H26.0003V356V317.333H57.7337C75.067 317.333 92.667 316.8 96.8003 316.133C113.2 313.467 125.067 304.4 132.667 288.933C136.4 281.333 137.2 278.133 137.734 267.467C138.4 254.667 136.8 244.267 133.067 236.8C130.134 231.067 120.8 221.6 114.4 217.733C103.2 211.2 98.267 210.667 47.7337 210.667H0.666992V302.667ZM94.0003 235.2C102.667 238.4 108.4 244.667 110.934 253.2C114.8 266.4 110.8 281.733 102 288C94.0003 293.733 87.467 294.667 55.7337 294.667H26.0003V264.267C26.0003 247.467 26.4003 233.333 27.067 232.8C27.6003 232.267 41.467 232 58.0003 232.267C82.1337 232.8 89.2003 233.333 94.0003 235.2Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M398 241.333V260H388.667H379.2L379.6 269.6L380 279.333L389.067 279.733L398 280.133V327.867C398 377.067 398.4 380.8 403.6 388.4C407.867 394.4 413.2 396.267 426 396.267C443.334 396.267 443.334 396.267 443.334 385.2V376H434.934C421.334 376 422 378.8 422 325.6V280.133L432.4 279.733L442.667 279.333L443.067 269.6L443.467 260H432.667H422V241.333V222.667H410H398V241.333Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M206.934 257.333C182.934 261.467 163.6 282.667 158.667 310C153.467 339.067 159.2 365.333 174.267 382C188.4 397.6 211.2 403.333 235.467 397.6C248 394.533 255.867 390 263.467 381.6C275.6 368.133 282 348.533 282 325.467C282 317.6 281.067 307.6 280 302.8C272.267 270 242.267 251.467 206.934 257.333ZM234.667 279.6C253.067 286.4 262.134 313.333 256 342.933C250.667 368.133 240 378.933 220.134 379.067C198.267 379.467 185.6 366.533 181.734 340.133C178.134 315.333 187.467 288.133 202.4 280.533C210.8 276.133 224.4 275.733 234.667 279.6Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M788.667 257.867C777.6 260.267 768.8 265.067 760.667 273.2C746.8 287.333 740.667 304.533 740.667 329.733C740.667 354.267 745.734 369.067 758.934 382.8C776.267 400.933 806.934 405.2 832.134 392.667C846.667 385.6 854.534 375.467 861.467 355.333C864.8 345.6 865.067 342.933 865.067 325.333C865.067 303.6 863.067 295.067 854.8 282C841.867 262 814.934 252.133 788.667 257.867ZM819.734 280.4C828.4 284 834.8 291.867 838.134 302.933C840.534 310.667 840.8 314.533 840.4 328.4C839.2 362.4 827.467 378.667 804 378.933C791.734 379.067 784.934 376.667 777.867 369.467C769.067 360.667 765.734 351.067 764.934 333.2C764.267 316.667 766.4 306.267 773.2 294C782 278 800.8 272.4 819.734 280.4Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M355.067 259.333C348.667 261.733 340.134 268.533 335.2 275.2L331.334 280.533V270.267V260H320H308.667V327.333V394.667H320.667H332.667V351.467V308.267L337.067 299.867C339.867 294.267 343.067 290.267 346.667 287.867C352.134 284.133 365.6 280.667 371.467 281.6C374.667 282 374.667 281.867 375.067 269.6L375.467 257.333L367.734 257.467C363.467 257.467 357.734 258.4 355.067 259.333Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M501.733 259.867C481.466 266.4 467.6 281.2 460.666 303.333C456.8 315.733 456.4 342 459.866 354C467.2 378.933 484.4 394.8 508.266 398.933C516.266 400.267 519.733 400.133 529.6 398.4C536 397.333 544.4 394.933 548 393.2C560.8 387.2 574.8 369.733 577.466 356.267L578.4 352H566.933H555.466L552.933 357.6C549.333 365.733 544.533 370.933 537.2 374.667C531.733 377.467 528.8 378 518.666 378C508.4 378 505.866 377.467 501.066 374.8C491.333 369.2 483.6 354.8 482.933 341.067L482.666 335.333L531.733 334.933L580.666 334.667V331.467C580.666 322.267 577.6 300.533 575.333 294.133C569.333 276.4 555.733 264.4 536 259.2C526.666 256.667 510.8 256.933 501.733 259.867ZM531.2 279.467C543.866 283.2 552.8 294.8 554.933 310L555.733 316H519.466H483.333V312.667C483.333 307.867 488.4 295.2 492.266 290.667C497.066 284.933 503.066 281.067 509.866 279.2C518.266 276.933 522.933 276.933 531.2 279.467Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M642 259.2C622.133 264.8 607.2 280.8 601.2 302.8C598.533 313.2 597.467 333.733 599.2 345.467C602 365.467 608.8 377.733 621.867 386.667C631.733 393.467 640.533 396 654.4 396C669.6 396 679.067 392.533 687.733 384L694 377.733V383.467C694 393.333 691.2 409.733 688.667 414.667C682.667 426.4 668.933 432.8 652.533 431.733C639.867 430.8 632.933 426.667 628.667 417.733L625.333 410.667H613.6H601.867L602.8 415.6C604.133 423.333 608.4 432.133 612.8 436.8C622.8 447.067 646.8 453.333 666.133 450.667C691.733 447.2 705.333 436.267 712.933 413.067C715.6 405.067 715.733 400.8 716.267 332.933L716.933 261.333H706.133H695.333V268.667C695.333 276.267 694.4 277.733 691.733 273.6C689.067 269.333 675.867 261.467 667.867 259.333C658.667 256.933 650.267 256.933 642 259.2ZM668.8 280.267C671.333 281.067 675.867 283.6 679.067 285.733C695.333 297.333 699.733 334.267 687.6 358.133C676.4 380 643.467 383.6 630.667 364.267C624.4 354.667 622.933 348.533 622.933 330C622.8 307.6 625.733 297.467 635.333 288C643.733 279.467 657.333 276.267 668.8 280.267Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                <div style={{ padding: "8px" }}>
                                  Aradhya complex, 1st floor, Darbe, Puttur, 574202
                                </div>
                              </div>
                              <div style={{
                                display: "flex",
                                alignItems: "center"
                              }}>
                                <div style={{ fontSize: "27px", fontWeight: "bold" }}>
                                  Payment Receipt
                                  <div style={{ fontSize: "16px", fontWeight: "400", marginTop: "10px" }}>
                                    {moment(row?.createdDate).format('DD MMM YYYY')}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div>
                                <div>
                                  <h2>Billing Address:</h2>
                                  <p>Name</p>
                                  <p>India</p>
                                </div>
                              </div>
                              <div style={{ marginTop: "30px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "0px",
                                    height: "35px"
                                  }}
                                >
                                  <h3>Invoice Number:</h3>
                                  &nbsp;{row?.invoiceNumber}
                                </div>
                                <div style={{ display: "flex", alignItems: "center", height: "35px" }}>
                                  <h3>Invoice Type:</h3>
                                  &nbsp;Manual Payment
                                </div>
                                <div style={{ display: "flex", alignItems: "center", height: "35px" }}>
                                  <h3>Payment Method:</h3>
                                  &nbsp;Razor Pay
                                </div>
                              </div>
                            </div>
                            <div style={{ marginTop: "30px" }}>
                              <table style={{ width: "100%" }}>
                                <tr
                                  style={{
                                    backgroundColor: "#dbdbdb",
                                    height: "60px"
                                  }}
                                >
                                  <th>Invoice Number</th>
                                  <th>Description</th>
                                  <th>Deposit</th>
                                  <th>Payment(USD)</th>
                                </tr>
                                <tr style={{ height: "60px" }}>
                                  <td style={{ textAlign: "center" }}>{row?.invoiceNumber}</td>
                                  <td style={{ textAlign: "center" }}>Description</td>
                                  <td style={{ textAlign: "center" }}>${row?.credits}</td>
                                  <td style={{ textAlign: "center" }}>${row?.properties?.platformCharges?.totalCost}</td>
                                </tr>
                              </table>
                            </div>
                            <div style={{ marginTop: "30px", display: "flex", justifyContent: "flex-end" }}>
                              <div style={{ width: "30%", height: "200px" }}>
                                <div style={{ marginTop: "10px" }}>GST 18%(USD)</div>
                                <div style={{ marginTop: "10px" }}>Total (USD)</div>
                                <div style={{
                                  borderTop: "1px solid black",
                                  borderBottom: "1px solid black",
                                  width: "100%", marginTop: "10px"
                                }}>Paid in total(USD) = ${row?.properties?.platformCharges?.totalCost}</div>
                              </div>
                            </div>
                          </div>
                        </div >
                      </>
                    ))}
                  </Scrollbars>}
              </>}
          </Hidden>
          <Box sx={{
            height: '50px', width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <CustomPagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              count={meta.total_count}
              rowsPerPage={meta.per_page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page"
                },
              }}
              page={meta.page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                style: {
                  background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                  color: (meta.page - 1) ? "#0062FD" : "#aaa",
                  borderRadius: '50%',
                  marginRight: '2px',
                  height: '25px',
                  width: '25px'
                },
              }}
              nextIconButtonProps={{
                style: {
                  background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                  color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                  borderRadius: '50%',
                  marginLeft: '2px',
                  height: '25px',
                  width: '25px'
                },
              }}
              labelRowsPerPage={
                <div style={{ fontFamily: 'Poppins', fontSize: '12px', wordWrap: 'break-word' }}>
                  Rows per page:
                </div>
              }
            />
          </Box>
        </Box>
      </Hidden>
    </>
  );
}


