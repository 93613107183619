import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Typography,
  InputAdornment,
  Divider,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { RootState, useDispatch } from "src/store";
import Home from "./Home";
import { keyDetailsDataFn } from "src/slices/apiKeys";
import CustomDialogBox from "src/components/CustomDialog";
import { ButtonPrimary } from "src/components/CustomButtons";
import { SuccessSnackbar } from "src/slices/Snackbar";
import { Check } from "@mui/icons-material";
import CopyFileIcon from "src/assets/icons/copyIcon";
import { LoadingButton } from "@mui/lab";
import moment from "moment";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function KeyAfterLoading({ defaultOpen }: any) {
  const [open, setOpen] = React.useState(defaultOpen);
  const { keyDetails } = useSelector((state: RootState) => state.apiKeys);
  const dispatch = useDispatch();
  const [copyID, setCopyID] = useState("copy");
  const [copyKey, setCopyKey] = useState("copy");

  const handleClose = () => {
    dispatch(keyDetailsDataFn(false));
    setOpen(false);
  };

  const handleCopySecretKey = () => {
    setCopyKey("copying");
    setTimeout(() => {
      setCopyKey("copied");
      dispatch(
        SuccessSnackbar({
          message: "Secret key is copied to the clipboard!",
          open: true,
        })
      );
    }, 1000);
    setTimeout(() => {
      setCopyKey("copy");
    }, 2000);
  };
  const handleCopyAppId = () => {
    setCopyID("copying");
    setTimeout(() => {
      setCopyID("copied");
      dispatch(
        SuccessSnackbar({
          message: "Application ID is copied to the clipboard!",
          open: true,
        })
      );
    }, 1000);
    setTimeout(() => {
      setCopyID("copy");
    }, 2000);
  };

  const exportData = () => {
    const value = {
      name: keyDetails[0]?.name,
      expiry: keyDetails[0]?.expiry,
      appID: keyDetails[0]?.appId,
      secretKey: keyDetails[0]?.appSecret,
    };
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(value)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${keyDetails[0]?.appId}.json`;
    link.click();
  };
  const home = false;

  return (
    <>
      {home ? (
        <Home />
      ) : (
        <Box sx={{ mt: 2, mb: 2, ml: 2, mr: 2 }}>
          <CustomDialogBox
            open={open}
            setOpen={handleClose}
            background={"transparent"}
            closeIconId="close-view-resource-btn"
            width={{
              xs: "300px",
              sm: "400px",
              md: "450px",
              lg: "450px",
              xl: "500px",
            }}
            height={{
              xs: "390px",
              sm: "390px",
              md: "390px",
              lg: "390px",
              xl: "400px",
            }}
            childrens={
              <>
                <Typography
                  sx={{
                    color: "#3086EC",
                    m: 0,
                    p: "16px 16px 0 16px",
                    fontSize: "16px",
                  }}
                >
                  Key details
                </Typography>
                <Divider sx={{ width: "100%" }} />
                <DialogContent>
                  <Grid container>
                    <Grid
                      md={12}
                      xs={12}
                      lg={12}
                      sx={{ mt: 0, display: "flex" }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Name:&nbsp;
                      </Typography>
                      {keyDetails[0]?.name ? (
                        <Typography
                          sx={{
                            maxWidth: { xs: "200px", sm: "300px" },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {keyDetails[0]?.name}
                        </Typography>
                      ) : (
                        <Skeleton
                          sx={{ mt: -0.5, width: { lg: 140, xs: 100 } }}
                          variant="text"
                          animation="wave"
                          height={30}
                        // width={140}
                        // width="40%"

                        />
                      )}
                    </Grid>

                    <Grid
                      md={12}
                      xs={12}
                      lg={12}
                      sx={{ mt: 2, display: "flex" }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Expiry:&nbsp;
                      </Typography>
                      {keyDetails[0]?.name ? (
                        <Typography>
                          {keyDetails[0]?.expiry
                            ? moment(keyDetails[0]?.expiry).format(
                              "DD MMM YYYY"
                            )
                            : "Indefinite"}
                        </Typography>
                      ) : (
                        <Skeleton
                          sx={{ mt: -0.5, ml: 0.2, width: { lg: 140, xs: 100 } }}
                          variant="text"
                          animation="wave"
                          height={32}
                        // width={140}
                        // width="40%"
                        />
                      )}
                    </Grid>

                    <Grid md={12} xs={12} lg={12} sx={{ mt: 2 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Application ID
                      </Typography>
                      {keyDetails[0]?.appId ? (
                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: false }}
                          value={keyDetails[0]?.appId}
                          fullWidth
                          inputProps={{ readOnly: true }}
                          label=""
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {copyID === "copying" && (
                                  <CircularProgress
                                    size={20}
                                    sx={{ color: "#686868" }}
                                  />
                                )}
                                {copyID === "copied" && (
                                  <Check
                                    sx={{
                                      height: "20px",
                                      width: "20px",
                                      color: "green",
                                    }}
                                  />
                                )}
                                {copyID === "copy" && (
                                  <CopyToClipboard
                                    text={keyDetails[0]?.appId}
                                    onCopy={handleCopyAppId}
                                  >
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        transform: "translateY(3px)",
                                      }}
                                    >
                                      <CopyFileIcon
                                        props={{
                                          height: "25px",
                                          width: "25px",
                                        }}
                                      />
                                    </div>
                                  </CopyToClipboard>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <Skeleton
                          sx={{ mt: 1, borderRadius: "8px", width: { lg: 400, xs: "100%" } }}
                          variant="rectangular"
                          animation="wave"
                          height={26}
                        // width={400}
                        // width="100%"
                        />
                      )}
                    </Grid>

                    <Grid md={12} xs={12} lg={12} sx={{ mt: 2 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Secret Key
                      </Typography>
                      {keyDetails[0]?.appSecret ? (
                        <TextField
                          variant="outlined"
                          value={keyDetails[0]?.appSecret}
                          size="small"
                          type="password"
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: false }}
                          fullWidth
                          label=""
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {copyKey === "copying" && (
                                  <CircularProgress
                                    size={20}
                                    sx={{ color: "#686868" }}
                                  />
                                )}
                                {copyKey === "copied" && (
                                  <Check
                                    sx={{
                                      height: "20px",
                                      width: "20px",
                                      color: "green",
                                    }}
                                  />
                                )}
                                {copyKey === "copy" && (
                                  <CopyToClipboard
                                    text={keyDetails[0]?.appSecret}
                                    onCopy={handleCopySecretKey}
                                  >
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        transform: "translateY(3px)",
                                      }}
                                    >
                                      <CopyFileIcon
                                        props={{
                                          height: "25px",
                                          width: "25px",
                                        }}
                                      />
                                    </div>
                                  </CopyToClipboard>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <Skeleton
                          sx={{ mt: 1, borderRadius: "8px", width: { lg: 400, xs: "100%" } }}
                          variant="rectangular"
                          animation="wave"
                          height={26}
                        // width={400}
                        // width="100%"
                        />
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>

                <Box sx={{ m: 2, width: "93%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Typography fontSize="12px" color="warning">
                      Please make sure you save a copy of secret key
                    </Typography>
                    <Box>
                      {keyDetails[0]?.name ? (
                        <ButtonPrimary
                          id="download"
                          responsive={false}
                          onClick={exportData}
                        >
                          Download
                        </ButtonPrimary>
                      ) : (
                        <LoadingButton loading variant="outlined">
                          Download
                        </LoadingButton>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            }
          />
        </Box>
      )}
    </>
  );
}

export default KeyAfterLoading;
