import { Box, Card, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


export const HeadingWrapper = styled(Box)(() => ({
 textAlign: 'left',
}));

export const CustomGrid = styled(Grid)(() => ({
 display: 'flex',
 justifyContent: 'center',
 alignItems: 'center',

}));

export const HeadingTitle = styled(Typography)(({ theme }) => ({
 fontSize: '30px',
 fontWeight: 600,
 margin:'20px 0',
 [theme.breakpoints.down('md')]: {
  fontSize: '24px',
 },
 [theme.breakpoints.down('sm')]: {
  fontSize: '20px',
  textAlign: 'center',
  margin:'10px 0',
 },
}));

export const HeadingSubTitle = styled(Typography)(({ theme }) => ({
 fontSize: '14px',
 fontWeight: 400,
 color: '#B2B2B2',
 [theme.breakpoints.down('md')]: {
  fontSize: '13px',
 },
 [theme.breakpoints.down('sm')]: {
  fontSize: '12px',
  textAlign: 'center'
 },
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
 marginTop: '40px',
 marginBottom: '20px',
 display: 'flex',
 flexDirection: 'row',
 gap: '15px',
 [theme.breakpoints.down('sm')]: {
  marginBottom: '5px',
  justifyContent: 'center'
 },
}));

export const BannerContainer = styled(Box)(({ theme }) => ({
 height: '200px',
 padding: '10px',
 width: '100%',
 display: 'flex',
 justifyContent: 'center',
 alignItems: 'center',
 flexDirection: 'column',
 gap: '10px',
 background: 'linear-gradient(91.56deg, #1A75FF 0%, rgba(71, 255, 26, 0.57) 100%)',
 color: 'white',
 margin:'30px 0',
 [theme.breakpoints.down('md')]: {
  height: 'auto',
 },
}));

export const BannerHeading = styled(Typography)(({ theme }) => ({
 fontSize: '22px',
 fontWeight: 600,
 textAlign: 'center',
 color: '#ffffff',
 [theme.breakpoints.down('md')]: {
  fontSize: '18px',
 },
 [theme.breakpoints.down('sm')]: {
  fontSize: '16px',
 },
}));

export const BannerSubHeading = styled(Typography)(({ theme }) => ({
 fontSize: '14px',
 fontWeight: 400,
 textAlign: 'center',
 color: '#ffffff',
 marginBottom: '10px',
 [theme.breakpoints.down('md')]: {
  fontSize: '13px'
 },
 [theme.breakpoints.down('sm')]: {
  fontSize: '12px',
 },
}));

export const FeatureGridContainerRow = styled(Grid)(({ theme }) => ({
 margin: 'auto',
 display: 'flex',
 flexDirection: 'row',
 justifyContent:'space-between',
 alignItems:'center',
 [theme.breakpoints.down('sm')]: {
  flexDirection: 'column-reverse',
  marginBottom:'20px',
  padding:'0 10px'
 },
}));

export const FeatureGridContainerRowReverse = styled(Grid)(({ theme }) => ({
 margin: 'auto',
 display: 'flex',
 flexDirection: 'row-reverse',
 justifyContent:'space-between',
 alignItems:'center',
 [theme.breakpoints.down('sm')]: {
  flexDirection: 'column-reverse',
  marginBottom:'20px',
  padding:'0 10px'
 },
}));

export const FeatureHeading = styled(Typography)(({ theme }) => ({
 fontSize: '22px',
 fontWeight: 600,
 marginBottom: '15px',
 [theme.breakpoints.down('md')]: {
  fontSize: '18px',
 },
 [theme.breakpoints.down('sm')]: {
  fontSize: '16px',
 }
}));

export const FeatureContent = styled(Typography)(({theme}) => ({
 fontSize: '16px',
 fontWeight: 400,
 color: '#3F3D56',
 [theme.breakpoints.down('md')]: {
  fontSize: '13px',
 },
 [theme.breakpoints.down('sm')]: {
  fontSize: '12px',
 }
}));

export const FeatureWrapper = styled(Box)(({ theme }) => ({
 display: 'flex',
 flexDirection: 'column',
 justifyContent: 'start',
 alignItems: 'start',
 [theme.breakpoints.down('sm')]: {
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
 }
}));

// Pricing Plans Card
export const PlansContainer = styled(Box)(({theme}) => ({
 display: 'flex',
 flexDirection: 'column',
 justifyContent: 'center',
 alignItems: 'center',
 margin:"45px 20px 0 20px",
 [theme.breakpoints.down('md')]: {
  margin:"30px 30px 0 30px",
},
 [theme.breakpoints.down('sm')]: {
  margin:"10px 10px 0 10px",
},
}));

export const CustomInfoCard = styled(Card)(() => ({
 color: 'black',
 padding: '20px 20px',
 boxShadow: '0px 0px 80px rgba(91, 157, 255, 0.15)',
 borderRadius: '8px',
 height: '235px',
}));

export const IconContainer = styled(Box)(() => ({
 textAlign: 'center',
 color: '#686868',
}));

export const IntroHeading = styled(Typography)(() => ({
 fontSize: '20px',
 fontWeight: 600,
 textAlign: 'center',
}));

export const IntroSubHeading = styled(Typography)(({ theme }) => ({
 fontSize: '14px',
 fontWeight: 400,
 textAlign: 'center',
 color: '#686868',
 [theme.breakpoints.down('md')]: {
     fontSize: '12px',
 },
 [theme.breakpoints.down('sm')]: {
     fontSize: '12px',
 },
}));

export const UseCount = styled(Typography)(({ theme }) => ({
 fontSize: '20px',
 fontWeight: 600,
 textAlign: 'center',
 color: '#212121',
}));

export const CustomBox = styled(Box)(() => ({
 textAlign: 'center',
 marginBottom: '5px',
}));

// Contact Card

export const ContactCard = styled(Card)({
 width: "100%",
 marginBottom: "8vh",
 height: "100%",
 padding: "5%",
 boxShadow: "0 2px 30px 0 rgb(3 37 103 / 8%)",
 boxSizing: "border-box",
});