import React from "react";
function NotifyIcon({props}) {
    return (
    <>
        <svg
         width={props.width}
         height={props.height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#DDEFFF"/>
<path d="M28.1562 26.9375L27.5 25.9062C27.3438 25.6875 27.2813 25.4688 27.2813 25.2188V18.5C27.2813 16.6562 26.5 14.9062 25.0625 13.625C23.8438 12.5313 22.25 11.875 20.5625 11.75V10.9375C20.5625 10.625 20.3125 10.375 20 10.375C19.6875 10.375 19.4375 10.625 19.4375 10.9375V11.7188C19.3125 11.7188 19.1875 11.75 19.0625 11.75C15.4375 12.1562 12.6563 15.125 12.6563 18.5938V25.2188C12.625 25.5 12.5625 25.7188 12.4688 25.875L11.8438 26.9375C11.6875 27.2188 11.6875 27.5312 11.8438 27.7812C12 28.0312 12.25 28.1875 12.5625 28.1875H19.4375V29.0625C19.4375 29.375 19.6875 29.625 20 29.625C20.3125 29.625 20.5625 29.375 20.5625 29.0625V28.1875H27.4375C27.75 28.1875 28 28.0312 28.1562 27.7812C28.3125 27.5 28.3125 27.1875 28.1562 26.9375ZM13.0312 27.0938L13.4375 26.4375C13.5938 26.125 13.7188 25.7812 13.75 25.3437V18.625C13.75 15.6875 16.0938 13.2188 19.1875 12.875C21.0938 12.6562 22.9375 13.25 24.3125 14.4688C25.5313 15.5625 26.1875 17 26.1875 18.5312V25.2812C26.1875 25.7188 26.3125 26.1562 26.5625 26.5625L26.9375 27.1562H13.0312V27.0938Z" fill="#0062FD"/>
</svg>
        </>
            );
};
export default NotifyIcon;
