import React from 'react';
const CalendarIcon = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.15912 9.52148H4.29355C4.13617 9.52148 4.03125 9.6264 4.03125 9.78378V10.6231C4.03125 10.7805 4.13617 10.8854 4.29355 10.8854H5.15912C5.3165 10.8854 5.42141 10.7805 5.42141 10.6231V9.78378C5.42141 9.6264 5.3165 9.52148 5.15912 9.52148Z" fill="#686868" />
            <path d="M7.93842 9.52148H7.07284C6.91546 9.52148 6.81055 9.6264 6.81055 9.78378V10.6231C6.81055 10.7805 6.91546 10.8854 7.07284 10.8854H7.93842C8.09579 10.8854 8.20071 10.7805 8.20071 10.6231V9.78378C8.20071 9.6264 8.06956 9.52148 7.93842 9.52148Z" fill="#686868" />
            <path d="M10.6923 9.52148H9.82675C9.66937 9.52148 9.56445 9.6264 9.56445 9.78378V10.6231C9.56445 10.7805 9.66937 10.8854 9.82675 10.8854H10.6923C10.8497 10.8854 10.9546 10.7805 10.9546 10.6231V9.78378C10.9546 9.6264 10.8497 9.52148 10.6923 9.52148Z" fill="#686868" />
            <path d="M13.4736 9.52148H12.608C12.4506 9.52148 12.3457 9.6264 12.3457 9.78378V10.6231C12.3457 10.7805 12.4506 10.8854 12.608 10.8854H13.4736C13.6309 10.8854 13.7359 10.7805 13.7359 10.6231V9.78378C13.7359 9.6264 13.6047 9.52148 13.4736 9.52148Z" fill="#686868" />
            <path d="M5.15912 12.249H4.29355C4.13617 12.249 4.03125 12.3539 4.03125 12.5113V13.3507C4.03125 13.508 4.13617 13.613 4.29355 13.613H5.15912C5.3165 13.613 5.42141 13.508 5.42141 13.3507V12.5113C5.42141 12.3539 5.3165 12.249 5.15912 12.249Z" fill="#686868" />
            <path d="M7.93842 12.249H7.07284C6.91546 12.249 6.81055 12.3539 6.81055 12.5113V13.3507C6.81055 13.508 6.91546 13.613 7.07284 13.613H7.93842C8.09579 13.613 8.20071 13.508 8.20071 13.3507V12.5113C8.20071 12.3539 8.06956 12.249 7.93842 12.249Z" fill="#686868" />
            <path d="M10.6923 12.249H9.82675C9.66937 12.249 9.56445 12.3539 9.56445 12.5113V13.3507C9.56445 13.508 9.66937 13.613 9.82675 13.613H10.6923C10.8497 13.613 10.9546 13.508 10.9546 13.3507V12.5113C10.9546 12.3539 10.8497 12.249 10.6923 12.249Z" fill="#686868" />
            <path d="M13.4736 12.249H12.608C12.4506 12.249 12.3457 12.3539 12.3457 12.5113V13.3507C12.3457 13.508 12.4506 13.613 12.608 13.613H13.4736C13.6309 13.613 13.7359 13.508 13.7359 13.3507V12.5113C13.7359 12.3539 13.6047 12.249 13.4736 12.249Z" fill="#686868" />
            <path d="M15.4402 3.69836H9.27625V2.83279C9.87953 2.67541 10.3517 2.09836 10.3517 1.44262C10.3517 0.655738 9.69593 0 8.88281 0C8.0697 0 7.41396 0.655738 7.41396 1.44262C7.41396 2.09836 7.85986 2.64918 8.48937 2.83279V3.69836H2.32544C1.53855 3.69836 0.882812 4.3541 0.882812 5.14098V14.5574C0.882812 15.3443 1.53855 16 2.32544 16H15.4402C16.2271 16 16.8828 15.3443 16.8828 14.5574V5.14098C16.8828 4.3541 16.2271 3.69836 15.4402 3.69836ZM8.20084 1.44262C8.20084 1.07541 8.48937 0.786885 8.88281 0.786885C9.25002 0.786885 9.56478 1.07541 9.56478 1.44262C9.56478 1.80984 9.27625 2.09836 8.88281 2.09836C8.5156 2.09836 8.20084 1.78361 8.20084 1.44262ZM2.32544 4.48525H15.4402C15.8074 4.48525 16.0959 4.77377 16.0959 5.14098V7.08197H1.6697V5.14098C1.6697 4.77377 1.95822 4.48525 2.32544 4.48525ZM15.4402 15.2131H2.32544C1.95822 15.2131 1.6697 14.9246 1.6697 14.5574V7.86885H16.0959V14.5574C16.0959 14.9246 15.8074 15.2131 15.4402 15.2131Z" fill="#686868" />
        </svg>

    );
};


export default CalendarIcon;