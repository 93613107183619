import { Box, Card, Grid, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

//Custom slider
export const PrettoSlider = styled(Slider)({
    color: '#3687EC',
    height: 5,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 14,
        width: 14,
        backgroundColor: '#fff',
        border: '2px solid currentcolor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-rail': {
        backgroundColor: 'grey',
    },
});

export const MainContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '20px',
}));

export const CustomGrid = styled(Grid)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const CustomBillingInfoCard = styled(Card)(() => ({
    color: 'black',
    boxShadow: '0px 0px 80px rgba(91, 157, 255, 0.15)',
    borderRadius: '6px',
    padding: '10px 30px',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

export const CustomBox = styled(Box)(() => ({
    textAlign: 'center',
    marginBottom: '5px',
}));
export const HeadingTitle = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontWeight: 600,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
    },
}));

export const IntroHeading = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'center',
    height: '30px'
}));

export const IntroSubHeading = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
    color: '#686868',
    height: '75px',
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    },
}));

export const IconContainer = styled(Box)(() => ({
    textAlign: 'center',
    color: '#686868',
    height: '60px'
}));

export const Heading = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'center',
}));

export const SubHeading = styled(Typography)(({theme}) => ({
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    },
}));

export const SmallText = styled(Typography)(({theme}) => ({
    fontSize: '12px',
    fontWeight: 300,
    marginBottom: '10px',
    textAlign: 'left',
    color: '#373737',
    [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
    },
}));

export const TermsText = styled(Typography)(({theme}) => ({
    fontSize: '10px',
    fontWeight: 300,
    textAlign: 'left',
    color: '#373737',
    [theme.breakpoints.down('sm')]: {
        fontSize: '8px',
    },
}));

export const CustomInfoCard = styled(Card)(() => ({
    color: 'black',
    padding: '10px 10px',
    boxShadow: '0px 0px 80px rgba(91, 157, 255, 0.15)',
    borderRadius: '6px',
    height: '255px',
}));

export const BlurBoxContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    backdropFilter: 'blur(7px)',
    boxShadow: '0px 0px 80px rgba(91, 157, 255, 0.15)',
    borderRadius: '6px',
}));

export const CustomEstimationCard = styled(Card)(() => ({
    color: 'black',
    padding: '20px 25px',
    height: '100%',
    boxShadow: '0px 0px 80px rgba(91, 157, 255, 0.15)',
    borderRadius: '6px',
}));

export const Title = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '15px'
}));

export const AmountText = styled(Typography)(() => ({
    fontSize: '24px',
    fontWeight: 400,
}));

export const BannerContainer = styled(Box)(({ theme }) => ({
    height: '200px',
    padding: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
    background: 'linear-gradient(91.56deg, #1A75FF 0%, rgba(71, 255, 26, 0.57) 100%)',
    color: 'white',
    [theme.breakpoints.down('md')]: {
        height: 'auto',
    },
}));

export const BannerHeading = styled(Typography)(({ theme }) => ({
    fontSize: '24px',
    fontWeight: 600,
    textAlign: 'center',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
    },
}));

export const BannerSubHeading = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
    color: '#ffffff',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: {
        fontSize: '12px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    },
}));

export const FaqContainer = styled(Box)(({ theme }) => ({
    border: '1px solid #F1F5FD',
    borderRadius: '8px',
    textAlign: 'left',
    background: '#F1F5FD',
    height: '400px',
    padding: '20px 30px',
    overflow: 'scroll',
    [theme.breakpoints.down('sm')]: {
        padding: '10px',
        height: '300px',
    },
}));

export const FaqTitle = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: '600',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    },
}));


export const FaqContent = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
    },
}));
