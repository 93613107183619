import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
// import { SuccessSnackbar, FailureSnackbar } from "src/slices/Snackbar";

//User webhook hits Data
const WebhookHits = [
    {
        name: "REGISTERED",
        count: 0,
        color: "#33C2FF"
    },
    {
        name: "HIT SUCCESS",
        count: 0,
        color: "#44D600",
    },
    {
        name: "HIT FAILURE",
        count: 0,
        color: "#FF1A43",
    },
    {
        name: "DISABLED",
        count: 0,
        color: "#FFA31A",
    },
];

//No of application Data
const application = [
    {
        name: "CONFERENCES",
        count: 0,
        color: "#44D600",
    },
    {
        name: "RECORDINGS",
        count: 0,
        color: "#FF1A43",
    },
    {
        name: "USERS",
        count: 0,
        color: "#33C2FF",
    },
];

interface WebhookDetails {
    name: string;
    count: number;
    color: string;
}

interface applicationDetails {
    name: string;
    count: number;
    color: string;
}
interface getKeyDetails {
    name: string;
    appId: string;
}
interface overview {
    dashboardWebhookCount: WebhookDetails[];
    dashboardApplicationCount: applicationDetails[];
    pageLoader: boolean;
    appDetails: getKeyDetails[];
    appIdSearchData: {
        appId: string,
        name: string,
      },
}
const initialState: overview = {
    dashboardWebhookCount: WebhookHits,
    dashboardApplicationCount: application,
    pageLoader: false,
    appDetails: [],
    appIdSearchData: {
        appId: "",
        name: "",
      },
};
const slice = createSlice({
    name: "overview",
    initialState,
    reducers: {
        setUserWebhookDetails(state, action) {
            state.dashboardWebhookCount = action.payload;
        },
        setUserApplicationDetails(state, action) {
            state.dashboardApplicationCount = action.payload;
        },
        setPageLoader(state, action) {
            state.pageLoader = action.payload;
        },
        appDetails(state: any, action) {
            state.appDetails = action.payload;
        },
        searchAppIdDetails(state, action) {
            state.appIdSearchData = action.payload;
          },
    },
});

export const DashboardSummary = slice.actions;
const BASE_URL = process.env.REACT_APP_API_URL;

//User webhook Details
export const getWebhookHits = (appId) => async (dispatch) => {
    dispatch(slice.actions.setPageLoader(true));
    try {
        let res: any = "";
        if (appId) {
            res = await axios.get(`${BASE_URL}/api/v1/webhooks/summary?app_id=${appId}`)
        } else {
            res = await axios.get(`${BASE_URL}/api/v1/webhooks/summary`)
        }
        if (res.data.status) {
            let list = [];
            WebhookHits.map((item) => (
                list.push({
                    name: item.name,
                    count:
                        item.name === "REGISTERED"
                            ? res.data.data.registered
                            : item.name === "HIT SUCCESS"
                                ? res.data.data?.hit_success
                                : item.name === "HIT FAILURE"
                                    ? res.data.data?.hit_failure
                                    : res.data.data?.disabled,
                    color: item.color,
                })
            ))
            dispatch(slice.actions.setUserWebhookDetails(list));
        }
        else {
            throw new Error(res?.data?.message);
        }
    } catch (error) {
        throw new Error(error);
    } finally {
        dispatch(slice.actions.setPageLoader(false));
    }
};

//Application count Details
export const getApplicationCount = (appId) => async (dispatch) => {
    try {
        let res: any = "";
        if (appId) {
            res = await axios.get(`${BASE_URL}/api/v1/application/summary?app_id=${appId}`)
        } else {
            res = await axios.get(`${BASE_URL}/api/v1/application/summary`)
        }
        if (res.data.status) {
            let list = [];
            application.map((item) => (
                list.push({
                    name: item.name,
                    count:
                        item.name === "CONFERENCES"
                            ? res.data.data.conference_room
                            : item.name === "RECORDINGS"
                                ? res.data.data?.recordings
                                : res.data.data?.users,
                    color: item.color,
                })
            ))
            dispatch(slice.actions.setUserApplicationDetails(list));
        }
        else {
            throw new Error(res?.data?.message);
        }
    } catch (error) {
        throw new Error(error);
    }
};

export const getApplicationDetails = () => async (dispatch) => {
    try {
        const responseData = await axios.get(
            `${BASE_URL}/api/v1/application/`
        );
        if (responseData.data?.status) {
            const idValue = [];
            for (const value of responseData.data.data) {
                idValue.push({
                    id: value.id,
                    name: value.name
                })
            }
            dispatch(slice.actions.appDetails(idValue));
        }
        if (!responseData.data?.status) {
            throw new Error(responseData.data?.message);
        }
        dispatch(slice.actions.appDetails(responseData.data.data));
    } catch (error) {
        throw new Error(error);
    }
};

export const reducer = slice.reducer;
export default slice;
