import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
// import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import KeyAfterLoading from "./keyAfterLoading";
import { useSelector, useDispatch } from "react-redux";
import {
  editApiKeys,
  postEditApiKeys,
  editTableData2,
  keyDetailsDataFn,
} from "../../slices/apiKeys";
import { RootState } from "../../store";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import Table from "./table";
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import { format } from 'date-fns';
import moment from "moment";
import CustomDialogBox from "src/components/CustomDialog";
import Loader from "src/components/Loader/Loader";
import IconButton from "@mui/material/IconButton";
import { Divider, Hidden, InputAdornment } from "@mui/material";
import CalendarIcon from "src/assets/icons/calendarIcon";
import { ButtonPrimary } from "src/components/CustomButtons";
import APIKeyIcon from "src/assets/icons/apiKeyIcon";
import { FailureSnackbar } from "src/slices/Snackbar";
import ContentWrapper from "src/components/ContentWrapper";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
}

function Home() {
  const {
    addApiKeys,
    tableData,
    keyDetailsData,
    pageLoader,
    meta,
    tableLoader,
  } = useSelector((state: RootState) => state.apiKeys);
  const dispatch = useDispatch();
  const [openDailogAddApiKey, setOpenDailogAddApiKey] = React.useState(false);
  const theme = useTheme();
  const [progress, setProgress] = React.useState(false);
  const [openFromDate, setOpenFromDate] = useState<boolean>(false);

  /*eslint-disable*/

  React.useEffect(() => {
    if (keyDetailsData) {
      const data = {
        page: meta.page,
        per_page: meta.per_page,
      };
      // dispatch(editTableData1(data));
    }
  }, [keyDetailsData]);

  const onLoad = () => {
    if (addApiKeys.name.length < 3) {
      setNameValidation({
        value: true,
        text: "Please enter minimum 3 characters",
      });
      setTimeout(() => {
        setNameValidation({ value: false, text: "" });
      }, 3000);
    } else if (searchFilter?.length > 0) {
      setNameValidation({
        value: true,
        text: "application name is already exist!",
      });
      setTimeout(() => {
        setNameValidation({ value: false, text: "" });
      }, 3000);
    } else {
      dispatch(
        postEditApiKeys(addApiKeys, {
          page: meta.page,
          per_page: meta.per_page,
        })
      );
      dispatch(editApiKeys({ key: "name", value: "" }));
      dispatch(editApiKeys({ key: "expiry", value: "" }));
      dispatch(keyDetailsDataFn(true));
      setOpenDailogAddApiKey(false);
    }
  };

  const handleClickOpen = () => {
    dispatch(editApiKeys({ key: "name", value: "" }));
    dispatch(editApiKeys({ key: "expiry", value: "" }));
    setNameValidation({ value: false, text: "" });
    setOpenDailogAddApiKey(true);
  };

  const handleClose = () => {
    setOpenDailogAddApiKey(false);
  };

  const [nameValidation, setNameValidation] = React.useState({
    value: false,
    text: "",
  });
  const searchFilter = tableData?.filter(function (data) {
    if (data.name === addApiKeys.name) {
      return data.name;
    } else {
      return null;
    }
  });

  const handleChangeGenerateNameKeys = (e: any) => {
    const data = { key: e.target.name, value: e.target.value };
    dispatch(editApiKeys(data));
  };

  const handleChangeGenerateExpiryKeys = (newValue) => {
    if (!(newValue instanceof Date && !isNaN(newValue.valueOf()))) return;
    const date = moment(newValue).format("YYYY-MM-DD");

    const currentDate = new Date().toLocaleDateString("en-CA");

    if (currentDate < date) {
      const formatedDate = new Date(date).toISOString();
      const data = { key: "expiry", value: formatedDate };
      dispatch(editApiKeys(data));
    } else {
      dispatch(
        FailureSnackbar({ message: "Please enter a valid date", open: true })
      );
    }
  };

  const handleClear = () => {
    dispatch(
      editApiKeys({
        key: "expiry",
        value: "",
      })
    );
  };

  React.useEffect(() => {
    if (progress) {
      setTimeout(() => {
        setProgress(false);
      }, 2000);
    }
  }, [progress]);

  React.useEffect(() => {
    dispatch(editTableData2({ page: 1, per_page: 5 }));
  }, [dispatch]);

  return (
    <>
      <ContentWrapper title="API Keys">
        {pageLoader ? (
          <Box sx={{ height: "90vh" }}>
            <Loader />
          </Box>
        ) : (
          <>
            <Box>
              <Hidden smDown>
                <Grid
                  container
                  className="apikey_header justify_space_between align_center"
                  sx={{ p: "15px" }}
                >
                  <Grid item xs={12} sm={9} md={8}>
                    <ThemeProvider theme={theme}>
                      <Box display="flex" flexDirection="column">
                        <Typography
                          className="align_start apikey_heading"
                          sx={{ color: "#3086EC" }}
                        >
                          API Keys
                        </Typography>
                        <Typography
                          color="#808080"
                          className="align_start apikey_subheading"
                        >
                          Creating API keys for generating access tokens for
                          your application
                        </Typography>
                      </Box>
                    </ThemeProvider>
                  </Grid>
                  {tableData?.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={4}
                      className="align_center justify_end"
                    >
                      <ButtonPrimary
                        id="create-api-keys"
                        responsive={false}
                        onClick={handleClickOpen}
                        fixedHeight="40px"
                        fixedWidth="170px"
                      >
                        <AddIcon fontSize="small" />
                        Create API keys
                      </ButtonPrimary>
                    </Grid>
                  )}
                </Grid>
              </Hidden>
              <div className="justify_center apikey_list_container">
                <Grid
                  container
                  className="apikey_header justify_space_between align_center"
                  sx={{ pl: "10px", pr: "10px", pt: "10px" }}
                >
                  <Grid item xs={8} sm={9} md={8}>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        className="align_start apikey_heading"
                        sx={{ color: "#3086EC" }}
                      >
                        API Keys
                      </Typography>
                      <Typography
                        color="#808080"
                        className="align_start apikey_subheading"
                      >
                        Creating API keys for generating access tokens for your
                        application
                      </Typography>
                    </Box>
                  </Grid>
                  {tableData?.length > 0 && (
                    <Grid
                      item
                      xs={3.3}
                      sm={3}
                      md={4}
                      className="align_center justify_end"
                    >
                      <ButtonPrimary
                        id="create-api-keys"
                        responsive={false}
                        onClick={handleClickOpen}
                        fixedHeight="40px"
                        fixedWidth="170px"
                      >
                        <AddIcon fontSize="small" />
                        Add
                      </ButtonPrimary>
                    </Grid>
                  )}
                </Grid>
              </div>
              {!tableData || tableData?.length === 0 ? (
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 13, mb: 2 }}
                >
                  {tableLoader ? (
                    <Box sx={{ width: "100%", height: "57vh" }}>
                      <Loader />
                    </Box>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      sx={{
                        width: "100%",
                        height: "40vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <APIKeyIcon props={{ height: "100%", width: "20vh" }} />
                      </Box>

                      <Box
                        className="align_center justify_end"
                        sx={{ mt: "8px" }}
                      >
                        <ButtonPrimary
                          id="create-api-keys"
                          responsive={false}
                          onClick={handleClickOpen}
                          fixedHeight="40px"
                          fixedWidth="170px"
                        >
                          <AddIcon fontSize="small" />
                          Create API keys
                        </ButtonPrimary>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            marginTop: "20px",
                          }}
                        >
                          Learn how to setup your first API key
                        </Typography>
                      </Box>
                      <Box>
                        {/* <ArticleTwoToneIcon color="primary" /> */}
                        <Link
                          href="http://www.africau.edu/images/default/sample.pdf"
                          target="_blank"
                          underline="none"
                        >
                          <Typography sx={{ color: "#3087EC" }}>
                            Check the documentation
                          </Typography>
                        </Link>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Table />
              )}
              <div>
                {progress === false ? (
                  <CustomDialogBox
                    open={openDailogAddApiKey}
                    setOpen={handleClose}
                    background={"transparent"}
                    closeIconId="close-view-resource-btn"
                    width={{
                      xs: "300px",
                      sm: "400px",
                      md: "450px",
                      lg: "450px",
                      xl: "500px",
                    }}
                    height={{
                      xs: "300px",
                      sm: "300px",
                      md: "300px",
                      lg: "300px",
                      xl: "350px",
                    }}
                    childrens={
                      <>
                        <Typography
                          sx={{
                            color: "#3086EC",
                            m: 0,
                            p: "16px 16px 0 16px",
                            fontSize: "16px",
                          }}
                        >
                          Add API keys
                        </Typography>
                        <Divider sx={{ width: "100%" }} />
                        <DialogContent sx={{ width: "100%" }}>
                          <Grid container>
                            <Grid item md={12} xs={12} lg={12} sx={{ mt: 0 }}>
                              <Typography>Name</Typography>
                              <TextField
                                fullWidth
                                size="small"
                                id="outlined-basic"
                                label=""
                                name="name"
                                onChange={handleChangeGenerateNameKeys}
                                value={addApiKeys.name}
                                placeholder="Enter name here"
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                sx={{ width: "100%" }}
                              />
                              <Typography color="red">
                                {nameValidation.text?.length > 0 ? (
                                  <>{nameValidation.text}</>
                                ) : (
                                  ""
                                )}
                              </Typography>
                            </Grid>

                            <Grid item md={12} xs={12} lg={12} sx={{ mt: 2 }}>
                              <Typography>Expiry (optional)</Typography>

                              <DesktopDatePicker
                                open={openFromDate}
                                onOpen={() => setOpenFromDate(true)}
                                onClose={() => setOpenFromDate(false)}
                                inputFormat="dd MMM yyyy"
                                value={addApiKeys.expiry !== "" ? addApiKeys.expiry : null}
                                minDate={new Date().setDate(new Date().getDate() + 1)}
                                onChange={(date) => {
                                  handleChangeGenerateExpiryKeys(date);
                                  setOpenFromDate(false); // Close the calendar after selecting a date
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    color="primary"
                                    fullWidth
                                    size="small"
                                    {...params}
                                    placeholder="dd mmm yyyy"
                                    onClick={() => setOpenFromDate(true)}
                                    inputProps={{ readOnly: true }}
                                    value={addApiKeys.expiry !== "" ? moment(addApiKeys.expiry).format('DD MMM YYYY') : ""}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          {addApiKeys.expiry.length > 0 && (
                                            <IconButton
                                              size="small"
                                              disableRipple
                                              sx={{
                                                transform: "translateX(20px)",
                                              }}
                                              onClick={() => handleClear()}
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                          )}
                                          <IconButton
                                            disableRipple
                                            sx={{
                                              transform: "translateX(20px)",
                                            }}
                                            onClick={() => setOpenFromDate(true)} // Open the calendar when the icon is clicked
                                          >
                                            <CalendarIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                      onClick: () => setOpenFromDate(true), // Open the calendar when the text field is clicked
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>

                        <Box
                          sx={{
                            width: "96%",
                            display: "flex",
                            justifyContent: "end",
                            mb: "3%",
                          }}
                        >
                          <ButtonPrimary
                            id="generate"
                            responsive={false}
                            onClick={onLoad}
                          >
                            <AddIcon fontSize="small" />
                            Generate
                          </ButtonPrimary>
                        </Box>
                      </>
                    }
                  />
                ) : (
                  <Box
                    width="100%"
                    height="40%"
                    display="flex"
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </div>
            </Box>
            {keyDetailsData && <KeyAfterLoading defaultOpen={true} />}
          </>
        )}
      </ContentWrapper>
    </>
  );
}

export default Home;
