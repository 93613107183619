import { createSlice } from "@reduxjs/toolkit";
import { HomeDiscountIcon } from "src/assets/icons/homePageIcon";

const planRecords = [{
    icon: HomeDiscountIcon,
    subscription_type: "Dev",
    status:"Monthly active users",
    active_users: 25,
    subscription_plan: "Start for free"
},
{
    icon: HomeDiscountIcon,
    subscription_type: "Basic",
    status:"Monthly active users",
    active_users: 300,
    subscription_plan: "$99.00/month"
},
{
    icon: HomeDiscountIcon,
    subscription_type: "Standard",
    status:"Monthly active users",
    active_users: 1500,
    subscription_plan: "$499.00/month"
},
{
    icon: HomeDiscountIcon,
    subscription_type: "Enterprise",
    status:"Monthly active users",
    active_users: 3000,
    subscription_plan: "$999.00/month"
}];

const documentDetails = [
    {
        title: "Why Portego?",
        content: "Portego is an enterprise-ready video meeting platform that allows developers, organisations and businesses to easily build and deploy video solutions. With Portego we now give you all the power of Portego running on our global platform so you can focus on building secure and branded video experiences.Use personalized virtual desktops on cloud platform.",
        buttonleft: "Start now",
        buttonright: "Talk to our expert",
    },
];

interface HomePlanRecord {
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    subscription_type: string,
    status:string,
    active_users: number,
    subscription_plan: string
}

interface homeCoverDetail {
    title: string,
    content: string,
    buttonleft: string,
    buttonright: string,
}

interface HomePlans {
    homePlanRecords: HomePlanRecord[],
    homeCoverDetail: homeCoverDetail[],
    openSidebar: boolean
}

const initialState: HomePlans = {
    homePlanRecords: planRecords,
    homeCoverDetail: documentDetails,
    openSidebar: false,

};

const slice = createSlice({
    name: "HomePlanCards",
    initialState,

    reducers: {
        // Single Payload
        HomePlanListing(state, action) {
            state.homePlanRecords = action.payload
        },
        HomeCoverDetail(state, action) {
            state.homeCoverDetail = action.payload
        },
        OpenSidebar(state, action) {
            state.openSidebar = action.payload
        },

    },
});


export const SetOpenSidebar = (data) => async (dispatch) => {
    dispatch(slice.actions.OpenSidebar(data));
}

export const HomePlanCardAPI = () => async (dispatch) => {
    dispatch(slice.actions.HomePlanListing(planRecords));
}

export const HomeCoverDetailsBlock = () => async (dispatch) => {
    dispatch(slice.actions.HomeCoverDetail(documentDetails));
}

export const reducer = slice.reducer;

export default slice;