import React, { useState, useEffect } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

const ScrollTopArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box sx={{ position: 'fixed', bottom: '2rem', right: '2rem', zIndex: 999 }}>
      {showScroll && (
        <IconButton onClick={handleScrollTop} aria-label="scroll to top" sx={{ backgroundColor: '#2e3440', borderRadius: '50%', p: 1 }}>
          <ArrowUpwardIcon sx={{ color: '#3087EC' }} />
        </IconButton>
      )}
    </Box>
  );
};

export default ScrollTopArrow;
