import {
  Button,
  Dialog,
  Hidden,
  IconButton,
  List,
  Tooltip,
  Typography,
} from "@mui/material";
import { SideBarDrawer, DrawerContainer } from "./indexStyled";
import { Box } from "@mui/system";
//import useAuth from "src/hooks/useAuth";
import { NavLink, useNavigate } from "react-router-dom";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import APIKeySBIcon from "src/assets/icons/apiKeySBIcon";
import WebhookIcon from "@mui/icons-material/Webhook";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import Logo from "src/components/Logo";
import { SetOpenSidebar } from "src/slices/Home";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "src/assets/icons/CloseIcon";
import { BellContainer } from "src/components/CustomButtons";
import Bell from "src/assets/icons/Bell";
import { useEffect, useState } from "react";
import { getNotificationsData1 } from "src/slices/Notifications/Notification";
import { RootState } from "src/store";
import { setActivityTabs } from "src/slices/Conference/ConferenceTable";
import { useAuth0 } from "@auth0/auth0-react";

const SidebarMobile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const { logout } = useAuth();
  const { logout } = useAuth0();
  const [isUnread, setUnread] = useState<boolean>(false);
  const { notificationsData1 } = useSelector(
    (state: RootState) => state.notifications
  );
  const handleLogout = async (): Promise<void> => {
    // try {
    //   props.close();
    //   await logout()
    // } catch (err) {
    //   console.error(err)
    // }
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  const handleNotificationOpen = (): void => {
    setUnread(!isUnread);
  };
  const closeMyAccountDropDown = () => {
    props.close();
  };
  const closeSidebar = () => {
    dispatch(SetOpenSidebar(false));
  };

  useEffect(() => {
    dispatch(getNotificationsData1());
  }, [dispatch]);
  const handleViewAll = () => {
    navigate("/activity/notifications");
    dispatch(setActivityTabs("/activity/notifications"));
    setUnread(false);
  };

  const handleNotificationTime = (createdDate) => {
    const currentDate = new Date();
    const difference = currentDate.valueOf() - new Date(createdDate).valueOf();
    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);

    if (daysDifference > 0) {
      return daysDifference + " days ago";
    } else {
      return hoursDifference + " hours ago";
    }
  };
  return (
    <SideBarDrawer
      BackdropProps={{ invisible: false }}
      anchor="right"
      open={props.open}
    >
      <DrawerContainer>
        <Box
          sx={{
            height: "60px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Box>
            <Hidden smUp>
              <Logo />
            </Hidden>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <BellContainer onClick={handleNotificationOpen} id="notification">
                <IconButton>
                  <Bell
                    props={{
                      height: "40px",
                      width: "40px",
                    }}
                  />
                </IconButton>
              </BellContainer>
              <Dialog open={isUnread} onClose={() => setUnread(false)}>
                <Box
                  sx={{
                    zIndex: 1,
                    position: "fixed",
                    background: "white",
                    top: "6vh",
                    right: "40px",
                    borderRadius: "4px",
                    width: "280px",
                    // maxHeight: '280px',
                    minHeight: "30vh",
                    maxHeight: "88vh",
                    overflow: "scroll",
                  }}
                >
                  <Box
                    sx={{
                      p: 1,
                      color: "#3087EC",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      borderBottom: "1px solid #eee",
                      position: "sticky",
                      top: 0,
                      background: "white",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    Notifications
                  </Box>
                  {notificationsData1?.length === 0 && (
                    <Box
                      sx={{
                        p: 1,
                        fontFamily: "Poppins",
                        textAlign: "center",
                        background: "white",
                        fontSize: "12px",
                        fontWeight: 400,
                        height: "30vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Notifications Available
                    </Box>
                  )}

                  {notificationsData1?.map((notification, index) => (
                    <Box
                      key={notification?.id}
                      // onClick={() => handleNotificationClick(notification)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "10px",
                          borderBottom: "1px solid #eee",
                          cursor: "pointer",
                          background: "#f4f9fd",
                        }}
                      >
                        <Box></Box>
                        <Box sx={{ ml: "15px" }}>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "10px",
                              color: "#3087EC",
                            }}
                          >
                            {notification?.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "10px",
                            }}
                          >
                            {notification?.desc === ""
                              ? "Not available"
                              : notification?.desc}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "10px",
                              color: "#686868",
                            }}
                          >
                            {handleNotificationTime(notification?.createdDate)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}

                  {notificationsData1?.length > 0 && (
                    <Box
                      onClick={handleViewAll}
                      sx={{
                        height: "30px",
                        width: "100%",
                        position: "sticky",
                        bottom: 0,
                        background: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        // border: "1px solid green"
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontSize: "13px",
                          fontWeight: 500,
                          color: "#3807ec",
                        }}
                      >
                        View all
                      </Typography>
                    </Box>
                  )}
                </Box>{" "}
              </Dialog>
            </Box>
            <Box>
              <IconButton
                sx={{ color: "#3086EC", "&:hover": { color: "#3086EC" } }}
                color="primary"
                onClick={closeSidebar}
                id="close-sidebar"
              >
                <CloseIcon props={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <List
          className="sidebar_menu"
          sx={{
            p: 1,
            minWidth: 210,
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
          }}
          component="nav"
        >
          <Box sx={{ p: "10px" }}>
            <Typography sx={{ fontWeight: 600 }}>Home</Typography>
          </Box>
          <Button
            className="sideBarButton"
            onClick={closeMyAccountDropDown}
            to="/dashboard"
            component={NavLink}
            startIcon={<BarChartOutlinedIcon />}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "#373737",
              "&.active": {
                color: "#ffffff",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#3087EC",
              },
            }}
            id="overview"
          >
            Overview
          </Button>
          <Button
            className="sideBarButton"
            id="api-keys"
            onClick={closeMyAccountDropDown}
            to="/api-keys"
            component={NavLink}
            startIcon={<APIKeySBIcon />}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "#373737",
              "&.active": {
                color: "white",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#3087EC",
              },
            }}
          >
            Api Keys
          </Button>
          <Button
            className="sideBarButton"
            id="webhooks"
            onClick={closeMyAccountDropDown}
            to="/webhooks"
            component={NavLink}
            startIcon={<WebhookIcon />}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "#373737",
              "&.active": {
                color: "#ffffff",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#3087EC",
              },
            }}
          >
            Webhooks
          </Button>
          <Tooltip title="For Enterprise Users Only" arrow>
            <Button
              className="sideBarButton"
              //onClick={closeMyAccountDropDown}
              //   to="/recording"
              //   component={NavLink}
              startIcon={<RecordVoiceOverIcon />}
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                color: "#373737",
                "&.active": {
                  color: "#ffffff",
                  borderRadius: "6px",
                  fontWeight: 500,
                  backgroundColor: "#3087EC",
                },
              }}
            >
              Recording
            </Button>
          </Tooltip>
          <Button
            className="sideBarButton"
            id="activity"
            onClick={closeMyAccountDropDown}
            to="/activity/conference-report"
            component={NavLink}
            startIcon={<LocalActivityIcon />}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "#373737",
              "&.active": {
                color: "#ffffff",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#3087EC",
              },
            }}
          >
            Activity
          </Button>
          <Button
            className="sideBarButton"
            id="billing"
            onClick={closeMyAccountDropDown}
            to="/billing"
            component={NavLink}
            startIcon={<AccountBalanceTwoToneIcon />}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "#373737",
              "&.active": {
                color: "#ffffff",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#3087EC",
              },
            }}
          >
            Billing
          </Button>
          <Box sx={{ p: "10px" }}>
            <Typography sx={{ fontWeight: 600 }}>Documentation</Typography>
          </Box>
          <Button
            id="star-guide"
            className="sideBarButton"
            onClick={closeMyAccountDropDown}
            //   to="/docs"
            //   component={NavLink}
            startIcon={<TravelExploreIcon />}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "#373737",
              "&.active": {
                color: "#ffffff",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#3087EC",
              },
            }}
            disabled
          >
            Star Guide
          </Button>
          <Button
            id="meeting-works"
            className="sideBarButton"
            onClick={closeMyAccountDropDown}
            //   to="/docs"
            //   component={NavLink}
            startIcon={<MeetingRoomIcon />}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "#373737",
              "&.active": {
                color: "#ffffff",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#3087EC",
              },
            }}
            disabled
          >
            How Meeting Works
          </Button>
          <Button
            id="faq"
            className="sideBarButton"
            onClick={closeMyAccountDropDown}
            //   to="/docs"
            //   component={NavLink}
            startIcon={<LiveHelpIcon />}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "#373737",
              "&.active": {
                color: "#ffffff",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#3087EC",
              },
            }}
            disabled
          >
            FAQ
          </Button>
          <Button
            id="contact-support"
            className="sideBarButton"
            onClick={closeMyAccountDropDown}
            //   to="/docs"
            //   component={NavLink}
            startIcon={<ConnectWithoutContactIcon />}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "#373737",
              "&.active": {
                color: "#ffffff",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#3087EC",
              },
            }}
            disabled
          >
            Contact Support
          </Button>
          <Box
            sx={{ p: "10px" }}
            onClick={() => {
              navigate("/profile");
              closeMyAccountDropDown();
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>My Profile</Typography>
          </Box>
        </List>
        <Box sx={{ ml: 4, mr: 4, mt: 3 }} className="signOut">
          <Button
            fullWidth
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#373737",
              "&.active": {
                color: "#ffffff",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#3087EC",
              },
            }}
            onClick={handleLogout}
            id="signout-button"
            startIcon={<LockOpenTwoToneIcon sx={{ mr: 1 }} />}
          >
            {"Sign out"}
          </Button>
        </Box>
      </DrawerContainer>
    </SideBarDrawer>
  );
};

export default SidebarMobile;
