import React from 'react';
import { Box, Grid, Hidden, styled, Typography } from '@mui/material';
import TopCard from './topCard';
import { ButtonPrimary, ButtonSecondary } from 'src/components/CustomButtons';
import PricingCoverIcon from 'src/assets/icons/pricingCover';
import FAQIcon from 'src/assets/icons/faqIcon';
import FAQSection from './faqSection';
import { CustomGrid } from './pricingStyle';
// import { useNavigate } from 'react-router-dom';

const CustomBox = styled(Box)(() => ({
    textAlign: 'center',
    marginBottom: '50px',
}));

const CoverImageBox = styled(Box)(() => ({
    textAlign: 'center',
    marginTop: '40px',
    marginBottom: '20px',
}));

const Pricing = () => {
    // const navigate = useNavigate();
    return (
        <div style={{ marginBottom: '20px'}}>
            <Typography variant="h1" gutterBottom className="pricing_title">
                Transparent and predictable pricing
            </Typography>
            <Typography variant="h1" gutterBottom className="pricing_subtitle">
                Embark on your passion project, foster teamwork, and expand your reach to a global audience of millions
            </Typography>
            <CoverImageBox>
                <Hidden smDown>
                    <PricingCoverIcon props={{ width: '500px', height: '350px' }} />
                </Hidden>
                <Hidden smUp>
                    <PricingCoverIcon props={{ width: '250px', height: '200px' }} />
                </Hidden>
            </CoverImageBox>
            <CustomBox>
                <ButtonPrimary
                    id="sign-up"
                    responsive={false}
                    margin="1.3rem 1.3rem 0 0"
                    fixedHeight="40px"
                // onClick={() => navigate("/contact-us")}
                >
                    Start today
                </ButtonPrimary>
                <ButtonSecondary
                    id="contact-us"
                    responsive={false}
                    margin="1.3rem 0 0 0"
                    fixedHeight="40px"
                // onClick={() => navigate("/pages/get-demo")}
                >
                    Talk to an Expert
                </ButtonSecondary>
            </CustomBox>
            <TopCard />
            <br />
            <CustomGrid container sx={{ width: { xs: '95%', sm: '90%', md: '85%', lg: '75%' }, margin: 'auto', padding: '10px' }} columnGap={3} rowGap={4}>
                <CustomGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h2' mt={'30px'} mb={'30px'} className="pricing_title">Frequently asked questions</Typography>
                </CustomGrid>
                <CustomGrid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Hidden smDown>
                        <FAQIcon props={{ width: '350px', height: '350px' }} />
                    </Hidden>
                    <Hidden smUp>
                        <FAQIcon props={{ width: '250px', height: '200px' }} />
                    </Hidden>
                </CustomGrid>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <FAQSection />
                </Grid>
            </CustomGrid>
        </div>
    )
}

export default Pricing;