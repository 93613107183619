import React from 'react'
import Home from './Home'
import "./ApiKeyDialog.css";

function Index() {
    return (
        <>
            <Home/>
            </>
    )
}

export default Index
