import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Typography,
  InputAdornment,
  Divider,
  CircularProgress,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import Loader from "src/components/Loader/Loader";
import CustomDialogBox from "src/components/CustomDialog";
import { ButtonPrimary } from "src/components/CustomButtons";
import CopyFileIcon from "src/assets/icons/copyIcon";
import { Check } from "@mui/icons-material";
import { SuccessSnackbar } from "src/slices/Snackbar";
import moment from "moment";
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function GenerateKeyDetails({ close }: any) {
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const { generateKey, appDetailsLoader } = useSelector(
    (state: RootState) => state.apiKeys
  );
  const [copyID, setCopyID] = useState("copy");
  const [copyKey, setCopyKey] = useState("copy");
  const handleClose = () => {
    setOpen(false);
    close(false);
  };

  const handleCopySecretKey = () => {
    setCopyKey("copying");
    setTimeout(() => {
      setCopyKey("copied");
      dispatch(
        SuccessSnackbar({
          message: "Secret key is copied to the clipboard!",
          open: true,
        })
      );
    }, 1000);
    setTimeout(() => {
      setCopyKey("copy");
    }, 2000);
  };

  const handleCopyAppId = () => {
    setCopyID("copying");
    setTimeout(() => {
      setCopyID("copied");
      dispatch(
        SuccessSnackbar({
          message: "Application ID is copied to the clipboard!",
          open: true,
        })
      );
    }, 1000);
    setTimeout(() => {
      setCopyID("copy");
    }, 2000);
  };

  const exportData = () => {
    const value = {
      name: generateKey[0]?.name,
      expiry: generateKey[0]?.expiry,
      appID: generateKey[0]?.appId,
      secretKey: generateKey[0]?.appSecret,
    };
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(value)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${generateKey[0]?.appId}.json`;
    link.click();
  };

  return (
    <Box sx={{ mt: 2, mb: 2, ml: 2, mr: 2 }}>
      <CustomDialogBox
        open={open}
        setOpen={handleClose}
        background={"transparent"}
        closeIconId="close-view-resource-btn2"
        width={{
          xs: "300px",
          sm: "400px",
          md: "450px",
          lg: "450px",
          xl: "500px",
        }}
        height={{
          xs: "390px",
          sm: "390px",
          md: "390px",
          lg: "390px",
          xl: "400px",
        }}
        childrens={
          appDetailsLoader ? (
            <Box sx={{ height: "100%", width: "100%" }}>
              <Loader />
            </Box>
          ) : (
            <>
              <Typography
                sx={{
                  color: "#3086EC",
                  m: 0,
                  p: "16px 16px 0 16px",
                  fontSize: "16px",
                }}
              >
                Key details
              </Typography>
              <Divider sx={{ width: "100%" }} />
              <DialogContent>
                <Grid container>
                  <Grid md={12} xs={12} lg={12} sx={{ mt: 0, display: "flex" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Name:&nbsp;
                    </Typography>
                    <Typography
                      sx={{
                        maxWidth: { xs: "200px", sm: "300px" },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {generateKey[0]?.name}
                    </Typography>
                  </Grid>

                  <Grid md={12} xs={12} lg={12} sx={{ mt: 2, display: "flex" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Expiry:&nbsp;
                    </Typography>
                    {/* <Typography>{generateKey[0]?.expiry}</Typography> */}
                    <Typography>
                      {generateKey[0]?.expiry
                        ? moment(generateKey[0]?.expiry).format('DD MMM YYYY')
                        : "Indefinite"}
                    </Typography>
                  </Grid>

                  <Grid md={12} xs={12} lg={12} sx={{ mt: 2 }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Application ID
                    </Typography>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      // onChange={(event) => dispatch(editKeyDetails(event.target.value))}
                      value={generateKey[0]?.appId}
                      fullWidth
                      inputProps={{ readOnly: true }}
                      label=""
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {copyID === "copying" && (
                              <CircularProgress
                                size={20}
                                sx={{ color: "#686868" }}
                              />
                            )}
                            {copyID === "copied" && (
                              <Check
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  color: "green",
                                }}
                              />
                            )}
                            {copyID === "copy" && (
                              <CopyToClipboard
                                text={generateKey[0]?.appId}
                                onCopy={handleCopyAppId}
                              >
                                <div
                                  style={{
                                    cursor: "pointer",
                                    transform: "translateY(3px)",
                                  }}
                                >
                                  <CopyFileIcon
                                    props={{ height: "25px", width: "25px" }}
                                  />
                                </div>
                              </CopyToClipboard>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid md={12} xs={12} lg={12} sx={{ mt: 2 }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Secret key
                    </Typography>
                    <TextField
                      variant="outlined"
                      value={generateKey[0]?.appSecret}
                      size="small"
                      type="password"
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: false }}
                      fullWidth
                      label=""
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {copyKey === "copying" && (
                              <CircularProgress
                                size={20}
                                sx={{ color: "#686868" }}
                              />
                            )}
                            {copyKey === "copied" && (
                              <Check
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  color: "green",
                                }}
                              />
                            )}
                            {copyKey === "copy" && (
                              <CopyToClipboard
                                text={generateKey[0]?.appSecret}
                                onCopy={handleCopySecretKey}
                              >
                                <div
                                  style={{
                                    cursor: "pointer",
                                    transform: "translateY(3px)",
                                  }}
                                >
                                  <CopyFileIcon
                                    props={{ height: "25px", width: "25px" }}
                                  />
                                </div>
                              </CopyToClipboard>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <Box sx={{ m: 2, width: "93%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Typography fontSize="12px" color="warning">
                    Please make sure you save a copy of secret key
                  </Typography>
                  <Box className="justify_space_between generate_key_button_container">
                    <ButtonPrimary
                      id="download"
                      responsive={false}
                      onClick={exportData}
                    >
                      Download
                    </ButtonPrimary>
                  </Box>
                </Box>
              </Box>
            </>
          )
        }
      />
    </Box>
  );
}

export default GenerateKeyDetails;
