import React, { useEffect } from 'react';
import { Grid, Hidden } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { HomeCoverDetailsBlock } from 'src/slices/Home';
import { RootState } from 'src/store';
import { ButtonPrimary, ButtonSecondary } from 'src/components/CustomButtons';
import { HomeCoverIcon } from 'src/assets/icons/homePageIcon';
import { BannerContainer, BannerHeading, BannerSubHeading, ButtonBox, CustomGrid, HeadingSubTitle, HeadingTitle, HeadingWrapper } from './homePageStyled';

const HomePageCover = () => {
    const { homeCoverDetail } = useSelector((state: RootState) => state.Home);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(HomeCoverDetailsBlock());
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);



    return (
        <>
            <CustomGrid container sx={{ width: { xs: '95%', sm: '90%', md: '75%', lg: '85%' }, margin: 'auto', padding: '10px', }} columnGap={4} rowGap={2}>
                {homeCoverDetail.map((record, index) =>
                    <CustomGrid item xs={12} sm={6} md={5} lg={5.5} xl={4} key={index} id='landing-page-section'>
                        <HeadingWrapper>
                            <HeadingTitle>{record.title} </HeadingTitle>
                            <HeadingSubTitle>{record.content}</HeadingSubTitle>
                            <ButtonBox>
                                <ButtonPrimary
                                    id="try-now-its-free"
                                    responsive={false}
                                    fixedHeight="40px"
                                // onClick={() => navigate("/contact-us")}
                                >
                                    {record.buttonleft}
                                </ButtonPrimary>
                                <ButtonSecondary
                                    id="talk-to-us"
                                    responsive={false}
                                    fixedHeight="40px"
                                // onClick={() => navigate("/pages/get-demo")}
                                >
                                    {record.buttonright}
                                </ButtonSecondary>
                            </ButtonBox>
                        </HeadingWrapper>
                    </CustomGrid>)}
                <Grid item xs={12} sm={5} md={6} lg={6} xl={7} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Hidden mdDown>
                        <HomeCoverIcon props={{ width: '600px', height: '500px' }} />
                    </Hidden>
                    <Hidden only={['xs', 'md', 'lg', 'xl']}>
                        <HomeCoverIcon props={{ width: '300px', height: '350px' }} />
                    </Hidden>
                    <Hidden smUp>
                        <HomeCoverIcon props={{ width: '250px', height: '250px' }} />
                    </Hidden>
                </Grid>
            </CustomGrid>
            <BannerContainer>
                <BannerHeading>Features that make Audio Video Conferencing experience smoother</BannerHeading>
                <BannerSubHeading>Get all great features of Protego tailored for your monthly active users</BannerSubHeading>
                <ButtonSecondary
                    id="know-more"
                    responsive={false}
                //   onClick={handleBeginToday}
                >
                    Know more
                </ButtonSecondary>
            </BannerContainer>
        </>
    )
}

export default HomePageCover;

