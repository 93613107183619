import { Box, Button } from "@mui/material";
import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

export const Button1 = styled(Button)`
  color: #ffffff;
  background: #3087ec;
  font-family: Poppins;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.3s ease;
  :hover {
    color: #ffffff;
    background: #3087ec;
    opacity: 0.8;
  }
`;

export const Button2 = styled(Button)`
  color: #ffffff;
  background: #ff5a5a;
  font-family: Poppins;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.3s ease;
  :hover {
    color: #ffffff;
    background: #ff5a5a;
    opacity: 0.8;
  }
`;

export const Button3 = styled(Button)`
  font-family: Poppins;
  font-weight: 500;
  border-radius: 6px;
  border: 0.5px solid gray;
`;

export const Button4 = styled(LoadingButton)`
  font-family: Poppins;
  font-weight: 500;
  border-radius: 6px;
  border: 0.5px solid gray;
`;

export const Button5 = styled(Button)`
  color: #3087ec;
  background: white;
  variant: contained;
  font-family: Poppins;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid #bababa;
  transition: all 0.3s ease;
  :hover {
    color: #373737;
    background: white;
    opacity: 0.8;
    border: 1px solid #3087ec;
  }
`;

interface ICustomButton {
  id: string;
  children?: any;
  responsive: boolean;
  width?: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  height?: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  display?: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  fixedWidth?: string;
  fixedHeight?: string;
  onClick?: Function;
  margin?: string;
  padding?: string;
  minWidth?: string;
  disabled?:any
}

// success button
export const ButtonPrimary: FC<ICustomButton> = ({
  id,
  children,
  responsive = false,
  width,
  height,
  display,
  fixedWidth,
  fixedHeight,
  onClick,
  margin,
  padding,
  minWidth,
  disabled,
}) => {
  return (
    <Button1
      id={id}
      sx={{
        width: !responsive
          ? fixedWidth
          : {
              xs: width?.xs,
              sm: width?.sm,
              md: width?.md,
              lg: width?.lg,
              xl: width?.xl,
            },
        height: !responsive
          ? fixedHeight
          : {
              xs: height?.xs,
              sm: height?.sm,
              md: height?.md,
              lg: height?.lg,
              xl: height?.xl,
            },
        display: {
          xs: display?.xs,
          sm: display?.sm,
          md: display?.md,
          lg: display?.lg,
          xl: display?.xl,
        },
        margin: margin,
        padding: padding,
        minWidth: minWidth,
      }}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {children}
    </Button1>
  );
};

// danger button
export const ButtonDanger: FC<ICustomButton> = ({
  id,
  children,
  responsive = false,
  width,
  height,
  fixedWidth,
  fixedHeight,
  onClick,
  margin,
  padding,
  minWidth,
}) => {
  return (
    <Button2
      id={id}
      sx={{
        width: !responsive
          ? fixedWidth
          : {
              xs: width?.xs,
              sm: width?.sm,
              md: width?.md,
              lg: width?.lg,
              xl: width?.xl,
            },
        height: !responsive
          ? fixedHeight
          : {
              xs: height?.xs,
              sm: height?.sm,
              md: height?.md,
              lg: height?.lg,
              xl: height?.xl,
            },
        margin: margin,
        padding: padding,
        minWidth: minWidth,
      }}
      onClick={() => onClick()}
    >
      {children}
    </Button2>
  );
};

// disabled button
export const ButtonDisabled: FC<ICustomButton> = ({
  id,
  children,
  responsive = false,
  width,
  height,
  fixedWidth,
  fixedHeight,
  margin,
  padding,
  minWidth,
}) => {
  return (
    <Button3
      id={id}
      disabled
      sx={{
        width: !responsive
          ? fixedWidth
          : {
              xs: width?.xs,
              sm: width?.sm,
              md: width?.md,
              lg: width?.lg,
              xl: width?.xl,
            },
        height: !responsive
          ? fixedHeight
          : {
              xs: height?.xs,
              sm: height?.sm,
              md: height?.md,
              lg: height?.lg,
              xl: height?.xl,
            },
        margin: margin,
        padding: padding,
        minWidth: minWidth,
      }}
    >
      {children}
    </Button3>
  );
};

// loading button
export const ButtonLoading: FC<ICustomButton> = ({
  id,
  children,
  responsive = false,
  width,
  height,
  fixedWidth,
  fixedHeight,
  margin,
  padding,
  minWidth,
}) => {
  return (
    <Button4
      id={id}
      loading
      sx={{
        width: !responsive
          ? fixedWidth
          : {
              xs: width?.xs,
              sm: width?.sm,
              md: width?.md,
              lg: width?.lg,
              xl: width?.xl,
            },
        height: !responsive
          ? fixedHeight
          : {
              xs: height?.xs,
              sm: height?.sm,
              md: height?.md,
              lg: height?.lg,
              xl: height?.xl,
            },
        margin: margin,
        padding: padding,
        minWidth: minWidth,
      }}
    >
      {children}
    </Button4>
  );
};

// Inactive button5
export const ButtonSecondary: FC<ICustomButton> = ({
  id,
  children,
  responsive = false,
  width,
  height,
  fixedWidth,
  fixedHeight,
  onClick,
  margin,
  padding,
  minWidth,
}) => {
  return (
    <Button5
      id={id}
      sx={{
        width: !responsive
          ? fixedWidth
          : {
              xs: width?.xs,
              sm: width?.sm,
              md: width?.md,
              lg: width?.lg,
              xl: width?.xl,
            },
        height: !responsive
          ? fixedHeight
          : {
              xs: height?.xs,
              sm: height?.sm,
              md: height?.md,
              lg: height?.lg,
              xl: height?.xl,
            },
        margin: margin,
        padding: padding,
        minWidth: minWidth,
      }}
      onClick={() => onClick()}
    >
      {children}
    </Button5>
  );
};

export const BellContainer = styled(Box)(() => ({
  background: "#DDEFFF",
  borderRadius: "100%",
  height: "40px",
  width: "40px",
  // marginTop: '.5em',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // position: 'absolute',
}));
