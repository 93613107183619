import React from "react";
function CloseIcon({ props }) {
  return (
    <>
     <svg 
     width={ props.width }
     height={ props.height } 
     viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2254_13370)">
<path d="M11.8598 11.4996L21.3816 1.50898C21.6223 1.25742 21.6223 0.862109 21.3816 0.610547C21.141 0.358984 20.7629 0.358984 20.5223 0.610547L11.0004 10.6012L1.44414 0.646484C1.20352 0.394922 0.825391 0.394922 0.584766 0.646484C0.344141 0.898047 0.344141 1.29336 0.584766 1.54492L10.141 11.4996L0.619141 21.4902C0.378516 21.7418 0.378516 22.1371 0.619141 22.3887C0.722266 22.4965 0.894141 22.5684 1.03164 22.5684C1.16914 22.5684 1.34102 22.4965 1.44414 22.3887L11.0004 12.398L20.5223 22.3887C20.6254 22.4965 20.7973 22.5684 20.9348 22.5684C21.0723 22.5684 21.2441 22.4965 21.3473 22.3887C21.5879 22.1371 21.5879 21.7418 21.3473 21.4902L11.8598 11.4996Z" fill="#0062FD"/>
</g>
<defs>
<clipPath id="clip0_2254_13370">
<rect width="22" height="23" fill="white"/>
</clipPath>
</defs>
</svg>

    </>
  );
}

export default CloseIcon;