import { Box, styled, Tooltip, TooltipProps, Typography, tooltipClasses, } from "@mui/material";

export const MyAccountBox = styled(Typography)(() => ({
 zIndex: 9,
 color: '#FFFFFF',
 position: 'absolute',
 margin: '4rem 1rem 20rem 2%',
 fontStyle: 'normal',
 fontWeight: 600,
 fontSize: '24px',
 height: '36px',
}));

export const SubTabContainer = styled(Box)(({ theme }) => ({
 display: 'flex',
 justifyContent: 'space-around',
 alignItems: 'space-around',
 background: '#F1F5FD',
 [theme.breakpoints.down('sm')]: {
  flexDirection:'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap:'15px',
 },
}));

export const ProfileMainContainer = styled(Box)(({theme}) => ({
 display: 'flex',
 justifyContent: 'center',
 alignItems: 'center',
 flexDirection: 'column',
 marginTop: '2rem',
 [theme.breakpoints.down('sm')]: {
  marginTop: '4rem',

 }

}));

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
 <Tooltip {...props} classes={{ popper: className }} />
))({
 [`& .${tooltipClasses.tooltip}`]: {
  maxWidth: 200,
  fontSize: '14px',
 },
});

export const UserTitle = styled(Typography)(() => ({
 fontStyle: 'normal',
 fontWeight: 600,
 fontSize: '16px',
 width: '140px',
 textAlign: 'center',
 letterSpacing: '0px',
 whiteSpace: 'nowrap',
 overflow: 'hidden',
 textOverflow: 'ellipsis',
 display: 'inline-block',
}));

export const UserDetails = styled(Typography)(() => ({
 fontStyle: 'normal',
 fontWeight: 400,
 fontSize: '14px',
}));

export const UserCardEmailInfo = styled(Typography)(() => ({
 fontStyle: 'normal',
 fontWeight: 400,
 fontSize: '14px',
 width: '140px',
 textAlign: 'center',
 letterSpacing: '0px',
 whiteSpace: 'nowrap',
 overflow: 'hidden',
 textOverflow: 'ellipsis',
 display: 'inline-block',
}));

export const UserInfo = styled(Box)(() => ({
 display: 'flex',
 justifyContent: 'flex-start',
 alignItems: 'flex-start',
 flexDirection: 'column',
 marginTop: '3rem',
 rowGap: '10px',
 columnGap: '20px',
 margin: '20px',
}));

export const UserInfoDetails = styled(Box)(() => ({
 display: 'flex',
 justifyContent: 'center',
 alignItems: 'center',
 flexDirection: 'row',
 columnGap: '20px',
}));

export const UserCardInfo = styled(Typography)(() => ({
 fontStyle: 'normal',
 fontWeight: 400,
 fontSize: '14px',
 width: '140px',
 textAlign: 'left',
 letterSpacing: '0px',
 whiteSpace: 'nowrap',
 overflow: 'hidden',
 textOverflow: 'ellipsis',
 display: 'inline-block',
}));
