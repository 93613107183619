import { FC } from "react";
import {
  Divider,
  DialogActions,
  DialogContent,
  IconButton,
  styled,
  Button,
  Typography,
  Dialog,
} from "@mui/material";
import CloseButtonDialog from "src/assets/icons/CloaseButtonDialog";

interface IClosealertprops {
  id: Function;
  mainTitle: string;
  title: string;
  subTitle: string;
  openCloseDialog: boolean;
  setOpenCloseDialog: Function;
  cancel: string;
  accept: string;
  textColor: string;
  BackgroundColor: string;
}

export const CloseAlertButtons = styled(Button)(() => ({
    fontFamily: "poppins",
    letterSpacing: "0px",
    color: "#FFFFFF",
    fontSize: "14px",
  }));
  export const CloseAlertMainTitle = styled(Typography)(() => ({
    textAlign: "left",
    letterspacing: "0px",
    color: "#FF5A5A",
    opacity: 1,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
  }));
  
  export const CloseAlertTitle = styled(Typography)(() => ({
    textAlign: "center",
    letterSpacing: "0px",
    color: "#FF5A5A",
    opacity: 1,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
  }));
  
  export const CloseAlertSubTitle = styled(Typography)(() => ({
    textAlign: "center",
    fontFamily: "poppins",
    letterSpacing: "0px",
    color: "#4E4B48",
    opacity: 1,
    fontSize: "14px",
  }));
  export const DialogOpen = styled(Dialog)`
  & .MuiPaper-root.MuiPaper-elevation {
    overflow: visible;
  }
`;
const CloseAlert: FC<IClosealertprops> = ({
  id,
  mainTitle,
  title,
  subTitle,
  openCloseDialog,
  setOpenCloseDialog,
  accept,
  cancel,
  BackgroundColor,
}) => {
  return (
    <DialogOpen
      open={openCloseDialog}
      // onClose={() => setOpenCloseDialog(false)}
      data-testid="dialog-reference"
      PaperProps={{
        sx: {
          width: "400px",
          height: "228px",
        },
      }}
    >
      <IconButton
        // size="medium"
        id="dialog-cross-btn"
        style={{
          background: "none",
          borderRadius: "0px",
          width: "70px",
          height: "70px",
          display: "flex",
          position: "absolute",
          top: -35,
          right: 0,
        }}
        onClick={() => {
          setOpenCloseDialog(false);
        }}
      >
        <CloseButtonDialog
          props={{
            width: "70px",
            height: "70px",
          }}
        />
      </IconButton>
      <CloseAlertMainTitle
        sx={{ m: 1.5 }}
        id="dialog-title"
        data-testid="mainTitle"
      >
        {mainTitle}
      </CloseAlertMainTitle>
      <Divider />
      <DialogContent
        style={{
          background: "#FFFFFF 0% 0% no-repeat padding-box",
        }}
      >
        <CloseAlertTitle data-testid="title">{title}</CloseAlertTitle>
        <CloseAlertSubTitle data-testid="title">{subTitle}</CloseAlertSubTitle>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          justifyContent: "right",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
        }}
      >
        <CloseAlertButtons
          data-testid="dialog-close-btn"
          id="dialog-close-btn"
          style={{
            background: "#FFFFFF",
            color: "black",
            width: "55px",
            height: "40px",
            border: "1px solid #BABABA",
          }}
          variant="contained"
          onClick={() => {
            setOpenCloseDialog(false);
          }}
          autoFocus
        >
          {cancel}
        </CloseAlertButtons>
        <CloseAlertButtons
          data-testid="dialog-accept-btn"
          id="dialog-accept-btn"
          variant="contained"
          style={{
            background: BackgroundColor,
            width: "55px",
            height: "40px",
          }}
          onClick={() => {
            id();
            setOpenCloseDialog(false);
          }}
        >
          {accept}
        </CloseAlertButtons>
      </DialogActions>
    </DialogOpen>
  );
};

export default CloseAlert;
