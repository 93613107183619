import React from 'react';
const PricingCoverIcon = ({props}) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 492 356" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M399.161 160.898H242.86C238.462 160.898 234.898 157.437 234.898 153.166V8.6549C234.898 4.38399 238.462 0.921875 242.86 0.921875H399.161C403.559 0.921875 407.124 4.38399 407.124 8.6549V153.166C407.124 157.438 403.559 160.898 399.161 160.898Z" fill="#5876FF" />
            <path d="M332.891 24.0794H258.729C257.409 24.0794 256.339 23.0402 256.339 21.7583C256.339 20.4765 257.409 19.4375 258.729 19.4375H332.891C334.211 19.4375 335.281 20.4765 335.281 21.7583C335.281 23.0402 334.211 24.0794 332.891 24.0794Z" fill="#E1E9FF" />
            <path d="M309.05 35.6575H258.729C257.409 35.6575 256.339 34.6185 256.339 33.3367C256.339 32.0548 257.409 31.0156 258.729 31.0156H309.05C310.37 31.0156 311.44 32.0548 311.44 33.3367C311.44 34.6185 310.37 35.6575 309.05 35.6575Z" fill="#E1E9FF" />
            <path d="M271.3 54.6299H258.047C257.104 54.6299 256.339 53.8879 256.339 52.9722C256.339 52.0564 257.104 51.3145 258.047 51.3145H271.3C272.243 51.3145 273.006 52.0564 273.006 52.9722C273.006 53.8879 272.243 54.6299 271.3 54.6299Z" fill="#2E53FF" />
            <path d="M277.068 107.914V149.282C277.068 150.197 276.303 150.939 275.36 150.939H268.464C267.521 150.939 266.756 150.197 266.756 149.282V107.914C266.756 106.998 267.521 106.256 268.464 106.256H275.36C276.303 106.256 277.068 106.999 277.068 107.914Z" fill="#2E53FF" />
            <path d="M295.811 91.2925V149.28C295.811 150.196 295.047 150.938 294.104 150.938H287.207C286.264 150.938 285.5 150.196 285.5 149.28V91.2925C285.5 90.3767 286.264 89.6348 287.207 89.6348H294.104C295.047 89.6348 295.811 90.3767 295.811 91.2925Z" fill="#2E53FF" />
            <path d="M318.383 107.914V149.282C318.383 150.197 317.619 150.939 316.676 150.939H309.779C308.836 150.939 308.072 150.197 308.072 149.282V107.914C308.072 106.998 308.836 106.256 309.779 106.256H316.676C317.619 106.256 318.383 106.999 318.383 107.914Z" fill="#2E53FF" />
            <path d="M337.125 91.2925V149.28C337.125 150.196 336.36 150.938 335.417 150.938H328.521C327.578 150.938 326.813 150.196 326.813 149.28V91.2925C326.813 90.3767 327.578 89.6348 328.521 89.6348H335.417C336.36 89.6348 337.125 90.3767 337.125 91.2925Z" fill="#2E53FF" />
            <path d="M359.697 120.029V149.283C359.697 150.199 358.933 150.941 357.99 150.941H351.093C350.15 150.941 349.385 150.199 349.385 149.283V120.029C349.385 119.113 350.15 118.371 351.093 118.371H357.99C358.933 118.371 359.697 119.113 359.697 120.029Z" fill="#2E53FF" />
            <path d="M378.44 107.914V149.282C378.44 150.197 377.676 150.939 376.733 150.939H369.836C368.893 150.939 368.129 150.197 368.129 149.282V107.914C368.129 106.998 368.893 106.256 369.836 106.256H376.733C377.676 106.256 378.44 106.999 378.44 107.914Z" fill="#2E53FF" />
            <path d="M277.068 123.191V149.281C277.068 150.196 276.303 150.938 275.36 150.938H268.464C267.521 150.938 266.756 150.196 266.756 149.281V123.191C266.756 122.275 267.521 121.533 268.464 121.533H275.36C276.303 121.533 277.068 122.275 277.068 123.191Z" fill="#D2DCFF" />
            <path d="M295.811 112.255V149.281C295.811 150.197 295.047 150.939 294.104 150.939H287.207C286.264 150.939 285.5 150.197 285.5 149.281V112.255C285.5 111.339 286.264 110.598 287.207 110.598H294.104C295.047 110.598 295.811 111.339 295.811 112.255Z" fill="#D2DCFF" />
            <path d="M318.383 123.191V149.281C318.383 150.196 317.619 150.938 316.676 150.938H309.779C308.836 150.938 308.072 150.196 308.072 149.281V123.191C308.072 122.275 308.836 121.533 309.779 121.533H316.676C317.619 121.533 318.383 122.275 318.383 123.191Z" fill="#D2DCFF" />
            <path d="M337.125 112.255V149.281C337.125 150.197 336.36 150.939 335.417 150.939H328.521C327.578 150.939 326.813 150.197 326.813 149.281V112.255C326.813 111.339 327.578 110.598 328.521 110.598H335.417C336.36 110.598 337.125 111.339 337.125 112.255Z" fill="#D2DCFF" />
            <path d="M359.697 131.164V149.28C359.697 150.196 358.933 150.938 357.99 150.938H351.093C350.15 150.938 349.385 150.196 349.385 149.28V131.164C349.385 130.248 350.15 129.506 351.093 129.506H357.99C358.933 129.506 359.697 130.248 359.697 131.164Z" fill="#D2DCFF" />
            <path d="M378.44 123.191V149.28C378.44 150.195 377.676 150.937 376.733 150.937H369.836C368.893 150.937 368.129 150.195 368.129 149.28V123.191C368.129 122.275 368.893 121.533 369.836 121.533H376.733C377.675 121.533 378.44 122.275 378.44 123.191Z" fill="#D2DCFF" />
            <path d="M383.114 11.9625L380.491 21.395L380.295 22.092L379.082 26.4741L379.074 26.5027L375.156 26.3977L360.127 25.9967L359.028 22.3116L358.84 21.691L355.932 11.953C364.958 15.6286 372.394 7.61861 383.114 11.9625Z" fill="#FBC343" />
            <path d="M380.295 22.0916L379.082 26.4737L375.156 26.4069L360.127 26.0059L359.028 22.3207C364.192 22.2062 373.079 22.063 380.295 22.0916Z" fill="#E49B31" />
            <path d="M397.091 75.3447C396.317 78.2375 394.786 80.6625 392.815 82.3618C390.836 84.0708 388.4 85.0446 385.818 85.0446H351.778C346.859 85.0446 342.541 81.1876 341.23 75.6216C340.473 72.4043 340.073 69.1391 340.008 65.8931C340 65.5781 340 65.263 340 64.9575C340 64.5852 340.008 64.2129 340.016 63.8501C340.293 52.0785 345.205 41.1185 353.082 33.8054L361.227 26.2441L379.864 26.7501L390.111 38.2448C391.552 39.8583 392.839 41.6722 393.866 43.6676C395.104 46.0639 396.097 48.6034 396.855 51.2193C399.079 58.9238 399.225 67.3729 397.091 75.3447Z" fill="#FBC343" />
            <path d="M366.774 74.5054V70.2856C364.233 70.152 361.765 69.35 360.323 68.3762L361.463 63.1731C363.06 64.1946 365.292 65.1302 367.76 65.1302C369.918 65.1302 371.401 64.1564 371.401 62.3711C371.401 60.6813 370.187 59.612 367.377 58.5046C363.32 56.9006 360.551 54.6857 360.551 50.3704C360.551 46.4561 362.905 43.3915 366.961 42.4559V38.2266H370.676V42.1409C373.217 42.2745 374.928 42.8951 376.174 43.6111L375.074 48.6329C374.089 48.146 372.345 47.1149 369.609 47.1149C367.141 47.1149 366.35 48.356 366.35 49.6067C366.35 51.0769 367.678 52.003 370.904 53.4255C375.416 55.2967 377.241 57.7408 377.241 61.741C377.241 65.6935 374.854 69.0732 370.488 69.9706V74.5054H366.774V74.5054Z" fill="white" />
            <path d="M382.935 30.33H356.568C355.476 30.33 354.589 29.2799 354.589 28.0101V27.0172C354.589 25.7379 355.485 24.6973 356.568 24.6973H382.935C384.026 24.6973 384.914 25.7474 384.914 27.0172V28.0101C384.914 29.2799 384.026 30.33 382.935 30.33Z" fill="#ED7545" />
            <path opacity="0.4" d="M397.091 75.3459C396.317 78.2387 394.786 80.6637 392.815 82.363C390.835 84.072 388.4 85.0458 385.818 85.0458H351.778C346.858 85.0458 342.541 81.1888 341.23 75.6228C340.472 72.4054 340.073 69.1403 340.008 65.8943C345.637 72.1859 352.275 80.4154 367.792 80.6732C387.056 80.9883 395.356 70.4006 396.847 51.2109C399.079 58.925 399.225 67.3741 397.091 75.3459Z" fill="#E49B31" />
            <path d="M363.458 272.531L373.703 355H377.062L366.818 272.531H363.458Z" fill="#D2DCFF" />
            <path d="M408.726 272.529L409.878 281.804L418.969 354.998H422.328L413.193 281.441L412.086 272.529H408.726Z" fill="#D2DCFF" />
            <path d="M363.458 354.998H366.818L368.234 343.593L369.915 330.069L375.44 285.586L377.062 272.529H373.702L372.034 285.959L368.234 316.544L366.556 330.067L363.458 354.998Z" fill="#D2DCFF" />
            <path d="M318.192 354.998H321.552L329.502 290.988H329.503L331.795 272.529H328.435L326.098 291.344L318.192 354.998Z" fill="#D2DCFF" />
            <path d="M411.922 217.402H408.562V267.288H411.922V217.402Z" fill="#D2DCFF" />
            <path d="M366.27 217.402H362.911V267.288H366.27V217.402Z" fill="#D2DCFF" />
            <path d="M327.025 267.287H373.183C371.692 267.287 370.484 268.46 370.484 269.908C370.484 271.356 371.692 272.53 373.183 272.53H327.025C325.534 272.53 324.325 271.356 324.325 269.908C324.325 268.46 325.534 267.287 327.025 267.287Z" fill="#D2DCFF" />
            <path d="M370.484 269.908C370.484 268.46 371.692 267.287 373.183 267.287H408.49H413.039C414.53 267.287 415.739 268.46 415.739 269.908C415.739 271.356 414.53 272.53 413.039 272.53H408.49H373.183C371.692 272.53 370.484 271.356 370.484 269.908Z" fill="#86A2E2" />
            <path d="M360.166 179.859H414.667C416.745 179.859 418.43 181.496 418.43 183.513V213.747C418.43 215.765 416.745 217.401 414.667 217.401H360.166C358.088 217.401 356.403 215.765 356.403 213.747V183.513C356.403 181.495 358.087 179.859 360.166 179.859Z" fill="#D2DCFF" />
            <path d="M408.726 272.529L409.878 281.804C411.092 281.671 412.201 281.55 413.192 281.442L412.084 272.529H408.726Z" fill="#2E53FF" />
            <path d="M373.702 272.529L372.034 285.959C373.175 285.835 374.311 285.71 375.44 285.587L377.062 272.529H373.702Z" fill="#2E53FF" />
            <path d="M326.098 291.344C327.182 291.232 328.319 291.113 329.502 290.988H329.503L331.795 272.529H328.435L326.098 291.344Z" fill="#2E53FF" />
            <path d="M411.922 217.402H408.562V224.292L411.922 223.931V217.402Z" fill="#2E53FF" />
            <path d="M362.911 217.402V229.199L366.27 228.838V217.402H362.911Z" fill="#2E53FF" />
            <path d="M344.719 139.365C345.966 154.058 356.959 163.27 363.409 163.27C364.377 163.27 365.431 163.102 366.531 162.809V170.138H382.893V151.504C383.044 151.119 383.19 150.692 383.334 150.236C390.588 149.563 390.288 137.922 385.978 137.922C385.194 137.922 384.669 138.641 384.339 139.366L383.27 140.405V135.145C383.27 135.145 380.863 135.365 376.123 128.352C374.078 131.364 357.767 136.095 344.678 133.976C344.677 133.976 344.644 136.592 344.719 139.365Z" fill="#FCBEB7" />
            <path d="M364.537 114.033C375.821 114.033 378.078 119.877 378.078 119.877C382.893 119.877 390.266 128.425 385.826 137.921L385.804 137.944L383.269 140.405V135.146C383.269 135.146 380.862 135.366 376.122 128.352C374.077 131.365 357.765 136.096 344.676 133.977C344.676 133.977 344.642 136.593 344.717 139.365C344.717 139.368 344.717 139.371 344.717 139.374C339.828 125.64 349.642 114.033 364.537 114.033Z" fill="#311643" />
            <path d="M369.39 143.375C369.889 143.375 370.292 142.983 370.292 142.498V140.861C370.292 140.377 369.889 139.984 369.39 139.984C368.891 139.984 368.487 140.377 368.487 140.861V142.498C368.487 142.983 368.891 143.375 369.39 143.375Z" fill="#311643" />
            <path d="M352.651 143.375C353.15 143.375 353.555 142.983 353.555 142.498V140.861C353.555 140.377 353.15 139.984 352.651 139.984C352.152 139.984 351.749 140.377 351.749 140.861V142.498C351.749 142.983 352.152 143.375 352.651 143.375Z" fill="#311643" />
            <path d="M362.752 153.61C364.363 153.61 365.46 153.021 366.125 152.485C366.963 151.81 367.311 151.072 367.326 151.041C367.42 150.839 367.327 150.602 367.119 150.51C366.911 150.419 366.667 150.509 366.572 150.71C366.525 150.809 365.381 153.14 362.056 152.766C361.829 152.742 361.624 152.898 361.597 153.12C361.57 153.341 361.733 153.539 361.961 153.564C362.238 153.595 362.501 153.61 362.752 153.61Z" fill="#311643" />
            <path d="M375.383 150.647C377.848 150.647 379.847 148.706 379.847 146.312C379.847 143.918 377.848 141.977 375.383 141.977C372.917 141.977 370.919 143.918 370.919 146.312C370.919 148.706 372.917 150.647 375.383 150.647Z" fill="#F49795" />
            <path d="M345.182 142.712C345.748 145.623 346.697 148.277 347.896 150.64C350.257 150.526 352.134 148.632 352.134 146.311C352.134 143.917 350.136 141.977 347.671 141.977C346.75 141.976 345.893 142.248 345.182 142.712Z" fill="#F49795" />
            <path d="M346.326 139.271C346.326 142.658 349.164 145.413 352.651 145.413C356.059 145.413 358.843 142.783 358.969 139.503C360.861 138.463 362.545 139.21 363.068 139.498C363.192 142.781 365.979 145.413 369.388 145.413C372.876 145.413 375.713 142.658 375.713 139.271C375.713 135.884 372.875 133.129 369.388 133.129C366.13 133.129 363.439 135.533 363.099 138.611C362.274 138.243 360.71 137.804 358.938 138.614C358.599 135.534 355.909 133.129 352.65 133.129C349.164 133.129 346.326 135.884 346.326 139.271ZM363.891 139.271C363.891 136.327 366.358 133.932 369.39 133.932C372.422 133.932 374.888 136.327 374.888 139.271C374.888 142.214 372.422 144.61 369.39 144.61C366.358 144.61 363.891 142.215 363.891 139.271ZM347.154 139.271C347.154 136.327 349.619 133.932 352.651 133.932C355.683 133.932 358.15 136.327 358.15 139.271C358.15 142.214 355.683 144.61 352.651 144.61C349.619 144.61 347.154 142.215 347.154 139.271Z" fill="white" />
            <path d="M366.531 170.139V162.811C370.231 161.823 374.443 159.404 377.609 157.098L366.531 170.139Z" fill="#F9AFAB" />
            <path d="M320.042 295.421L323.458 294.967L326.693 268.92H323.333L320.042 295.421Z" fill="#EBEDF4" />
            <path d="M329.115 195.883L326.149 201.407H341.963L329.115 195.883Z" fill="#FCBEB7" />
            <path d="M359.406 340.597V352.489C359.406 353.876 358.248 354.999 356.82 354.999H322.086C321.756 354.999 321.488 354.739 321.488 354.419V352.227C321.488 351.169 322.123 350.208 323.113 349.768L343.197 340.596H359.406V340.597Z" fill="#2F3D6C" />
            <path d="M359.406 333.832L359.398 340.598H343.198L342.078 333.832H359.406Z" fill="#FCBEB7" />
            <path d="M366.56 163.228L344.567 172.875C341.543 174.201 339.081 176.5 337.599 179.383L329.117 195.884L341.964 201.407L340.9 237.882H404.92L402.125 203.817L413.703 203.89V185.251C413.703 179.478 410.131 174.271 404.645 172.046L382.894 163.227C382.893 163.228 373.39 169.283 366.56 163.228Z" fill="#005E47" />
            <path d="M361.296 165.445C364.768 168.27 369.108 169.808 373.691 169.808C378.516 169.808 383.272 167.938 386.828 164.823L386.129 164.539C382.725 167.439 378.26 169.165 373.69 169.165C369.376 169.165 365.285 167.754 361.977 165.158L361.296 165.445Z" fill="white" />
            <path d="M325.32 340.597V352.489C325.32 353.876 324.162 354.999 322.734 354.999H288C287.669 354.999 287.401 354.739 287.401 354.419V352.227C287.401 351.169 288.036 350.208 289.026 349.768L309.11 340.596H325.32V340.597Z" fill="#2F3D6C" />
            <path d="M325.32 333.832L325.311 340.598H309.112L307.99 333.832H325.32Z" fill="#FCBEB7" />
            <path d="M370.835 237.882V238.517C370.848 254.493 357.515 267.45 341.063 267.45H327.233L325.32 333.83H307.991L295.214 254.683C293.789 245.857 300.816 237.881 310.015 237.881H370.835V237.882Z" fill="#2E53FF" />
            <path d="M295.031 252.465C296.523 254.584 299.034 255.976 301.879 255.976C306.46 255.976 310.173 252.37 310.173 247.922C310.173 243.65 306.748 240.155 302.42 239.885C297.95 242.444 295.007 247.182 295.031 252.465Z" fill="#4A5EA3" />
            <path d="M329.119 252.156L327.233 267.452H338.581L329.119 252.156Z" fill="#4A5EA3" />
            <path d="M404.921 237.882V238.517C404.934 254.493 391.601 267.45 375.149 267.45H361.319L359.406 333.83H342.077L329.3 254.683C327.875 245.857 334.902 237.881 344.101 237.881H404.921V237.882Z" fill="#2E53FF" />
            <path d="M413.702 203.888L413.086 224.546C412.919 230.129 408.21 234.572 402.458 234.572H365.427L362.942 226.161L395.605 220.933L395.837 203.775L413.702 203.888Z" fill="#FCBEB7" />
            <path d="M402.719 230.276C402.719 231.908 403.476 233.371 404.667 234.349C408.495 233.563 411.57 230.777 412.661 227.154C411.664 225.815 410.043 224.943 408.211 224.943C405.178 224.942 402.719 227.331 402.719 230.276Z" fill="#F9AFAB" />
            <path d="M395.836 203.775L390.822 221.698L395.604 220.932L395.836 203.775Z" fill="#2E53FF" />
            <path d="M367.505 225.432L373.343 234.572H365.427L362.942 226.162L367.505 225.432Z" fill="#F9AFAB" />
            <path d="M303.872 201.406H352.157C354.209 201.406 356.014 202.726 356.58 204.641L365.428 234.571H385.008C385.949 234.571 386.712 235.312 386.712 236.226C386.712 237.14 385.949 237.881 385.008 237.881H366.406H360.49H308.547L299.45 207.106C298.606 204.251 300.813 201.406 303.872 201.406Z" fill="#4A5EA3" />
            <path d="M329.119 252.156C330.58 254.449 333.194 255.977 336.176 255.977C340.757 255.977 344.469 252.371 344.469 247.923C344.469 243.588 340.944 240.052 336.524 239.875C332.132 242.382 329.209 246.99 329.119 252.156Z" fill="#4A5EA3" />
            <path d="M330.774 223.446C331.76 223.362 332.456 222.161 332.33 220.763C332.203 219.365 331.301 218.3 330.315 218.385C329.33 218.469 328.633 219.67 328.759 221.068C328.886 222.466 329.788 223.531 330.774 223.446Z" fill="#89AEFF" />
            <path d="M403.989 179.632C403.989 182.236 406.164 184.348 408.846 184.348C410.781 184.348 412.449 183.25 413.23 181.661C412.544 179.087 411.132 176.755 409.17 174.927C409.063 174.919 408.955 174.916 408.846 174.916C406.163 174.916 403.989 177.027 403.989 179.632Z" fill="#2E53FF" />
            <path d="M337.899 178.829C338.673 179.335 339.605 179.63 340.608 179.63C343.29 179.63 345.464 177.518 345.464 174.915C345.464 174.123 345.264 173.377 344.909 172.723L344.566 172.873C341.738 174.113 339.403 176.204 337.899 178.829Z" fill="#2E53FF" />
            <path d="M239.521 221.359L222.571 354.443H219.205L236.155 221.359H239.521Z" fill="#D2DCFF" />
            <path d="M166.696 271.84L156.435 354.445H153.071L163.33 271.84H166.696Z" fill="#D2DCFF" />
            <path d="M121.354 271.838L120.2 281.129L111.094 354.443H107.729L116.88 280.765L117.989 271.838H121.354Z" fill="#D2DCFF" />
            <path d="M166.696 354.443H163.33L161.911 343.019L160.229 329.473L154.695 284.917L153.071 271.838H156.436L158.107 285.29L161.911 315.925L163.594 329.471L166.696 354.443Z" fill="#D2DCFF" />
            <path d="M212.036 354.443H208.672L200.708 290.327H200.708L198.411 271.838H201.776L204.118 290.684L212.036 354.443Z" fill="#D2DCFF" />
            <path d="M121.518 216.619H118.153V266.587H121.518V216.619Z" fill="#D2DCFF" />
            <path d="M167.245 216.619H163.88V266.587H167.245V216.619Z" fill="#D2DCFF" />
            <path d="M203.19 266.586H156.955C158.448 266.586 159.659 267.762 159.659 269.212C159.659 270.662 158.448 271.838 156.955 271.838H203.19C204.683 271.838 205.894 270.662 205.894 269.212C205.894 267.762 204.683 266.586 203.19 266.586Z" fill="#D2DCFF" />
            <path d="M159.659 269.212C159.659 267.762 158.448 266.586 156.955 266.586H121.589H117.034C115.541 266.586 114.33 267.762 114.33 269.212C114.33 270.662 115.541 271.838 117.034 271.838H121.589H156.955C158.448 271.838 159.659 270.662 159.659 269.212Z" fill="#86A2E2" />
            <path d="M168.651 216.619H116.747C113.924 216.619 111.635 214.396 111.635 211.654V183.98C111.635 181.239 113.924 179.016 116.747 179.016H168.651C171.474 179.016 173.763 181.239 173.763 183.98V211.654C173.763 214.397 171.475 216.619 168.651 216.619Z" fill="#D2DCFF" />
            <path d="M121.354 271.838L120.2 281.129C118.984 280.996 117.873 280.874 116.88 280.765L117.989 271.838H121.354Z" fill="#AEC3FF" />
            <path d="M156.436 271.838L158.107 285.291C156.964 285.166 155.826 285.041 154.695 284.917L153.071 271.838H156.436Z" fill="#AEC3FF" />
            <path d="M204.118 290.684C203.032 290.571 201.892 290.452 200.708 290.328H200.708L198.411 271.838H201.776L204.118 290.684Z" fill="#AEC3FF" />
            <path d="M118.153 216.619H121.518V223.521L118.153 223.16V216.619Z" fill="#AEC3FF" />
            <path d="M167.245 216.619V228.437L163.88 228.075V216.619H167.245Z" fill="#AEC3FF" />
            <path d="M173.827 128.93C169.873 135.282 157.788 144.422 139.818 138.843V138.844C139.762 138.836 139.705 138.831 139.646 138.831C135.371 138.831 135.074 150.376 142.268 151.044C142.374 151.383 142.483 151.704 142.594 152.006V170.781H158.821V163.483C159.952 163.793 161.036 163.971 162.029 163.971C168.741 163.971 180.409 153.916 180.674 138.076C178.878 137.684 173.827 133.362 173.827 128.93Z" fill="#FCBEB7" />
            <path d="M184.083 133.47C184.083 123.037 174.608 114.584 160.4 114.584C148.592 114.584 144.534 120.912 144.534 120.912C144.534 120.912 133.373 123.254 133.373 136.722C133.373 149.242 133.373 170.782 133.373 170.782H151.712H152.646H166.191H180.914V142.732C182.077 141.345 184.083 138.26 184.083 133.47ZM162.029 163.971C161.036 163.971 159.952 163.794 158.821 163.484V170.782H142.594V152.006C142.483 151.705 142.374 151.383 142.268 151.044C135.074 150.376 135.371 138.831 139.646 138.831C139.704 138.831 139.762 138.836 139.818 138.844V138.843C157.788 144.422 169.873 135.281 173.827 128.93C173.827 133.362 178.878 137.683 180.675 138.078C180.409 153.916 168.741 163.971 162.029 163.971Z" fill="#311643" />
            <path d="M129.885 169.396L142.594 163.678L149.568 163.857L158.821 164.281L172.118 168.201V184.677H129.885V169.396Z" fill="#FCBEB7" />
            <path d="M172.118 185.687V168.203L158.821 164.283V163.484C155.061 162.453 150.787 159.958 147.649 157.629L172.118 185.687Z" fill="#F9AFAB" />
            <path d="M150.154 151.448C152.599 151.448 154.581 149.523 154.581 147.149C154.581 144.774 152.599 142.85 150.154 142.85C147.709 142.85 145.727 144.774 145.727 147.149C145.727 149.523 147.709 151.448 150.154 151.448Z" fill="#F49795" />
            <path d="M180.104 143.579C179.543 146.466 178.603 149.099 177.415 151.441C175.074 151.328 173.212 149.449 173.212 147.148C173.212 144.773 175.194 142.85 177.638 142.85C178.551 142.85 179.399 143.119 180.104 143.579Z" fill="#F49795" />
            <path d="M172.617 134.076C169.385 134.076 166.716 136.463 166.38 139.516C164.623 138.713 163.072 139.148 162.253 139.513C161.915 136.461 159.247 134.076 156.016 134.076C152.557 134.076 149.744 136.809 149.744 140.168C149.744 143.527 152.557 146.26 156.016 146.26C159.398 146.26 162.163 143.647 162.284 140.391C162.805 140.1 164.46 139.358 166.349 140.398C166.474 143.65 169.236 146.26 172.615 146.26C176.074 146.26 178.889 143.527 178.889 140.168C178.889 136.809 176.076 134.076 172.617 134.076ZM156.016 145.463C153.01 145.463 150.563 143.088 150.563 140.168C150.563 137.249 153.009 134.873 156.016 134.873C159.023 134.873 161.469 137.249 161.469 140.168C161.469 143.088 159.023 145.463 156.016 145.463ZM172.617 145.463C169.611 145.463 167.164 143.088 167.164 140.168C167.164 137.249 169.61 134.873 172.617 134.873C175.624 134.873 178.07 137.249 178.07 140.168C178.07 143.088 175.624 145.463 172.617 145.463Z" fill="white" />
            <path d="M172.62 144.064C172.126 144.064 171.726 143.675 171.726 143.195V141.571C171.726 141.091 172.126 140.701 172.62 140.701C173.114 140.701 173.516 141.091 173.516 141.571V143.195C173.516 143.675 173.115 144.064 172.62 144.064Z" fill="#311643" />
            <path d="M156.289 144.064C155.795 144.064 155.394 143.675 155.394 143.195V141.571C155.394 141.091 155.795 140.701 156.289 140.701C156.784 140.701 157.183 141.091 157.183 141.571V143.195C157.184 143.675 156.784 144.064 156.289 144.064Z" fill="#311643" />
            <path d="M162.681 154.389C161.083 154.389 159.995 153.806 159.336 153.274C158.505 152.604 158.16 151.873 158.145 151.842C158.052 151.641 158.144 151.405 158.351 151.315C158.557 151.224 158.799 151.313 158.893 151.513C158.94 151.612 160.075 153.922 163.372 153.552C163.598 153.527 163.801 153.683 163.827 153.903C163.853 154.121 163.692 154.319 163.466 154.344C163.191 154.374 162.929 154.389 162.681 154.389Z" fill="#311643" />
            <path d="M199.411 348.235L184.526 342.021L185.289 345.603L166.978 340.02V345.115C166.978 346.907 167.942 348.531 169.459 349.444L171.035 353.816C171.171 354.194 171.538 354.447 171.951 354.447C172.487 354.447 172.921 354.026 172.921 353.505V350.662L185.436 354.447H198.096C199.937 354.447 201.429 352.998 201.429 351.211C201.428 349.915 200.634 348.746 199.411 348.235Z" fill="#2F3D6C" />
            <path d="M231.872 348.235L216.985 342.021L217.749 345.603L199.438 340.02V345.115C199.438 346.907 200.403 348.531 201.92 349.444L203.495 353.816C203.631 354.194 203.998 354.447 204.411 354.447C204.947 354.447 205.38 354.026 205.38 353.505V350.662L217.896 354.447H230.556C232.397 354.447 233.889 352.998 233.889 351.211C233.889 349.915 233.096 348.746 231.872 348.235Z" fill="#2F3D6C" />
            <path d="M166.978 333.24L166.985 340.018L185.289 345.601L184.526 342.02L183.005 341.266L184.334 333.24H166.978Z" fill="#FCBEB7" />
            <path d="M199.438 333.24L199.447 340.018L217.75 345.601L216.985 342.02L215.466 341.268L216.795 333.24H199.438Z" fill="#FCBEB7" />
            <path d="M153.848 237.133V237.77C153.835 253.773 167.19 266.751 183.669 266.751H197.521L199.438 333.241H216.796L229.594 253.964C231.021 245.123 223.983 237.134 214.769 237.134H153.848V237.133Z" fill="#2E53FF" />
            <path d="M196.33 258.936L197.521 266.751H194.993L196.33 258.936Z" fill="#4A5EA3" />
            <path d="M126.617 249.212C127.486 259.131 136.03 266.752 146.281 266.752H165.061L166.978 333.241H184.334L196.998 254.802C198.496 245.522 191.108 237.134 181.435 237.134H180.046L176.062 225.57H130.34L126.864 244.165C126.552 245.829 126.469 247.526 126.617 249.212Z" fill="#2E53FF" />
            <path d="M153.306 177.243C156.388 177.243 158.853 174.361 160.515 171.441H142.938C145.618 174.353 149.291 177.243 153.306 177.243Z" fill="white" />
            <path d="M186.337 177.689L185.12 175.851C183.541 173.464 181.32 171.565 178.718 170.345C177.993 170.006 177.24 169.719 176.461 169.489L162.919 165.484C162.919 165.96 162.067 168.718 160.515 171.444C158.853 174.363 156.388 177.245 153.306 177.245C149.291 177.245 145.619 174.356 142.938 171.444C140.218 168.49 138.52 165.514 138.52 165.514L126.419 170.959C121.467 173.187 117.82 177.475 116.502 182.619L111.108 199.358C110.185 202.223 109.714 205.207 109.714 208.209V209.596C109.714 213.194 112.719 216.11 116.424 216.11H129.325L130.339 225.569H176.061L187.584 179.575L186.337 177.689Z" fill="#FF59A9" />
            <path d="M153.756 181.396C151.214 181.396 148.628 180.645 146.068 179.163C144.036 177.987 142.017 176.351 140.065 174.298C136.752 170.816 134.733 167.26 134.713 167.225L135.393 166.919C135.412 166.954 137.37 170.4 140.624 173.818C142.526 175.816 144.489 177.407 146.458 178.545C148.898 179.956 151.353 180.671 153.756 180.671C156.024 180.671 158.067 179.935 159.828 178.486C161.253 177.312 162.503 175.67 163.542 173.606C165.328 170.056 165.875 166.393 165.88 166.357L166.622 166.577C166.6 166.727 166.054 170.257 164.221 173.909C163.137 176.068 161.823 177.792 160.318 179.033C158.417 180.602 156.209 181.396 153.756 181.396Z" fill="white" />
            <path d="M229.605 253.875C228.089 255.461 225.924 256.453 223.519 256.453C218.93 256.453 215.209 252.841 215.209 248.384C215.209 243.928 218.93 240.316 223.519 240.316C223.735 240.316 223.949 240.324 224.161 240.34C228.154 243.461 230.44 248.507 229.605 253.875Z" fill="#4A5EA3" />
            <path d="M197.188 252.219C195.783 254.741 193.037 256.453 189.878 256.453C185.289 256.453 181.57 252.841 181.57 248.384C181.57 243.928 185.289 240.316 189.878 240.316C190.325 240.316 190.763 240.351 191.191 240.417C194.819 243.204 197.131 247.494 197.188 252.219Z" fill="#4A5EA3" />
            <path d="M115.748 216.109H165.915V209.96L116.437 204.432C112.856 204.032 109.714 206.751 109.714 210.251C109.715 213.487 112.416 216.109 115.748 216.109Z" fill="#FCBEB7" />
            <path d="M228.675 179.572H177.416C175.361 179.572 173.554 180.894 172.987 182.813L164.124 212.793H144.552C143.627 212.793 142.838 213.491 142.806 214.389C142.772 215.332 143.549 216.107 144.513 216.107H169.068H221.098L231.009 182.581C231.454 181.075 230.29 179.572 228.675 179.572Z" fill="#4A5EA3" />
            <path d="M201.181 199.179C201.307 197.779 200.61 196.576 199.622 196.492C198.635 196.407 197.731 197.474 197.604 198.874C197.478 200.273 198.176 201.477 199.163 201.561C200.151 201.645 201.054 200.579 201.181 199.179Z" fill="#2F3D6C" />
            <path d="M130.339 225.57L128.51 235.354L177.112 228.621L176.061 225.57H130.339Z" fill="#4A5EA3" />
            <path d="M127.708 173.751C127.708 176.703 125.244 179.096 122.203 179.096C120.814 179.096 119.546 178.596 118.577 177.771C120.414 174.826 123.127 172.438 126.417 170.957L126.786 170.791C127.369 171.639 127.708 172.658 127.708 173.751Z" fill="#FF78BA" />
            <path d="M186.337 177.687C185.484 178.215 184.471 178.52 183.385 178.52C180.345 178.52 177.88 176.128 177.88 173.175C177.88 172.135 178.187 171.163 178.717 170.342C181.32 171.562 183.542 173.461 185.12 175.848L186.337 177.687Z" fill="#FF78BA" />
            <path d="M168.734 197.197C168.521 197.917 159.212 207.653 159.212 207.653L134.405 206.438L164.994 209.856L168.734 197.197Z" fill="#FF78BA" />
            <path d="M70.7455 221.361L66.2106 256.967L53.7955 354.444H50.4296L62.8096 257.246L67.3805 221.361H70.7455Z" fill="#D2DCFF" />
            <path d="M115.284 354.444H111.919L99.1595 254.269L94.9685 221.361H98.3335L102.49 253.996L115.284 354.444Z" fill="#D2DCFF" />
            <path d="M67.3805 221.361H70.7455L66.2106 256.967L62.8096 257.246L67.3805 221.361Z" fill="#AEC3FF" />
            <path d="M98.3335 221.361L102.49 253.996L99.1595 254.269L94.9685 221.361H98.3335Z" fill="#AEC3FF" />
            <path d="M284.058 354.444H280.693L266.193 240.589L263.744 221.361H267.109L269.524 240.316L284.058 354.444Z" fill="#D2DCFF" />
            <path d="M269.524 240.316L266.193 240.589L263.744 221.361H267.109L269.524 240.316Z" fill="#AEC3FF" />
            <path d="M276.028 216.109H104.391V221.361H276.028C277.521 221.361 278.732 220.185 278.732 218.735C278.732 217.285 277.521 216.109 276.028 216.109Z" fill="#D2DCFF" />
            <path d="M69.4546 216.109H59.3125C57.8195 216.109 56.6085 217.285 56.6085 218.735C56.6085 220.185 57.8195 221.361 59.3125 221.361H69.4546H104.391V216.109H69.4546V216.109Z" fill="#86A2E2" />
            <path d="M272.167 210.238V208.9C272.167 208.485 271.821 208.15 271.395 208.15H239.406C237.792 208.15 236.484 209.421 236.484 210.988V213.271C236.484 214.839 237.792 216.11 239.406 216.11H271.395C271.821 216.11 272.167 215.774 272.167 215.36V214.021C272.167 213.607 271.821 213.271 271.395 213.271H240.581C239.932 213.271 239.406 212.76 239.406 212.13C239.406 211.5 239.932 210.989 240.581 210.989H271.395C271.822 210.988 272.167 210.653 272.167 210.238Z" fill="#6277D7" />
            <path d="M270.786 210.988V213.271H240.582C239.933 213.271 239.406 212.761 239.406 212.13C239.406 211.5 239.933 210.989 240.582 210.989H270.786V210.988Z" fill="#D2DCFF" />
            <path d="M269.881 202.28V200.941C269.881 200.527 269.535 200.191 269.109 200.191H241.692C240.078 200.191 238.77 201.462 238.77 203.03V205.313C238.77 206.88 240.078 208.15 241.692 208.15H269.109C269.535 208.15 269.881 207.815 269.881 207.401V206.062C269.881 205.648 269.535 205.312 269.109 205.312H242.867C242.218 205.312 241.692 204.801 241.692 204.171C241.692 203.541 242.218 203.03 242.867 203.03H269.109C269.535 203.029 269.881 202.694 269.881 202.28Z" fill="#F9A0C0" />
            <path d="M268.865 203.029V205.312H242.867C242.218 205.312 241.692 204.802 241.692 204.171C241.692 203.541 242.218 203.03 242.867 203.03H268.865V203.029Z" fill="#FFD9E8" />
            <path d="M84.5215 203.34H99.6166C100.362 203.34 100.938 203.972 100.849 204.69L99.5725 215.048C99.4975 215.654 98.9685 216.111 98.3395 216.111H85.7986C85.1696 216.111 84.6405 215.654 84.5655 215.048L83.2885 204.69C83.2005 203.972 83.7775 203.34 84.5215 203.34Z" fill="#D2DCFF" />
            <path d="M97.2516 199.112V194.402L94.3495 199.756V191.061L92.0696 196.536L89.7896 191.061V199.756L86.8876 194.402V199.112L82.6586 192.509L84.5215 203.339H92.0696H99.6176L101.48 192.509L97.2516 199.112Z" fill="#6277D7" />
            <path d="M115.831 214.14C118.042 214.14 119.836 212.398 119.836 210.25C119.836 208.102 118.042 206.361 115.831 206.361C113.619 206.361 111.826 208.102 111.826 210.25C111.826 212.398 113.619 214.14 115.831 214.14Z" fill="#F9AFAB" />
            <path d="M468.496 271.627L466.248 289.739L463.546 311.486L461.848 325.158L461.27 329.795L459.572 343.471L458.14 355.001H454.743L457.875 329.798L459.572 316.123L460.149 311.486L461.848 297.813V297.81L462.974 288.737L465.099 271.627H468.496Z" fill="#D2DCFF" />
            <path d="M491.248 355.001H487.852L480.282 294.052L477.496 271.627H480.893L483.812 295.134L491.248 355.001Z" fill="#D2DCFF" />
            <path d="M446.197 271.627L444.765 283.144L438.971 329.795L437.273 343.471L435.841 355.001H432.443L435.573 329.798L437.273 316.123L441.495 282.139L442.8 271.627H446.197Z" fill="#D2DCFF" />
            <path d="M468.948 355.001H465.552L461.848 325.158L460.149 311.486L457.098 286.931L455.197 271.627H458.594L460.631 288.015L461.848 297.81V297.813L463.546 311.486L468.948 355.001Z" fill="#D2DCFF" />
            <path d="M483.186 271.626H439.109C437.644 271.626 436.458 270.473 436.458 269.051V268.902C436.458 267.479 437.645 266.326 439.109 266.326H483.186C484.651 266.326 485.838 267.48 485.838 268.902V269.051C485.838 270.473 484.651 271.626 483.186 271.626Z" fill="#D2DCFF" />
            <path d="M461.932 271.626H439.109C437.644 271.626 436.458 270.473 436.458 269.051V268.902C436.458 267.479 437.645 266.326 439.109 266.326H461.932C463.397 266.326 464.583 267.48 464.583 268.902V269.051C464.583 270.473 463.397 271.626 461.932 271.626Z" fill="#86A2E2" />
            <path d="M453.53 253.434H468.766C469.517 253.434 470.099 254.07 470.01 254.796L468.721 265.252C468.646 265.864 468.112 266.324 467.477 266.324H454.818C454.183 266.324 453.649 265.864 453.573 265.252L452.285 254.796C452.196 254.071 452.779 253.434 453.53 253.434Z" fill="#D2DCFF" />
            <path d="M466.379 249.168V244.414L463.448 249.818V241.041L461.148 246.568L458.847 241.041V249.818L455.917 244.414V249.168L451.65 242.504L453.53 253.434H461.148H468.766L470.646 242.504L466.379 249.168Z" fill="#FE9ECC" />
            <path d="M446.197 271.627L444.765 283.144L441.495 282.139L442.8 271.627H446.197Z" fill="#AEC3FF" />
            <path d="M460.631 288.015L457.098 286.931L455.197 271.627H458.594L460.631 288.015Z" fill="#AEC3FF" />
            <path d="M468.496 271.627L466.248 289.739L462.974 288.737L465.099 271.627H468.496Z" fill="#AEC3FF" />
            <path d="M483.812 295.134L480.282 294.052L477.496 271.627H480.893L483.812 295.134Z" fill="#AEC3FF" />
            <path d="M36.3205 301.194C48.1965 280.08 10.7065 270.151 1.66954 278.526C-7.36846 286.902 22.5935 325.6 36.3205 301.194Z" fill="#11BD60" />
            <path d="M52.0816 294.747C75.3866 294.747 64.8395 254.268 54.8755 254.268C42.3835 254.269 25.1556 294.747 52.0816 294.747Z" fill="#11BD60" />
            <path d="M70.0446 309.432C89.2586 324.621 106.145 290.635 99.3135 280.478C92.4815 270.321 47.8356 291.875 70.0446 309.432Z" fill="#11BD60" />
            <path d="M45.5425 319.48C42.2995 312.193 37.5526 305.403 31.4346 299.301C21.0256 288.92 10.0776 284.051 9.96759 284.003C9.64159 283.86 9.25753 284.001 9.11053 284.317C8.96353 284.633 9.10853 285.005 9.43353 285.149C9.54053 285.195 20.2995 289.987 30.5375 300.211C36.5375 306.201 41.1896 312.861 44.3636 320.005C44.7236 320.816 45.0586 321.634 45.3806 322.458H46.7656C46.3846 321.457 45.9795 320.463 45.5425 319.48Z" fill="#005E47" />
            <path d="M54.2965 262.569C54.3385 262.224 54.0845 261.912 53.7295 261.87C53.3735 261.828 53.0525 262.075 53.0105 262.42C52.9685 262.764 49.7005 289.891 49.8675 322.459H51.1624C50.9954 289.976 54.2545 262.912 54.2965 262.569Z" fill="#005E47" />
            <path d="M92.8416 285.026C92.6426 284.737 92.2405 284.659 91.9435 284.852C91.8505 284.913 82.5025 291.019 72.8855 301.062C67.6475 306.531 61.6935 313.865 57.3315 322.459H58.7795C63.0435 314.244 68.7655 307.214 73.8135 301.94C83.3295 291.999 92.5706 285.958 92.6636 285.898C92.9596 285.706 93.0396 285.316 92.8416 285.026Z" fill="#005E47" />
            <path d="M51.1625 322.459H49.8675H46.7665H45.3815H27.3046C26.6096 322.459 26.1165 323.118 26.3315 323.76L27.4725 327.16L28.0505 328.881L36.3115 353.494C36.5355 354.163 37.1775 354.615 37.9005 354.615H67.6775C68.4005 354.615 69.0425 354.163 69.2665 353.494L77.5276 328.881L78.1045 327.16L79.2455 323.76C79.4615 323.118 78.9686 322.459 78.2736 322.459H58.7755H57.3275H51.1625Z" fill="#FF5A5A" />
            <path d="M78.1066 327.16L77.5295 328.88H28.0505L27.4735 327.16H78.1066Z" fill="#AEC3FF" />
        </svg>


    );
};


export default PricingCoverIcon;