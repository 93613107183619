import { FC, ReactNode } from "react";
import { Container } from "@mui/material";
import PropTypes from "prop-types";
import { styled as experimentalStyled } from "@mui/material/styles";
import { Box } from "@mui/material";

const PageTitle = experimentalStyled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(2, 0)};
`
);

interface PageTitleWrapperProps {
  children?: ReactNode;
}

const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children }) => {
  return (
    <>
      <PageTitle>
        <Container maxWidth="xl">{children}</Container>
      </PageTitle>
    </>
  );
};

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitleWrapper;
