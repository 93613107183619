import { Grid, Hidden } from '@mui/material';
import React from 'react'
// import { useNavigate } from 'react-router-dom';
import { ButtonPrimary, ButtonSecondary } from 'src/components/CustomButtons';
import { audioVideoText } from 'src/constants/string';
import { FeedbackContainer, ButtonBox, CustomGrid, FeatureContent, FeatureGridContainerRow, FeatureGridContainerRowReverse, FeatureHeading, FeatureWrapper, HeadingSubTitle, HeadingTitle, HeadingWrapper, FeedbackHeading, FeedbackSubHeading, FeedbackTitle } from './productStyle';
import { AVProductCoverIcon, AVProductFeatureIcon1, AVProductFeatureIcon2, AVProductFeatureIcon3, AVProductFeatureIcon4, AVProductFeatureIcon5, AVProductFeatureIcon6 } from 'src/assets/icons/productPageIcon';
import PersonIcon from 'src/assets/icons/personIcon';

const AudioVideo = () => {
  // const navigate = useNavigate();
  return (
    <>
      <CustomGrid container sx={{ width: { xs: '95%', sm: '90%', md: '75%', lg: '85%' }, margin: 'auto', padding: '10px', }} columnGap={4} rowGap={2}>
        <CustomGrid item xs={12} sm={6} md={5} lg={5.5} xl={4} >
          <HeadingWrapper>
            <HeadingTitle>{audioVideoText.title} </HeadingTitle>
            <HeadingSubTitle>{audioVideoText.subTitle}</HeadingSubTitle>
            <ButtonBox>
              <ButtonPrimary
                id="try-now-its-free"
                responsive={false}
                fixedHeight="40px"
              // onClick={() => navigate("/contact-us")}
              >
                {audioVideoText.buttonLeft}
              </ButtonPrimary>
              <ButtonSecondary
                id="talk-to-us"
                responsive={false}
                fixedHeight="40px"
              // onClick={() => navigate("/pages/get-demo")}
              >
                {audioVideoText.buttonRight}
              </ButtonSecondary>
            </ButtonBox>
          </HeadingWrapper>
        </CustomGrid>
        <CustomGrid item xs={12} sm={5} md={6} lg={6} xl={7}>
          <Hidden mdDown>
            <AVProductCoverIcon props={{ width: '700px', height: '500px' }} />
          </Hidden>
          <Hidden only={['xs', 'md', 'lg', 'xl']}>
            <AVProductCoverIcon props={{ width: '300px', height: '350px' }} />
          </Hidden>
          <Hidden smUp>
            <AVProductCoverIcon props={{ width: '250px', height: '250px' }} />
          </Hidden>
        </CustomGrid>
      </CustomGrid>
      <div style={{ marginTop: '10px' }}>
        <FeatureGridContainerRow container sx={{ width: { xs: '95%', sm: '85%', md: '75%', lg: '80%' } }} columnGap={5} rowGap={2}>
          <CustomGrid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} >
            <Hidden mdDown>
              <AVProductFeatureIcon1 props={{ width: '500px', height: '300px' }} />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
              <AVProductFeatureIcon1 props={{ width: '500px', height: '250px' }} />
            </Hidden>
            <Hidden smUp>
              <AVProductFeatureIcon1 props={{ width: '250px', height: '200px' }} />
            </Hidden>
          </CustomGrid>
          <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
            <FeatureWrapper>
              <FeatureHeading>{audioVideoText.firstFeatureTitle} </FeatureHeading>
              <FeatureContent>{audioVideoText.firstFeatureContent}</FeatureContent>
            </FeatureWrapper>
          </Grid>
        </FeatureGridContainerRow>

        <FeatureGridContainerRowReverse container sx={{ width: { xs: '95%', sm: '85%', md: '75%', lg: '80%' } }} columnGap={5} rowGap={2}>
          <CustomGrid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5}>
            <Hidden mdDown>
              <AVProductFeatureIcon2 props={{ width: '500px', height: '300px' }} />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
              <AVProductFeatureIcon2 props={{ width: '500px', height: '250px' }} />
            </Hidden>
            <Hidden smUp>
              <AVProductFeatureIcon2 props={{ width: '250px', height: '200px' }} />
            </Hidden>
          </CustomGrid>
          <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
            <FeatureWrapper>
              <FeatureHeading>{audioVideoText.secondFeatureTitle} </FeatureHeading>
              <FeatureContent>{audioVideoText.secondFeatureContent}</FeatureContent>
            </FeatureWrapper>
          </Grid>
        </FeatureGridContainerRowReverse>

        <FeatureGridContainerRow container sx={{ width: { xs: '95%', sm: '85%', md: '75%', lg: '80%' } }} columnGap={5} rowGap={2}>
          <CustomGrid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5}>
            <Hidden mdDown>
              <AVProductFeatureIcon3 props={{ width: '500px', height: '450px' }} />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
              <AVProductFeatureIcon3 props={{ width: '500px', height: '250px' }} />
            </Hidden>
            <Hidden smUp>
              <AVProductFeatureIcon3 props={{ width: '250px', height: '200px' }} />
            </Hidden>
          </CustomGrid>
          <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
            <FeatureWrapper>
              <FeatureHeading>{audioVideoText.thirdFeatureTitle} </FeatureHeading>
              <FeatureContent>{audioVideoText.thirdFeatureContent}</FeatureContent>
            </FeatureWrapper>
          </Grid>
        </FeatureGridContainerRow>

        <FeatureGridContainerRowReverse container sx={{ width: { xs: '95%', sm: '85%', md: '75%', lg: '80%' } }} columnGap={5} rowGap={2}>
          <CustomGrid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5}>
            <Hidden mdDown>
              <AVProductFeatureIcon4 props={{ width: '500px', height: '400px' }} />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
              <AVProductFeatureIcon4 props={{ width: '500px', height: '250px' }} />
            </Hidden>
            <Hidden smUp>
              <AVProductFeatureIcon4 props={{ width: '250px', height: '200px' }} />
            </Hidden>
          </CustomGrid>
          <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
            <FeatureWrapper>
              <FeatureHeading>{audioVideoText.fourthFeatureTitle} </FeatureHeading>
              <FeatureContent>{audioVideoText.fourthFeatureContent}</FeatureContent>
            </FeatureWrapper>
          </Grid>
        </FeatureGridContainerRowReverse>

        <FeatureGridContainerRow container sx={{ width: { xs: '95%', sm: '85%', md: '75%', lg: '80%' } }} columnGap={5} rowGap={2}>
          <CustomGrid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5}>
            <Hidden mdDown>
              <AVProductFeatureIcon5 props={{ width: '500px', height: '300px' }} />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
              <AVProductFeatureIcon5 props={{ width: '500px', height: '250px' }} />
            </Hidden>
            <Hidden smUp>
              <AVProductFeatureIcon5 props={{ width: '250px', height: '200px' }} />
            </Hidden>
          </CustomGrid>
          <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
            <FeatureWrapper>
              <FeatureHeading>{audioVideoText.fifthFeatureTitle} </FeatureHeading>
              <FeatureContent>{audioVideoText.fifthFeatureContent}</FeatureContent>
            </FeatureWrapper>
          </Grid>
        </FeatureGridContainerRow>

        <FeatureGridContainerRowReverse container sx={{ width: { xs: '95%', sm: '85%', md: '75%', lg: '80%' } }} columnGap={5} rowGap={2}>
          <CustomGrid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5}>
            <Hidden mdDown>
              <AVProductFeatureIcon6 props={{ width: '500px', height: '400px' }} />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
              <AVProductFeatureIcon6 props={{ width: '500px', height: '250px' }} />
            </Hidden>
            <Hidden smUp>
              <AVProductFeatureIcon6 props={{ width: '250px', height: '200px' }} />
            </Hidden>
          </CustomGrid>
          <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
            <FeatureWrapper>
              <FeatureHeading>{audioVideoText.sixthFeatureTitle} </FeatureHeading>
              <FeatureContent>{audioVideoText.sixthFeatureContent}</FeatureContent>
            </FeatureWrapper>
          </Grid>
        </FeatureGridContainerRowReverse>
      </div >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <FeedbackTitle>See why customers choose Us </FeedbackTitle>
        <FeedbackContainer container sx={{ width: { xs: '90%', sm: '85%', md: '75%', lg: '80%' } }} columnGap={2} rowGap={2}>
          <Grid item sm={4} md={3} lg={2} xl={2}><PersonIcon /></Grid>
          <Grid item sm={7} md={8} lg={8} xl={8}>
            <FeedbackHeading>A cost-effective cloud-based communication platform that is simple to integrate was our goal, and Portego proved to be the perfect solution. With Portego, launching chat, video, and voice communication within our app is a breeze.</FeedbackHeading>
            <FeedbackSubHeading sx={{fontWeight:700}}>JUAN GARCIA</FeedbackSubHeading>
            <FeedbackSubHeading>Senior Developer, Digital Buzz Corporation</FeedbackSubHeading>
          </Grid>
        </FeedbackContainer>
      </div>
    </>
  )
}

export default AudioVideo;