import HeaderUserbox from "./Userbox";
import {
  Box,
  Hidden,
  IconButton,
  alpha,
  Tooltip,
  Typography,
  Dialog,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "./Logo";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import SidebarMobile from "src/layouts/AccentSidebarLayout/Sidebar/SidebarMobile";
import { SetOpenSidebar } from "src/slices/Home";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import MenuIcon from "src/assets/icons/MenuIcon";
import Bell from "src/assets/icons/Bell";
import { BellContainer } from "src/components/CustomButtons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationStatus, getNotificationsData1, updateNotificationsData } from "src/slices/Notifications/Notification";
import { setActivityTabs } from "src/slices/Conference/ConferenceTable";
import { getUserDetails } from "src/slices/profile";
const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    height: 60px;
    color: black;
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    background: white;
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
    border-bottom: 1px solid #E8E8E8;
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-left: ${theme.spacing(2)};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

function Header() {
  const { openSidebar } = useSelector((state: RootState) => state.Home);
  const { notificationsData1, meta } = useSelector(
    (state: RootState) => state.notifications
  );
  const [currentNotificationData, setCurrentNotificationData] = useState(0);
  const { oldProfileData } = useSelector((state: RootState) => state.profile);
  const [isUnread, setUnread] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSidebar = () => {
    dispatch(SetOpenSidebar(!openSidebar));
  };

  const handleNotificationOpen = (): void => {
    setUnread(!isUnread);
  };

  const closeSidebar = () => {
    dispatch(SetOpenSidebar(false));
  };
  useEffect(() => {
    dispatch(getNotificationsData1());
  }, [dispatch]);

  const handleViewAll = () => {
    navigate("/activity/notifications");
    dispatch(setActivityTabs("/activity/notifications"));
    dispatch(updateNotificationsData({ page: meta.page, per_page: meta.per_page }))
    setUnread(false);
    if (currentNotificationData < notificationsData1.length) {
      dispatch(NotificationStatus(false));
      setCurrentNotificationData(notificationsData1.length);
    }
  };

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(updateNotificationsData({ page: meta.page, per_page: meta.per_page }))
    //eslint-disable-next-line
  }, []);




  const handleNotificationTime = (createdDate) => {
    const currentDate = new Date();
    const difference = currentDate.valueOf() - new Date(createdDate).valueOf();
    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);

    if (daysDifference > 0) {
      return daysDifference + " days ago";
    } else {
      return hoursDifference + " hours ago";
    }
  };
  return (
    <HeaderWrapper>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          ml: { xl: "12px", lg: "12px", md: "12px", sm: "12px", xs: "0px" },
        }}
      >
        <Logo />
      </Box>
      <Box display="flex" alignItems="center">
        <Hidden lgDown>
          <Box sx={{ display: "flex", alignItems: "center", pl: "8px" }}>
            {oldProfileData.firstName ? (
              <Typography sx={{ width: "auto", marginRight: "10px" }}>
                Hi, {oldProfileData?.firstName}
              </Typography>
            )
              : (
                <Typography sx={{ width: "auto", marginRight: "10px" }}>
                  Hi
                </Typography>
              )}
            <HeaderUserbox />
            <BellContainer onClick={handleNotificationOpen} id="notification">
              <IconButton>
                <Bell
                  props={{
                    height: "40px",
                    width: "40px",
                  }}
                />
              </IconButton>
            </BellContainer>
            <Hidden only={["md", "sm", "xs"]}>
              <Dialog open={isUnread} onClose={() => setUnread(false)}>
                <Box
                  sx={{
                    zIndex: 1,
                    position: "fixed",
                    background: "white",
                    // background:'yellow',
                    top: "6vh",
                    right: "40px",
                    borderRadius: "4px",
                    width: "280px",
                    // maxHeight: '280px',
                    minHeight: "30vh",
                    maxHeight: "82vh",
                    overflow: "scroll",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        p: 1,
                        color: "#3087EC",
                        fontFamily: "Poppins",
                        textAlign: "center",
                        borderBottom: "1px solid #eee",
                        position: "sticky",
                        top: 0,
                        background: "white",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      Notifications
                    </Box>
                    {notificationsData1?.length === 0 && (
                      <Box
                        sx={{
                          p: 1,
                          fontFamily: "Poppins",
                          textAlign: "center",
                          background: "white",
                          fontSize: "12px",
                          fontWeight: 400,
                          height: "30vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No Notifications Available
                      </Box>
                    )}
                    {/* data */}
                    <Box sx={{ height: "400px", overflow: "auto" }}>
                      {notificationsData1?.map((notification, index) => (
                        <Box
                          key={notification?.id}
                        // onClick={() => handleNotificationClick(notification)}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              padding: "10px",
                              borderBottom: "1px solid #eee",
                              cursor: "pointer",

                            }}
                          >
                            <Box sx={{ ml: "15px" }}>
                              <Typography
                                sx={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "10px",
                                  color: "#3087EC",
                                }}
                              >
                                {notification?.title}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "10px",
                                }}
                              >
                                {notification?.desc === ""
                                  ? "Not available"
                                  : notification?.desc}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "10px",
                                  color: "#686868",
                                }}
                              >
                                {handleNotificationTime(
                                  notification?.createdDate
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  {notificationsData1?.length > 0 && (
                    <Box sx={{ p: 1 }}>
                      <Button
                        size="small"
                        variant="text"
                        sx={{
                          color: "#3087EC",
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "13px",
                          fontFamily: "Poppins",
                          cursor: "ponter",
                          left: 0,
                          bottom: 2,
                          margin: "auto",
                          alignSelf: "center",
                          "&:hover": {
                            background: "none",
                          },
                        }}
                        onClick={handleViewAll}
                      >
                        {/* <Typography>View all</Typography> */}
                        View all
                      </Button>
                    </Box>
                  )}
                </Box>
              </Dialog>
            </Hidden>
          </Box>
        </Hidden>

        <Hidden lgUp>
          <Tooltip arrow title="Menu">
            <IconButtonPrimary
              sx={{ color: "#3086EC", "&:hover": { color: "#3086EC" } }}
              color="primary"
              onClick={handleSidebar}
              id="account-button"
            >
              {!openSidebar ? (
                <MenuIcon props={{ width: "18px", height: "16px" }} />
              ) : (
                <CloseTwoToneIcon
                  style={{
                    color: "black",
                  }}
                />
              )}
            </IconButtonPrimary>
          </Tooltip>
        </Hidden>
      </Box>
      <SidebarMobile open={openSidebar} close={closeSidebar} />
    </HeaderWrapper>
  );
}

export default Header;
