import React from 'react';
function BannerMobile({ props }) {
    return (
        <>
            <svg width={props.width} height={props.width} fill="none" xmlns="http://www.w3.org/2000/svg">
                <g mask="url(#mask0_8853_33692)">
                    <rect y="-20" width="1200" height="208.333" fill="#3087EC" />
                    <circle cx="1059.5" cy="-2.5" r="252.5" fill="#0062FD" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M656.666 137.374L720.833 45.7998L656.666 -45.774V-46.165H656.392L656.041 -46.666V-46.165H203.333V138.334H656.666V137.374Z" fill="#9AC8FF" />
                    <circle cx="120.417" cy="90.4167" r="162.917" fill="#0062FD" />
                    <circle cx="184.166" cy="2.49934" r="160.833" fill="#438DE3" />
                    <path d="M504.167 -95V34.1667H386.667L504.167 -95Z" fill="#0062FD" />
                    <path d="M504.167 34.166V163.333H386.667L504.167 34.166Z" fill="#599DED" />
                    <path d="M621.667 -95V34.1667H504.167L621.667 -95Z" fill="#599DED" />
                    <path d="M621.667 34.166V163.333H504.167L621.667 34.166Z" fill="#0062FD" />
                    <rect opacity="0.2" y="-20" width="1200" height="208.333" fill="#171717" />
                </g>
            </svg>


        </>
    );
}

export default BannerMobile;

