import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import { SuccessSnackbar } from "../Snackbar";

interface InotificationsData {
  id: string;
  userId: string;
  appId: string;
  context: {};
  desc: string;
  title: string;
  read: boolean;
  createdDate: string;
}

interface notificationsPayload {
  notificationsData: InotificationsData[];
  notificationsData1: InotificationsData[];
  meta: {
    page: number;
    per_page: number;
    page_count: number;
    total_count: number;
  };
  loading: boolean;
  readStatus: boolean;
}

const initialState: notificationsPayload = {
  notificationsData: [],
  notificationsData1: [],
  meta: {
    page: 1,
    per_page: 5,
    page_count: 0,
    total_count: 0,
  },
  loading: false,
  readStatus: false,
};

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setConferenceTableData(state, action) {
      state.notificationsData = action.payload;
    },
    setConferenceTableData1(state, action) {
      state.notificationsData1 = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setReadStatus(state, action) {
      state.readStatus = action.payload;
    },
  },
});

export const ConferenceAction = slice.actions;
const BASE_URL = process.env.REACT_APP_API_URL;
export const getNotificationsData =
  (data: any, range: any) => async (dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const responseData = await axios.get(
        `${BASE_URL}/api/v1/notifications?page=${data.page}&per_page=${data.per_page}&meta=true&from_date=${range?.startDate}&to_date=${range?.endDate}`
      );
      if (responseData?.data?.status) {
        dispatch(
          slice.actions.setConferenceTableData(responseData?.data?.data)
        );
        dispatch(slice.actions.setMeta(responseData?.data?.meta));
        // dispatch(
        //   SuccessSnackbar({
        //     message: responseData?.data?.message,
        //     open: true,
        //   })
        // );
      } else {
        dispatch(slice.actions.setConferenceTableData([]));
        dispatch(
          slice.actions.setMeta({
            page: 1,
            per_page: 5,
            page_count: 0,
            total_count: 0,
          })
        );
        throw new Error(responseData.data.message);
      }
    } catch (error) {
      //dispatch(FailureSnackbar({ message: error?.detail[0]?.msg  || "something went wrong", open: true }));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

export const getNotificationsData1 = () => async (dispatch) => {
  //dispatch(slice.actions.setLoading(true));
  try {
    const responseData = await axios.get(
      `${BASE_URL}/api/v1/notifications?page=1&per_page=10&meta=true`
    );
    if (responseData?.data?.status) {
      dispatch(slice.actions.setConferenceTableData1(responseData?.data?.data));
    } else {
      throw new Error(responseData.data.message);
    }
  } catch (error) {
    //dispatch(FailureSnackbar({ message: error?.detail[0]?.msg  || "something went wrong", open: true }));
  } finally {
    //dispatch(slice.actions.setLoading(false));
  }
};

export const NotificationStatus = (data)=>(dispatch)=>{
  dispatch(slice.actions.setReadStatus(data));
}

export const updateNotificationsData = (data) => async (dispatch) => {
  dispatch(slice.actions.setLoading(true));
  try {
    const responseData = await axios.post(`${BASE_URL}/api/v1/notifications`);
    if (responseData?.data?.status) {
      SuccessSnackbar({
        message: responseData?.data?.message,
        open: true,
      });
      dispatch(slice.actions.setReadStatus(true));
      // dispatch(getNotificationsData(data));
    } else {
      throw new Error(responseData.data.message);
    }
  } catch (error) {
    // dispatch(
    //   FailureSnackbar({
    //     message: error?.message || "something went wrong",
    //     open: true,
    //   })
    // );
    throw new Error(error);
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
export const reducer = slice.reducer;

export default slice;
