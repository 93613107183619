import React, { ChangeEvent, useState } from 'react';
import { Box, CardContent, Tabs, Tab, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { experimentalStyled } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Preferences from './Preferences';
import Recording from './Recording';
import { TabContext } from '@mui/lab';
import { IconContext } from 'react-icons';
import { GrCopy, GrDocumentUpdate } from 'react-icons/gr';
import { TabBox } from './indexStyled';


const DesktopVersion = styled(Box)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`
const MobileVersion = styled(Box)`
  display: none;
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
  }
`

const TabsContainerWrapper = experimentalStyled(CardContent)(
    ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
`,
)

function Index() {
    const { t }: { t: any } = useTranslation()

    const [currentTab, setCurrentTab] = useState<string>('Recordings')
    const tabs = [
        { value: 'Recordings', label: t('Recordings') },
        { value: 'Preferences', label: t('Preferences') }
    ]
    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value)
    }

    return (
        <>
            {/* desktop version */}
            <DesktopVersion>
                <Grid
                    container
                    style={{
                        // position: 'sticky',
                        // top: 0,
                        zIndex: 100,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        height: 'auto',
                        width: '100%',
                    }}
                >
                    <Grid item xs={12} md={12} lg={112} xl={12}>
                        <TabsContainerWrapper>
                            <Tabs
                                onChange={handleTabsChange}
                                value={currentTab}
                                scrollButtons="auto"
                                textColor="primary"
                                TabIndicatorProps={{
                                    style: {
                                      backgroundColor: "#3087EC",
                                      border: "none",
                                      boxShadow: 'none'
                                    }
                                  }}
                            >
                                {tabs.map((tab) => (
                                    <Tab key={tab.value} label={tab.label} value={tab.value}/>
                                ))}
                            </Tabs>
                        </TabsContainerWrapper>
                    </Grid>
                </Grid>

                <Box>
                    {currentTab === 'Recordings' && (
                        <>
                            <Recording />
                        </>
                    )}
                    {currentTab === 'Preferences' && (
                        <>
                            <Preferences />
                        </>
                    )}
                </Box>
            </DesktopVersion>
            {/* mobile version */}
            <MobileVersion>
                <TabContext value='1'>
                    <Box style={{ margin: '20px 0' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} style={{ padding: '10px' }}>
                                <div className="column_center flex_center" onClick={(ev) => handleTabsChange(ev, 'Recordings')}>
                                    <IconContext.Provider value={{ size: '20' }}>
                                        <GrCopy />
                                    </IconContext.Provider>
                                    <span>Recordings</span>
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{ padding: '10px' }}>
                                <div className="column_center flex_center" onClick={(ev) => handleTabsChange(ev, 'Preferences')}>
                                    <IconContext.Provider value={{ size: '20' }}>
                                        <GrDocumentUpdate />
                                    </IconContext.Provider>
                                    <span>Preferences</span>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    {currentTab === 'Recordings' && (
                        <TabBox>
                            <Recording />
                        </TabBox>
                    )}
                    {currentTab === 'Preferences' && (
                        <TabBox>
                            <Preferences />
                        </TabBox>
                    )}
                </TabContext>
            </MobileVersion>
        </>
    )
}
export default Index;
