import { createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axios";
//import axios1 from "axios";
import { FailureSnackbar } from "./Snackbar";
//import { getToken } from "src/contexts/Auth0Context";
//import { FailureSnackbar } from "./Snackbar";
// import { Auth0Util } from 'src/utils/Auth0GetToken';

interface portegoDev {
  activeUserCount: number;
  activeUserCountTag: string;
  discription: string;
  recording: number;
  RTMP: number;
  transcription: number;
  PSTNOutboundFirst: number;
  PSTNOutboundSecond: number;
  PSTNInbound: number;
  SIPInOut: number;
}

interface outstanding {
  //top of the table data
  billingDate: string;
  id: string;
  credits: {
    units: number
  }
}

interface invoice {
  // table data array of objects
  invoiceId: string;
  invoiceNumber: string;
  createdDate: string
  credits: number;
  properties: {
    platformCharges: {
      totalCost: number
      currencyType: string,
    }
  }
  type: string;
  status: string;
  userId: string;
  id: string;
  issueDate: string;
  dueDate: string;
}

interface addPayment {
  name: string;
  cardNumber: number;
  expirationDate: string;
  CVCcode: number;
  NameOnTheCard: string;
  zipCose: number;
  country: string;
  couponCode: string;
}
interface IPaymentSuccessData {
  action: boolean
  payment: boolean
  paymentId: string
}
interface billing {
  portegoDev: portegoDev;
  addPayment: addPayment;

  outstanding: outstanding;
  invoice: invoice[];
  meta: {
    page: number;
    per_page: number;
    page_count: number;
    total_count: number;
  };
  invoiceLoading: boolean;
  selectedUnits: number;
  currencyConverter: number;
  selectedCurrency: string;
  selectedPaymentMethod: string;
  paymentSuccess: IPaymentSuccessData;
  amountLoader:boolean;
}

const initialState: billing = {
  portegoDev: {
    activeUserCount: 25,
    activeUserCountTag: "Free",
    discription:
      "Your Plan includes a fixed number of monthly active users (MAU*).If you exceed your includes MAU,we will apply an overage charge of $0.99 per MAU",
    recording: 0.01,
    RTMP: 0.01,
    transcription: 0.01,
    PSTNOutboundFirst: 0.01,
    PSTNOutboundSecond: 0.03,
    PSTNInbound: 2,
    SIPInOut: 0.06,
  },

  addPayment: {
    name: "",
    cardNumber: null,
    expirationDate: "",
    CVCcode: null,
    NameOnTheCard: "",
    zipCose: null,
    country: "",
    couponCode: "",
  },
  outstanding: {
    billingDate: "",
    id: "",
    credits: {
      units: 0
    }
  },
  invoice: [],
  meta: {
    page: 1,
    per_page: 5,
    page_count: 0,
    total_count: 0,
  },
  invoiceLoading: false,
  selectedUnits: 10,
  currencyConverter: 1,
  selectedCurrency: 'USD',
  selectedPaymentMethod: 'Razor Pay',
  paymentSuccess: {
    action: false,
    payment: false,
    paymentId: '',
  },
  amountLoader: false,
};

const slice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    portegoDevReducer(state, action) {
      state.portegoDev = action.payload;
    },
    addPaymentReducer(state, action) {
      state.addPayment[action.payload.key] = action.payload.value;
    },
    outstandingReducer(state, action) {
      state.outstanding = action.payload;
    },
    invoiceReducer(state, action) {
      state.invoice = action.payload.data;
    },
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setInvoiceLoading(state, action) {
      state.invoiceLoading = action.payload;
    },
    setSelectedUnits(state, action) {
      state.selectedUnits = action.payload;
    },
    SelectedCurrency(state, action) {
      state.selectedCurrency = action.payload;
    },
    SelectedPaymentMethod(state, action) {
      state.selectedPaymentMethod = action.payload;
    },
    PaymentSuccess(state, action) {
      state.paymentSuccess = action.payload;
    },
    SetAmountLoader(state, action) {
      state.amountLoader = action.payload;
    },
  },
});

const BASE_URL = process.env.REACT_APP_API_URL;
let RAZOR_PAY_KEY = process.env.REACT_APP_RAZOR_PAY_KEY;
export const editPortegoDev = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.portegoDevReducer(data));
};

export const editOutstanding = (token) => async (dispatch: any) => {
  // const token = await Auth0Util.getAccessTokenSilently()();
  dispatch(slice.actions.SetAmountLoader(true));
  try {
    const outstandingResponse = await axios.get(`${BASE_URL}/api/v1/license`, { headers: { Authorization: `Bearer ${token}`, 'Cache-Control': 'no-cache' } });
    if (outstandingResponse?.data.status) {
      dispatch(slice.actions.outstandingReducer(outstandingResponse.data.data));
    } else {
      throw new Error(outstandingResponse.data.message)
    }
  } catch (error) {
    // dispatch(slice.actions.outstandingReducer([{amount: {
    //   unit: "$",
    //   value: 0
    // }}]));
    dispatch(FailureSnackbar({ message: error?.message || "something went wrong", open: true, }));
  }finally{
    dispatch(slice.actions.SetAmountLoader(false));
  }
};

export const editInvoice = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setInvoiceLoading(true));
  try {
    const invoice = await axios.get(
      `${BASE_URL}/api/v1/billing/invoices?page=${data?.page}&per_page=${data?.per_page}`
    );
    if (invoice.data.status) {
      dispatch(slice.actions.invoiceReducer(invoice.data));
      dispatch(slice.actions.setMeta(invoice?.data?.meta));
    } else {
      //dispatch(slice.actions.invoiceReducer([]));
      dispatch(
        slice.actions.setMeta({
          page: 1,
          per_page: 5,
          page_count: 0,
          total_count: 0,
        })
      );
      throw new Error(invoice?.data.message);
    }
  } catch (error) {
    //dispatch( FailureSnackbar({ message: error?.message || "something went wrong", open: true,}));
  } finally {
    dispatch(slice.actions.setInvoiceLoading(false));
  }
};

export const PaymentMethod = (paymentData, token) => async (dispatch, getState) => {
  const { data, Razorpay } = paymentData;
  const totalAmount = parseFloat(
    (data.selectedUnits * data.currencyConverter * 1.18).toFixed(2),
  );
  let paymentHistResponse;
  if (data.paymentMode === 'Razor Pay') {
    try {
      const payloadBody = {
        type: 'invoice',
        subType: 'platform',
        credits: data.selectedUnits,
        totalCost: totalAmount * 100,
        snapshot: {},
        currencyType: data.selectedCurrency,
        exchangeRate: data.currencyConverter,
        unit: data.selectedUnits,
        paymentGatewayName: 'razorpay',
        contextId: data.contextId,
      };

      paymentHistResponse = await axios.post(
        `${BASE_URL}/api/v1/invoice/create-order`,
        payloadBody

      );

      if (!paymentHistResponse?.data?.data?.orderId) {
        throw new Error('Order creation failed');
      }
    } catch (error) {
      dispatch(
        FailureSnackbar({ message: 'Order creation failed', open: true }),
      );
      return;
    }

    const options = {
      key: RAZOR_PAY_KEY,
      amount: `${totalAmount * 100}`,
      currency: data.selectedCurrency,
      name: 'Portego',
      description: 'Transaction',
      order_id: paymentHistResponse?.data?.data?.orderId,
      handler: (res: any) => {
        dispatch(
          slice.actions.PaymentSuccess({
            action: true,
            payment: true,
            paymentId: res.razorpay_payment_id,
          }),
        );
        setTimeout(() => {
          dispatch(
            slice.actions.PaymentSuccess({
              action: false,
              payment: false,
              paymentId: '',
            }),
          );
        }, 5000);

        dispatch(editInvoice({ page: 1, per_page: 5 }));
        dispatch(editOutstanding(token));
      },
      prefill: {
        name: "Name",
        email: data.userEmail,
        contact: '',
      },
      theme: {
        color: '#3399cc',
      },
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
  }
};

export const PaymentSuccess = (data) => async (dispatch) => {
  dispatch(slice.actions.PaymentSuccess(data));
};
export const editAddPaymentPost = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.addPaymentReducer(data));
};

export const SelectedUnits = (data) => (dispatch) => {
  dispatch(slice.actions.setSelectedUnits(data));
};

export const SelectedCurrency = (data) => (dispatch) => {
  dispatch(slice.actions.SelectedCurrency(data));
};
export const SelectedPaymentMethod = (data) => (dispatch) => {
  dispatch(slice.actions.SelectedPaymentMethod(data));
};

export const reducer = slice.reducer;
export default slice;
