import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../utils/axios"
import { FailureSnackbar } from "src/slices/Snackbar";

const EMPTY_FORM = {
  contactUs: {
    fullName: "",
    email: "",
    phoneNumber: "",
    website: '',
    jobTitle: "",
    readTermsAndContdions: false,
  },
  successfull: false
};

interface IDemoRequest {
  contactUs: {
    fullName: string,
    email: string,
    phoneNumber: string,
    website: string,
    jobTitle: string,
    readTermsAndContdions: boolean,
  },
  successfull: boolean;
}

const initialState: IDemoRequest = {
  contactUs: {
    fullName: '',
    email: '',
    phoneNumber: '',
    website: '',
    jobTitle: '',
    readTermsAndContdions: false,
  },
  successfull: false,
};

const slice = createSlice({
  name: "contactUs",
  initialState,

  reducers: {
    storeCallRequestdata(state, action) {


      state.contactUs[action.payload.name] = action.payload.value;
    },

    setSuccess(state, action) {
      state.successfull = action.payload;
    },
    clearCallRequestData(state: any, action) {
      state.contactUs = action.payload;
    },
  },
});

export const ContactUsSliceAction = slice.actions;

export const callRequestFormChange = (data) => async (dispatch) => {
  dispatch(slice.actions.storeCallRequestdata(data));
};


export const callRequestSubmit = (data: any) => async (dispatch) => {

  const data1 = {
    fullName: data.fullName,
    email: data.email,
    phoneNumber: +data.phoneNumber,
    website: data.website,
    jobTitle: data.jobTitle,
    readTermsAndContdions: data.readTermsAndContdions,
  };
  const BASE_URL = process.env.REACT_APP_API_URL;
  await axios
    .post(`${BASE_URL}/api/v1/contact-us`, data1)
    .then(() => {
      dispatch(ContactUsSliceAction.setSuccess(true));
      // dispatch(SuccessSnackbar({
      //   message:res.data.message,
      //   open:true
      // }));

    })
    .catch((err) =>
      dispatch(
        FailureSnackbar({
          message: err.message,
          open: true,
        })
      )

    );

};

export const emptyContactusForm = () => async (dispatch: any) => {
  dispatch(slice.actions.clearCallRequestData(EMPTY_FORM.contactUs));
};

export const reducer = slice.reducer;

export default slice;
