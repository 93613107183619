import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
// import { FailureSnackbar} from "../Snackbar";
interface IwebHookHitsData {
  appId: string;
  hookName: string;
  status: boolean;
  createdDate: string;
  webhookId: string;
}
interface webhookHitsPayload {
  webHookHitsData: IwebHookHitsData[];
  meta: {
    page: number;
    per_page: number;
    page_count: number;
    total_count: number;
  };
  appIdData: {
    appId: string;
    name: string;
  };
  hookName: {
    id: string;
    name: string;
    value: string;
  };
  loading: boolean;
}

const initialState: webhookHitsPayload = {
  webHookHitsData: [],
  meta: {
    page: 1,
    per_page: 5,
    page_count: 0,
    total_count: 0,
  },
  appIdData: {
    appId: "",
    name: "",
  },
  hookName: {
    id: "00",
    name: "all",
    value: "ALL",
  },
  loading: false,
};

const slice = createSlice({
  name: "webHookHits",
  initialState,
  reducers: {
    setWbHookHitsData(state, action) {
      state.webHookHitsData = action.payload;
    },
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setAppId(state, action) {
      state.appIdData = action.payload;
    },
    setHookName(state, action) {
      state.hookName = action.payload;
    },
  },
});

export const WebhookTableActions = slice.actions;

const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchWebhookHits = (data: any, range) => async (dispatch: any) => {
  dispatch(slice.actions.setLoading(true));
  try {
    let responseTableData: any = "";
    if (!data?.appId) {
      responseTableData = await axios.get(
        `${BASE_URL}/api/v1/webhooks/audits?hook_name=${data.hooksName}&meta=true&page=${data.page}&per_page=${data.per_page}&from_date=${range.from_date}&to_date=${range.to_date}`
      );
    } else if (data.hooksName === "") {
      responseTableData = await axios.get(
        `${BASE_URL}/api/v1/webhooks/audits?app_id=${data.appId}&meta=true&page=${data.page}&per_page=${data.per_page}&from_date=${range.from_date}&to_date=${range.to_date}`
      );
    } else {
      responseTableData = await axios.get(
        `${BASE_URL}/api/v1/webhooks/audits?app_id=${data.appId}&hook_name=${data.hooksName}&meta=true&page=${data.page}&per_page=${data.per_page}&from_date=${range.from_date}&to_date=${range.to_date}`
      );
    }
    if (responseTableData?.data?.status) {
      dispatch(
        slice.actions.setWbHookHitsData(responseTableData?.data?.data?.data)
      );
      dispatch(slice.actions.setMeta(responseTableData?.data?.data?.meta));
      // dispatch(
      //   SuccessSnackbar({
      //     message: responseTableData?.data?.message,
      //     open: true,
      //   })
      // );
    } else {
      dispatch(slice.actions.setWbHookHitsData([]));
      dispatch(
        slice.actions.setMeta({
          page: 1,
          per_page: 5,
          page_count: 0,
          total_count: 0,
        })
      );
      throw new Error(responseTableData?.data.message);
    }
  } catch (error) {
    // dispatch(
    //   FailureSnackbar({
    //     message: "Data not found" || "something went wrong",
    //     open: true,
    //   })
    // );
    throw new Error(error);
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

export const reducer = slice.reducer;

export default slice;
