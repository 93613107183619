import React from "react";
function OnlintDot({ props }) {
  return (
    <>
      <svg
        // width="10"
        // height="10"
        width={props.width}
        height={props.height}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="5" cy="5" r="5" fill="#11BD60" />
      </svg>
    </>
  );
}

export default OnlintDot;