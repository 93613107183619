import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import * as XLSX from "xlsx";
// import { FailureSnackbar } from "../Snackbar";

interface IconferenceData {
  appId: string;
  roomName: string;
  roomId: string;
  participantName: string;
  causeCode: string;
  reason: string;
  cost: string;
  costCode: string;
  sessionId: string;
  startTime: Date;
  endTime: Date;
  minutes: number;
  status: string;
}
interface conferencePayload {
  conferenceData: IconferenceData[];
  meta: {
    page: number;
    per_page: number;
    page_count: number;
    total_count: number;
  };
  appIdData: {
    appId: string;
    name: string;
  };
  exportToExcel: {
    page: number;
    per_page: number;
    excelData: [
      {
        appId: string;
        roomName: string;
        roomId: string;
        participantName: string;
        causeCode: string;
        reason: string;
        cost: string;
        costCode: string;
        sessionId: string;
        startTime: Date;
        endTime: Date;
        minutes: number;
        status: string;
      }
    ];
  };
  page: number;
  loading: boolean;
  setTab: string;
}

const initialState: conferencePayload = {
  conferenceData: [],
  meta: {
    page: 1,
    per_page: 5,
    page_count: 0,
    total_count: 0,
  },
  appIdData: {
    appId: "",
    name: "",
  },
  exportToExcel: {
    page: 1,
    per_page: 2,
    excelData: [
      {
        appId: "",
        roomName: "",
        roomId: "",
        participantName: "",
        causeCode: "",
        reason: "",
        cost: "",
        costCode: "",
        sessionId: "",
        startTime: null,
        endTime: null,
        minutes: null,
        status: "",
      },
    ],
  },
  page: 0,
  loading: false,
  setTab: "/activity/conference-report",
};

const slice = createSlice({
  name: "conference",
  initialState,
  reducers: {
    setConferenceTableData(state, action) {
      state.conferenceData = action.payload;
    },
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setAppId(state, action) {
      state.appIdData = action.payload;
    },
    setPageNumber(state, action) {
      state.page = action.payload;
    },
    setExcelData(state, action) {
      state.exportToExcel.excelData = action.payload;
    },
    setActivityTab(state, action) {
      state.setTab = action.payload;
    },
  },
});

export const ConferenceAction = slice.actions;
const BASE_URL = process.env.REACT_APP_API_URL;

// fetching conference data
export const fetchConferenceTableData =
  (data, id: string, range, status) => async (dispatch: any) => {
    dispatch(slice.actions.setLoading(true));
    try {
      let url = "";
      if (id === "" || id === undefined) {
        url = `${BASE_URL}/api/v1/events/cdr-summary?page=${data.page}&per_page=${data.per_page}&meta=true&from_date=${range?.startDate}&to_date=${range?.endDate}&status=${status}`;
      } else {
        url = `${BASE_URL}/api/v1/events/cdr-summary?page=${data.page}&per_page=${data.per_page}&meta=true&app_id=${id}&from_date=${range?.startDate}&to_date=${range?.endDate}&status=${status}`;
      }
      const responseData = await axios.get(url);
      if (responseData.data.status) {
        let data = [];
        for (const k in responseData.data.data) {
          data.push({
            appId: responseData.data.data[k].appId,
            roomName: responseData.data.data[k].roomName,
            roomId: responseData.data.data[k].roomId,
            participantName: responseData.data.data[k].participantName,
            causeCode: responseData.data.data[k].causeCode,
            reason: responseData.data.data[k].reason,
            cost: responseData.data.data[k].cost,
            costCode: responseData.data.data[k].costCode,
            sessionId: responseData.data.data[k].sessionId,
            startTime: responseData.data.data[k].startTime,
            endTime: responseData.data.data[k].endTime,
            minutes: responseData.data.data[k].minutes,
            status: responseData.data.data[k].status,
          });
        }
        dispatch(slice.actions.setConferenceTableData(data));
        dispatch(
          slice.actions.setMeta({
            page: responseData.data.meta.page,
            per_page: responseData.data.meta.per_page,
            page_count: responseData.data.meta.page_count,
            total_count: responseData.data.meta.total_count,
          })
        );
        // dispatch(
        //   SuccessSnackbar({
        //     message: responseData?.data?.message,
        //     open: true,
        //   })
        // );
      } else {
        throw new Error(responseData.data.message);
      }
    } catch (error) {
      dispatch(
        slice.actions.setMeta({
          page: 1,
          per_page: 5,
          page_count: 0,
          total_count: 0,
        })
      );
      dispatch(slice.actions.setConferenceTableData([]));
      // dispatch(
      //   FailureSnackbar({
      //     message: "Data not found" || "something went wrong",
      //     open: true,
      //   })
      // );
      throw new Error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

export const fetchCompleteData =
  (per_page: number, id: string) => async (dispatch: any) => {
    await axios
      .get(
        `${BASE_URL}/api/v1/events/cdr-summary?page=1&per_page=${per_page}&meta=true&app_id=${id}`
      )
      .then((res: any) => {
        let data = [];
        if (res.data.data) {
          for (const k in res.data.data) {
            data.push({
              appId: res.data.data[k].appId,
              roomName: res.data.data[k].roomName,
              roomId: res.data.data[k].roomId,
              participantName: res.data.data[k].participantName,
              causeCode: res.data.data[k].causeCode,
              reason: res.data.data[k].reason,
              cost: res.data.data[k].cost,
              costCode: res.data.data[k].costCode,
              sessionId: res.data.data[k].sessionId,
              startTime: res.data.data[k].startTime,
              endTime: res.data.data[k].endTime,
              minutes: res.data.data[k].minutes,
              status: res.data.data[k].status,
            });
          }
          dispatch(slice.actions.setExcelData(data));
          const ExportData: any = data.map((data) => {
            return {
              APP_ID: data.appId,
              ROOM_NAME: data.roomName,
              ROOM_ID: data.roomId,
              PARTICIPANT_NAME: data.participantName,
              CAUSE_CODE: data.causeCode,
              COST: data.cost,
              SESSION_ID: data.sessionId,
              START_TIME: data.startTime,
              END_TIME: data.endTime,
              MINUTES: data.minutes,
              STATUS: data.status,
            };
          });
          const wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(ExportData);
          XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
          XLSX.writeFile(wb, `${ExportData[0]?.APP_ID}.xlsx`);
        }
        if (res.detail.msg.startsWith("ensure this value is ")) return;
        if (res.data.message.startsWith("Data not found")) return;
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

export const setActivityTabs = (value) => async (dispatch) => {
  dispatch(slice.actions.setActivityTab(value));
};
export const reducer = slice.reducer;

export default slice;
