import React from 'react';
function Phone({ props }) {
  return (
    <>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.275 15.325C10.225 15.325 7.27501 13.8499 4.57501 11.2749C0.900006 7.77495 -0.674994 3.87495 0.925006 2.17495C1.00001 2.09995 1.07501 2.04995 1.17501 1.99995L3.27501 0.824948C3.75001 0.574948 4.35001 0.699948 4.65001 1.12495L6.17501 3.29995C6.32501 3.52495 6.40001 3.79995 6.35001 4.04995C6.30001 4.32495 6.15001 4.54995 5.92501 4.69995L5.00001 5.29995C4.95001 5.32495 4.95001 5.34995 4.95001 5.37495C4.95001 5.39995 4.95001 5.42495 4.97501 5.44995C5.65001 6.44995 7.57501 8.99995 10.625 10.8249C10.7 10.8749 10.825 10.8499 10.875 10.7999L11.525 9.92495C11.85 9.47495 12.475 9.37495 12.95 9.67495L15.225 11.1249C15.7 11.4249 15.85 12.0249 15.55 12.4999L14.3 14.5C14.25 14.6 14.175 14.675 14.1 14.725C13.625 15.15 13 15.325 12.275 15.325ZM3.80001 1.54995C3.77501 1.54995 3.75001 1.54995 3.70001 1.57495L1.60001 2.74995C1.57501 2.77495 1.57501 2.77495 1.55001 2.77495C0.500006 3.89995 1.70001 7.32495 5.20001 10.6499C8.70001 13.9749 12.325 15.125 13.525 14.1C13.525 14.1 13.525 14.0999 13.55 14.0749L14.8 12.0749C14.825 12.0249 14.825 11.9499 14.75 11.8999L12.475 10.4499C12.4 10.3999 12.275 10.425 12.225 10.475L11.575 11.35C11.25 11.775 10.65 11.8999 10.175 11.6249C6.95001 9.69995 4.95001 7.02495 4.25001 5.94995C4.10001 5.72495 4.05001 5.47495 4.10001 5.19995C4.15001 4.94995 4.30001 4.69995 4.52501 4.57495L5.45001 3.94995C5.50001 3.92495 5.50001 3.89995 5.50001 3.87495C5.50001 3.84995 5.50001 3.82495 5.47501 3.77495L3.95001 1.59995C3.92501 1.57495 3.85001 1.54995 3.80001 1.54995Z"
          fill="#212121"
        />
      </svg>
    </>
  );
}

export default Phone;
