import { Box, TablePagination, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableHeaderWrapper = styled(Box)`
 background: #CEE2FF;
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding-left: 13px;
 height: 50px;
`;
export const TableHeaderWrapperNotification = styled(Box)`
 background: #CEE2FF;
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding-left: 13px;
 height: 63px;
`;
export const TableHeaderName = styled(Typography)`
color: #1A75FF;
width: 52%; 
`;
export const TableHeaderValue = styled(Typography)`
width: 48%; 
`;
export const MenuWrapperMob = styled(Box)`
display: flex;
justify-content: space-between; 
align-items: center;
width: 48%;  
`;
export const ContentWrapperMob = styled(Box)`
display: flex;
flex-direction: column;
align-items: center; 
padding-top: 10px;
padding-right: 15px;
padding-bottom: 10px;
padding-left: 15px;
`;
export const ContentWrapperCenterMob = styled(Box)`
display: flex;
justify-content: space-between;
align-items: center; 
width: 100%; 
`;
export const DeviderBox = styled(Box)`
width: 100%;
padding-top: 15px;
`;
export const CustomPagination = styled(TablePagination)`
  & .MuiTablePagination-displayedRows {
    // border: 2px solid red;
    width: 100px;
    display: flex;
    font-family: Poppins;
    align-items: center;
    justify-content: center;
    ${(props) => props.theme.breakpoints.down('sm')} {
      width: 80px;
      margin-right: 2px;
    }
  }
  .css-6wccg8-MuiInputBase-root-MuiTablePagination-select {
    ${(props) => props.theme.breakpoints.down('sm')} {
      margin-right: 2px;
      margin-left: 2px;
    }
  }
  .css-ufbphr {
    ${(props) => props.theme.breakpoints.down('sm')} {
      margin-right: 2px;
      margin-left: 2px;
    }
  }
  .css-ttjyrr-MuiToolbar-root-MuiTablePagination-toolbar
    .MuiTablePagination-actions {
    ${(props) => props.theme.breakpoints.down('sm')} {
      margin-left: 2px;
    }
  }
  css-lwnhii .MuiTablePagination-actions {
    ${(props) => props.theme.breakpoints.down('sm')} {
      margin-left: 2px;
    }
  }
  .css-a1rsia-MuiTablePagination-selectLabel {
    ${(props) => props.theme.breakpoints.down('sm')} {
      width: 50px;
    }
  }
  .css-19eepvi {
    ${(props) => props.theme.breakpoints.down('sm')} {
      width: 50px;
    }
  }
`;