import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../utils/axios";
import { SuccessSnackbar, FailureSnackbar } from "src/slices/Snackbar";

const arr = [
  // { id: "ALL", name: "ALL", checked: false },
  { id: "ROOM_CREATED", name: "ROOM_CREATED", checked: false },
  { id: "PARTICIPANT_JOINED", name: "PARTICIPANT_JOINED", checked: false },
  // { id: "SETTINGS_PROVISIONING", name: "SETTINGS_PROVISIONING", checked: false },
  { id: "PARTICIPANT_LEFT", name: "PARTICIPANT_LEFT", checked: false },
  { id: "ROOM_DESTROYED", name: "ROOM_DESTROYED", checked: false },

  { id: "TOKEN_CREATED", name: "TOKEN_CREATED", checked: false },
  { id: "RECORDING_STARTED", name: "RECORDING_STARTED", checked: false },
  { id: "RECORDING_STOPPED", name: "RECORDING_STOPPED", checked: false },
  { id: "RECORDING_READY", name: "RECORDING_READY", checked: false },
];
interface allData {
  id: string;
  appId: string;
  appName: string;
  hookNames: string[];
  registeredHookNames: string[];
  createdDate: string;
  status: string;
  url: string;
  description: string;
  authorizationHeader: string;
}
interface getAppNameInfo {
  appId: string;
  name: string;
}
interface hookNamesObj {
  id: string;
  name: string;
  checked: boolean;
}
interface WebHook {
  webhook: {
    id: string;
    appId: string;
    appName: string;
    hookNames: string[];
    registeredHookNames: string[];
    createdDate: string;
    status: string;
    url: string;
    description: string;
    authorizationHeader: string;
    button: boolean;
  };
  Addsnack: boolean;
  Editsnack: boolean;
  Deletesnack: boolean;
  allData: allData[];
  getAppName: getAppNameInfo[];
  editgetData: allData[];
  openWebhook: boolean;
  webhookMode: string;
  storeId: string;
  hookNamesObj: hookNamesObj[];
  dialogLoader: boolean;
  tableLoader: boolean;
  pageLoader: boolean;
  loader:boolean;
  meta: {
    page: number;
    per_page: number;
    page_count: number;
    total_count: number;
  };
}

const initialState: WebHook = {
  webhook: {
    id: "",
    appId: "",
    appName: "",
    hookNames: [],
    registeredHookNames: [],
    createdDate: "",
    status: "",
    url: "",
    description: "",
    authorizationHeader: "",
    button: true,
  },
  Addsnack: false,
  Editsnack: false,
  Deletesnack: false,
  allData: [],
  getAppName: [],
  editgetData: [],
  openWebhook: false,
  webhookMode: "",
  storeId: "",
  hookNamesObj: arr,
  dialogLoader: false,
  tableLoader: false,
  pageLoader: false,
  loader:false,
  meta: {
    page: 1,
    per_page: 5,
    page_count: 0,
    total_count: 0,
  },
};

const slice = createSlice({
  name: "WebHook",
  initialState,
  reducers: {
    // Single Payload
    webhookdata(state: any, action) {
      state.webhook[action.payload.key] = action.payload.value;
    },

    webhookdata1(state: any, action) {
      state.webhook = action.payload;
    },

    webdatapush(state, action) {
      state.allData = action.payload;
    },

    deleteStoreData(state, action) {
      state.allData = action.payload;
    },

    webhookEdit(state, action) {
      state.allData = action.payload;
    },

    Addsnack(state: any, action) {
      state.Addsnack = action.payload;
    },
    Editsnack(state: any, action) {
      state.Editsnack = action.payload;
    },
    Deletesnack(state: any, action) {
      state.Deletesnack = action.payload;
    },

    // get appname and store
    getAppName(state, action) {
      state.getAppName = action.payload;
    },
    getEditData(state, action) {
      // state.editgetData = action.payload;
      action.payload.forEach((value) => {
        state.webhook.appId = value.appId;
        state.webhook.appName = value.appName;
        state.webhook.url = value.url;
        state.webhook.description = value.description;
        state.webhook.authorizationHeader = value.authorizationHeader;
        state.webhook.registeredHookNames = value.registeredHookNames;
        state.webhook.hookNames = value.registeredHookNames;
      });
    },
    setOpenWebhook(state, action) {
      state.openWebhook = action.payload;
    },
    setWebhookMpde(state, action) {
      state.webhookMode = action.payload;
    },
    setStoreId(state, action) {
      state.storeId = action.payload;
    },
    setHookNamesObj(state, action) {
      state.hookNamesObj = action.payload;
    },
    setDialogLoader(state, action) {
      state.dialogLoader = action.payload;
    },
    setTableLoader(state, action) {
      state.tableLoader = action.payload;
    },
    setPageLoader(state, action) {
      state.pageLoader = action.payload;
    },
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setLoader(state, action) {
      state.loader = action.payload;
    },
  },
});

export const setTableLoaders = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setTableLoader(data));
};

export const setDialogLoaders = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setDialogLoader(data));
};
export const setHookNamesObject = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setHookNamesObj(data));
};

export const setStoreId1 = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setStoreId(data));
};
export const webhookMode1 = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setWebhookMpde(data));
};

export const webhookInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.webhookdata(data));
};

export const webdata = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.webdatapush(data));
};

export const webhookEdit = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.webhookEdit(data));
};
export const deleteData = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.deleteStoreData(data));
};

export const webhookeditstore = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.webhookdata1(data));
};

export const AddSnackInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.Addsnack(data));
};
export const EditSnackInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.Editsnack(data));
};
export const DeleteSnackInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.Deletesnack(data));
};
export const setOpenWebhook = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setOpenWebhook(data));
};

const BASE_URL = process.env.REACT_APP_API_URL;

// In dialog fetch appname from application api
export const fetchAppName = () => async (dispatch: any) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/application/`);
    if (response.status === 200 || response.status === 201) {
      dispatch(slice.actions.getAppName(response.data.data));
    } else {
      throw new Error(response?.data?.message);
    }
  } catch (err) {
    dispatch(
      FailureSnackbar({ message: err || "something went wrong", open: true })
    );
  }
};

// After refresh fetch table from webhook api
export const fetchWebhookTable = (data) => async (dispatch: any) => {
  dispatch(slice.actions.setTableLoader(true));
  try {
    const getResponse = await axios.get(
      `${BASE_URL}/api/v1/webhooks/?meta=true&page=${data?.page}&per_page=${data?.per_page}`
    );
    if (getResponse.data.status) {
      dispatch(slice.actions.webdatapush(getResponse?.data?.data));
      dispatch(slice.actions.setMeta(getResponse?.data?.meta));
    } else {
      dispatch(slice.actions.webdatapush([]));
      dispatch(
        slice.actions.setMeta({
          page: 1,
          per_page: 5,
          page_count: 0,
          total_count: 0,
        })
      );
      throw new Error(getResponse?.data?.message);
    }
  } catch (error) {
    // dispatch(FailureSnackbar({ message: error?.message || "something went wrong", open: true }))
  } finally {
    dispatch(slice.actions.setTableLoader(false));
  }
};

export const fetchWebhookTable1 =
  (data: any, range, status) => async (dispatch: any) => {
    dispatch(slice.actions.setPageLoader(true));
    try {
      const getResponse = await axios.get(
        `${BASE_URL}/api/v1/webhooks/?meta=true&page=${data?.page}&per_page=${data?.per_page}&from_date=${range?.startDate}&to_date=${range?.endDate}&status=${status}`
      );
      if (getResponse.data.status) {
        dispatch(slice.actions.webdatapush(getResponse?.data?.data));
        dispatch(slice.actions.setMeta(getResponse?.data?.meta));
      } else {
        dispatch(slice.actions.webdatapush([]));
        dispatch(
          slice.actions.setMeta({
            page: 1,
            per_page: 5,
            page_count: 0,
            total_count: 0,
          })
        );
        throw new Error(getResponse?.data?.message);
      }
    } catch (error) {
      // dispatch(FailureSnackbar({ message: error?.message || "something went wrong", open: true }))
    } finally {
      dispatch(slice.actions.setPageLoader(false));
    }
  };

// Webhook add post api
export const webHookDataPost = (data: any, data1) => async (dispatch) => {
  dispatch(slice.actions.setTableLoader(true));
  try {
    const webhookData = {
      url: data.url,
      appId: data.appId,
      description: data.description,
      authorizationHeader: data.authorizationHeader,
      hookNames: data.hookNames,
    };

    const postResponse = await axios.post(
      `${BASE_URL}/api/v1/webhooks/`,
      webhookData
    );
    if (!postResponse.data?.status) {
      throw new Error(postResponse.data?.message);
    }
    dispatch(slice.actions.webhookdata(postResponse.data.data));
    dispatch(fetchWebhookTable(data1));
    dispatch(
      SuccessSnackbar({ message: postResponse.data?.message, open: true })
    );
  } catch (error) {
    dispatch(slice.actions.setTableLoader(false));
    dispatch(
      FailureSnackbar({
        message: error?.message || "something went wrong",
        open: true,
      })
    );
  } finally {
  }
};

// Webhook get edit data from webhook api
export const webHookEdit = (data: any) => async (dispatch) => {
  dispatch(slice.actions.setDialogLoader(true));
  try {
    const editWebhookData = await axios.get(
      `${BASE_URL}/api/v1/webhooks/?webhook_id=${data}`
    );
    if (editWebhookData?.data?.status) {
      dispatch(slice.actions.getEditData(editWebhookData?.data?.data));
    } else {
      throw new Error(editWebhookData?.data?.message);
    }
  } catch (error) {
    dispatch(
      FailureSnackbar({
        message: error?.message || "something went wrong",
        open: true,
      })
    );
  } finally {
    dispatch(slice.actions.setDialogLoader(false));
  }
};

// Webhook edit data and update to webhook api
export const webHookEditPost = (data: any, data1) => async (dispatch) => {
  dispatch(slice.actions.setTableLoader(true));
  try {
    const webhookData = {
      url: data.url,
      // appId: data.appId,
      description: data.description,
      authorizationHeader: data.authorizationHeader,
      hookNames: data.hookNames,
    };

    const postResponse = await axios.patch(
      `${BASE_URL}/api/v1/webhooks/${data.id}`,
      webhookData
    );
    if (!postResponse.data?.data) {
      throw new Error(postResponse.data?.message);
    }
    dispatch(slice.actions.webhookdata(postResponse.data.data));
    dispatch(fetchWebhookTable(data1));
    dispatch(
      SuccessSnackbar({ message: postResponse.data?.message, open: true })
    );
  } catch (error) {
    dispatch(slice.actions.setTableLoader(false));
    dispatch(
      FailureSnackbar({
        message: error?.message || "something went wrong",
        open: true,
      })
    );
  } finally {
  }
};

// Webhook delete table row from webhook and application api
export const deleteWebhook = (data: any, data1) => async (dispatch: any) => {
  dispatch(slice.actions.setTableLoader(true));
  try {
    const deleteResponse = await axios.delete(
      `${BASE_URL}/api/v1/webhooks/${data}`
    );
    if (!deleteResponse.data?.status) {
      throw new Error(deleteResponse.data?.message);
    }
    if (
      data1.total_count % data1.per_page === 1 &&
      data1.page === data1.page_count &&
      data1.page !== 1
    ) {
      dispatch(
        fetchWebhookTable({ page: data1.page - 1, per_page: data1.per_page })
      );
    } else {
      dispatch(fetchWebhookTable(data1));
    }
    dispatch(
      SuccessSnackbar({ message: deleteResponse.data?.message, open: true })
    );
  } catch (error) {
    dispatch(slice.actions.setTableLoader(false));
    dispatch(
      FailureSnackbar({
        message: error?.message || "something went wrong",
        open: true,
      })
    );
  } finally {
  }

  // dispatch(slice.actions.getAppName(response.data.data));
};

// Webhook test
export const webhookTest = (data: any, data1) => async (dispatch) => {
  dispatch(slice.actions.setLoader(true));
  try {
    const webhookData = {
      url: data.url,
      appId: data.appId,
      description: data.description,
      authorizationHeader: data.authorizationHeader,
      hookNames: data.hookNames,
    };

    const response = await axios.post(
      `${BASE_URL}/api/v1/webhooks/test/`,
      webhookData
    );
    if (!response.data?.status) {
      throw new Error(response.data?.message);
    }
    dispatch(
      SuccessSnackbar({ message: response.data?.message, open: true })
    );
  } catch (error) {
    dispatch(
      FailureSnackbar({
        message: error?.message || "something went wrong",
        open: true,
      })
    );
  } finally {
    dispatch(slice.actions.setLoader(false));
  }
};

export const updateWebhookStatus = (
  status: string,
  webhookId: string,
  data1,
  range,
  statusFilter
) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/v1/webhooks/status/?webhook_status=${status}&webhook_id=${webhookId}`
      );
      // Dispatch success action
      dispatch(updateWebhookStatusSuccess(status));
      dispatch(fetchWebhookTable1(data1, range, statusFilter));
      dispatch(
        SuccessSnackbar({ message: response.data?.message, open: true })
      );
    } catch (error) {
      // Dispatch failure action
      console.error("Failed to update webhook status:", error);
    }
  };
};

export const updateWebhookStatusSuccess = (status: string) => {
  return {
    type: "UPDATE_WEBHOOK_STATUS_SUCCESS",
    payload: status,
  };
};

export const reducer = slice.reducer;
export default slice;
