import { BrowserRouter } from "react-router-dom";
import routes, { renderRoutes } from "./router";
import { SnackbarProvider } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ScrollToTop from "./utils/ScrollToTop";
import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import CustomSnackbar from "./components/Snackbar";
import ScrollTopArrow from "./components/ScrollUp/scrollUp";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";

function App() {
  const onRedirectCallback = (appState) => {
    history.push(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
    );
  };
  const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      ...(process.env.REACT_APP_AUDIENCE
        ? { audience: process.env.REACT_APP_AUDIENCE }
        : null),
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <CustomSnackbar />
            <ScrollTopArrow />
            <BrowserRouter>
              <ScrollToTop />
              <CssBaseline />
              <Auth0Provider {...providerConfig}>
                {renderRoutes(routes)}
              </Auth0Provider>
            </BrowserRouter>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
export default App;
