import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Divider, Grid } from '@mui/material';
import { setMonthValue, setSpeakerCount, setTabState, setTimeValue, setViewerCount, showAudioData, showRecordingData, showSimulcastData, showStorageData } from 'src/slices/pricing';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ButtonPrimary } from 'src/components/CustomButtons';
import { AmountText, BlurBoxContainer, CustomEstimationCard, HeadingTitle, PrettoSlider, SmallText, SubHeading, Title } from './pricingStyle';

export const LiveStreaming = () => {
    const dispatch = useDispatch();
    const {
        speakerCount, viewerCount, timeValue, monthValue, isAudio, tabState, isRecording, isSimulcast, isStorage,
        liveSpRate, liveViRate, audioRate, recordingRate, reStreamingRate, storageRate } = useSelector(
            (state: RootState) => state.pricing
        );

    const liveMinValue = +(timeValue * monthValue * 0.0006).toFixed(2);

    const speakerAudioMinValue = +(speakerCount * timeValue * monthValue * audioRate).toFixed(2);
    const speakerVideoMinValSD = +(speakerCount * timeValue * monthValue * 0.002).toFixed(2);
    const speakerVideoMinValHD = +(speakerCount * timeValue * monthValue * liveSpRate).toFixed(2);
    const viewerAudioMinValue = +(viewerCount * timeValue * monthValue * 0.0003).toFixed(2);
    const viewerVideoMinValSD = +(viewerCount * timeValue * monthValue * 0.001).toFixed(2);
    const viewerVideoMinValHD = +(viewerCount * timeValue * monthValue * liveViRate).toFixed(2);
    const freeAudioMinValue = +(10000 * 0.0003).toFixed(2);
    const freeVideoMinValSD = +(10000 * 0.001).toFixed(2);
    const freeVideoMinValHD = +(10000 * 0.0015).toFixed(2);
    const recordingValue = +(timeValue * monthValue * recordingRate).toFixed(2);
    const storageAudioValue = +(timeValue * monthValue * 0.0003).toFixed(2);
    const storageVideoValue = +(timeValue * monthValue * storageRate).toFixed(2);
    const simulstreamValue = +(timeValue * monthValue * reStreamingRate).toFixed(2);

    const totalAudioValue = +(
        speakerAudioMinValue 
        + viewerAudioMinValue
        - freeAudioMinValue 
        + (isRecording && recordingValue) 
        + (isStorage && storageAudioValue) 
        + (isSimulcast && simulstreamValue)
      ).toFixed(2);
    // const totalVideoSDValue = +(
    //       speakerVideoMinValSD
    //     + viewerVideoMinValSD
    //     - freeVideoMinValSD 
    //     + (isRecording && recordingValue) 
    //     + (isStorage && storageVideoValue) 
    //     + (isSimulcast && simulstreamValue)
    //     ).toFixed(2);
        // const totalVideoHDValue = +(
        //       speakerVideoMinValHD
        //     + viewerVideoMinValHD 
        //     - freeVideoMinValHD 
        //     + (isRecording && recordingValue) 
        //     + (isStorage && storageVideoValue) 
        //     + (isSimulcast && simulstreamValue)
        //     ).toFixed(2);

    const handleSpeakerChange = (event, newValue) => {
        dispatch(setSpeakerCount(newValue));
    };

    const handleViewerChange = (event, newValue) => {
        dispatch(setViewerCount(newValue));
    };

    const handleTimeChange = (event, newValue) => {
        dispatch(setTimeValue(newValue));
    };

    const handleMonthChange = (event, newValue) => {
        dispatch(setMonthValue(newValue));
    };

    const handleRecordingDataChange = () => {
        if (isRecording) {
            dispatch(showRecordingData(false));
            dispatch(showStorageData(false));
        } else {
            dispatch(showRecordingData(true));
        }
    };
    const handleStorageDataChange = () => {
        if (isStorage) {
            dispatch(showStorageData(false));
        } else dispatch(showStorageData(true));
    };

    const handleSimulcastDataChange = () => {
        if (isSimulcast) {
            dispatch(showSimulcastData(false));
        } else dispatch(showSimulcastData(true));
    };

    return (
        <>
            <Grid container sx={{ width: { xs: '97%', sm: '90%', md: '90%', lg: '70%' }, margin: 'auto', display: 'flex', justifyContent: 'center', padding: '10px' }} columnGap={3} rowGap={2} >
                <Grid item xs={12} sm={12} md={7} lg={6.7} xl={6.7} >
                    <Grid container columnGap={6} rowGap={2}>
                        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                            <Title>Live stream mode</Title>
                            <Box
                                sx={{
                                    marginBottom: "1rem",
                                    height: "auto",
                                    // background: "#F1F5FD",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'space-between',
                                    textAlign: 'center',
                                }}
                            >
                                <div style={{ display: "flex", gap: '10px' }}>
                                    <Button
                                        id="audio-btn"
                                        sx={{
                                            border: isAudio ? '0.1px solid #3087EC' : '0.1px solid #BABABA',
                                            borderRadius: "16px",
                                            background: isAudio ? "#3087EC" : "transparent",
                                            color: isAudio ? "white" : "#686868",
                                            "&:hover": {
                                                background: isAudio ? "#3087EC" : "none",
                                            },
                                        }}
                                        onClick={() => {
                                            dispatch(showAudioData(true));
                                            dispatch(setTabState(1));
                                        }}
                                    >
                                        <Typography sx={{ fontSize: "10px", }}>
                                            Audio
                                        </Typography>
                                    </Button>
                                    <Button
                                        id="audio-video-btn"
                                        sx={{
                                            border: isAudio ? '0.1px solid #BABABA' : '0.1px solid #3087EC',
                                            borderRadius: "16px",
                                            color: isAudio ? "#686868" : "white",
                                            // mr: "10px",
                                            background: isAudio ? "transperant" : "#3087EC",
                                            "&:hover": {
                                                background: isAudio ? "none" : "#3087EC",
                                            },
                                        }}
                                        onClick={() => {
                                            dispatch(showAudioData(false));
                                            dispatch(setTabState(1));
                                        }}
                                    >
                                        <Typography sx={{ fontSize: "10px", }}>
                                            Audio & Video
                                        </Typography>
                                    </Button>
                                </div>

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                            {!isAudio ? <Title>Live Stream Resolution</Title> : ""}
                            <Box
                                sx={{
                                    marginBottom: "1rem",
                                    height: "auto",
                                    // background: "#F1F5FD",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'space-between',
                                    textAlign: 'center',
                                }}
                            >
                                {!isAudio &&
                                    <div style={{ display: "flex", gap: '10px' }}>
                                        <Button
                                            id="sd-btn"
                                            sx={{
                                                border: (tabState === 2 || tabState === 3) ? '0.1px solid #BABABA' : '0.1px solid #3087EC',
                                                borderRadius: "16px",
                                                background: (tabState === 2 || tabState === 3) ? "transparent" : "#3087EC",
                                                color: (tabState === 2 || tabState === 3) ? "#686868" : "white",
                                                "&:hover": {
                                                    background: (tabState === 2 || tabState === 3) ? "none" : "#3087EC",
                                                },
                                            }}
                                            onClick={() => {
                                                dispatch(setTabState(1));
                                            }}
                                        >
                                            <Typography sx={{ fontSize: "10px", }}>
                                                SD
                                            </Typography>
                                        </Button>
                                        <Button
                                            id="hd-btn"
                                            sx={{
                                                border: (tabState === 1 || tabState === 3) ? '0.1px solid #BABABA' : '0.1px solid #3087EC',
                                                borderRadius: "16px",
                                                color: (tabState === 1 || tabState === 3) ? "#686868" : "white",
                                                // mr: "10px",
                                                background: (tabState === 1 || tabState === 3) ? "transperant" : "#3087EC",
                                                "&:hover": {
                                                    background: (tabState === 1 || tabState === 3) ? "none" : "#3087EC",
                                                },
                                            }}
                                            onClick={() => {
                                                dispatch(setTabState(2));
                                            }}
                                        >
                                            <Typography sx={{ fontSize: "10px", }}>
                                                HD
                                            </Typography>
                                        </Button>
                                        <Button
                                            id="full-hd-btn"
                                            sx={{
                                                border: (tabState === 1 || tabState === 2) ? '0.1px solid #BABABA' : '0.1px solid #3087EC',
                                                borderRadius: "16px",
                                                color: (tabState === 1 || tabState === 2) ? "#686868" : "white",
                                                // mr: "10px",
                                                background: (tabState === 1 || tabState === 2) ? "transperant" : "#3087EC",
                                                "&:hover": {
                                                    background: (tabState === 1 || tabState === 2) ? "none" : "#3087EC",
                                                },
                                            }}
                                            onClick={() => {
                                                dispatch(setTabState(3));
                                            }}
                                        >
                                            <Typography sx={{ fontSize: "10px", }}>
                                                Full HD
                                            </Typography>
                                        </Button>
                                    </div>}

                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container columnGap={6} rowGap={2}>
                        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                            <Title>Number of Speakers</Title>
                            <AmountText>{speakerCount}</AmountText>
                            <PrettoSlider
                                value={speakerCount}
                                onChange={handleSpeakerChange}
                                size="small"
                                aria-label="slider"
                                min={1}
                                max={20}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                            <Title>Number of Viewers </Title>
                            <AmountText>{viewerCount}</AmountText>
                            <PrettoSlider
                                value={viewerCount}
                                onChange={handleViewerChange}
                                size="small"
                                aria-label="slider"
                                min={1}
                                max={1000}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                            <Title>Avg. live stream duration (minutes)</Title>
                            <AmountText>{timeValue}</AmountText>
                            <PrettoSlider
                                value={timeValue}
                                onChange={handleTimeChange}
                                size="small"
                                aria-label="slider"
                                min={1}
                                max={240}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                            <Title>Monthly Sessions </Title>
                            <AmountText>{monthValue}</AmountText>
                            <PrettoSlider
                                value={monthValue}
                                onChange={handleMonthChange}
                                size="small"
                                aria-label="slider"
                                min={1}
                                max={5000}
                            />
                        </Grid>
                    </Grid>
                    <Grid container columnGap={6} rowGap={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Title>Additional services (Select the services)</Title>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box
                                sx={{
                                    marginBottom: "1rem",
                                    height: "auto",
                                    // background: "#F1F5FD",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'space-between',
                                    textAlign: 'center',
                                }}
                            >
                                <div style={{ display: "flex", gap: '10px' }}>
                                    <Button
                                        id="recording-btn"
                                        sx={{
                                            border: !isRecording ? '0.1px solid #BABABA' : '0.1px solid #3087EC',
                                            borderRadius: "16px",
                                            background: !isRecording ? "transparent" : "#3087EC",
                                            color: !isRecording ? "#686868" : "white",
                                            "&:hover": {
                                                background: !isRecording ? "none" : "#3087EC",
                                            },
                                        }}
                                        onClick={handleRecordingDataChange}
                                    >
                                        <Typography sx={{ fontSize: { xs: "10px" }, }}>
                                            Recording
                                        </Typography>
                                    </Button>
                                    {isRecording ?
                                        <Button
                                            id="storage-btn"
                                            sx={{
                                                border: !isStorage ? '0.1px solid #BABABA' : '0.1px solid #3087EC',
                                                borderRadius: "16px",
                                                color: !isStorage ? "#686868" : "white",
                                                // mr: "10px",
                                                background: !isStorage ? "transperant" : "#3087EC",
                                                "&:hover": {
                                                    background: !isStorage ? "none" : "#3087EC",
                                                },
                                            }}
                                            onClick={handleStorageDataChange}
                                        >
                                            <Typography sx={{ fontSize: { xs: "10px" }, }}>
                                                Storage
                                            </Typography>
                                        </Button>
                                        : <Button
                                            id="storage-btn"
                                            disabled
                                            sx={{
                                                border: '0.1px solid #BABABA',
                                                borderRadius: "16px",
                                                color: "#686868",
                                                // mr: "10px",
                                                background: "transperant"
                                            }}
                                        >
                                            <Typography sx={{ fontSize: { xs: "10px" }, }}>
                                                Storage
                                            </Typography>
                                        </Button>}
                                    <Button
                                        id="simulcast-btn"
                                        sx={{
                                            border: !isSimulcast ? '0.1px solid #BABABA' : '0.1px solid #3087EC',
                                            borderRadius: "16px",
                                            color: !isSimulcast ? "#686868" : "white",
                                            // mr: "10px",
                                            background: !isSimulcast ? "transperant" : "#3087EC",
                                            "&:hover": {
                                                background: !isSimulcast ? "none" : "#3087EC",
                                            },
                                        }}
                                        onClick={handleSimulcastDataChange}
                                    >
                                        <Typography sx={{ fontSize: { xs: "10px" }, }}>
                                            Simulstream
                                        </Typography>
                                    </Button>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Pricing card */}
                <Grid item xs={12} sm={10} md={4.5} lg={5} xl={5} sx={{ position: 'relative' }}>
                    <CustomEstimationCard>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                            <HeadingTitle gutterBottom>Estimated Monthly Invoice</HeadingTitle>
                        </Box>
                        <Divider sx={{ marginBottom: '10px' }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom>
                                {isAudio ? "Speakers minutes"
                                    : tabState === 1 ? "Speakers minutes ( SD )"
                                        : tabState === 2 ? "Speakers minutes ( HD )"
                                            : "Speakers minutes ( Full HD )"
                                }
                            </SubHeading>
                            <SubHeading gutterBottom>
                                ${isAudio ? speakerAudioMinValue
                                    : tabState === 1 ? speakerVideoMinValSD
                                        : tabState === 2 ? speakerVideoMinValHD
                                            : "0"
                                }
                            </SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            ({speakerCount}&nbsp;x&nbsp;{timeValue}&nbsp;x&nbsp;{monthValue}
                            &nbsp;=&nbsp;{(speakerCount * timeValue * monthValue).toLocaleString()}&nbsp;mins)
                        </SmallText>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom>
                                {isAudio ? "Viewers minutes"
                                    : tabState === 1 ? "Viewers minutes ( SD )"
                                        : tabState === 2 ? "Viewers minutes ( HD )"
                                            : "Viewers minutes ( Full HD )"
                                }
                            </SubHeading>
                            <SubHeading gutterBottom>
                                ${isAudio ? viewerAudioMinValue
                                    : tabState === 1 ? viewerVideoMinValSD
                                        : tabState === 2 ? viewerVideoMinValHD
                                            : "0"
                                }
                            </SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            ({viewerCount}&nbsp;x&nbsp;{timeValue}&nbsp;x&nbsp;{monthValue}
                            &nbsp;=&nbsp;{(viewerCount * timeValue * monthValue).toLocaleString()}&nbsp;mins)
                        </SmallText>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom >
                                {isAudio ? 'Free audio minutes' : "Free audio & video minutes"}</SubHeading>
                            <SubHeading gutterBottom>
                                -${isAudio ? `${freeAudioMinValue}`
                                    : tabState === 1 ? `${freeVideoMinValSD}`
                                        : tabState === 2 ? `${freeVideoMinValHD}`
                                            : ""
                                }
                            </SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            (10,000&nbsp;mins)
                        </SmallText>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom>Livestreaming encoding</SubHeading>
                            <SubHeading gutterBottom>${liveMinValue}</SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            ({timeValue}&nbsp;x&nbsp;{monthValue}
                            &nbsp;=&nbsp;{(timeValue * monthValue).toLocaleString()}&nbsp;mins)
                        </SmallText>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom >Recording</SubHeading>
                            <SubHeading gutterBottom>${isRecording ? `${recordingValue}` : '0'}</SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            {!isRecording ? '(0 min)' : `(${timeValue} x ${monthValue} = ${(timeValue * monthValue).toLocaleString()} mins)`}
                        </SmallText>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom >Storage</SubHeading>
                            <SubHeading gutterBottom>${isStorage ? isAudio ? `${storageAudioValue}` : `${storageVideoValue}` : '0'}</SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            {isStorage ? `(${timeValue} x ${monthValue} = ${(timeValue * monthValue).toLocaleString()} mins)` : '(0 min)'}
                        </SmallText>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom >Simulstream (RTMP out)</SubHeading>
                            <SubHeading gutterBottom>${isSimulcast ? `${simulstreamValue}` : '0'}</SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            {!isSimulcast ? '(0 min)' : `(${timeValue} x ${monthValue} = ${(timeValue * monthValue).toLocaleString()} mins)`}
                        </SmallText>
                        <Divider sx={{ marginBottom: '10px' }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom >Total amount</SubHeading>
                            <SubHeading gutterBottom>${totalAudioValue}</SubHeading>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <ButtonPrimary
                                id="get-started"
                                responsive={false}
                                margin="1.3rem 0 0 0"
                                fixedHeight="40px"
                                fixedWidth='100%'
                            // onClick={() => navigate("/contact-us")}
                            >
                                Start now
                            </ButtonPrimary>
                        </Box>
                    </CustomEstimationCard>
                    {tabState === 3 &&
                        <BlurBoxContainer sx={{ height: '550px' }}>
                            <ButtonPrimary id='contact-us' responsive={false}>
                                Contact us
                            </ButtonPrimary>
                        </BlurBoxContainer>}
                </Grid>
                {/* Pricing card */}
            </Grid>
        </>
    );
}


