import React, { ChangeEvent } from "react";
import { Box, CardContent, Tabs, Tab, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { experimentalStyled } from "@mui/material/styles";
import ConferenceTable from "./ConferenceTable";
import WebhookHits from "./WebhookHits";
import NotificationsTable from "./Notification";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ConferenceAction,
  setActivityTabs,
} from "src/slices/Conference/ConferenceTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import ContentWrapper from "src/components/ContentWrapper";
import { WebhookTableActions } from "src/slices/WebhookHits/WebHookHits";
const TabsContainerWrapper = experimentalStyled(CardContent)(
  ({ theme }) => `
      // background-color: ${theme.colors.alpha.black[5]};
`
);

function Index() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setTab } = useSelector((state: RootState) => state.conference);
  const { t }: { t: any } = useTranslation();
  const tabs = [
    { value: "/activity/conference-report", label: t("Conference Report") },
    { value: "/activity/webhook-hits", label: t("Webhook Hits") },
    { value: "/activity/notifications", label: t("Notifications") },
  ];
  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    navigate(value);
    dispatch(setActivityTabs(value));
    if (value === "/activity/conference-report") {
      dispatch(WebhookTableActions.setAppId({ appId: "" }));
      // dispatch(WebhookTableActions.setHookName({ name: "" }));
      dispatch(WebhookTableActions.setWbHookHitsData([]));
    } else if (value === "/activity/webhook-hits") {
      dispatch(ConferenceAction.setAppId(""));
      dispatch(ConferenceAction.setConferenceTableData([]));
    } else if (value === "/activity/notifications") {
      dispatch(ConferenceAction.setAppId(""));
      dispatch(ConferenceAction.setConferenceTableData([]));
      dispatch(WebhookTableActions.setAppId({ appId: "" }));
    }
  };
  if (pathname) {
    dispatch(setActivityTabs(pathname));
  }

  return (
    <>
      {/* desktop version */}
      <ContentWrapper title="Activity">
        <Box sx={{height: "99%",}}>
          <Grid
            container
            style={{
              position: "sticky",
              top: 0,
              display: "flex",
              justifyContent: "flex-start",
              alignItems:'center',
              height: "10%",
              width: "100%",
              background: "#FFFFFF",
            }}
          >
            <Grid xs={12} md={12} lg={12} xl={12}>
              <TabsContainerWrapper>
                <Tabs
                  onChange={handleTabsChange}
                  value={setTab}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#3087EC",
                      border: "none",
                      boxShadow: "none",
                    },
                  }}
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      sx={{
                        width: {
                          xl: "180px",
                          lg: "180px",
                          md: "180px",
                          sm: "150px",
                          xs: "90px",
                        },
                        fontSize: {
                          xl: "13px",
                          lg: "13px",
                          md: "13px",
                          sm: "11px",
                          xs: "11px",
                        },
                      }}
                    />
                  ))}
                </Tabs>
              </TabsContainerWrapper>
            </Grid>
          </Grid>

          <Box sx={{height: "90%",}}>
            {setTab === "/activity/conference-report" && (
              <>
                <ConferenceTable />
              </>
            )}
            {setTab === "/activity/webhook-hits" && (
              <>
                <WebhookHits />
              </>
            )}
            {setTab === "/activity/notifications" && (
              <>
                <NotificationsTable />
              </>
            )}
          </Box>
        </Box>
      </ContentWrapper>
    </>
  );
}
export default Index;
