import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VerifyEmailCodeIcon } from 'src/assets/icons/VerifyEmailDialogIcons';
import { ProfileAction, verifyEmail, verifyEmailOTPAPI } from 'src/slices/profile';
import { RootState } from 'src/store';
import { MuiOtpInput } from 'mui-one-time-password-input';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Wrapper,DisplayFlexColumn, PopinsForteenText, PoppinsTwentyText
} from './indexStyled';

const VerifyEmail = () => {
    const dispatch = useDispatch();
    const { emailOTP, profileData, verifyOTPLoader, enableResend } = useSelector((state: RootState) => state.profile);
    const regexOTP = /^[\d]*$/;
    const handleChange = (newValue: string) => {
        if (!regexOTP.test(newValue)) {
            return;
        }
        dispatch(ProfileAction.setEmailOTP(newValue));
    };

    const verifyEmailCodeHandler = () => {
        dispatch(verifyEmailOTPAPI(profileData?.email, +emailOTP));
    };

    const resendVerificationOTP = () => {
        dispatch(verifyEmail(profileData.email));
    };
    const validateChar = (value) => {
        if (value === " ") {
            return false;
        }
        return ((!isNaN(Number(value))));
    };
    return (
        <>
            <Box sx={{ width: "auto", height: "auto", padding: { xs: "10px 0", sm: "30px" } }}>
                <Wrapper>
                    <PoppinsTwentyText sx={{ color: "#3087EC" }}>Verification</PoppinsTwentyText>
                    <Box sx={{ width: "220px", height: "150px" }}>
                        <VerifyEmailCodeIcon />
                    </Box>
                    <DisplayFlexColumn sx={{ width: "100%", height: "auto", gap: "10px" }}>

                        <PopinsForteenText sx={{ color: "#686868" }}>
                            OTP Verification
                        </PopinsForteenText>
                        <PopinsForteenText sx={{ color: "#686868", textAlign: "center" }}>
                            Enter the OTP sent to {profileData?.email}
                        </PopinsForteenText>
                        <Box sx={{
                            width: {
                                xs: "98%",
                                sm: "60%"
                            }
                        }}>
                            <MuiOtpInput
                                value={emailOTP}
                                onChange={(e) => handleChange(e)}
                                length={6}
                                textTransform={undefined}
                                TextFieldsProps={{ size: 'small' }}
                                validateChar={validateChar}
                                gap={1}
                            />
                        </Box>
                        <PopinsForteenText sx={{ color: "#686868", display: "flex", gap: "10px" }}>Didn&#39;t receive the OTP?
                            {enableResend ? <Typography sx={{ color: "#3087EC", borderBottom: "1px solid #3087EC", fontFamily: 'Poppins', cursor: "pointer" }} onClick={resendVerificationOTP}>Resend OTP</Typography> : <Typography sx={{ color: "grey", fontFamily: 'Poppins', cursor: "pointer" }}>Resend OTP</Typography>}
                        </PopinsForteenText>
                    </DisplayFlexColumn>

                    <Box sx={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {emailOTP.length === 6 ? <>
                            {!verifyOTPLoader ? <Button variant='contained' sx={{
                                height: "40px", width: "130px", background: "#3087EC", color: "white", fontFamily: 'Poppins', transition: "all 0.3s ease", "&:hover": {
                                    background: "#3087EC", color: "white", opacity: 0.8
                                }
                            }}
                                onClick={verifyEmailCodeHandler}>
                                Verify
                            </Button> : <LoadingButton loading sx={{
                                height: "40px", width: "130px", border: "1px solid #BABABA"
                            }}>
                                Verify</LoadingButton>}
                        </> : <Button variant='contained' disabled sx={{
                            height: "40px", width: "130px", fontFamily: 'Poppins', border: "1px solid #BABABA"
                        }}>
                            Verify
                        </Button>}
                    </Box>
                </Wrapper>
            </Box>
        </>
    );
};

export default VerifyEmail;
