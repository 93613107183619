import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Hidden,
  styled,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  Button,
  Skeleton,
} from "@mui/material";
import useRazorpay from "react-razorpay";
import Paper from "@mui/material/Paper";
import Table from "./Table";
import Wallet from "../../assets/icons/Wallet";
import {
  PaymentMethod,
  PaymentSuccess,
  SelectedCurrency,
  SelectedPaymentMethod,
  SelectedUnits,
  editInvoice,
  editOutstanding,
} from "src/slices/billing";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/store";
import {
  BillingCardHeader,
  BillingCardSubHeaderText,
  BillingCardSubValue,
  InputLabelText,
  PayNowButtonBox,
  PaymentExtraText,
} from "./BillingStyled";
import { ButtonDisabled, ButtonPrimary } from "src/components/CustomButtons";
//import useAuth from 'src/hooks/useAuth';
import { useAuth0 } from "@auth0/auth0-react";
import ContentWrapper from "src/components/ContentWrapper";

const BillingCardHeaderText = styled(Typography)(() => ({
  fontStyle: "normal",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "0px",
  paddingRight: "20px",
  color: "#3087EC",
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function Home() {
  const {
    outstanding,
    selectedUnits,
    currencyConverter,
    selectedCurrency,
    paymentSuccess,
    amountLoader
  } = useSelector((state: RootState) => state.Billing);
  const Razorpay = useRazorpay();
  const dispatch = useDispatch();
  // const { user } = useAuth0();
  const paymentMethods = ["Razor Pay"];
  const [paymentMode, setPaymentMode] = useState("Razor Pay");
  const Currency = ["USD"];
  const [currency, setCurrency] = useState("USD");
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const accessToken = getAccessTokenSilently();
    dispatch(editOutstanding(accessToken));
    dispatch(editInvoice({ page: 1, per_page: 5 }));
  }, [dispatch, getAccessTokenSilently]);

  const handleUnitChange = (event) => {
    // if (event.target < 1) {
    //   dispatch(SelectedUnits(null))
    // } else {
    //   dispatch(SelectedUnits(event.target.value))
    // }
    dispatch(SelectedUnits(+event.target.value));
  };

  const handleCurrenyChange = (event) => {
    setCurrency(event.target.value);
    dispatch(SelectedCurrency(event.target.value));
  };

  const handlePaymentMethod = (event) => {
    setPaymentMode(event.target.value);
    dispatch(SelectedPaymentMethod(event.target.value));
  };

  const openPaymentGateway = async () => {
    const data = {
      paymentMode: paymentMode,
      selectedUnits: selectedUnits,
      currencyConverter: currencyConverter,
      userName: user.name,
      contextId: outstanding?.id,
      userEmail: user.email,
      selectedCurrency: selectedCurrency,
    };
    const accessToken = getAccessTokenSilently();
    dispatch(PaymentMethod({ data, Razorpay }, accessToken));
  };
  const handleClose = () => {
    dispatch(PaymentSuccess({ action: false, payment: false, paymentId: "" }));
  };
  return (
    <>
      <ContentWrapper title="Billing">
        <Box>
          <Box sx={{ ml: 2, mr: 2, mt: 2 }}>
            <Typography variant="h3" sx={{ mt: 1, color: "#3087EC" }}>
              Billing
            </Typography>
            <Paper
              elevation={1}
              square
              style={{
                marginBottom: "16px",
                marginTop: "8px",
                backgroundColor: "#f7f8fb",
              }}
            >
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Grid
                  id="current-balance"
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2.5}
                  sx={{
                    borderRadius: "6px",
                    background: "#DDEFFF",
                    m: "15px",
                    height: "83px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      margin: "20px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: "#0062FD",
                        color: "#FFFFFF",
                        borderRadius: "50%",
                        width: "44px",
                        height: "44px",
                        padding: "12px",
                      }}
                    >
                      <Wallet props={{ height: "20px", width: "20px" }} />
                    </Box>
                    <Box style={{ marginLeft: "20px" }}>
                      {!amountLoader ? <BillingCardSubValue
                        style={{ color: "#3086ec" }}
                        id="current-balance"
                      >
                        $&nbsp;{outstanding?.credits?.units}
                      </BillingCardSubValue> : <BillingCardSubValue>
                        <Skeleton
                          sx={{ mt: 1, borderRadius: "4px", width: { xs: '100%' } }}
                          variant="rectangular"
                          animation="wave"
                          height={18}
                        /></BillingCardSubValue>}
                      
                        <BillingCardSubHeaderText>
                          Current balance
                        </BillingCardSubHeaderText> 
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box>
            <Box sx={{ ml: 2, mr: 2 }}>
              <Grid container>
                {/* heading */}
                <Grid item xs={12} md={12}>
                  <BillingCardHeader
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <BillingCardHeaderText
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            marginLeft: "0px",
                          }}
                        >
                          Payment
                        </BillingCardHeaderText>
                      </div>
                      <Hidden only={"xs"}>
                        <Divider
                          sx={{
                            width: { sm: "89%", lg: "90%", xl: "92%" },
                            marginTop: ".7rem",
                            marginLeft: "10px",
                          }}
                        />
                      </Hidden>
                    </div>
                  </BillingCardHeader>
                </Grid>
                {/* heading */}
                {/* TextField */}
                <Box
                  width={"100%"}
                  id="payment-details"
                  pt={"10px"}
                  pb={"10px"}
                >
                  <Grid container rowGap={2} columnGap={3} md={12}>
                    <Grid item xs={12} sm={3.73} md={3.73} lg={3.85}>
                      <TextField
                        id="minimum-amount"
                        variant="outlined"
                        label="Amount (min. 10 Units)"
                        fullWidth
                        type="number"
                        InputProps={{
                          sx: { fontFamily: "Poppins" },
                          inputProps: { min: 10 },
                        }}
                        InputLabelProps={{
                          sx: { fontFamily: "Poppins" },
                        }}
                        onChange={handleUnitChange}
                        value={selectedUnits === 0 ? "" : selectedUnits}
                        error={!!(selectedUnits < 10)}
                        helperText={
                          selectedUnits < 10 && (
                            <span style={{ color: "red" }}>
                              Please add minimum 10 units
                            </span>
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3.73} md={3.73} lg={3.8}>
                      <FormControl
                        variant="outlined"
                        sx={{ minWidth: 120 }}
                        fullWidth
                      >
                        <InputLabelText>Currency</InputLabelText>
                        <Select
                          value={currency}
                          label="Currency"
                          onChange={handleCurrenyChange}
                          MenuProps={{ MenuListProps: { sx: { p: 0, m: 0 } } }}
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "400",
                          }}
                          id="currency"
                        >
                          {Currency.map((data, index) => (
                            <MenuItem
                              value={data}
                              key={index}
                              id={`${data.toLowerCase()}`}
                              sx={{ fontFamily: "Poppins" }}
                            >
                              {data}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3.73} md={3.73} lg={3.8}>
                      <FormControl
                        variant="outlined"
                        sx={{ minWidth: 120 }}
                        fullWidth
                      >
                        <InputLabelText>Payment Mode</InputLabelText>
                        <Select
                          value={paymentMode}
                          label="Payment Mode"
                          onChange={handlePaymentMethod}
                          MenuProps={{ MenuListProps: { sx: { p: 0, m: 0 } } }}
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "400",
                            // fontWeight: 'bold',
                          }}
                          id="payment-mode"
                        >
                          {paymentMethods.map((data, index) => (
                            <MenuItem
                              key={index}
                              value={data}
                              id={`${data.toLowerCase().replaceAll(" ", "-")}`}
                              sx={{ fontFamily: "Poppins" }}
                            >
                              {data}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                {/* TextField */}
                {/* Last Grid */}
                <Grid container md={12} style={{ paddingTop: "15px" }}>
                  <Grid
                    item
                    sm={7}
                    md={9}
                    style={{ background: "#F5F5F5", borderRadius: "4px" }}
                    id="total-amount"
                  >
                    <PaymentExtraText>
                      You will be charged total $
                      {(selectedUnits * currencyConverter * 1.18).toFixed(2)} ($
                      {(selectedUnits * currencyConverter).toFixed(2)} + 18.00%
                      GST)
                      {/* </PaymentExtraText>
                                          <PaymentExtraText> */}
                      <div>
                        Terms of Service: Deposit in USD: $
                        {(selectedUnits * currencyConverter * 1.18).toFixed(2)}.
                        Exchange rate {currencyConverter} USD/USD (Updated{" "}
                        {new Date().toLocaleDateString()})
                      </div>
                    </PaymentExtraText>
                  </Grid>
                  <Grid item sm={5} md={3}>
                    <Grid container direction="row-reverse">
                      <PayNowButtonBox sx={{ mt: 2 }}>
                        {selectedUnits < 10 ? (
                          <ButtonDisabled id="pay-now-btn" responsive={false}>
                            Pay Now
                          </ButtonDisabled>
                        ) : (
                          <ButtonPrimary
                            id="pay-now-btn"
                            responsive={false}
                            onClick={openPaymentGateway}
                          >
                            Pay Now
                          </ButtonPrimary>
                        )}
                      </PayNowButtonBox>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Last Grid */}
              </Grid>

              <Dialog
                onClose={handleClose}
                open={paymentSuccess.action}
                maxWidth="xs"
                fullWidth={true}
              >
                <Typography
                  variant="h3"
                  style={{ textAlign: "center", margin: "1rem" }}
                >
                  Transaction Success
                </Typography>
                <Typography sx={{ textAlign: "center" }}>
                  Payment Id: {paymentSuccess.paymentId}
                </Typography>
                <Box
                  sx={{
                    padding: "1rem 2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <TaskAltIcon color="success" sx={{ fontSize: 100 }} /> */}
                  TaslAltIcon
                </Box>
                <Box
                  sx={{
                    paddingBottom: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    {new Date().toLocaleDateString([], {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}{" "}
                    {new Date().toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                      marginTop: "4px",
                    }}
                  >
                    ${(selectedUnits * currencyConverter * 1.18).toFixed(2)}
                  </span>
                  <Button variant="outlined" sx={{ mt: 2 }} id="download-slip">
                    Download Slip
                  </Button>
                </Box>
              </Dialog>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: "20px",
                mt: "10px",
                pl: 2,
                pr: 2,
              }}
            >
              <div style={{ display: "flex" }}>
                <BillingCardHeaderText
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginLeft: "0px",
                  }}
                >
                  Payment History
                </BillingCardHeaderText>
              </div>
              <Hidden only={["xs"]}>
                <Divider
                  sx={{
                    width: { sm: "84%", md: "87%", lg: "89%", xl: "90%" },
                    marginTop: ".7rem",
                  }}
                />
              </Hidden>
            </Box>
            <Table />
          </Box>
        </Box>
      </ContentWrapper>
    </>
  );
}

export default Home;
