import { Box, Typography, styled } from "@mui/material";



export const CustomBox = styled(Box)({
 marginTop: "30px",
});

export const MiniBoxMarginFifteen = styled(Box)({
 marginTop: "15px",
});

export const Paragraph = styled(Typography)({
 fontFamily: "Poppins",
 fontWeight: 300,
 fontSize: "16px",
 color: "#686868",
});

export const Heading = styled(Typography)({
 fontFamily: "poppins",
 fontWeight: 600,
 fontSize: "32px",
 color: "#373737",
});

export const SubHeading = styled(Typography)({
 fontFamily: "poppins",
 fontWeight: 600,
 fontSize: "20px",
 color: "#373737",
 textAlign: "left",
});

export const ParagraphBold = styled(Typography)({
 fontFamily: "poppins",
 fontWeight: 600,
 fontSize: "14px",
 color: "#373737",
 textAlign: "justify",
});