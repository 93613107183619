import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  marginTop: '40px',
  marginBottom: '20px',
  display: 'flex',
  flexDirection: 'row',
  gap: '15px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '5px',
    justifyContent: 'center'
  },
}));

export const FeatureGridContainerRow = styled(Grid)(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    marginBottom: '20px',
    padding: '0 10px'
  },
}));

export const FeatureGridContainerRowReverse = styled(Grid)(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    marginBottom: '20px',
    padding: '0 10px'
  },
}));

export const FeatureContent = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: '#3F3D56',
  [theme.breakpoints.down('md')]: {
    fontSize: '13px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  }
}));

export const FeatureWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
}));

export const FeatureHeading = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  fontWeight: 600,
  marginBottom: '15px',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  }
}));

export const HeadingTitle = styled(Typography)(({ theme }) => ({
  fontSize: '30px',
  fontWeight: 600,
  margin: '20px 0',
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    textAlign: 'center',
    margin: '10px 0',
  },
}));

export const HeadingSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: '#B2B2B2',
  [theme.breakpoints.down('md')]: {
    fontSize: '13px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    textAlign: 'center'
  },
}));

export const HeadingWrapper = styled(Box)(() => ({
  textAlign: 'left',
}));

export const FeedbackContainer = styled(Grid)(({ theme }) => ({
  height: '200px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  background: 'linear-gradient(90.88deg, #1A75FF 0.12%, rgba(71, 255, 26, 0.57) 100%)',
  color: 'white',
  margin: '30px 5px',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}));

export const FeedbackTitle = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    marginTop:'30px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    
  }
}));

export const FeedbackHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'left',
  marginBottom: '20px',
  color: 'black',
  [theme.breakpoints.down('md')]: {
    fontSize: '13px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));

export const FeedbackSubHeading = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  textAlign: 'left',
  color: 'black',
  [theme.breakpoints.down('md')]: {
    fontSize: '13px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));