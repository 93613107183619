import React from 'react';
const LiveConfIcon = ({ props }) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 165 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M88.6997 43.2549H79.8005C78.29 43.2549 77.0674 42.0231 77.0674 40.5053V20.3102C77.0674 18.7905 78.29 17.5605 79.8005 17.5605C81.3111 17.5605 82.5337 18.7905 82.5337 20.3102V37.7556H88.6997C90.2084 37.7556 91.4329 38.9875 91.4329 40.5053C91.4329 42.0231 90.2084 43.2549 88.6997 43.2549Z" fill="#3087EC" />
            <path d="M98.0271 43.2549C96.5184 43.2549 95.2939 42.0231 95.2939 40.5053V20.3102C95.2939 18.7905 96.5184 17.5605 98.0271 17.5605C99.5358 17.5605 100.76 18.7905 100.76 20.3102V40.5053C100.76 42.0231 99.5358 43.2549 98.0271 43.2549Z" fill="#3087EC" />
            <path d="M138.828 43.2549H129.928C128.42 43.2549 127.195 42.0231 127.195 40.5053V20.3102C127.195 18.7905 128.42 17.5605 129.928 17.5605H138.828C140.336 17.5605 141.561 18.7905 141.561 20.3102C141.561 21.8298 140.336 23.0598 138.828 23.0598H132.662V37.7556H138.828C140.336 37.7556 141.561 38.9875 141.561 40.5053C141.561 42.0231 140.338 43.2549 138.828 43.2549Z" fill="#3087EC" />
            <path d="M135.75 33.1575H129.93C128.422 33.1575 127.197 31.9275 127.197 30.4078C127.197 28.8882 128.422 27.6582 129.93 27.6582H135.75C137.259 27.6582 138.483 28.8882 138.483 30.4078C138.483 31.9275 137.259 33.1575 135.75 33.1575Z" fill="#3087EC" />
            <path d="M114.012 43.2547C112.855 43.2547 111.825 42.5234 111.437 41.4272L104.291 21.2321C103.784 19.8022 104.528 18.2295 105.949 17.7199C107.368 17.2121 108.934 17.9582 109.44 19.388L114.012 32.3039L118.582 19.388C119.088 17.9582 120.657 17.2121 122.073 17.7199C123.494 18.2295 124.238 19.8022 123.731 21.2321L116.587 41.4272C116.198 42.5215 115.169 43.2547 114.012 43.2547Z" fill="#3087EC" />
            <path d="M26.3268 44.4578C25.6199 44.4578 24.9129 44.2836 24.266 43.9372C22.8448 43.1709 21.9629 41.688 21.9629 40.0675V20.7468C21.9629 19.1245 22.8448 17.6415 24.2679 16.8771C25.6873 16.1091 27.4037 16.1952 28.7466 17.0953L43.1722 26.7538C44.3894 27.5695 45.1183 28.9352 45.1183 30.4071C45.1183 31.8791 44.3912 33.2448 43.1722 34.0605L28.7448 43.7227C28.0123 44.2103 27.1705 44.4578 26.3268 44.4578ZM27.4274 22.82V37.9961L38.7609 30.4071L27.4274 22.82Z" fill="#3087EC" />
            <path d="M30.9332 60.6089C14.3793 60.6089 0.912109 47.0606 0.912109 30.407C0.912109 13.7534 14.3793 0.205078 30.9332 0.205078C36.9389 0.205078 42.7368 1.985 47.6984 5.35055C48.9502 6.19927 49.2818 7.90953 48.4364 9.16703C47.5946 10.4264 45.8945 10.7582 44.6427 9.90944C40.5867 7.15797 35.8456 5.70434 30.9332 5.70434C17.3931 5.70434 6.37844 16.7853 6.37844 30.407C6.37844 44.0287 17.3931 55.1097 30.9332 55.1097C44.4733 55.1097 55.4879 44.0287 55.4879 30.407C55.4879 27.6922 55.0543 25.0232 54.1961 22.4771C53.7114 21.0381 54.4785 19.4781 55.9088 18.9905C57.3465 18.5066 58.8916 19.2765 59.3745 20.7136C60.4222 23.8299 60.9543 27.0909 60.9543 30.407C60.9543 47.0606 47.487 60.6089 30.9332 60.6089Z" fill="#3087EC" />
            <path d="M139.529 55.9323H63.2065C61.696 55.9323 60.4734 54.7005 60.4734 53.1827C60.4734 51.6649 61.696 50.433 63.2065 50.433H139.529C150.507 50.433 159.436 41.4491 159.436 30.4066C159.436 19.3641 150.507 10.3801 139.529 10.3801H46.1716C44.6611 10.3801 43.4385 9.15012 43.4385 7.63049C43.4385 6.11086 44.6611 4.88086 46.1716 4.88086H139.529C153.519 4.88086 164.902 16.3321 164.902 30.4066C164.902 44.481 153.519 55.9323 139.529 55.9323Z" fill="#3087EC" />
        </svg>

    );
};


export default LiveConfIcon;