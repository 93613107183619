import ContentWrapper from 'src/components/ContentWrapper';
// import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Autocomplete, Box, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip } from "chart.js";
import { SummaryCard } from './indexStyled';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DashboardSummary, getApplicationCount, getApplicationDetails, getWebhookHits } from 'src/slices/dashboard';
import { RootState } from 'src/store';
import { FiberManualRecordRounded } from '@mui/icons-material';
import Loader from 'src/components/Loader/Loader';
Chart.register(ArcElement, Tooltip);

function Dashboard() {
  const dispatch = useDispatch();
  const { dashboardWebhookCount, dashboardApplicationCount, pageLoader, appDetails, appIdSearchData} = useSelector(
    (state: RootState) => state.overview,
  )

  // const { tableData } = useSelector((state: RootState) => state.apiKeys);
  // const idList = [...tableData];

  const configWebhook = {
    data: {
      labels: [
        "HIT SUCCESS",
        "HIT FAILURE",
      ],
      datasets: [
        {
          // label: 'Good Morning',
          data: [dashboardWebhookCount[1]?.count, dashboardWebhookCount[2]?.count],
          backgroundColor: [
            "#44D600",
            "#FF1A43",
          ],
          hoverOffset: 4,
          borderRadius: 5,
          spacing: 0,
          radius: 100,
          cutout: 100,
        },
      ],
    },
  };

  const configApplication = {
    data: {
      labels: [
        "CONFERENCES",
        "RECORDINGS",
        "USERS",
      ],
      datasets: [
        {
          // label: 'Good Morning',
          data: [dashboardApplicationCount[0]?.count, dashboardApplicationCount[1]?.count, dashboardApplicationCount[2]?.count],
          backgroundColor: [
            "#44D600",
            "#FF1A43",
            "#33C2FF",
          ],
          hoverOffset: 4,
          borderRadius: 5,
          spacing: 0,
          radius: 100,
          cutout: 100,
        },
      ],
    },
  };

  const configNoData = {
    data: {
      labels: ["No data"],
      datasets: [
        {
          data: [20],
          backgroundColor: [
            "grey",
          ],
          hoverOffset: 4,
          borderRadius: 5,
          spacing: 0,
          radius: 100,
          cutout: 100,
        },
      ],
    },
  };

  useEffect(() => {
    dispatch(DashboardSummary.searchAppIdDetails(''));
    dispatch(getWebhookHits(""));
    dispatch(getApplicationCount(""));
    dispatch(getApplicationDetails());
  }, [dispatch]);


  return (
    <ContentWrapper title="Dashboard">
      <PageTitleWrapper>
        {/* <PageHeader /> */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h2' sx={{ color: "#3087EC" }}>Overview</Typography>
          <Autocomplete
            sx={{
              width: "40ch",
              ".MuiIconButton-root.MuiAutocomplete-clearIndicator": {
                display: appIdSearchData.name ? "" : "none",
                background: "#f6f8fb",
                color: "black",
                marginRight: "-2px",
                "&:hover": {
                  display: appIdSearchData.name ? "" : "none",
                  background: "#f6f8fb",
                  color: "black",
                  marginRight: "-2px",
                },
              },
            }}
            value={appIdSearchData}
            onChange={(e: any, newvalue) => {
              if (newvalue === null) {
                dispatch(DashboardSummary.searchAppIdDetails(""));
                dispatch(getWebhookHits(""));
                dispatch(getApplicationCount(""));
                return;
              }
              dispatch(DashboardSummary.searchAppIdDetails(newvalue));
              dispatch(getWebhookHits(newvalue.appId));
              dispatch(getApplicationCount(newvalue.appId));
            }}
            id="overview-app-id"
            size="small"
            options={appDetails}
            getOptionLabel={(option: any) =>
              option?.name ? `${option.name} (${option.appId})` : ""
            }
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name} ({option.appId})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                sx={{ pr: "0px" }}
                {...params}
                label="App name"
                placeholder="Select an application name"
              />
            )}
          />
        </Box>
        <Typography variant='h6' sx={{ color: '#223354', opacity: 0.7, marginBottom: '10px' }}>Overview of portego activities</Typography>
      </PageTitleWrapper>
      {pageLoader ? (
        <Box sx={{ height: "90vh" }}>
          <Loader />
        </Box>
      ) :
        <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} rowGap={2} columnGap={4}>
            <Grid item xs={12} sm={8} md={5.8} lg={5.3} xl={5}>
              <SummaryCard >
                <Box>
                  <Typography variant='h4' sx={{ opacity: 0.8, paddingBottom: '10px', margin: '5px' }}>Webhook Hits</Typography>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '30px' }}>
                    {dashboardWebhookCount.map((data, index) => (
                      <Box key={index}>
                        <Typography sx={{ padding: '10px', opacity: '0.7' }}>{data.name}</Typography>
                        <Typography sx={{ textAlign: 'center', fontSize: '18px' }}>{data.count}</Typography>
                      </Box>
                    ))}
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box>
                      {((dashboardWebhookCount[1].count) === 0 && (dashboardWebhookCount[2].count) === 0) ?
                        <Doughnut {...configNoData} options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            tooltip: {
                              enabled: false, // Hide the tooltip
                            },
                          },
                        }} /> : <Doughnut {...configWebhook} options={{
                          responsive: true,
                          maintainAspectRatio: true,
                        }} />
                      }
                    </Box>
                    <Box>
                      {((dashboardWebhookCount[1].count) !== 0 || (dashboardWebhookCount[2].count) !== 0) ?
                        <>
                          {dashboardWebhookCount.map((data, index) => {
                            if (index === 1 || index === 2) {
                              return (
                                <Typography key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                  <FiberManualRecordRounded sx={{ fontSize: '15px', color: data.color, mr: 1 }} />
                                  {data.name.charAt(0).toUpperCase() + data.name.slice(1).toLowerCase()}
                                </Typography>
                              );
                            }
                            return null;
                          })
                          }
                        </>
                        : <>
                          <Typography sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <FiberManualRecordRounded sx={{ fontSize: '15px', color: "#44D600", mr: 1 }} />
                            Hit Success
                          </Typography>
                          <Typography sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <FiberManualRecordRounded sx={{ fontSize: '15px', color: "#FF1A43", mr: 1 }} />
                            Hit Failure
                          </Typography>
                          <Typography sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <FiberManualRecordRounded sx={{ fontSize: '15px', color: 'grey', mr: 1 }} />
                            No Data
                          </Typography></>}
                    </Box >
                  </Box>
                </Box>
              </SummaryCard>
            </Grid>


            <Grid item xs={12} sm={8} md={5.8} lg={5.3} xl={5}>
              <SummaryCard >
                <Box>
                  <Typography variant='h4' sx={{ opacity: 0.8, paddingBottom: '10px', margin: '5px' }}>Applications</Typography>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '30px' }}>
                    {dashboardApplicationCount.map((data, index) => (
                      <Box key={index}>
                        <Typography sx={{ padding: '10px', opacity: '0.7' }}>{data.name}</Typography>
                        <Typography sx={{ textAlign: 'center', fontSize: '18px', }}>{data?.count}</Typography>
                      </Box>
                    ))}
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box>
                      {((dashboardApplicationCount[0].count) === 0 && (dashboardApplicationCount[1].count) === 0 && (dashboardApplicationCount[2].count) === 0) ?
                        <Doughnut {...configNoData} options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            tooltip: {
                              enabled: false, // Hide the tooltip
                            },
                          },
                        }} /> : <Doughnut {...configApplication} options={{
                          responsive: true,
                          maintainAspectRatio: true,
                        }} />
                      }
                    </Box>
                    <Box>
                      {((dashboardApplicationCount[0].count) !== 0 || (dashboardApplicationCount[1].count) !== 0 || (dashboardApplicationCount[2].count) !== 0) ?
                        <>
                          {dashboardApplicationCount.map((data, index) => (
                            <Typography key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              <FiberManualRecordRounded sx={{ fontSize: '15px', color: data.color, mr: 1 }} />
                              {data?.name.charAt(0).toUpperCase() + data?.name.slice(1).toLowerCase()}
                            </Typography>
                          ))}
                        </> : <Typography sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <FiberManualRecordRounded sx={{ fontSize: '15px', color: 'grey', mr: 1 }} />
                          No Data
                        </Typography>}
                    </Box>
                  </Box>
                </Box>
              </SummaryCard>
            </Grid>
          </Grid>
        </Container>
      }
    </ContentWrapper>
  );
}

export default Dashboard;
