import { Box, Button, Hidden, IconButton, Paper, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React, { useState } from 'react'
import { CustomWidthTooltip, MyAccountBox, ProfileMainContainer, SubTabContainer, UserCardEmailInfo, UserCardInfo, UserDetails, UserInfo, UserInfoDetails, UserTitle } from './profileStyled';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import UserCamera from 'src/assets/icons/UserCamera';
import { BsFlag } from 'react-icons/bs';
import Pin from 'src/assets/icons/pin';
import Phone from 'src/assets/icons/Phone';
import { FailureSnackbar } from 'src/slices/Snackbar';
import {
  profilePicDelete,
  profilePicUpdate,
} from 'src/slices/profile';
import CloseAlert from 'src/components/AlertDialog';
import PersonalDetails from './myProfile';
import { pink } from '@mui/material/colors';
import BannerMobile from 'src/assets/icons/bannerMobile';
import Banner from 'src/assets/icons/banner';
import Loader from 'src/components/Loader/Loader';

/*eslint-disable*/
function Index() {
  const dispatch = useDispatch();
  const [boxOpen, setBoxOpen] = useState(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { oldProfileData, profilePicture, profilePictureLoading, profilePageLoading } = useSelector(
    (state: RootState) => state.profile,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setBoxOpen(!boxOpen);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    setBoxOpen(false);
  };
  const open = Boolean(anchorEl);

  // profile picture Change Handler
  const profilePictureChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation();
    if (e.target.files[0].size > 5000000) {
      dispatch(
        FailureSnackbar({
          message: 'Image size is too large(max 5MB)',
          open: true,
        }),
      );
    } else {
      dispatch(profilePicUpdate(e.target.files[0]));
    }
    setBoxOpen(false);
    e.stopPropagation();
  };

  //delete profile picture handler
  const deleteProfileHandler = () => {
    dispatch(profilePicDelete());
    setBoxOpen(false);
  };

  return (
    <>
    {profilePageLoading ? <Box sx={{ height: '90vh' }}><Loader /></Box> :
    <>
      <Box
        style={{
          position: 'relative',
          zIndex: 1,
        }}
        onClick={(e) => {
          handleClose(e);
        }}
      >
        <Hidden smUp>
          <div style={{
            position: 'absolute',
            width: '100%',
          }}>

            <BannerMobile props={{ height: '100%', width: '100%' }} />
          </div>
        </Hidden>
        <Hidden smDown>
          <div style={{
            position: 'absolute',
            width: '100%',
          }}>

            <Banner props={{ width: '100%' }} />
          </div>
        </Hidden>
        <Hidden only={['xs', 'sm']}>
          <MyAccountBox>My Account</MyAccountBox>
        </Hidden>

        <SubTabContainer>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: { sm: '30%', md: '20%' } }}>
            {/* Profile Picture */}
            <Paper
              sx={{
                zIndex: 10,
                marginTop: { xs: '2rem', sm: '5rem', md: '7rem' },
                width: '100%',
                height: '330px',
                background: '#FFFFFF',
                boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.05)',
                borderRadius: '8px',
                display: { xs: 'none', sm: 'flex' },
                justifyContent: 'center',
              }}
              elevation={3}
            >
              <Box>
                <ProfileMainContainer>
                  <Avatar
                    alt="Remy Sharp"
                    src={profilePicture}
                    sx={{
                      width: '130px',
                      height: '130px',
                      position: 'relative',
                    }}
                  />
                  <Box>
                    {boxOpen ? (
                      <Box
                        sx={{
                          position: 'absolute',
                          border: '1px solid greay',
                          marginLeft: '13px',
                          marginTop: '0px',
                          background: '#FFFFFF',
                          boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.05)',
                          borderRadius: '4px',
                          transform: 'translate(40px,-20px)',
                        }}
                      >
                        <Box
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Button
                            component="label"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            sx={{ color: '#373737', '&:hover': { color: '#3087EC' } }}
                          >
                            <Typography>
                              {profilePicture
                                ? 'Change photo'
                                : ' Upload photo'}
                            </Typography>
                            <input
                              hidden
                              accept=".jpg, .jpeg, .png"
                              multiple
                              type="file"
                              name="avatar"
                              onChange={profilePictureChangeHandler}
                            />
                          </Button>

                          <Button
                            disabled={profilePicture ? false : true}
                            onClick={() => {
                              setCloseAlert(true);
                              setAnchorEl(null);
                            }}
                            sx={{ color: '#373737', '&:hover': { color: '#3087EC' } }}
                          >
                            <Typography>
                              Remove photo
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      null
                    )}
                  </Box>

                  {!profilePictureLoading ? (
                    <>
                      <IconButton
                        onClick={handleClick}
                        style={{
                          position: 'absolute',
                          background: '#3087EC',
                          height: '35px',
                          width: '35px',
                          borderRadius: '100%',
                          border: '3px solid #ffffff',
                          margin: '2rem 0rem 0rem 7rem',
                        }}
                      >
                        <UserCamera props={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    </>
                  ) : (
                    <LoadingButton
                      loading
                      sx={{
                        position: 'absolute',
                      }}
                    >
                      Update Profile Picture
                    </LoadingButton>
                  )}
                  <CustomWidthTooltip
                    title={`${oldProfileData.firstName} ${oldProfileData.lastName}`}
                    arrow
                  >
                    <UserTitle>
                      {oldProfileData.firstName}&ensp;{oldProfileData.lastName}
                    </UserTitle>
                  </CustomWidthTooltip>
                  <CustomWidthTooltip
                    title={`${oldProfileData.email}`}
                    arrow>
                    <UserCardEmailInfo
                      style={{
                        width: '180px',
                      }}
                    >
                      {oldProfileData.email}
                    </UserCardEmailInfo>
                  </CustomWidthTooltip>
                </ProfileMainContainer>
                <UserInfo>
                  <UserInfoDetails>
                    <BsFlag style={{ height: '20px', width: '20px' }} />
                    {oldProfileData.country && oldProfileData.state !== '' ? (
                      <CustomWidthTooltip
                        title={`${oldProfileData.country} - ${oldProfileData.state}`}
                        arrow
                      >
                        <UserCardInfo>
                          {oldProfileData.country},&ensp;{oldProfileData.state}
                        </UserCardInfo>
                      </CustomWidthTooltip>
                    ) : (
                      <CustomWidthTooltip
                        title={
                          oldProfileData.country !== '' &&
                            oldProfileData.state !== ''
                            ? `${oldProfileData.country} - ${oldProfileData.state}`
                            : oldProfileData.country === ''
                              ? `${oldProfileData.state}`
                              : `${oldProfileData.country}`
                        }
                        arrow
                      >
                        <UserCardInfo>
                          {oldProfileData.country}

                          {oldProfileData.state}
                        </UserCardInfo>
                      </CustomWidthTooltip>
                    )}
                  </UserInfoDetails>
                  <UserInfoDetails>
                    {oldProfileData.city || oldProfileData.pincode !== '' ? (
                      <Pin
                        props={{
                          height: '20px',
                          width: '20px',
                        }}
                      />
                    ) : (
                      ''
                    )}
                    {oldProfileData.city && oldProfileData.pincode !== '' ? (
                      <CustomWidthTooltip
                        title={
                          oldProfileData.city + ' - ' + oldProfileData.pincode
                        }
                        arrow
                      >
                        <UserCardInfo>
                          {oldProfileData.city},&ensp;
                          {oldProfileData.pincode}
                        </UserCardInfo>
                      </CustomWidthTooltip>
                    ) : (
                      <CustomWidthTooltip
                        title={
                          oldProfileData.city !== '' &&
                            oldProfileData.pincode !== ''
                            ? oldProfileData.city +
                            ' - ' +
                            oldProfileData.pincode
                            : oldProfileData.city === ''
                              ? `${oldProfileData.pincode}`
                              : `${oldProfileData.city}`
                        }
                        arrow
                      >
                        <UserCardInfo>
                          {oldProfileData.city}

                          {oldProfileData.pincode}
                        </UserCardInfo>
                      </CustomWidthTooltip>
                    )}
                  </UserInfoDetails>
                  <UserInfoDetails>
                    {oldProfileData.contactNo !== '' ? (
                      <Phone props={{ height: '20px', width: '20px' }} />
                    ) : (
                      ''
                    )}
                    {oldProfileData.contactNo !== '' ? (
                      <CustomWidthTooltip
                        title={`${oldProfileData.countryCode} - ${oldProfileData.contactNo}`}
                        arrow
                      >
                        <UserDetails>
                          {oldProfileData.countryCode}&ensp;
                          {oldProfileData.contactNo}
                        </UserDetails>
                      </CustomWidthTooltip>
                    ) : (
                      <UserDetails></UserDetails>
                    )}
                  </UserInfoDetails>
                </UserInfo>
              </Box>
            </Paper>
            {/* Profile Picture */}
            {/* Profile picture Mobile */}
            <Hidden smUp>
              <ProfileMainContainer>
                <Avatar
                  alt="Remy Sharp"
                  // src={user.avatar}
                  src={profilePicture}
                  sx={{
                    width: '130px',
                    height: '130px',
                    position: 'relative',
                    bgcolor: pink[500],
                  }}
                />
                <Box>
                  {boxOpen ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        border: '1px solid greay',
                        marginLeft: '13px',
                        marginTop: '0px',
                        background: '#FFFFFF',
                        boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.05)',
                        borderRadius: '4px',
                        transform: 'translate(10px,-30px)',
                        zIndex: 10
                      }}
                    >
                      <Box
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Button
                          component="label"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          sx={{ color: '#373737', '&:hover': { color: '#3087EC' } }}
                        >
                          <Typography>
                            {profilePicture
                              ? 'Change photo'
                              : ' Upload photo'}
                          </Typography>
                          <input
                            hidden
                            accept=".jpg, .jpeg, .png"
                            multiple
                            type="file"
                            name="avatar"
                            onChange={profilePictureChangeHandler}
                          />
                        </Button>

                        <Button
                          disabled={profilePicture ? false : true}
                          onClick={() => {
                            setCloseAlert(true);
                            setAnchorEl(null);
                          }}
                          sx={{ color: '#373737', '&:hover': { color: '#3087EC' } }}
                        >
                          <Typography>
                            Remove photo
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    null
                  )}
                </Box>

                {!profilePictureLoading ? (
                  <>
                    <IconButton
                      onClick={handleClick}
                      style={{
                        position: 'absolute',
                        background: '#3087EC',
                        height: '35px',
                        width: '35px',
                        borderRadius: '100%',
                        border: '3px solid #ffffff',
                        margin: '2rem 0rem 0rem 7rem',
                      }}
                    >
                      <UserCamera props={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  </>
                ) : (
                  <LoadingButton
                    loading
                    sx={{
                      position: 'absolute',
                    }}
                  >
                    Update Profile Picture
                  </LoadingButton>
                )}
              </ProfileMainContainer>
            </Hidden>
          </Box>
          {/* Profile details */}
          <Paper
            sx={{
              zIndex: 9,
              marginTop: { sm: '5rem', md: '7rem' },
              width: {
                xs: '90%',
                sm: '65%',
                md: '75%',
                lg: '75%',
                xl: '75%',
              },
              // minHeight: '736px',
              background: '#FFFFFF',
              boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.05)',
              borderRadius: '8px',
              padding: { xs: '0px', sm: '10px' },
              marginBottom: '15px'
            }}
            elevation={3}
          >
            <PersonalDetails />
          </Paper>
          {/* Profile details */}
        </SubTabContainer>


      </Box>
      {closeAlert && (
        <CloseAlert
          id={deleteProfileHandler}
          openCloseDialog={closeAlert}
          setOpenCloseDialog={setCloseAlert}
          accept={`Yes`}
          cancel={`No`}
          title={`Are you sure you want to remove profile picture?`}
          mainTitle={`Close Alerts`}
          subTitle={undefined}
          textColor={'#FF5A5A'}
          BackgroundColor={'#FF5A5A'}
        />
      )}</>}
    </>
  )
};

export default Index;