import {
  Divider,
  Grid,
  MenuItem,
  TextField,
  Hidden,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  AutocompleteField,
  ContactError,
  DiscardChangesButton,
  ProfileContainer,
  SaveChangesButton,
  ValidationText,
  BillingCardHeader,
  BillingCardHeaderText,
} from "./indexStyled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/store";
import {
  discardChanges,
  getData,
  ProfileAction,
  updateUserDetails,
  verifyEmail,
} from "src/slices/profile";
import { LoadingButton } from "@mui/lab";
import { nanoid } from "@reduxjs/toolkit";
import { CheckCircleRounded } from "@mui/icons-material";
import PasswordUpdate from "./PasswordUpdate";
import VerifyEmail from "./verifyEmail";
import { PopinsTwelveText } from "src/content/Profile/myProfile/indexStyled";
import CustomDialogBox from "src/components/CustomDialog";
import { DiscardChanges, SaveChanges } from "src/constants/string";
import { ButtonDanger } from "src/components/CustomButtons";
import ContentWrapper from "src/components/ContentWrapper";
import { useAuth0 } from "@auth0/auth0-react";
//import useAuth from "src/hooks/useAuth";

const PersonalDetails = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const {
    profileData,
    countryNameList,
    countryCodeList,
    oldProfileData,
    fieldsTouched,
    updateDataLoading, verifyEmailDialog
  } = useSelector((state: RootState) => state.profile);

  // valid states
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validMobileNo, setValidMobileNo] = useState(true);
  const [validOrganizationName, setValidOrganizationName] = useState(true);
  const [validAddress1, setValidAddress1] = useState(true);
  const [validAddress2, setValidAddress2] = useState(true);
  const [validPostal, setValidPostal] = useState(true);
  const [validCity, setValidCity] = useState(true);
  const [validState, setValidState] = useState(true);
  const [validCountry, setValidCountry] = useState(true);
  const [validGst, setValidGst] = useState(true);
  // valid states

  // regex validation
  const firstNameRegex = /^[a-zA-Z\s]{3,40}$/;
  const lastNameRegex = /^[a-zA-Z\s]{1,25}$/;
  const organizationRegex = /^.{3,40}$/;
  const addressRegex = /^.{3,25}$/;
  const postalRegex = /^[0-9A-Z]{5,7}$/;
  const cityRegex = /^[a-zA-Z\s]{3,30}$/;
  const stateRegex = /^[a-zA-Z\s]{3,40}$/;
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const phoneRegex = /^[0-9]{7,15}$/;
  const charHandler = /^[a-zA-Z0-9]+$/;
  const numberHandler = /^[0-9]+$/;

  // profile data change handlers
  const profileDataChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      ProfileAction.setProfileData({
        key: e.target.name,
        value: e.target.value,
      })
    );
  };

  const addressHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value?.replace(/ [0-9]+(?= )/g, "");
    dispatch(
      ProfileAction.setProfileData({
        key: e.target.name,
        value: value,
      })
    );
  };

  const pinGstHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value !== "" && !charHandler.test(value)) {
      return;
    }
    dispatch(
      ProfileAction.setProfileData({
        key: e.target.name,
        value: e.target.value,
      })
    );
  };

  const profileNameHandlers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value?.replace(/ [0-9]+(?= )/g, "");
    const value1 = value?.replace(/[^a-zA-Z ]/g, "");
    dispatch(
      ProfileAction.setProfileData({
        key: e.target.name,
        value: value1,
      })
    );
  };

  const profilePhoneNoHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value !== "" && !numberHandler.test(value)) {
      return;
    }
    dispatch(
      ProfileAction.setProfileData({
        key: e.target.name,
        value: e.target.value,
      })
    );
  };

  // profile data update form handler
  const profileDataUpdateHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (profileData.firstName && profileData.firstName.match(firstNameRegex)) {
      setValidFirstName(true);
    } else {
      setValidFirstName(false);
    }
    if (!profileData.lastName || profileData.lastName.match(lastNameRegex)) {
      setValidLastName(true);
    } else {
      setValidLastName(false);
    }
    if (!profileData.contactNo || profileData.contactNo.match(phoneRegex)) {
      setValidMobileNo(true);
    } else {
      setValidMobileNo(false);
    }
    if (
      !profileData.organizationName ||
      profileData.organizationName.match(organizationRegex)
    ) {
      setValidOrganizationName(true);
    } else {
      setValidOrganizationName(false);
    }
    if (
      !profileData.streetAddress1 ||
      profileData.streetAddress1.match(addressRegex)
    ) {
      setValidAddress1(true);
    } else {
      setValidAddress1(false);
    }
    if (
      !profileData.streetAddress2 ||
      profileData.streetAddress2.match(addressRegex)
    ) {
      setValidAddress2(true);
    } else {
      setValidAddress2(false);
    }
    if (!profileData.pincode || postalRegex.test(profileData.pincode)) {
      setValidPostal(true);
    } else {
      setValidPostal(false);
    }
    if (!profileData.city || profileData.city.match(cityRegex)) {
      setValidCity(true);
    } else {
      setValidCity(false);
    }
    if (!profileData.state || profileData.state.match(stateRegex)) {
      setValidState(true);
    } else {
      setValidState(false);
    }
    if (profileData.country !== null) {
      setValidCountry(true);
    } else {
      setValidCountry(false);
    }
    if (!profileData.gst || profileData.gst.match(gstRegex)) {
      setValidGst(true);
    } else {
      setValidGst(false);
    }
    if (
      profileData.firstName &&
      profileData.firstName.match(firstNameRegex) &&
      (!profileData.lastName || profileData.lastName.match(lastNameRegex)) &&
      (!profileData.contactNo || profileData.contactNo.match(phoneRegex)) &&
      (!profileData.organizationName ||
        profileData.organizationName.match(organizationRegex)) &&
      (!profileData.streetAddress1 ||
        profileData.streetAddress1.match(addressRegex)) &&
      (!profileData.streetAddress2 ||
        profileData.streetAddress2.match(addressRegex)) &&
      (!profileData.pincode || postalRegex.test(profileData.pincode)) &&
      (!profileData.city || profileData.city.match(cityRegex)) &&
      (!profileData.state || profileData.state.match(stateRegex)) &&
      profileData.country !== null &&
      (!profileData.gst || profileData.gst.match(gstRegex))
    ) {
      let updatesData: any = {
        address: {
          country: profileData.country,
        },
        countryCode: profileData.countryCode,
      };
      if (profileData.firstName !== "") {
        updatesData = { firstName: profileData.firstName, ...updatesData };
      }
      if (profileData.lastName) {
        updatesData = { lastName: profileData.lastName, ...updatesData };
      }
      if (profileData.countryCode) {
        updatesData = { countryCode: profileData.countryCode, ...updatesData };
      }
      if (profileData.contactNo) {
        updatesData = { phoneNumber: +profileData.contactNo, ...updatesData };
      }
      if (profileData.organizationName) {
        updatesData.address = {
          companyName: profileData.organizationName,
          ...updatesData,
        };
      }
      if (profileData.gst) {
        updatesData = {
          gstNunber: profileData.gst,
          ...updatesData,
        };
      }
      if (profileData.pincode) {
        updatesData.address = {
          pincode: profileData.pincode.toString(),
          ...updatesData.address,
        };
      }
      if (profileData.state) {
        updatesData.address = {
          state: profileData.state,
          ...updatesData.address,
        };
      }
      if (profileData.city) {
        updatesData.address = {
          city: profileData.city,
          ...updatesData.address,
        };
      }
      if (profileData.streetAddress1) {
        updatesData.address = {
          streetAddress1: profileData.streetAddress1,
          ...updatesData.address,
        };
      }
      if (profileData.streetAddress2) {
        updatesData.address = {
          streetAddress2: profileData.streetAddress2,
          ...updatesData.address,
        };
      }
      dispatch(updateUserDetails(updatesData));
      setTimeout(() => {
        dispatch(getData());
        dispatch(ProfileAction.setTouchedFields(false));
      }, 1000);
    }
  };
  // profile data update form handler
  // discard changes
  const discardChangesHandler = () => {
    dispatch(
      ProfileAction.replaceProfileData({
        firstName: oldProfileData.firstName,
        lastName: oldProfileData.lastName,
        email: oldProfileData.email,
        emailVerified: oldProfileData.emailVerified,
        countryCode: oldProfileData.countryCode,
        contactNo: oldProfileData.contactNo,
        organizationName: oldProfileData.organizationName,
        streetAddress1: oldProfileData.streetAddress1,
        streetAddress2: oldProfileData.streetAddress2,
        pincode: oldProfileData.pincode,
        city: oldProfileData.city,
        state: oldProfileData.state,
        country: oldProfileData.country,
        gst: oldProfileData.gst,
      })
    );
    dispatch(ProfileAction.setTouchedFields(false));
    setValidFirstName(true);
    setValidLastName(true);
    setValidMobileNo(true);
    setValidOrganizationName(true);
    setValidAddress1(true);
    setValidAddress2(true);
    setValidPostal(true);
    setValidCity(true);
    setValidState(true);
    setValidCountry(true);
    setValidGst(true);
    dispatch(discardChanges());
  };
  // discard changes
  // verify email
  const verifyEmailGandler = () => {
    dispatch(verifyEmail(profileData.email));
  };
  // verify email
  // enabling and disabling profile update buttons
  const disableButton =
    profileData?.firstName === oldProfileData?.firstName &&
    profileData?.lastName === oldProfileData?.lastName &&
    profileData?.countryCode === oldProfileData?.countryCode &&
    profileData?.contactNo === oldProfileData?.contactNo &&
    profileData?.organizationName === oldProfileData?.organizationName &&
    profileData?.streetAddress1 === oldProfileData?.streetAddress1 &&
    profileData?.streetAddress2 === oldProfileData?.streetAddress2 &&
    profileData?.pincode === oldProfileData?.pincode &&
    profileData?.city === oldProfileData?.city &&
    profileData?.state === oldProfileData?.state &&
    profileData?.country === oldProfileData?.country &&
    profileData?.gst === oldProfileData?.gst;
  useEffect(() => {
    if (disableButton) {
      dispatch(ProfileAction.setTouchedFields(false));
      setValidFirstName(true);
      setValidLastName(true);
      setValidMobileNo(true);
      setValidOrganizationName(true);
      setValidAddress1(true);
      setValidAddress2(true);
      setValidPostal(true);
      setValidCity(true);
      setValidState(true);
      setValidCountry(true);
      setValidGst(true);
    } else {
      dispatch(ProfileAction.setTouchedFields(true));
    }
  }, [dispatch, disableButton]);
  // enabling and disabling profile update buttons

  // close custom dialog
  const closeCustomModal = () => {
    dispatch(ProfileAction.setVerifyEmailDialog(false));
    dispatch(ProfileAction.setEmailOTP(""));
  };

  return (
    <ProfileContainer>
      <ContentWrapper title="My Profile">
        <Grid container>
          <Grid item xs={12} md={12}>
            <BillingCardHeader
              style={{ paddingLeft: "10px", paddingRight: "0px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex" }}>
                  <BillingCardHeaderText
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      marginLeft: "0px",
                    }}
                  >
                    Personal Details
                  </BillingCardHeaderText>
                </div>
                <Hidden only={"xs"}>
                  <Divider
                    sx={{
                      width: { sm: "82%", md: "82%", lg: "85%", xl: "89%" },
                      marginTop: ".7rem",
                      marginLeft: "10px",
                    }}
                  />
                </Hidden>
              </div>
            </BillingCardHeader>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: "10px" }}>
            <TextField
              autoComplete="off"
              label="First Name"
              variant="outlined"
              fullWidth
              name="firstName"
              value={profileData?.firstName?.trimLeft()}
              placeholder="Enter first name here.."
              onChange={profileNameHandlers}
              InputLabelProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
              inputProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
                minLength: 3,
                maxLength: 40,
              }}
            />
            {!validFirstName && (
              <ValidationText>
                Please enter minimum 3 to 40 character
              </ValidationText>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: "10px" }}>
            <TextField
              autoComplete="off"
              label="Last Name"
              variant="outlined"
              fullWidth
              name="lastName"
              value={profileData?.lastName?.trimLeft()}
              placeholder="Enter last name here.."
              onChange={profileNameHandlers}
              InputLabelProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
              inputProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
                minLength: 3,
                maxLength: 25,
              }}
            />
            {!validLastName && (
              <ValidationText>
                Please enter minimum 3 character
              </ValidationText>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: "10px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                autoComplete="off"
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                inputProps={{ readOnly: true }}
                value={profileData.email}
                placeholder="Enter email address here.."
                onChange={profileDataChangeHandler}
                InputLabelProps={{
                  style: {

                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                  },
                }}
                InputProps={{
                  style: {

                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                  },
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton disableFocusRipple disableRipple>
                        {profileData.emailVerified && (
                          <CheckCircleRounded
                            color="success"
                            style={{ width: "20px" }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            </Box>
            {!profileData.emailVerified && (
              <PopinsTwelveText
                style={{
                  color: "#1A75FF",
                  display: "block",
                  float: "right",
                  cursor: "pointer",
                }}
                onClick={verifyEmailGandler}
              >
                Verify email address
              </PopinsTwelveText>
            )}
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={4} style={{ padding: "10px" }}>
            <TextField
              id="input-with-icon-textfield"
              label="Country Code"
              variant="outlined"
              select
              name="countryCode"
              value={profileData.countryCode}
              onChange={profileDataChangeHandler}
              fullWidth
              InputLabelProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
              InputProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: { maxHeight: 215 },
                  },
                },
              }}
            >
              {countryCodeList.map((code) => {
                return (
                  <MenuItem key={nanoid()} value={code}>
                    {code}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={4}
            xs={8}
            style={{ padding: "10px" }}
            className="phone-number-field"
          >
            <TextField
              autoComplete="off"
              label="Phone Number"
              variant="outlined"
              fullWidth
              name="contactNo"
              value={profileData.contactNo}
              placeholder="Enter mobile no here.."
              onChange={(e: any) => {
                if (Number(e.target.value) >= 0) {
                  profilePhoneNoHandler(e);
                }
              }}
              InputLabelProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
              inputProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
                maxLength: 15,
              }}
            />
            <ContactError>
              {!validMobileNo && (
                <ValidationText>
                  Please enter mobile number 7 to 15 digits
                </ValidationText>
              )}
            </ContactError>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "5px" }}>
          <Grid item xs={12} md={12}>
            <BillingCardHeader
              style={{ paddingLeft: "10px", paddingRight: "0px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex" }}>
                  <BillingCardHeaderText
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      marginLeft: "0px",
                    }}
                  >
                    Billing Details
                  </BillingCardHeaderText>
                </div>
                <Hidden only={"xs"}>
                  <Divider
                    sx={{
                      width: { sm: "84%", md: "85%", lg: "87%", xl: "89%" },
                      marginTop: ".7rem",
                      marginLeft: "10px",
                    }}
                  />
                </Hidden>
              </div>
            </BillingCardHeader>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: "10px" }}>
            <TextField
              autoComplete="off"
              label="Street Address 1 (Optional)"
              variant="outlined"
              fullWidth
              name="streetAddress1"
              value={profileData?.streetAddress1?.trimLeft()}
              placeholder="Enter street address here.."
              onChange={addressHandler}
              inputProps={{ maxLength: 25 }}
              InputLabelProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
              InputProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
            />
            {!validAddress1 && (
              <ValidationText>
                Please enter 3to 25 characters
              </ValidationText>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: "10px" }}>
            <TextField
              autoComplete="off"
              label="Street Address 2 (Optional)"
              variant="outlined"
              fullWidth
              name="streetAddress2"
              value={profileData?.streetAddress2?.trimLeft()}
              placeholder="Enter street address here.."
              onChange={addressHandler}
              inputProps={{ maxLength: 25 }}
              InputLabelProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
              InputProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
            />
            {!validAddress2 && (
              <ValidationText>
                Please enter 3 to 25 characters
              </ValidationText>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: "10px" }}>
            <TextField
              autoComplete="off"
              label="Company Name (Optional)"
              variant="outlined"
              fullWidth
              name="organizationName"
              value={profileData?.organizationName?.trimLeft()}
              placeholder="Enter company name here.."
              onChange={addressHandler}
              inputProps={{ maxLength: 40 }}
              InputLabelProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
              InputProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
            />
            {!validOrganizationName && (
              <ValidationText>
                Please enter 3 to 40 characters
              </ValidationText>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: "10px" }}>
            <Box style={{}}>
              <AutocompleteField
                value={profileData.country}
                onChange={(e: any, newvalue: string) =>
                  dispatch(ProfileAction.setCountry(newvalue))
                }
                id="combo-box-demo"
                options={countryNameList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    name="country"
                    value={profileData.country}
                    variant="outlined"
                    InputLabelProps={{
                      style: {

                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "12px",
                      },
                    }}
                  />
                )}
              />
              {!validCountry && (
                <ValidationText>
                  Please select country
                </ValidationText>
              )}
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: "10px" }}>
            <Box>
              <TextField
                autoComplete="off"
                label="State (Optional)"
                variant="outlined"
                fullWidth
                name="state"
                value={profileData?.state?.trimLeft()}
                placeholder="Enter state name here.."
                onChange={profileNameHandlers}
                inputProps={{ maxLength: 40 }}
                InputLabelProps={{
                  style: {

                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                  },
                }}
                InputProps={{
                  style: {

                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                  },
                }}
              />
              {!validState && (
                <ValidationText>
                  Please enter 3 to 40 characters
                </ValidationText>
              )}
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: "10px" }}>
            <Box>
              <TextField
                autoComplete="off"
                label="City (Optional)"
                variant="outlined"
                fullWidth
                name="city"
                value={profileData?.city?.trimLeft()}
                placeholder="Enter city name here.."
                onChange={profileNameHandlers}
                inputProps={{ maxLength: 30 }}
                InputLabelProps={{
                  style: {

                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                  },
                }}
                InputProps={{
                  style: {

                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                  },
                }}
              />
              {!validCity && (
                <ValidationText>
                  Please enter 3 to 30 characters
                </ValidationText>
              )}
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: "10px" }}>
            <Box>
              <TextField
                autoComplete="off"
                label="Postal (Optional)"
                variant="outlined"
                fullWidth
                name="pincode"
                value={profileData.pincode}
                placeholder="Enter postal code here.."
                onChange={pinGstHandler}
                InputLabelProps={{
                  style: {

                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                  },
                }}
                inputProps={{ maxLength: 7 }}
                InputProps={{
                  style: {

                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                  },
                }}
              />
              {!validPostal && (
                <ValidationText>
                  Postal code should be 5 to 7 characters
                </ValidationText>
              )}
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: "10px" }}>
            <PopinsTwelveText>
              If you&#39;re a registered business, please enter your VAT/GST
              Registration Number for the correct tax rate.
            </PopinsTwelveText>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: "10px" }}>
            <TextField
              autoComplete="off"
              label="VAT/GST Number (Optional)"
              variant="outlined"
              fullWidth
              name="gst"
              value={profileData.gst}
              placeholder="Enter VAT/GST no here.."
              onChange={pinGstHandler}
              InputLabelProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
              InputProps={{
                style: {

                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                },
              }}
              inputProps={{ maxLength: 15 }}
            />
            {!validGst && (
              <ValidationText>
                Please enter valid GST number
              </ValidationText>
            )}
          </Grid>
        </Grid>
        {(user?.sub?.startsWith('auth0')) &&
          <>
            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} md={12}>
                <BillingCardHeader
                  style={{ paddingLeft: "10px", paddingRight: "0px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <BillingCardHeaderText
                        style={{
                          paddingLeft: "0px",
                          paddingRight: "0px",
                          marginLeft: "0px",
                        }}
                      >
                        Reset Password
                      </BillingCardHeaderText>
                    </div>
                    <Hidden only={"xs"}>
                      <Divider
                        sx={{
                          width: { sm: "82%", md: "82%", lg: "85%", xl: "89%" },
                          marginTop: ".7rem",
                          marginLeft: "10px",
                        }}
                      />
                    </Hidden>
                  </div>
                </BillingCardHeader>
              </Grid>
            </Grid>
            <PasswordUpdate /></>}
        <Grid container>
          <Box
            style={{ display: "flex", justifyContent: "right", width: "100%" }}
          >
            {fieldsTouched && (
              <Box
                sx={{
                  padding: "0 5px",
                  margin: "1rem 0",
                  width: "100%",
                  display: { xs: 'block', sm: "flex" },
                  justifyContent: "right",
                  gap: "1.5rem",
                }}
              >
                <ButtonDanger
                  id="discard-changes"
                  responsive={false}
                  onClick={discardChangesHandler}
                >
                  {DiscardChanges}
                </ButtonDanger>
                {!updateDataLoading ? (
                  <SaveChangesButton
                    id="user-profile-save-changes"
                    variant="contained"
                    onClick={(e) => {
                      profileDataUpdateHandler(e);
                    }}
                  >
                    {SaveChanges}
                  </SaveChangesButton>
                ) : (
                  <LoadingButton
                    id="user-profile-loading-btn"
                    loading
                    variant="contained"
                    sx={{
                      border: "1px solid #BABABA",
                    }}
                  >
                    {SaveChanges}
                  </LoadingButton>
                )}
              </Box>
            )}
            {!fieldsTouched && (
              <Box
                sx={{
                  padding: "0 5px",
                  margin: "1rem 0",
                  width: "100%",
                  display: { xs: 'block', sm: "flex" },
                  justifyContent: "right",
                  gap: "1.5rem",
                }}
              >
                <DiscardChangesButton
                  id="user-profile-changes-discard"
                  disabled
                  variant="contained"
                  type="reset"
                >
                  {DiscardChanges}
                </DiscardChangesButton>
                <SaveChangesButton
                  id="user-profile-changes-disable"
                  disabled
                  variant="contained"
                  onClick={(e) => {
                    profileDataUpdateHandler(e);
                  }}
                >
                  {SaveChanges}
                </SaveChangesButton>
              </Box>
            )}
          </Box>
        </Grid>

        {/* verify email through OTP */}
        <CustomDialogBox
          open={verifyEmailDialog}
          setOpen={closeCustomModal}
          background={"#F1F5FD"}
          closeIconId="close-verify-email-dialog-btn"
          width={
            { xs: "90vw", sm: "auto", md: "auto", lg: "auto", xl: "auto", }
          }
          height={
            { xs: "auto", sm: "auto", md: "auto", lg: "auto", xl: "auto", }
          }
          childrens={<Box sx={{ width: "100%", height: "100%", padding: "0", margin: "auto" }}>
            <VerifyEmail />
          </Box>}
        />
        {/* verify email through OTP */}
      </ContentWrapper>
    </ProfileContainer>
  );
};
export default PersonalDetails;
