import { LoadingButton } from "@mui/lab";
import { Autocomplete, Button, Dialog, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const ProfileContainer = styled(Box)`
  width: 100%;
  padding: 15px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 0;
  }
`;

export const TextFieldCode = styled(TextField)`
  width: 35%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 45%;
  }
`;

export const ContactError = styled(Box)`
  margin-left: 0%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-left: 1%;
  }
`;

export const DiscardChangesButton = styled(Button)`
  background: #ff5a5a;
  color: #ffffff;
  border-radius: 6px;
  font-family: Poppins;
  font-weight: 500;
  :hover {
    background: #ff5a6a;
    color: #ffffff;
  }
`;

export const SaveChangesButton = styled(Button)`
border-color #1A75FF;
color: #ffffff;
background: #3087EC;
font-family:Poppins;
font-weight:500;
border-radius: 6px;
:hover {
    color: #ffffff;
    background: #3087EC;
    opacity:0.8;
}
`;

export const UpdatePasswordButton = styled(Button)`
border-color #ffffff;
color: #ffffff;
background: #3087EC;
border-radius: 6px;
font-weight:500;
font-family:Poppins;
:hover {
    border-color #1A75FF;
    background:#1A75FF
  color: #ffffff;
}
`;

export const UpdatePasswordButtonLoading = styled(LoadingButton)`
border-color #1A75FF;
color: #1A75FF;
border-radius: 6px;
font-family:'Poppins;
:hover {
    border-color #1A75FF;
    background:#1A75FF
  color: #ffffff;
}
`;

export const ProfileNameBox = styled(Box)`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
`;

export const AutocompleteField = styled(Autocomplete)`
  & .MuiAutocomplete-clearIndicator {
    display: none;
  }
`;

export const OptionsDialog = styled(Dialog)`
  & .MuiPaper-root.MuiPaper-elevation {
    overflow: visible;
    padding:30px;
    background:#F1F5FD;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    & .MuiPaper-root.MuiPaper-elevation {
        padding:10px;
      }
}
`;

export const Wrapper = styled(Box)`
width:600px;
height:500px;
background:#FFFFFF;
margin:auto;
border-radius:4px;
display:flex;
flex-direction:column;
align-items:center;
justifyContent:center;
gap:30px;
padding:1rem 0;
${(props) => props.theme.breakpoints.down('sm')} {
    width:90%;
height:auto;
padding: 0.5rem;
}
`;

export const ValidationText = styled(Typography)(() => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: 'red',
}));

export const PopinsTwelveText = styled(Typography)(() => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
}));

export const PopinsForteenText = styled(Typography)(() => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
}));


export const PoppinsTwentyText = styled(Typography)(() => ({
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "20px",
}));

export const DisplayFlexColumn = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const BillingCardHeaderText = styled(Typography)(() => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: '0px',
  paddingRight: '20px',
  color: '#3087EC',
}));

export const BillingCardHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
}));