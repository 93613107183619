import React, { useState } from "react";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import {
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Typography,
  InputAdornment,
  Hidden,
  Tooltip,
} from "@mui/material";
import { Divider } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AddIcon from "@mui/icons-material/Add";

import { Box } from "@mui/material";
import {
  deleteTableData,
  postGenerateKey,
  getApplicationDetails,
  newExiryValue,
  updateExpiryDate,
  updateExpiryAndPostGenerateKey,
  setGenerateKeyDetails,
  editTableData1,
} from "../../slices/apiKeys";
import { FailureSnackbar } from "src/slices/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { RootState } from "src/store";
import GenerateKeyDetails from "./GenerateKeyDetails";
import Loader from "src/components/Loader/Loader";
import { Scrollbars } from "react-custom-scrollbars-2";
import CloseAlert from "src/components/AlertDialog";
import CustomDialogBox from "src/components/CustomDialog";
import CalendarIcon from "src/assets/icons/calendarIcon";
import {
  ContentWrapperCenterMob,
  ContentWrapperMob,
  DeviderBox,
  MenuWrapperMob,
  TableHeaderName,
  TableHeaderValue,
  TableHeaderWrapper,
} from "src/components/TableMobileCss";

export default function BasicTable() {
  const dispatch = useDispatch();
  const {
    tableData,
    appDetails,
    appDetailsLoader,
    updateExpiry,
    tableLoader,
    openGenerateKeyDetails,
    meta,
  } = useSelector((state: RootState) => state.apiKeys);
  const [openExtendExpiryDialog, setOpenExtendExpiryDialog] =
    React.useState(false);
  const [isExpiryDialog, setIsExpiryDialog] = React.useState(true);
  const [openFromDate, setOpenFromDate] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [storeOptionId, setStoreOptionId] = React.useState();
  const [openDeleteDailog, setOpenDeleteDailog] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    const data = {
      page: newPage + 1,
      per_page: meta.per_page,
    };
    dispatch(editTableData1(data));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = {
      page: 1,
      per_page: event.target.value,
    };
    dispatch(editTableData1(data));
  };

  const handleClickOption = (event: React.MouseEvent<HTMLElement>, id: any) => {
    dispatch(getApplicationDetails(id));
    setStoreOptionId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDeleteDailog = () => {
    setOpenDeleteDailog(false);
    handleClose();
  };

  const handleDeleteTableData = () => {
    handleClose();
    const data = {
      page: meta.page,
      per_page: meta.per_page,
      page_count: meta.page_count,
      total_count: meta.total_count,
    };
    dispatch(deleteTableData(storeOptionId, data));
    setOpenDeleteDailog(false);
  };

  const handleGenerateKey = (appData) => {
    handleClose();
    if (updateExpiry) {
      dispatch(
        updateExpiryAndPostGenerateKey(appData, {
          page: meta.page,
          per_page: meta.per_page,
        })
      );
    } else {
      dispatch(postGenerateKey(storeOptionId));
    }
  };

  const closeFunction = (data) => {
    dispatch(setGenerateKeyDetails(data));
  };

  const extendExpiryHandler = (isExpiryPopup: boolean = true) => {
    setIsExpiryDialog(isExpiryPopup);
    setOpenExtendExpiryDialog(true);
    handleClose();
  };

  const extendExpirySubmitHandler = () => {
    setOpenExtendExpiryDialog(false);
    const data = {
      page: meta.page,
      per_page: meta.per_page,
      appId: appDetails[0].appId,
      name: appDetails[0].name,
      expiry: updateExpiry,
    };
    if (isExpiryDialog) {
      dispatch(updateExpiryDate(data));
    }
    dispatch(newExiryValue(null));

    if (!isExpiryDialog) {
      handleGenerateKey(data);
      //const [openGenerateKeyDetails, setOpenGenerateKeyDetails] = React.useState(false);
      // setGenerateKeyDetails
    }
  };
  const handleRow = (row) => {
    setSelectedRow(row);
  };

  const handleExpiryDialogClose = () => {
    setOpenExtendExpiryDialog(false);
    dispatch(newExiryValue(null));
  };

  const expiryOnChangeHandler = (newValue) => {
    const currentDate = new Date().toLocaleDateString("en-CA");
    const selectedDate = new Date(newValue);
    const date = moment(selectedDate).format("YYYY-MM-DD");

    if (currentDate >= date) {
      dispatch(newExiryValue(null));
      dispatch(
        FailureSnackbar({ message: "Please enter a valid date", open: true })
      );
    } else {
      dispatch(newExiryValue(new Date(date).toISOString()));
    }
  };

  const handleClear = () => {
    dispatch(newExiryValue(""));
  };

  return (
    <>
      <div>
        <CloseAlert
          id={handleDeleteTableData}
          openCloseDialog={openDeleteDailog}
          setOpenCloseDialog={handleCloseDeleteDailog}
          accept={`Yes`}
          cancel={`No`}
          title={`Are you sure want to delete this record?`}
          mainTitle={`Delete API Keys`}
          subTitle={
            "This action cannot be undone. Please make sure you are performing the correct operation."
          }
          textColor={"#FF5A5A"}
          BackgroundColor={"#FF5A5A"}
        />

        <CustomDialogBox
          open={openExtendExpiryDialog}
          setOpen={handleExpiryDialogClose}
          background={"transparent"}
          closeIconId="close-view-resource-btn"
          width={{
            xs: "300px",
            sm: "400px",
            md: "450px",
            lg: "450px",
            xl: "500px",
          }}
          height={{
            xs: appDetails[0]?.expiry !== "" ? "300px" : "230px",
            sm: appDetails[0]?.expiry !== "" ? "300px" : "230px",
            md: appDetails[0]?.expiry !== "" ? "300px" : "230px",
            lg: appDetails[0]?.expiry !== "" ? "300px" : "230px",
            xl: appDetails[0]?.expiry !== "" ? "300px" : "230px",
          }}
          childrens={
            <Box sx={{ width: "100%", height: "100%" }}>
              {appDetailsLoader ? (
                <Box sx={{ height: "100%" }}>
                  <Loader />
                </Box>
              ) : (
                <>
                  <Typography
                    sx={{
                      color: "#3086EC",
                      m: 0,
                      p: "16px 16px 12px 16px",
                      fontSize: "16px",
                    }}
                  >
                    {/* {isExpiryDialog ? 'Extend expiry' : 'Generate key'} */}

                    {tableData.map((row, index) => {
                      const isSelectedRow = selectedRow === row;

                      return (
                        <React.Fragment key={row.appId}>
                          {isSelectedRow && isExpiryDialog && row.expiry === ""
                            ? "Add Expiry"
                            : isSelectedRow &&
                              isExpiryDialog &&
                              row.expiry !== ""
                              ? "Extend Expiry"
                              : isSelectedRow && !isExpiryDialog
                                ? "Generate key"
                                : null}
                        </React.Fragment>
                      );
                    })}
                  </Typography>
                  <Divider sx={{ width: "100%" }} />
                  <DialogContent>
                    {!appDetailsLoader && (
                      <Grid container>
                        {appDetails[0]?.expiry !== "" && (
                          <Grid item md={12} xs={12} lg={12} sx={{ mt: 2 }}>
                            <Typography>Current expiry</Typography>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={
                                appDetails[0]?.expiry
                                  ? moment(appDetails[0]?.expiry).format(
                                    "DD MMM YYYY"
                                  )
                                  : null
                              }
                              disabled
                            />
                          </Grid>
                        )}
                        <Grid item md={12} xs={12} lg={12} sx={{ mt: 2 }}>
                          <Typography>
                            {tableData.map((row, index) => {
                              const isSelectedRow = selectedRow === row;

                              return (
                                <React.Fragment key={row.appId}>
                                  {isSelectedRow &&
                                    isExpiryDialog &&
                                    row.expiry === ""
                                    ? "Add Expiry"
                                    : isSelectedRow &&
                                      isExpiryDialog &&
                                      row.expiry !== ""
                                      ? "Extend Expiry"
                                      : isSelectedRow && !isExpiryDialog
                                        ? "Extend Expiry"
                                        : null}
                                </React.Fragment>
                              );
                            })}
                            {!isExpiryDialog && "(optional)"}{" "}
                          </Typography>
                          {new Date(appDetails[0]?.expiry).toLocaleDateString(
                            "en-CA"
                          ) >= new Date().toLocaleDateString("en-CA") ? (
                            <DesktopDatePicker
                              open={openFromDate}
                              onOpen={() => setOpenFromDate(true)}
                              onClose={() => setOpenFromDate(false)}
                              inputFormat="dd MMM yyyy"
                              value={
                                updateExpiry
                                  ? moment(updateExpiry).format("DD MMM YYYY")
                                  : null
                              }
                              minDate={new Date().setDate(
                                new Date().getDate() + 1
                              )}
                              // onChange={expiryOnChangeHandler}
                              onChange={(date) => {
                                expiryOnChangeHandler(date);
                                setOpenFromDate(false); // Close the calendar after selecting a date
                              }}
                              renderInput={(params) => (
                                <TextField
                                  color="primary"
                                  fullWidth
                                  size="small"
                                  {...params}
                                  inputProps={{ readOnly: true }}
                                  placeholder="dd mmm yyyy"
                                  value={
                                    updateExpiry
                                      ? moment(updateExpiry).format(
                                        "DD MMM YYYY"
                                      )
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        {updateExpiry?.length > 0 && (
                                          <IconButton
                                            disableRipple
                                            sx={{
                                              transform: "translateX(20px)",
                                            }}
                                            onClick={() => handleClear()}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        )}
                                        <IconButton
                                          disableRipple
                                          sx={{ transform: "translateX(20px)" }}
                                          onClick={() => setOpenFromDate(true)}
                                        >
                                          <CalendarIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    onClick: () => setOpenFromDate(true),
                                  }}
                                />
                              )}
                            />
                          ) : (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography sx={{ color: "red" }}>
                                Date already expired
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </DialogContent>
                  <DialogActions className="extend_generate_key_container">
                    <Box
                      sx={{ mb: 1, pr: 2 }}
                      className="justify_end extend_generate_key_box"
                    >
                      <Button
                        onClick={extendExpirySubmitHandler}
                        sx={{
                          background: "#3086EC",
                          "&:hover": { background: "#3086EC", opacity: "0.8" },
                        }}
                        autoFocus
                        color="primary"
                        variant="contained"
                        disabled={
                          isExpiryDialog ? (updateExpiry ? false : true) : false
                        }
                      >
                        <AddIcon />{" "}
                        {tableData.map((row, index) => {
                          const isSelectedRow = selectedRow === row;
                          return (
                            <React.Fragment key={row.appId}>
                              {isSelectedRow &&
                                isExpiryDialog &&
                                row.expiry === ""
                                ? "Add"
                                : isSelectedRow &&
                                  isExpiryDialog &&
                                  row.expiry !== ""
                                  ? "Extend"
                                  : isSelectedRow && !isExpiryDialog
                                    ? "Generate"
                                    : null}
                            </React.Fragment>
                          );
                        })}
                      </Button>
                    </Box>
                  </DialogActions>
                </>
              )}
            </Box>
          }
        />
      </div>
      <>
        {tableData && tableData.length && (
          <Hidden smDown>
            <Paper
              square
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "6px",
                ml: "15px",
                mr: "15px",
                mt: "10px",
              }}
              className="webhook_table_container"
            >
              <TableContainer
                sx={{
                  height: { xl: "77vh", lg: "66vh", md: "65vh", sm: "57vh" },
                  width: "100%",
                }}
              >
                <Table
                  stickyHeader
                  sx={{ width: "100%", position: "relative" }}
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="center">  <Checkbox {...label} /></TableCell> */}
                      <TableCell
                        width="20%"
                        align="left"
                        sx={{ zIndex: "1", color: "#3087EC" }}
                      >
                        APPLICATION NAME
                      </TableCell>
                      <TableCell
                        width="30%"
                        align="left"
                        sx={{ zIndex: "1", color: "#3087EC" }}
                      >
                        APP ID
                      </TableCell>

                      <TableCell
                        width="20%"
                        align="left"
                        sx={{ zIndex: "1", color: "#3087EC" }}
                      >
                        EXPIRY
                      </TableCell>
                      <TableCell
                        width="20%"
                        align="left"
                        sx={{ zIndex: "1", color: "#3087EC" }}
                      >
                        CREATED
                      </TableCell>

                      <TableCell
                        width="10%"
                        align="center"
                        sx={{ zIndex: "1", color: "#3087EC" }}
                      >
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {tableLoader ? (
                    <Box
                      sx={{
                        position: "absolute",
                        width: "100%",
                        height: "57vh",
                      }}
                    >
                      <Loader />
                    </Box>
                  ) : (
                    <TableBody>
                      {tableData.map((row) => (
                        <TableRow
                          key={row.appId}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            sx={{
                              maxWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row?.name?.length > 20 ? (
                              <Tooltip
                                title={row.name}
                                placement={"top-start"}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      padding: "10px",
                                      // '& .MuiTooltip-arrow': {
                                      //   color: 'common.black',
                                      // },
                                    },
                                  },
                                }}
                              >
                                <span> {row.name}</span>
                              </Tooltip>
                            ) : (
                              <span> {row.name}</span>
                            )}
                          </TableCell>
                          <TableCell align="left">{row.appId}</TableCell>
                          <TableCell align="left">
                            {row.expiry !== ""
                              ? moment(row?.expiry).format("DD MMM YYYY")
                              : "Not applicable"}
                          </TableCell>
                          <TableCell align="left">
                            {moment(row?.createdDate).format("DD MMM YYYY")}
                            {/* {new Date(row.createdDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-')} */}
                          </TableCell>

                          <TableCell align="center">
                            <IconButton
                              id="fade-button"
                              onClick={(event) => {
                                handleClickOption(event, row.appId);
                                handleRow(row);
                              }}
                              aria-controls={open ? "fade-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="apikeys-menu"
                              MenuListProps={{
                                "aria-labelledby": "fade-button",
                              }}
                              anchorEl={anchorEl}
                              open={open && storeOptionId === row.appId}
                              onClose={handleClose}
                              TransitionComponent={Fade}
                              sx={{ mt: "-15px" }}
                              PaperProps={{
                                style: {
                                  padding: 0,
                                  margin: 0,
                                  transform: "translateX(-30px)",
                                  boxShadow:
                                    "rgba(149, 157, 165, 0.2) 0px 2px 2px",
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() => extendExpiryHandler(false)}
                              >
                                Generate key
                              </MenuItem>

                              <MenuItem
                                onClick={() => setOpenDeleteDailog(true)}
                              >
                                Delete
                              </MenuItem>
                              {row.expiry === "" ? (
                                <MenuItem onClick={() => extendExpiryHandler()}>
                                  Add expiry
                                </MenuItem>
                              ) : (
                                new Date(row.expiry).toLocaleDateString(
                                  "en-CA"
                                ) >= new Date().toLocaleDateString("en-CA") && (
                                  <MenuItem
                                    onClick={() => extendExpiryHandler()}
                                  >
                                    Extend expiry
                                  </MenuItem>
                                )
                              )}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                sx={{ margin: "10px" }}
                component="div"
                count={meta.total_count}
                rowsPerPage={meta.per_page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                }}
                page={meta.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={{
                  style: {
                    background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                    color: (meta.page - 1) ? "#0062FD" : "#aaa",
                    borderRadius: "50%",
                    marginRight: "2px",
                  },
                }}
                nextIconButtonProps={{
                  style: {
                    background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                    color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                    borderRadius: "50%",
                    marginLeft: "2px",
                  },
                }}
              />
            </Paper>
          </Hidden>
        )}
      </>

      <div className="justify_center apikey_list_container">
        {tableLoader ? (
          <Box sx={{ height: "64vh" }}>
            <Loader />
          </Box>
        ) : (
          <>
            <Scrollbars style={{ height: "64vh" }}>
              {tableData.map((row) => (
                <>
                  <Box>
                    <TableHeaderWrapper>
                      <TableHeaderName>Applcation Name</TableHeaderName>
                      <MenuWrapperMob>
                        <Typography>{row.name}</Typography>
                        <IconButton
                          id="fade-button"
                          onClick={(event) =>
                            handleClickOption(event, row.appId)
                          }
                          aria-controls={open ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="apikeys-menu"
                          MenuListProps={{
                            "aria-labelledby": "fade-button",
                          }}
                          anchorEl={anchorEl}
                          open={open && storeOptionId === row.appId}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                          sx={{ mt: "-15px" }}
                          PaperProps={{
                            style: {
                              padding: 0,
                              margin: 0,
                              transform: "translateX(-30px)",
                              boxShadow: "rgba(149, 157, 165, 0.2) 0px 2px 2px",
                            },
                          }}
                        >
                          <MenuItem onClick={() => extendExpiryHandler(false)}>
                            Generate key
                          </MenuItem>

                          <MenuItem onClick={() => setOpenDeleteDailog(true)}>
                            Delete
                          </MenuItem>
                          {row.expiry === "" ? (
                            <MenuItem onClick={() => extendExpiryHandler()}>
                              Add expiry
                            </MenuItem>
                          ) : (
                            new Date(row.expiry).toLocaleDateString("en-CA") >=
                            new Date().toLocaleDateString("en-CA") && (
                              <MenuItem onClick={() => extendExpiryHandler()}>
                                Extend expiry
                              </MenuItem>
                            )
                          )}
                        </Menu>
                      </MenuWrapperMob>
                    </TableHeaderWrapper>
                    <ContentWrapperMob>
                      <ContentWrapperCenterMob>
                        <TableHeaderName>App ID</TableHeaderName>
                        <TableHeaderValue>{row.appId}</TableHeaderValue>
                      </ContentWrapperCenterMob>
                      <DeviderBox>
                        <Divider sx={{ height: "1.5px" }} />
                      </DeviderBox>
                    </ContentWrapperMob>
                    <ContentWrapperMob>
                      <ContentWrapperCenterMob>
                        <TableHeaderName>Expiry</TableHeaderName>
                        <TableHeaderValue>
                          {row.expiry
                            ? moment(row?.createdDate).format("DD MMM YYYY")
                            : "Not applicable"}
                        </TableHeaderValue>
                      </ContentWrapperCenterMob>
                      <DeviderBox>
                        <Divider sx={{ height: "1.5px" }} />
                      </DeviderBox>
                    </ContentWrapperMob>
                    <ContentWrapperMob>
                      <ContentWrapperCenterMob>
                        <TableHeaderName>Created Date</TableHeaderName>
                        <TableHeaderValue>
                          {moment(row?.createdDate).format("DD MMM YYYY")}
                          {/* {new Date(row.createdDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-')} */}
                        </TableHeaderValue>
                      </ContentWrapperCenterMob>
                      <DeviderBox>
                        <Divider sx={{ height: "1.5px" }} />
                      </DeviderBox>
                    </ContentWrapperMob>
                  </Box>
                </>
              ))}
            </Scrollbars>
            <Box
              sx={{
                position: "fixed",
                bottom: "0px",
                height: "50px",
                width: "100%",
              }}
            >
              <TablePagination
                style={{}}
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={meta.total_count}
                rowsPerPage={meta.per_page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                }}
                page={meta.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={{
                  style: {
                    background: (meta.page - 1) ? "rgb(221,239,255,0.7)" : "#eee",
                    color: (meta.page - 1) ? "#0062FD" : "#aaa",
                    borderRadius: "50%",
                    marginRight: "2px",
                    height: "25px",
                    width: "25px",
                  },
                }}
                nextIconButtonProps={{
                  style: {
                    background: meta.page !== meta.page_count ? "rgb(221,239,255,0.7)" : "#eee",
                    color: meta.page !== meta.page_count ? "#0062FD" : "#aaa",
                    borderRadius: "50%",
                    marginLeft: "2px",
                    height: "25px",
                    width: "25px",
                  },
                }}
                labelRowsPerPage={
                  <div style={{ fontFamily: "Poppins", fontSize: "12px" }}>
                    Rows per page:
                  </div>
                }
              />
            </Box>
          </>
        )}
      </div>
      {openGenerateKeyDetails ? (
        <GenerateKeyDetails close={closeFunction} />
      ) : null}
    </>
  );
}
