import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PortegoIcon from "src/assets/icons/PortegoIcon";
import { Hidden } from "@mui/material";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
      <LogoWrapper to="/">
        <Hidden only={['xs']}>
      <PortegoIcon props={{heith: '90px', width: '90px'}}/>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md', 'sm']}>
      <PortegoIcon props={{heith: '80px', width: '80px'}}/>
      </Hidden>
      </LogoWrapper>
  );
}

export default Logo;
