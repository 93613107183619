import React from "react";
function PersonIcon() {
 return (
  <>
   <svg width="150" height="150" viewBox="0 0 757 757" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M378.5 757C587.54 757 757 587.54 757 378.5C757 169.46 587.54 0 378.5 0C169.46 0 0 169.46 0 378.5C0 587.54 169.46 757 378.5 757Z" fill="#FFC200" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M644.148 648.08C572.718 718.486 476.261 757.658 375.968 756.99C275.674 756.321 179.748 715.867 109.263 644.516C118.277 619.587 135.785 598.631 158.713 585.327C174.165 573.902 190.921 564.355 208.628 556.885C181.704 464.367 254.893 418.885 282.895 405.791C282.895 448.504 322.889 420.159 372.522 420.698C413.386 421.152 494.194 462.284 494.194 408.02C539.148 421.323 588.145 506.994 554.423 559.629C568.249 566.389 581.312 574.61 593.388 584.152C616.995 599.174 634.872 621.68 644.148 648.08Z" fill="#677079" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M294.372 473.799L477.13 474.901V517.002L477.044 517.651C476.918 519.558 476.631 521.451 476.187 523.31L475.575 527.487L475.207 528.357C463.374 590.509 411.597 614.53 379.627 631.262C345.941 612.913 298.316 579.852 294.531 521.362L294.372 520.64V518.19C294.346 517.345 294.33 516.5 294.323 515.654C294.323 516.095 294.36 516.512 294.372 516.953V473.799Z" fill="#F9DCA4" />
    <path opacity="0.11" fill-rule="evenodd" clip-rule="evenodd" d="M477.142 473.027V522.085C477.107 526.633 476.718 531.17 475.979 535.657C475.051 541.211 473.489 546.641 471.324 551.839C407.824 599.794 306.646 560.462 290.489 478.552L477.142 473.027Z" fill="black" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M221.759 309.107C161.248 324.664 212.695 398.404 250.753 398.086C235.405 370.589 225.558 340.367 221.759 309.107ZM536.968 309.107C597.479 324.664 546.032 398.404 507.962 398.073C523.314 370.582 533.166 340.364 536.968 309.107Z" fill="#F9DCA4" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M376.454 139.164C627.845 139.164 529.875 536.601 376.454 536.601C231.546 536.601 125.064 139.164 376.454 139.164Z" fill="#FFE8BE" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M257.809 270.769C259.954 273.12 261.544 275.921 262.463 278.968C263.382 282.014 263.607 285.228 263.121 288.372C262.634 291.517 261.449 294.513 259.652 297.139C257.855 299.765 255.492 301.955 252.738 303.548C234.976 318.161 234.327 369.118 234.327 369.118C227.468 362.307 227.627 325.829 212.756 304.748C197.886 283.667 187.866 136.163 271.026 82.5235C265.916 88.5342 262.233 95.624 260.253 103.261C258.274 110.898 258.05 118.884 259.597 126.621C259.597 126.621 317.364 43.7793 413.202 46.1679C405.604 50.3107 398.965 56.0079 393.716 62.8883C388.467 69.7686 384.726 77.6777 382.738 86.1002C382.738 86.1002 466.523 51.533 536.343 92.6535C518.191 97.6708 501.022 105.735 485.57 116.503C519.476 117.434 552.243 123.73 576.949 145.705C571.632 147.412 566.711 150.167 562.476 153.808C558.242 157.449 554.78 161.902 552.295 166.903C549.811 171.905 548.354 177.353 548.01 182.927C547.666 188.501 548.443 194.088 550.295 199.356C560.78 242.265 561.76 282.185 551.606 303.646C541.451 325.106 536.662 311.253 530.304 356.256C529.993 359.296 528.979 362.223 527.342 364.803C525.705 367.384 523.49 369.549 520.873 371.127C520.628 374.385 525.552 325.119 512.849 312C500.159 298.893 508.133 282.786 513.205 273.256C548.605 206.779 442.588 176.659 384.637 176.732C334.379 176.793 213.148 199.638 257.809 270.769Z" fill="#8A5C42" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M242.411 602.194C242.779 602.659 370.023 767.35 519.61 599.744C501.543 649.378 423.613 688.796 379.039 688.673C293.907 688.428 246.796 620.078 242.411 602.194Z" fill="#434955" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M445.087 751.157C440.065 752.039 435.018 752.81 429.935 753.508V644.037C430.121 642.204 431.017 640.516 432.432 639.335C433.847 638.154 435.667 637.573 437.505 637.717C439.344 637.57 441.168 638.149 442.585 639.33C444.002 640.512 444.9 642.202 445.087 644.037V751.157ZM324.8 753.19C319.72 752.463 314.67 751.647 309.647 750.74V644.037C309.834 642.204 310.73 640.516 312.145 639.335C313.559 638.154 315.38 637.573 317.217 637.717C319.059 637.566 320.886 638.144 322.306 639.326C323.726 640.508 324.626 642.199 324.812 644.037L324.8 753.19Z" fill="#E6E6E6" />
   </svg>

  </>
 )
};

export default PersonIcon;
