// import LandingFooter from '../LandingFooter'
import { Box, Typography } from "@mui/material";
import {
  CustomBox,
  Heading,
  MiniBoxMarginFifteen,
  Paragraph,
  ParagraphBold,
  SubHeading,
} from "./pageStyled";

const PrivacyPolicy = () => {
  return (
    <>
      <Box
        sx={{
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingBottom: "5%",
          alignItems: "center",
        }}
      >
        <Box>
          <Heading>Privacy Policy</Heading>
          <Typography style={{ fontSize: "16px" }}>
            Updated on June 14, 2021
          </Typography>
        </Box>
        {/* <CustomBox>
          <Typography style={{ fontSize: '16px' }}>
            Updated on June 14, 2021
          </Typography>
        </CustomBox> */}
        <CustomBox>
          <Paragraph>
            Thank you for choosing to be part of our community at{" "}
            <span style={{ fontWeight: 600 }}>EINSTON LABS Pvt. Ltd.</span>{" "}
            (&#34;Company&#34;, &#34;we&#34;, &#34;us&#34;, &#34;our&#34;). We
            are committed to protecting your personal information and your right
            to privacy. If you have any questions or concerns about this privacy
            notice, or our practices with regards to your personal information,
            please contact us at support@einstonlabs.in When you and more
            generally, use any of our services (the &#34;Services&#34;, which
            include the Site), we appreciate that you are trusting us with your
            personal information. We take your privacy very seriously. In this
            privacy notice, we seek to explain to you in the clearest way
            possible what information we collect, how we use it and what rights
            you have in relation to it. We hope you take some time to read
            through it carefully, as it is important. If there are any terms in
            this privacy notice that you do not agree with, please discontinue
            use of our Services immediately. This privacy notice applies to all
            information collected through our Services (which, as described
            above, includes our), as well as, any related services, sales,
            marketing or events.
            <span style={{ fontWeight: 600 }}>
              Please read this privacy notice carefully as it will help you
              understand what we do with the information that we collect.{" "}
            </span>
          </Paragraph>
        </CustomBox>
        <CustomBox>
          <SubHeading>WHAT INFORMATION DO WE COLLECT?</SubHeading>
        </CustomBox>
        <CustomBox>
          <ParagraphBold>
            Personal information you disclose to us{" "}
          </ParagraphBold>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: 600 }}>In Short:</span> We collect
            personal information that you provide to us.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We collect personal information that you voluntarily provide to us
            when you register on the express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the (such as by posting messages in our
            online forums or entering competitions, contests or giveaways) or
            otherwise when you contact us. The personal information that we
            collect depends on the context of your interactions with us and the
            Site, the choices you make and the products and features you use.
            The personal information we collect may include the following:{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            <span style={{ fontWeight: 600 }}>Social Media Login Data.</span> We
            may provide you with the option to register with us using your
            existing social media account details, like your Facebook, Twitter
            or other social media account. If you choose to register in this
            way, we will collect the information described in the section called
            &#34;HOW DO WE HANDLE YOUR SOCIAL LOGINS&#34; below. All personal
            information that you provide to us must be true, complete and
            accurate, and you must notify us of any changes to such personal
            information.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <ParagraphBold>Information automatically collected </ParagraphBold>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <Paragraph style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: 600 }}>In Short:</span> Some information
            — such as your Internet Protocol (IP) address and/or browser and
            device characteristics — is collected automatically when you visit
            our Site.{" "}
          </Paragraph>
        </CustomBox>
        <CustomBox>
          <Paragraph>
            We automatically collect certain information when you visit, use or
            navigate the Site. This information does not reveal your specific
            identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and
            device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about
            how and when you use our and other technical information. This
            information is primarily needed to maintain the security and
            operation of our Site, and for our internal analytics and reporting
            purposes.
          </Paragraph>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            Like many businesses, we also collect information through cookies
            and similar technologies.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>WILL YOUR INFORMATION BE SHARED WITH ANYONE? </SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: 600 }}>In Short:</span> We only share
            information with your consent, to comply with laws, to provide you
            with services, to protect your rights, or to fulfil business
            obligations.{" "}
          </Paragraph>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We may process or share your data that we hold based on the
            following legal basis:{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </Paragraph>
        </MiniBoxMarginFifteen>
        <Box style={{ paddingLeft: "20px" }}>
          <ul>
            <li>
              <Paragraph>
                <span style={{ fontWeight: 600 }}>Business Transfers.</span> We
                may share or transfer your information in connection with, or
                during negotiations of, any merger, sale of company assets,
                financing, or acquisition of all or a portion of our business to
                another company.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <span style={{ fontWeight: 600 }}>Affiliates.</span> We may
                share your information with our affiliates, in which case we
                will require those affiliates to honour this privacy notice.
                Affiliates include our parent company and any subsidiaries,
                joint venture partners or other companies that we control or
                that are under common control with us.{" "}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <span style={{ fontWeight: 600 }}>Business Partners.</span> We
                may share your information with our business partners to offer
                you certain products, services or promotions.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <span style={{ fontWeight: 600 }}>Other Users.</span> When you
                share personal information or otherwise interact with public
                areas of the Site, such personal information may be viewed by
                all users and may be publicly made available outside the in
                perpetuity. If you interact with other users of our and register
                for us through a social network (such as Facebook), your
                contacts on the social network will see your name, profile
                photo, and descriptions of your activity. Similarly, other users
                will be able to view descriptions of your activity, communicate
                with you within our, and view your profile.
              </Paragraph>
            </li>
          </ul>
        </Box>
        <CustomBox>
          <SubHeading>
            DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?{" "}
          </SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: 600 }}>In Short:</span> We may use
            cookies and other tracking technologies to collect and store your
            information.{" "}
          </Paragraph>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>HOW DO WE HANDLE YOUR SOCIAL LOGINS? </SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph>
            <span style={{ fontWeight: 600 }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              If you choose to register or log in to our services using a social
              media account, we may have access to certain information about
              you.
            </span>{" "}
            Our offers you the ability to register and login using your
            third-party social media account details (like your Facebook or
            Twitter logins). Where you choose to do this, we will receive
            certain profile information about you from your social media
            provider. The profile Information we receive may vary depending on
            the social media provider concerned, but will often include your
            name, email address, friends list, profile picture as well as other
            information you choose to make public on such social media platform.{" "}
          </Paragraph>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant. Please note that we do not control,
            and are not responsible for, other uses of your personal information
            by your third-party social media provider. We recommend that you
            review their privacy notice to understand how they collect, use and
            share your personal information, and how you can set your privacy
            preferences on their sites and apps.
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>
            IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
          </SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: 600 }}>In Short:</span> We may transfer,
            store, and process your information in countries other than your
            own.
          </Paragraph>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            Our servers are located across the country. If you are accessing us
            from outside, please be aware that your information may be
            transferred to, stored, and processed by us in our facilities and by
            those third parties with whom we may share your personal information
            (see &#34;WILL YOUR INFORMATION BE SHARED WITH ANYONE?&#34; above),
            in and other countries.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            If you are a resident in the European Economic Area, then these
            countries may not necessarily have data protection laws or other
            similar laws as comprehensive as those in your country. We will
            however take all necessary measures to protect your personal
            information in accordance with this privacy notice and applicable
            law.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>HOW LONG DO WE KEEP YOUR INFORMATION? </SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: 600 }}>In Short:</span> We keep your
            information for as long as necessary to fulfil the purposes outlined
            in this privacy notice unless otherwise required by law.{" "}
          </Paragraph>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than 90 days.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>DO WE COLLECT INFORMATION FROM MINORS?</SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: 600 }}>In Short:</span> We do not
            knowingly collect data from or market to children under 18 years of
            age
          </Paragraph>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the, you represent that you are at least 18
            or that you are the parent or guardian of such a minor and consent
            to such minor dependent’s use of the. If we learn that personal
            information from users less than 18 years of age has been collected,
            we will deactivate the account and take reasonable measures to
            promptly delete such data from our records. If you become aware of
            any data, we may have collected from children under age 18, please
            contact us at support@einstonlabs.in{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>WHAT ARE YOUR PRIVACY RIGHTS? </SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: 600 }}>In Short:</span> You may review,
            change, or terminate your account at any time.{" "}
          </Paragraph>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            If you are a resident in the European Economic Area and you believe
            we are unlawfully processing your personal information, you also
            have the right to complain to your local data protection supervisory
            authority. You can find their contact details here:
            <a
              href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html"
              style={{
                textAlign: "justify",
                display: "block",
                wordWrap: "break-word",
              }}
            >
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html.
            </a>
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            If you are a resident in Switzerland, the contact details for the
            data protection authorities are available here:
            <a
              href="https://www.edoeb.admin.ch/edoeb/en/home.html."
              style={{
                textAlign: "justify",
                display: "block",
                wordWrap: "break-word",
              }}
            >
              https://www.edoeb.admin.ch/edoeb/en/home.html.
            </a>
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>Account Information </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with applicable legal
            requirements.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <MiniBoxMarginFifteen>
          <Paragraph>
            Opting out of email marketing: You can unsubscribe from our
            marketing email list at any time by clicking on the unsubscribe link
            in the emails that we send or by contacting us using the details
            provided below. You will then be removed from the marketing email
            list — however, we may still communicate with you, for example to
            send you service-related emails that are necessary for the
            administration and use of your account, to respond to service
            requests, or for other non-marketing purposes. To otherwise opt-out,
            you may:{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>CONTROLS FOR DO-NOT-TRACK FEATURES </SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track (&#34;DNT&#34;) feature or
            setting you can activate to signal your privacy preference not to
            have data about your online browsing activities monitored and
            collected. At this stage no uniform technology standard for
            recognizing and implementing DNT signals has been finalized. As
            such, we do not currently respond to DNT browser signals or any
            other mechanism that automatically communicates your choice not to
            be tracked online. If a standard for online tracking is adopted that
            we must follow in the future, we will inform you about that practice
            in a revised version of this privacy notice.{" "}
          </Paragraph>
        </CustomBox>
        <CustomBox>
          <SubHeading>DO WE MAKE UPDATES TO THIS NOTICE? </SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: 600 }}>In Short:</span> Yes, we will
            update this notice as necessary to stay compliant with relevant
            laws.{" "}
          </Paragraph>
        </CustomBox>
        <MiniBoxMarginFifteen>
          <Paragraph>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated &#34;Revised&#34; date and
            the updated version will be effective as soon as it is accessible.
            If we make material changes to this privacy notice, we may notify
            you either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.{" "}
          </Paragraph>
        </MiniBoxMarginFifteen>
        <CustomBox>
          <SubHeading>HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph>
            If you have questions or comments about this notice, you may email
            us at support@einstonlabs.in{" "}
          </Paragraph>
        </CustomBox>
        <CustomBox>
          <SubHeading>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </SubHeading>
        </CustomBox>
        <CustomBox>
          <Paragraph>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please contact us at{" "}
            <a href="support@einstonlabs.in">support@einstonlabs.in</a> We will
            respond to your request within 30 days.{" "}
          </Paragraph>
        </CustomBox>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
