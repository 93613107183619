import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Loader from "src/components/Loader/Loader";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Auth0Util } from "src/utils/Auth0GetToken";

const Authenticated = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function getToken() {
      await Auth0Util.setAccessTokenSilently(getAccessTokenSilently);
    }
    if (isAuthenticated) {
      getToken();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export default withAuthenticationRequired(Authenticated, {
  onRedirecting: () => <Loader />,
});
