import React from 'react';
import { Grid, Hidden } from '@mui/material';
import { FeatureGridContainerRow, FeatureGridContainerRowReverse, FeatureContent, FeatureHeading, FeatureWrapper, CustomGrid } from './homePageStyled';
import { AccessMachineIcon, HybridLearningIcon, RecordLiveIcon } from 'src/assets/icons/homePageIcon';

const FeatureCardDetails = () => {
 const portegoUseRecords = {
  featureTitle1: "Hybrid learning & training is now at your fingertips",
  featureinfo1: "Where you can train, learn through your personalised virtual desktops on cloud platform just integrating our API in your software system. We provide end to end analytics of the entire conference.",
  featureTitle2: "Record live audio and video",
  featureinfo2: "A service that lets you capture & record live audio and video calls to meet your business needs. where you can set the expiry date of recording post the call. You can give access to your users to record a call as well.",
  featureTitle3: "Access others machine while on Call",
  featureinfo3: "We provide the Host a special permission to access the virtual machines of the Participants of the event. Which helps solve majority of the screen sharing problem which occurs during most training events",
 }

 return (
  <div style={{ marginTop: '10px' }}>
   <FeatureGridContainerRow container sx={{ width: { xs: '95%', sm: '85%', md: '75%', lg: '80%' } }} columnGap={5} rowGap={2}>
    <CustomGrid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} >
     <Hidden mdDown>
      <HybridLearningIcon props={{ width: '500px', height: '300px' }} />
     </Hidden>
     <Hidden only={['xs', 'md', 'lg', 'xl']}>
      <HybridLearningIcon props={{ width: '500px', height: '250px' }} />
     </Hidden>
     <Hidden smUp>
      <HybridLearningIcon props={{ width: '250px', height: '200px' }} />
     </Hidden>
    </CustomGrid>
    <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
     <FeatureWrapper>
      <FeatureHeading>{portegoUseRecords.featureTitle1} </FeatureHeading>
      <FeatureContent>{portegoUseRecords.featureinfo1}</FeatureContent>
     </FeatureWrapper>
    </Grid>
   </FeatureGridContainerRow>

   <FeatureGridContainerRowReverse container sx={{ width: { xs: '95%', sm: '85%', md: '75%', lg: '80%' } }} columnGap={5} rowGap={2}>
    <CustomGrid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5}>
     <Hidden mdDown>
      <RecordLiveIcon props={{ width: '500px', height: '300px' }} />
     </Hidden>
     <Hidden only={['xs', 'md', 'lg', 'xl']}>
      <RecordLiveIcon props={{ width: '500px', height: '250px' }} />
     </Hidden>
     <Hidden smUp>
      <RecordLiveIcon props={{ width: '250px', height: '200px' }} />
     </Hidden>
    </CustomGrid>
    <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
     <FeatureWrapper>
      <FeatureHeading>{portegoUseRecords.featureTitle2} </FeatureHeading>
      <FeatureContent>{portegoUseRecords.featureinfo2}</FeatureContent>
     </FeatureWrapper>
    </Grid>
   </FeatureGridContainerRowReverse>

   <FeatureGridContainerRow container sx={{ width: { xs: '95%', sm: '85%', md: '75%', lg: '80%' } }} columnGap={5} rowGap={2}>
    <CustomGrid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5}>
     <Hidden mdDown>
      <AccessMachineIcon props={{ width: '500px', height: '300px' }} />
     </Hidden>
     <Hidden only={['xs', 'md', 'lg', 'xl']}>
      <AccessMachineIcon props={{ width: '500px', height: '250px' }} />
     </Hidden>
     <Hidden smUp>
      <AccessMachineIcon props={{ width: '250px', height: '200px' }} />
     </Hidden>
    </CustomGrid>
    <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
     <FeatureWrapper>
      <FeatureHeading>{portegoUseRecords.featureTitle3} </FeatureHeading>
      <FeatureContent>{portegoUseRecords.featureinfo3}</FeatureContent>
     </FeatureWrapper>
    </Grid>
   </FeatureGridContainerRow>
  </div >
 )
}

export default FeatureCardDetails;



