import React from "react";
import { Dialog, DialogActions, Button, Hidden } from "@mui/material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

interface ICalender {
  open: boolean;
  setCalenderDialog: any;
  handleSelect: any;
  selectedRange: any;
  setSelectedRange: any;
  handleSave?: any;
}

const Calender: React.FC<ICalender> = ({
  open,
  setCalenderDialog,
  handleSelect,
  selectedRange,
  setSelectedRange,
  handleSave,
}) => {
  const [rangeSelection, setRangeSelection] = React.useState({
    startDate: selectedRange.startDate
      ? new Date(selectedRange.startDate)
      : new Date(),
    endDate: selectedRange.endDate
      ? new Date(selectedRange.endDate)
      : new Date(),
    key: "selection",
  });

  const handleClose = () => {
    setCalenderDialog(false);
  };

  const onDateChange = (ranges) => {
    setRangeSelection(ranges.selection);
  };

  const onSave = () => {
    handleSelect(rangeSelection);
    handleSave(rangeSelection);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: "660px",
        },
      }}
      open={open}
    >
      <Hidden only={["sm", "xs"]}>
        <div className="custom-date-range-container">
          <DateRange
            onChange={onDateChange}
            moveRangeOnFirstSelection={false}
            retainEndDateOnFirstSelection={true}
            direction="horizontal"
            months={2}
            ranges={[rangeSelection]}
            fixedHeight={true}
            color="#00A3FF"
            className="custom-date-range"
          />
        </div>
      </Hidden>

      <Hidden only={["xl", "lg", "md"]}>
        <DateRange
          onChange={onDateChange}
          moveRangeOnFirstSelection={false}
          retainEndDateOnFirstSelection={true}
          direction="horizontal"
          months={1}
          ranges={[rangeSelection]}
          fixedHeight={true}
          color="#00A3FF"
        />
      </Hidden>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={onSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Calender;
