import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Box, Divider, Pagination, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Typography, Select, MenuItem, SelectChangeEvent, TextField, FormControl,
  InputLabel, Button, Grid, Tooltip
} from '@mui/material';
import {
  AddFilterStack, TableBox, TableHeadCells, TableContainerStack, TablePaginationBox,
  ListContainerStack, ListTitle, ListBody, TextElipsis, IconContainer, ListPaginationBox, DownloadIconContainer
} from './indexStyled';
import { RootState } from 'src/store';
import { useSelector, useDispatch } from "react-redux";
import { SelectedApplication, SelectedRoom,
  RecordingDataList, ClearRecordingData, ClearPaginationData
} from "src/slices/Recording";
import { IconContext } from 'react-icons';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { GrDocumentVideo } from 'react-icons/gr';
import { RiFolderDownloadLine } from 'react-icons/ri';
import { Scrollbars } from "react-custom-scrollbars-2";
import Loader from "src/components/Loader/Loader";
import axios from "axios";
import { FailureSnackbar } from "src/slices/Snackbar";





const DesktopVersion = styled(Box)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`
const MobileVersion = styled(Box)`
  display: none;
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
  }
`

const Recording = () => {

  const dispatch = useDispatch();
  const { applicationData, selectedApplication, selectedRoom,
    buttonVisibility, recordingData, paginationData, showLoader } = useSelector((state: RootState) => state.recordings)
  const [columnSort, setColumnSort] = useState({ dateTime: 'desc' })

  // useEffect(() => {
  //   dispatch(GetAppicationData());
  // }, [dispatch])

  // useEffect(() => {
  //   if (selectedApplication || selectedRoom) {
  //     dispatch(ButtonVisibility(false));
  //   } else {
  //     dispatch(ButtonVisibility(true));
  //   }
  //   // eslint-disable-next-line 
  // }, [selectedApplication, selectedRoom])

  const handleAppIdChange = (ev: SelectChangeEvent) => {
    dispatch(SelectedApplication(ev.target.value));
  }

  const handleRoomIdChange = (event) => {
    dispatch(SelectedRoom(event.target.value));
  }

  const clearFilter = () => {
    dispatch(SelectedApplication(""));
    dispatch(SelectedRoom(""));
    dispatch(ClearRecordingData());
    dispatch(ClearPaginationData());
  }

  const searchRecording = (page = 1, sort = "desc") => {
    const data = {
      appId: selectedApplication,
      roomId: selectedRoom,
      page: page,
      per_page: paginationData.per_page,
      sort_mode: sort
    }
    dispatch(RecordingDataList(data));
  }

  const handlePagination = (value) => {
    searchRecording(parseInt(value));
  }

  const handleSort = (colName, sort) => {
    setColumnSort({ ...columnSort, [colName]: sort });
    searchRecording(1, sort);
  }

  const handleMediaDownload = (data) => {
      axios({
        url: data,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const urlObject = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = urlObject;
        link.setAttribute('download', 'test.mp4');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((error) => dispatch(FailureSnackbar({message: "Failed to download the recording", open: true })))
  }

  return (
    <>
      <DesktopVersion>
        <>
          <Box sx={{ ml: 2, mb: 2 }}>
            <Typography variant="h3">Call Recordings</Typography>
          </Box>

          <TableContainerStack>
            {/* add filter button */}
            <AddFilterStack>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4">Recordings</Typography>
                </Grid>
                <Grid item xs={12} md={6} className="justify_end">
                  <Box>
                    <FormControl sx={{ mr: 2 }} size="small">
                      <InputLabel>Select App ID</InputLabel>
                      <Select
                        label="Select App ID"
                        onChange={handleAppIdChange}
                        sx={{ width: '200px' }}
                        value={selectedApplication}
                      >
                        {applicationData.map((data, index) => <MenuItem key={index} value={data.appId}>{data.appId}</MenuItem>)}
                      </Select>
                    </FormControl>

                    <TextField label="Enter Room ID" variant="outlined" size="small" sx={{ mr: 2 }} value={selectedRoom} onChange={handleRoomIdChange} />
                    <Button variant="contained" sx={{ mr: 2 }} disabled={buttonVisibility} onClick={() => searchRecording()}>Search</Button>
                    <Button variant="contained" disabled={buttonVisibility} onClick={clearFilter}>Clear</Button>
                  </Box>
                </Grid>
              </Grid>


            </AddFilterStack>
            {/* add filter button */}
            <Divider />
            <TableBox>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableHeadCells style={{ width: '13%' }}>ROOM ID</TableHeadCells>
                      <TableHeadCells style={{ width: '25%' }}>APP ID</TableHeadCells>
                      <TableHeadCells style={{ width: '20%' }}>START & END TIME</TableHeadCells>
                      <TableHeadCells style={{ width: '5%' }}>DURATION</TableHeadCells>
                      <TableHeadCells style={{ width: '12%' }}>TITLE</TableHeadCells>
                      <TableHeadCells style={{ width: '20%' }}>
                        <div className="align_center">DATE & TIME

                          {(recordingData && recordingData?.length > 0) && columnSort.dateTime === 'asc' && (
                            <span style={{ marginLeft: "5px", marginTop: "8px", cursor: "pointer"}} >
                              <IconContext.Provider value={{ size: "20" }}>
                                <IoIosArrowUp onClick={() => handleSort("dateTime", "desc")} />
                              </IconContext.Provider>
                            </span>
                          )}
                          {(recordingData && recordingData?.length > 0) && columnSort.dateTime === 'desc' && (
                            <span style={{ marginLeft: "5px", marginTop: "8px", cursor: "pointer" }} >
                              <IconContext.Provider value={{ size: "20" }}>
                                <IoIosArrowDown onClick={() => handleSort("dateTime", "asc")} />
                              </IconContext.Provider>
                            </span>
                          )}
                        </div>
                      </TableHeadCells>
                      <TableHeadCells style={{ width: '5%' }}>URI</TableHeadCells>
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ height: "250px" }}>
                    {showLoader && (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Loader />
                        </TableCell>
                      </TableRow>
                    )}
                    {!showLoader && recordingData?.length > 0 && recordingData.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <span style={{ marginRight: "5px" }}>
                              <IconContainer>
                                <IconContext.Provider value={{ size: "15" }}>
                                  <GrDocumentVideo />
                                </IconContext.Provider>
                              </IconContainer>
                            </span>
                            {row.roomId}
                          </TableCell>
                          <TableCell>{row.appId}</TableCell>
                          <TableCell>
                            {new Date(row.startTime).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} | {new Date(row.startTime).toLocaleTimeString()} <br />
                            {new Date(row.endTime).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} | {new Date(row.endTime).toLocaleTimeString()}
                          </TableCell>
                          <TableCell>{row.duration} MINS</TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell>{new Date(row.createdDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} | {new Date(row.createdDate).toLocaleTimeString()}</TableCell>
                          <TableCell>
                            <div className="justify_center pointer">
                              <Tooltip title="Download Recording Link">
                                <DownloadIconContainer>
                                  <IconContext.Provider value={{ size: "20", color: "#1A75FF" }}>
                                    <RiFolderDownloadLine onClick={() => handleMediaDownload(row.uri)} />
                                  </IconContext.Provider>
                                </DownloadIconContainer>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })
                    }

                    {!showLoader && (!recordingData || !recordingData.length) && (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px', width: '100%' }}>
                            Please select App ID and Room ID
                          </Box>
                        </TableCell>
                      </TableRow>

                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableBox>
            <TablePaginationBox>
              <Pagination
                count={paginationData?.page_count || 0}
                shape="rounded"
                color="primary"
                page={paginationData.page}
                onChange={(event, value) => handlePagination(value)}
              />
            </TablePaginationBox>
          </TableContainerStack>
        </>
      </DesktopVersion>

      <MobileVersion>
        <ListContainerStack>
          <ListTitle>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h3">Recordings</Typography>
              </Grid>
            </Grid>
          </ListTitle>

          <ListBody>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl sx={{ mr: 2, width: '100%' }} size="small">
                  <InputLabel>Select App ID</InputLabel>
                  <Select
                    label="Select App ID"
                    onChange={handleAppIdChange}
                    sx={{ width: '100%' }}
                    value={selectedApplication}
                  >
                    {applicationData.map((data, index) => <MenuItem key={index} value={data.appId}>{data.appId}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField label="Enter Room ID" variant="outlined" size="small" sx={{ mr: 2 }} value={selectedRoom} onChange={handleRoomIdChange} fullWidth />
              </Grid>

              <Grid item xs={4}></Grid>
              <Grid item xs={8} className="justify_end" sx={{ mt: 1.1 }}>
                <Button variant="contained" sx={{ mr: 2 }} disabled={buttonVisibility} onClick={() => searchRecording()}>Search</Button>
                <Button variant="contained" disabled={buttonVisibility} onClick={clearFilter}>Clear</Button>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 1.5, mb: 1.5 }} />
            <Scrollbars style={{ height: 400 }}>
              {(!recordingData || !recordingData.length) && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px', width: '100%' }}>
                  Please select App ID and Room ID
                </Box>
              )}
              {recordingData?.length > 0 && recordingData.map((row, index) => <React.Fragment key={index}>
                <div className="flex_center">
                  <div style={{ width: "20%" }} className="justify_center">
                    <IconContainer>
                      <IconContext.Provider value={{ size: "20" }}>
                        <GrDocumentVideo />
                      </IconContext.Provider>
                    </IconContainer>
                  </div>
                  <div style={{ width: "60%" }}>
                    <TextElipsis title="row.roomId">{row.roomId}</TextElipsis>
                    <TextElipsis title="row.appId" sx={{ fontWeight: "bold" }}>{row.appId}</TextElipsis>
                    <Typography>
                      {new Date(row.startTime).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} | {new Date(row.startTime).toLocaleTimeString()} <br />
                    </Typography>
                    <Typography>
                      {new Date(row.endTime).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} | {new Date(row.endTime).toLocaleTimeString()}
                    </Typography>
                    <Typography>{row.duration} MINS</Typography>
                    <Typography sx={{ fontWeight: "bold" }}>{row.title}</Typography>
                    <Typography sx={{ fontWeight: "500" }}>{new Date(row.createdDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} | {new Date(row.createdDate).toLocaleTimeString()}</Typography>
                  </div>
                  <div style={{ width: "20%" }} className="justify_center pointer">
                    <Tooltip title="Download Recording Link">
                      <DownloadIconContainer>
                        <span onClick={() => handleMediaDownload(row.uri)}>
                          <IconContext.Provider value={{ size: "20", color: "#1A75FF" }}>
                            <RiFolderDownloadLine />
                          </IconContext.Provider>
                        </span>
                      </DownloadIconContainer>
                    </Tooltip>
                  </div>
                </div>
                <Divider sx={{ mt: 1.5, mb: 1.5 }} />
              </React.Fragment>)
              }
            </Scrollbars>

            <ListPaginationBox>
              <Pagination
                count={paginationData?.page_count || 0}
                shape="rounded"
                color="primary"
                page={paginationData.page}
                onChange={(event, value) => handlePagination(value)}
              />
            </ListPaginationBox>
          </ListBody>

        </ListContainerStack>
      </MobileVersion>
    </>
  )
}

export default Recording;