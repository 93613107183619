import React from 'react';
function SignOutIcon({ props }) {
 return (
  <>
   <svg width={props.width} height={props.width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.3252 12.6766H14.9627C15.3377 12.6766 15.6377 12.3766 15.6377 12.0016C15.6377 11.6266 15.3377 11.3266 14.9627 11.3266H5.5502L7.5377 9.37656C7.8002 9.11406 7.8002 8.70156 7.5377 8.43906C7.2752 8.17656 6.8627 8.17656 6.6002 8.43906L3.8252 11.2141C3.6002 11.4391 3.4502 11.7766 3.4502 12.0766C3.4502 12.4141 3.5627 12.7141 3.8252 12.9391L6.6002 15.7141C6.7127 15.8266 6.9002 15.9016 7.0502 15.9016C7.2002 15.9016 7.3877 15.8266 7.5002 15.7141C7.7627 15.4516 7.7627 15.0391 7.5002 14.7766L5.3252 12.6766Z" fill="#223354" />
    <path d="M17.3631 0.488281H12.9756C12.0756 0.488281 11.3256 1.23828 11.3256 2.13828V5.58828C11.3256 5.96328 11.6256 6.26328 12.0006 6.26328C12.3756 6.26328 12.6756 5.96328 12.6756 5.58828V2.10078C12.6756 1.91328 12.8256 1.76328 13.0131 1.76328H17.4006C18.4506 1.76328 19.2756 2.62578 19.2756 3.63828V20.2883C19.2756 21.3383 18.4131 22.1633 17.4006 22.1633H12.9756C12.7881 22.1633 12.6381 22.0133 12.6381 21.8258V18.3758C12.6381 18.0008 12.3381 17.7008 11.9631 17.7008C11.5881 17.7008 11.2881 18.0008 11.2881 18.3758V21.8258C11.2881 22.7258 12.0381 23.4758 12.9381 23.4758H17.3256C19.0881 23.4758 20.5131 22.0508 20.5131 20.2883V3.67578C20.5506 1.91328 19.1256 0.488281 17.3631 0.488281Z" fill="#223354" />
   </svg>
  </>
 );
}

export default SignOutIcon;
